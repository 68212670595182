<template>
    <div class="clipart">
        <img loading="lazy" :src="clipartImage" :title="clipartName" :alt="clipartName"
            @click="addImg(clipartImage, clipartId)" />
        <div class="list-item-btn">
            <div class="btn">
                <div title="Add" @click.stop="addImg(clipartImage, clipartId)">
                    <b class="icon-plus"></b>
                </div>
            </div>
            <div class="btn">
                <div title="download" @click.stop="downloadImg(clipartImage, clipartId)">
                    <i class="el-icon-download"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {newDownFile ,replacePicPrefix} from "@/utils/utils";
export default {
    // isCollection: 1收藏
    // checked: 1显示复选框,0隐藏复选框

    props: ['item', 'clipartImage', 'isCollection', 'checked','clipartName','clipartId'],
    data() {
        return {

        }
    },
    computed: {
        lang() {
            return this.$store.getters.lang?.design;
        },
		isLogin() {
			return this.$store.getters.isLogin;
		}
    },
    methods: {
        addImg(src,property) {
            this.$emit('addImg',{
                src,
                property
            })
        },
        addArtFavorite(item) {
			if (!this.isLogin) {
				this.$store.commit('setMask', 'login')
				return false;
			}
            this.$emit('addArtFavorite', item)
        },
        clipartChecked() {
            this.$emit('clipartChecked')
        },
        returnName (item) {
         let name = item.substring(item.lastIndexOf("/") + 1)
         return name
        },
        downloadImg(src,property){
            newDownFile(replacePicPrefix(src), this.returnName(src))
        }
    }
}
</script>

<style scoped lang="scss">
.clipart {
    aspect-ratio: 1;
    padding: 10px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .checkbox {
        position: absolute;
        top: 0;
        right: 5px;
    }

    .list-item-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.25);
        transition: all 0.3s;
        opacity: 0;

        .btn {
            display: flex;

            &>div {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 4px;
                width: 48px;
                height: 48px;
                background: #2A96FA;
                border-radius: 50%;
                border: none;

                b,i {
                    color: #ffffff;
                    font-size: 24px;
                }
            }

            &>div:last-child {
                background-color: #ce3d3a;
            }
        }

        .btn-Text {
            margin-top: 10px;
            padding: 5px 10px;
            background: #FFFFFF;
            border-radius: 4px;
            font-size: 14px;
            border: 1px solid #666666;
        }

        &:hover {
            opacity: 1;
        }
    }

    .lovebtnMB {
        display: none;
    }
}
</style>
