<template>
	<div class="templateList" v-show="showTemplateList" @click.stop>
		<div class="listWrap" scrollbar v-loadmore="loadTemplate" :infinite-scroll-disabled="disabledLoadTemplate"
		:class="{'style5':styleConfiguration == 5,'style3':styleConfiguration == 3}">
			<div class="list-item" v-for="item in templateList" :key="item.id" :title="item.templateName">
				<templates
						:item="item"
						:templateImg="item.templateImg"
						:isFavourite="item.isFavourite"
						@selectTemplate="selectTemplate"
					>
					</templates>
			</div>
		</div>
		<div class="loadMore" v-show="loadingTemplate">
			loading...
		</div>
	</div>
</template>

<script>
import { getTemplateListFormApp, getTemplateById } from "@/api/newDzxt";
import dzMixin from "@/mixins/dzMixin";
import templates from "@/components/MyDzxt/templates.vue";

export default {
	mixins: [dzMixin],
	data() {
		return {
			loadingTemplate: false,
			templateForm: {
				page: 1,
				pageSize: 60,
				templateTypeId: null,
				total: 0,
				pages: 1,
				searchWord: ""
			},
			templateList: [],
			recentTemplateList: []
		};
	},
	components: {
		templates
	},
	watch: {
		isLogin() {
			this.getTemplateList();
		}
	},
	computed: {
		showTemplateList() {
			return this.$store.state.design.showTemplateList;
		},
		noMoreTemplate() {
			return this.templateForm.page >= this.templateForm.pages;
		},
		disabledLoadTemplate() {
			return this.loadingTemplate || this.noMoreTemplate;
		},
		status() {
			return this.$store.state.design.status;
		},
		isLogin() {
			return this.$store.getters.isLogin;
		},
		userId() {
			return this.$store.state.userInfo?.id;
		},
		styleConfiguration() {
			return this.$store.state.design.pageInfo.styleConfiguration;
		}
	},
	methods: {
		loadTemplate() {
			this.templateForm.page++;
			this.getTemplateList("scroll");
		},
		async selectTemplate(item) {
			this.canvas.templateName = item.templateName;
			let loadData = () => {
				this.$store.commit('design/set_loading', true);
				this.$Bus.$emit('showDefault');
				getTemplateById({
					id: item.id,
					language: "en-us"
				})
					.then(async res => {
						//判断是否是光板
						if (res.data.isPaintless === 1) {
							await this.canvas.replaceBase(res.data.templateFile);
						} else {
							if(this.canvas.isSticker){
								let specification = JSON.parse(res.data.specification)
								this.canvas.stickerSize = {
									w:specification.width,
									h:specification.height
								}
							}
							await this.canvas.loadTemplate(res.data.templateFile);
						}
						this.$store.commit('design/set_loading', false);
					})
					.catch(err => {
						this.$store.commit('design/set_loading', false);
					});
			};
			if (this.canvas.isEmptyCanvas()) {
				loadData();
        this.$emit('templateId',item.id)
			} else {
				this.$confirm("Are you sure you want to replace your design with our template?", "Hint", {
					confirmButtonText: "Confirm",
					cancelButtonText: "Cancel",
					type: "warning"
				})
					.then(() => {
            this.$emit('templateId',item.id)
						loadData();
					})
					.catch(() => {});
			}
		},
		getTemplateList(type = "select") {
			this.loadingTemplate = true;
			getTemplateListFormApp(
				Object.assign({}, this.templateForm, {
					categoryId: this.$store.state.design?.pageInfo?.id,
					userId: this.userId,
					proId: this.$store.state.design.defaultProId,
					language: "en-us"
				})
			)
				.then(res => {
					if (res.data) {
						if (type === "scroll") {
							this.templateList = this.templateList.concat(res.data.records);
							this.templateForm.total = res.data.total;
							this.templateForm.pages = res.data.pages;
						} else {
							this.templateList = res.data.records;
							this.templateForm.total = res.data.total;
							this.templateForm.pages = res.data.pages;
						}
					} else {
						this.templateList = [];
						this.templateForm.total = 0;
						this.templateForm.pages = 0;
					}
				})
				.finally(() => {
					this.loadingTemplate = false;
				});
		},
		selectTemplateCategory(item) {
			this.templateList = [];
			this.templateForm.page = 1;
			this.templateForm.templateTypeId = item.id;
			this.templateForm.searchWord = "";
			this.getTemplateList();
		},
		searchTemplate(val) {
			this.templateList = [];
			this.templateForm.page = 1;
			this.templateForm.templateTypeId = null;
			this.templateForm.searchWord = val;
			this.getTemplateList();
		}
	},
	mounted() {
		this.getTemplateList();
		this.$Bus.$on("selectTemplateCategory", this.selectTemplateCategory);
		this.$Bus.$on("searchTemplate", this.searchTemplate);
	},
	beforeDestroy() {
		this.$Bus.$off("selectTemplateCategory", this.selectTemplateCategory);
		this.$Bus.$off("searchTemplate", this.searchTemplate);
	}
};
</script>

<style scoped lang="scss">
@import "@/assets/css/dzxt_theme";

.templateList {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 10px 30px;
	background-color: #e6e6e6;
	z-index: 2;

	.loadMore {
		position: absolute;
		left: 50%;
		bottom: 5px;
		transform: translateX(-50%);
		text-align: center;
	}

	.recent {
		margin-bottom: 10px;
		height: auto;
		background-color: #ffffff;

		.recent-title {
			padding: 10px 20px 0;
		}
	}

	.listWrap {
		display: grid;
		align-content: flex-start;
		overflow: hidden auto;
		padding: 20px;
		flex: 1;
		height: 0;
		background-color: #ffffff;

		&.style5{
			grid-template-columns: repeat(5,1fr);
			.list-item{
				aspect-ratio: 1;
			}
		}
		&.style3{
			grid-template-columns: repeat(3,1fr);
		}
	}

	.listWrap.recentList {
		height: auto;
	}
}

.templateList {
	.listWrap {
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 20px;
		grid-row-gap: 20px;

		.list-item {
			aspect-ratio: 382/215;
		}
	}
}
</style>
