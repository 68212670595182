<template>
  <div class="marketingWrap abFixed">
    <div class="tab">
      <div
          class="tab-item"
          :class="{ active: $route.path.indexOf(item.name) > -1 }"
          v-for="(item, index) in list"
          :active-name="sidebarItem"
          :key="index"
          v-show="item.show"
          @click="changeTab(item)"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="tabContent">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import {hasRoute} from "@/utils/utils";

export default {
  data() {
    return {
      sidebarItem: "",
      list: [
        {
          title: "标签库",
          name: "tagLibrary"
        },
        {
          title: "模板库",
          name: "templateLibrary"
        },
        {
          title: "元素库",
          name: "categoryLibrary"
        }
      ]
    };
  },
  methods: {
    changeTab(item) {
      this.sidebarItem = item.name;
      this.$router.push({
        name: item.name
      });
      sessionStorage.removeItem('templateData')
    }
  },
  created() {
    let routes = this.$store.state.permission.routes
    this.list.forEach(item=>{
      if(hasRoute(item.name,routes)){
        item.show = true;
      }
    })
  }
};
</script>

<style scoped lang="scss">
.marketingWrap {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .tab {
    display: flex;
    height: 60px;
    padding: 0 40px;
    border-bottom: 3px solid #d8d8d8;
    font-size: 18px;
    font-weight: 700;

    .tab-item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      margin-right: 40px;
      border-bottom: 3px solid transparent;
      transition: 0.3s;
      cursor: pointer;
      user-select: none;

      &:last-child {
        margin-right: 0;
      }

      &:hover,
      &.active {
        color: $color-primary;
        border-bottom: 3px solid $color-primary;
      }
    }
  }

  .tabContent {
    overflow: hidden;
    flex: 1;
    height: 0;
  }
}
</style>