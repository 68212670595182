<template>
	<div class="base-dialog modal" :id="id" v-show="isOpen" @click.self="close">
		<div class="base-dialog-model-con">
			<video :src="videoUrl" autoplay ref="video" controls></video>
		</div>
        <div class="close" @click.stop="close">
            <span>&times;</span>
        </div>
	</div>
</template>

<script>
export default {
	props: {
		videoUrl: {
			type: String,
			required: true
		},
		id: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			isOpen: false
		};
	},
	methods: {
		open() {
			this.isOpen = true;
		},
		close() {
			this.isOpen = false;
			this.$refs.video.pause(); // 停止视频播放
			this.$refs.video.currentTime = 0; // 重置视频播放位置
			const element = document.getElementById(this.id);
			element.parentNode.removeChild(element);
		}
	}
};
</script>

<style scoped lang="scss">
.base-dialog {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2000;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	overflow: auto;
	white-space: nowrap;
	text-align: center;

	&:after {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

    .close {
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-app-region: no-drag;
        background-color: rgba(0, 0, 0, 50%);
        border-radius: 50%;
        cursor: pointer;
        height: 80px;
        overflow: hidden;
        position: absolute;
        right: 40px;
        top: 40px;
        transition: background-color 0.15s;
        width: 80px;
        font-size: 60px;
        color: #ffffff;
        &:hover{
            background-color: #000000;
        }
    }

	.base-dialog-model-con {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		margin: 0 auto;
		text-align: left;
		white-space: normal;
        max-width: 90vw;
        max-height: 90vh;

        video{
            max-width: 90vw;
            max-height: 90vh;
        }

		@media screen and (max-width: 767px) {
			.el-icon-close {
				width: 24px;
				height: 24px;
				font-size: 14px;
			}
		}
	}
}
</style>