const state = {
	isDefault: true,
	showTemplateList: false,
	showArt: false,
	colorList: [],
	colorType:[],
	loading: false,
	pageInfo: "",
	quoteNameAndUrl: {
		"Lapel Pins": "/quote/pins-quote"
	},
	positionConfigs: [],
	showReplaceDialog: false,
	defaultProId: 358,
	designDialog:false
};

const mutations = {
	set_showTemplate(state, data) {
		state.showTemplateList = data;
	},
	set_showArt(state, data) {
		state.showArt = data;
	},
	set_isDefault(state, data) {
		state.isDefault = data;
	},
	set_colorList(state, data) {
		state.colorList = data;
	},
	set_colorType (state, data) {
		state.colorType = data;
	},
	set_loading(state, data) {
		state.loading = data;
	},
	set_pageInfo(state, data) {
		state.pageInfo = data;
	},
	set_position(state, data) {
		state.positionConfigs = data;
	},
	set_Replace(state, data) {
		state.showReplaceDialog = data;
	},
	set_designDialog(state,data){
		state.designDialog = data;
	}
};

const getters = {
	fontList() {
		let arr = ["Arial", "Century Gothic", "Comic Sans MS", "Times New Roman", "Courier New", "Georgia", "Impact", "Hanalei Fill", "Oswald", "MedievalSharp", "Ruslan Display", "Ranga", "Lobster Two", "Pacifico", "Dancing Script", "Permanent Marker", "Great Vibes", "Roboto", "Timmana", "Anton", "Fjalla One", "Acme", "Francois One", "Palanquin Dark", "Righteous", "Concert One", "Courgette", "Kaushan Script", "Fredoka One", "Passion One", "Oleo Script", "Boogaloo", "Amaranth", "Sigmar One", "Chewy", "Fugaz One", "Viga"];
		arr.sort((a, b) => a.localeCompare(b));
		return arr;
	},
	getQuoteUrl(state) {
		return state.quoteNameAndUrl[state.pageInfo.quoteCateName];
	}
};

const actions = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
