import request from '@/utils/request'

// 获取类目模版列表
export function list(data) {
    return request({
        url: '/design/category/list',
        method: 'get',
        params: data,
    })
}

// 获取待配置模版父类
export function getTemplateTypeListWithLevelOne(data) {
    return request({
        url: '/design/template/getTemplateTypeListWithLevelOne',
        method: 'get',
        params: data,
    })
}

// 获取已保存的类目模版
export function getCategoryTemplateConfigById(data) {
    return request({
        url: '/design/category/getCategoryTemplateConfigById',
        method: 'get',
        params: data,
    })
}


// 新增、编辑类目模版配置
export function updateCategoryTemplateConfig(data) {
    return request({
        url: '/design/category/updateCategoryTemplateConfig',
        method: 'post',
        data,
    })
}

//移除
export function delCategoryTemplateConfigById(id) {
    return request({
        url: '/design/category/delCategoryTemplateConfigById?id='+id,
        method: 'get',
        params:id,
    })
}