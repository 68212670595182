<template>
	<div class="bottom-area">
		<div class="bottom-container">
			<div class="stickerSize" v-if="canvas.isSticker">
				<div>Size:</div>
				<div class="size">
					<el-popover placement="top-start" popper-class="stickerSizeToolTip" effect="light" width="117" trigger="manual" content="This field is required" v-model="sizeWError">
						<input type="text" slot="reference" v-model="canvas.stickerSize.w" class="sizeInput" :class="{ isError: sizeWError }" @input="changeSize('w')" />
					</el-popover>
					<span class="c">X</span>
					<el-popover placement="top-start" popper-class="stickerSizeToolTip" effect="light" width="117" trigger="manual" content="This field is required" v-model="sizeHError">
						<input type="text" slot="reference" v-model="canvas.stickerSize.h" class="sizeInput" :class="{ isError: sizeHError }" @input="changeSize('h')" />
					</el-popover>
					<el-dropdown trigger="click" @command="handleStickerSizeCommand">
						<div class="sizeSelect">
							<b class="icon-down"></b>
						</div>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item :command="{ w: 1, h: 1 }">1” x 1”</el-dropdown-item>
							<el-dropdown-item :command="{ w: 2, h: 2 }">2” x 2”</el-dropdown-item>
							<el-dropdown-item :command="{ w: 3, h: 3 }">3” x 3”</el-dropdown-item>
							<el-dropdown-item :command="{ w: 4, h: 4 }">4” x 4”</el-dropdown-item>
							<el-dropdown-item :command="{ w: 5, h: 5 }">5” x 5”</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
			<template v-if="designData.relcache">
				<el-button type="primary" plain size="large" @click="cacheTemplate(1)" :loading="loadingCache">
					缓存模板
				</el-button>
				<el-button type="primary" size="large" @click="saveTemplate" :loading="loadingUpload">上传模板 </el-button>
				<el-button type="primary" style="position: absolute;right: 100px;" size="large" @click="saveUserTemplate" :loading="loadingUpload" v-if="myDesignShow">保存至用户模板 </el-button>
			</template>
			<template v-else>
				<el-button type="primary" plain size="large" @click="cacheTemplate(2)" :loading="loadingCache">
					缓存模板
				</el-button>
				<el-button type="primary" size="large" @click="saveTemplate" :loading="loadingCache">保存模板 </el-button>
				<el-button type="primary" style="position: absolute;right: 100px;" size="large" @click="saveUserTemplate" :loading="loadingUpload" v-if="myDesignShow">保存至用户模板 </el-button>
			</template>
			<template>
				<el-button type="primary" size="large" @click="copyTemplate" :loading="loadingCopy">
					复制模板
				</el-button>
				<el-button type="primary" size="large" @click="stickTemplate" :loading="loadingStick">
					粘贴模板
				</el-button>
			</template>
		</div>
		<!-- 新增编辑弹窗 -->
		<el-dialog :title="title" append-to-body :visible.sync="dialogVisible" :close-on-click-modal="false" width="70%" class="el_dialog" :before-close="handleClose('ruleForm')">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="" prop="resource">
					<el-radio-group v-model="ruleForm.resource">
						<el-radio label="添加新模板" :value="1"></el-radio>
						<el-radio label="更新模板" :value="2"></el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="Templates Name：" prop="templateName" label-width="200px">
					<el-input placeholder="Enter Templates Name" v-model="ruleForm.templateName" @blur="changeUrl"></el-input>
				</el-form-item>
				<el-form-item label="Seo Ranking：" prop="seo" label-width="200px" class="switch1">
					<el-switch :disabled="modelState == 3" :active-value="1" :inactive-value="0" v-model="ruleForm.seo" active-text="Enabeld" inactive-text="Disabled" class="operation"></el-switch>
				</el-form-item>
				<el-form-item v-if="ruleForm.seo == 1" label="SEO Title：" prop="seoTitle" label-width="200px">
					<el-input placeholder="Enter SEO title" v-model="ruleForm.seoTitle"></el-input>
				</el-form-item>
				<el-form-item v-if="ruleForm.seo == 1" label="SEO Descriptions：" prop="description" label-width="200px">
					<el-input placeholder="Enter SEO Descriptions" type="textarea" :rows="2" v-model="ruleForm.description"></el-input>
				</el-form-item>
				<el-form-item v-if="ruleForm.seo == 1" label="Templates URL：" prop="templatesUrl" label-width="200px">
					<el-input v-model="ruleForm.templatesUrl" disabled>
						<template slot="prepend">/templates/</template>
					</el-input>
				</el-form-item>
				<el-form-item v-if="ruleForm.seo" label="Templates NO：" prop="templatesNo" label-width="200px">
					<el-input placeholder="Enter Templates No" v-model="ruleForm.templatesNo"></el-input>
				</el-form-item>
				<el-form-item label="Choose Template Types：" prop="seoType" label-width="200px">
					<div style="width:100%">
						<el-cascader
							:filter-method="dataFilter"
							style="width:100%"
							v-model="ruleForm.templateType"
							filterable
							:options="allTypeList"
							:props="{
								checkStrictly: true,
								label: 'templateTypeName',
								value: 'id',
								multiple: true
							}"
							clearable
						></el-cascader>
					</div>
				</el-form-item>
				<el-form-item label="Choose Keywords Label：" prop="seoType" label-width="200px">
					<div style="width:100%">
						<el-cascader
							style="width:100%"
							:filter-method="dataFilter"
							v-model="ruleForm.keyword"
							filterable
							:options="labelList"
							:show-all-levels="false"
							:props="{
								multiple: true,
								label: 'labelTypeName',
								value: 'id',
								children: 'labelsList'
							}"
							clearable
						></el-cascader>
					</div>
				</el-form-item>
				<el-form-item label="显示限制：" prop="serialNumber" label-width="200px" class="switch2">
					<el-switch v-model="ruleForm.displayControl" :active-value="1" :inactive-value="0" active-text="启用" inactive-text="停用" class="operation"></el-switch>
				</el-form-item>
				<el-form-item label="展示平台类型限制：" label-width="200px" prop="platform" v-if="ruleForm.displayControl == '1'">
					<selectCheckBox :options1="platformOptions" @getSelected="getPlatformList" :selected="platform" :isPlatform="true"></selectCheckBox>
				</el-form-item>
				<el-form-item label="展示网站域名限制：" label-width="200px" prop="domain" v-if="ruleForm.displayControl == '1'">
					<selectCheckBox :options1="domainName" @getSelected="getdomainNameList" :selected="webSite"></selectCheckBox>
				</el-form-item>
				<el-form-item label="展示语言限制：" label-width="200px" prop="lanuage" v-if="ruleForm.displayControl == '1'">
					<selectCheckBox :options1="lanuageOptions" @getSelected="getLanuageList" :showImg="true" :selected="languages"></selectCheckBox>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" v-if="ruleForm.seo == 1" :loading="loadingUpload" @click="addLabelSave('ruleForm')">发 布</el-button>
				<el-button type="primary" v-else :loading="loadingUpload" @click="addLabelSave('ruleForm')">保 存</el-button>
				<el-button @click="cellLabelSave('ruleForm')">取 消</el-button>
			</span>
		</el-dialog>
		<!-- 根据邮箱保存至用户模板 -->
		<el-dialog title="保存用户模板" append-to-body :before-close="handleCloseDialogUserTemplate" :visible.sync="dialogUserTemplateVisible" width="40%">
			<div style="width:100%;height:60px;">
				用户邮箱：
				<el-input style="width:80%;" placeholder="请输入用户邮箱" v-model="emailValue" clearable> </el-input>
			</div>
			<div style="width:100%;height:60px;">
				模板名称：
				<el-input style="width:80%;" placeholder="请输入模板名称" v-model="templateName" clearable> </el-input>
			</div>
			<div class="block">
				选择平台：
				<el-cascader v-model="proId" :props="{ emitPath: false, value: 'id', label: 'proName' }" :options="options"></el-cascader>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleCloseDialogUserTemplate">取 消</el-button>
				<el-button type="primary" @click="saveUserTemplateForRequest">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import { getList, stagingTemplate, editUserTemplatesAndSave } from "@/api/newDzxt";
import dzMixin from "@/mixins/dzMixin";
import { getSystemProjectList, getLanguageByProId, getLabelsType, sureSaveTemplate, getAllTemplateTypeList } from "@/api/systemManagement/templateManagement";
import TabCommunication from "@/assets/js/broadcastChannel";
import { getTemplateById } from "@/api/systemManagement/templateManagement";

const TabCommunication1 = new TabCommunication("test");

export default {
	data() {
		return {
			myDesignShow: true,
			loadingStick: false,
			loadingCopy: false,
			loadingCache: false,
			loadingUpload: false,
			dialogVisible: false,
			dialogUserTemplateVisible: false,
			emailValue: "",
			templateName: "",
			proId: "",
			options: [],
			ruleForm: {
				seo: 0,
				templateName: "",
				seoTitle: "",
				description: "",
				templatesUrl: "",
				templatesNo: "",
				templateType: [],
				keyword: [],
				according: false,
				platform: [],
				domainName: [],
				lanuage: [],
				domain: [],
				displayControl: 0,
				resource: "添加新模板"
			},
			rules: {
				templateName: [{ required: true, message: "Enter Templates Name", trigger: "blur" }],
				seoTitle: [{ required: true, message: "Enter SEO title", trigger: "blur" }],
				description: [{ required: true, message: "Enter SEO Descriptions", trigger: "blur" }],
				templatesUrl: [{ required: true, message: "Enter Templates URL", trigger: "blur" }],
				templatesNo: [{ required: true, message: "Enter templatesNo", trigger: "blur" }],
				platform: [{ required: true, message: "请选择平台类型限制", trigger: "blur" }],
				domain: [{ required: true, message: "请选择网站域名限制", trigger: "blur" }],
				lanuage: [{ required: true, message: "请选择展示语言限制", trigger: "blur" }],
				resource: [{ required: true, message: "请选择新增还是修改", trigger: "change" }]
			},
			title: "Add more information for template",
			platform: [],
			webSite: [],
			languages: [],
			domainName: [], // 网站域名
			modelState: undefined,
			lanuageOptions: [], // 语言限制
			platformOptions: [
				{
					id: 0,
					name: "inquiryPage.dealeSelfOperatedPlatformrs"
				},
				{
					id: 1,
					name: "inquiryPage.dealer"
				},
				{
					id: 2,
					name: "inquiryPage.allianceBusiness"
				}
			], // 平台下拉框可选项
			webOptions: [],
			clipartImg: [],
			labelList: [],
			clipartTypeList: [],
			openLog: false,
			logList: [],
			allTypeList: [],
			options1: [
				{
					value: 1,
					label: "模板缓存草稿"
				},
				{
					value: 2,
					label: "已上传模板"
				},
				{
					value: 3,
					label: "已发布模板"
				},
				{
					value: 4,
					label: "已停用模板"
				}
			],
			editItem: this.designData.tId ? { id: $route.query.tId } : null,
			cacheId: this.designData.tId ? this.designData.tId : null,
			sizeWError: false,
			sizeHError: false
		};
	},
	props: {
		designData: {
			type: Object,
			default: {}
		},
		templateId: {
			type: Number,
			default: undefined
		}
	},
	components: {
		selectCheckBox: () => import("../../views/commodityManagement/designCenter/templateManagement/components/selectCheckBox.vue")
	},
	mixins: [dzMixin],
	computed: {
		isEdit() {
			return this.designData.tId;
		},
		loading() {
			return this.$store.state.design.loading;
		}
	},
	watch: {
		templateId: {
			handler(newVal) {
				console.log(newVal);
				this.editBox(newVal);
			}
		}
	},
	methods: {
		//复制模板
		async copyTemplate() {
			this.loadingCopy = true;
			let templateImg = this.canvas.copyTemplateData();
			let canvas = localStorage.setItem("canvasImg", JSON.stringify(templateImg));
			if (canvas !== null) {
				this.loadingCopy = false;
				this.$message.success("复制成功");
			} else {
				this.$message.success("复制失败");
			}
		},
		//粘贴模板
		stickTemplate() {
			this.loadingStick = true;
			let cc = this.canvas.pasteTemplateData(localStorage.getItem("canvasImg"));
			if (cc !== null) {
				this.loadingStick = false;
			}
		},
		checkStickerSize() {
			let w = parseFloat(this.canvas.stickerSize.w);
			let h = parseFloat(this.canvas.stickerSize.h);
			this.sizeWError = isNaN(w) || typeof w !== "number" || w <= 0;
			this.sizeHError = isNaN(h) || typeof h !== "number" || h <= 0;
		},
		changeSize(type) {
			this.canvas.stickerSize[type] = this.canvas.stickerSize[type].replace(/[^0-9.]/g, "");
			this.checkStickerSize();
			if (this.sizeWError || this.sizeHError) {
				return false;
			}
			this.canvas.initStickerShape();
		},
		handleStickerSizeCommand(command) {
			this.canvas.stickerSize.w = command.w.toString();
			this.changeSize("w");
			this.canvas.stickerSize.h = command.h.toString();
			this.changeSize("h");
		},
		editBox(id) {
			console.log(id, 999999);
			getTemplateById({ id: id, language: "en-us" }).then(res => {
				// console.log(res);
				this.ruleForm = {
					seo: res.data.seoState,
					templateName: res.data.templateName,
					seoTitle: res.data.seoTitle,
					description: res.data.seoDescription,
					templatesUrl: res.data.templateUrl == "" || !res.data.templateUrl ? "" : res.data.templateUrl.split("/")[2],
					templateType: [],
					keyword: [],
					// according: res.data,
					platform: [],
					domainName: [],
					lanuage: [],
					domain: [],
					displayControl: res.data.displayControl,
					templatesNo: res.data.templateCode,
					resource: "添加新模板"
				};
				if (this.ruleForm.templatesUrl == "") {
					this.changeUrl();
				}
				// 回显Types
				let list = [];
				if (res.data.types && res.data.types != "") {
					res.data.types.split(",").forEach((x, xndex) => {
						this.allTypeList.forEach(a => {
							if (a.id == x) {
								list.push([a.id]);
							}
							if (a.children && a.children.length > 0) {
								a.children.forEach(s => {
									if (s.id == x) {
										list.push([a.id, s.id]);
									}
									if (s.children && s.children.length > 0) {
										s.children.forEach(i => {
											if (i.id == x) {
												list.push([a.id, s.id, i.id]);
											}
										});
									}
								});
							}
						});
					});
				}
				this.ruleForm.templateType = list;

				// 回显Label
				let list1 = [];
				if (res.data.labels && res.data.labels != "") {
					res.data.labels.split(",").forEach((x, xndex) => {
						this.labelList.forEach(a => {
							if (a.id == x) {
								list1.push([a.id]);
							}
							a.labelsList.forEach(s => {
								if (s.id == x) {
									list1.push([a.id, s.id]);
								}
							});
						});
					});
				}
				this.ruleForm.keyword = list1;
				// 展示语言
				this.languages = [];
				this.ruleForm.lanuage = [];
				// 展示网站
				this.webSite = [];
				this.ruleForm.domain = [];
				// 展示平台clipartPlatformList
				this.platform = [];
				let platformList = [];
				this.ruleForm.platform = [];
				if (res.data.displayControl == 1) {
					res.data.languages.forEach(x => {
						this.ruleForm.lanuage.push({
							id: x.languageId,
							name: x.language
						});
						this.languages.push(x.language);
					});
					res.data.domains.forEach(x => {
						this.webSite.push(x.domainUrl);
						this.ruleForm.domain.push({
							id: x.proId,
							name: x.domainUrl
						});
					});
					res.data.platforms.forEach(x => {
						this.platform.push(x.proName);
						platformList.push({
							id: x.proType,
							name: x.proName
						});
					});
					this.ruleForm.platform = platformList;
				}
			});
		},
		getAllTemplateTypeList() {
			getAllTemplateTypeList({ language: "en-us" }).then(res => {
				this.allTypeList = res.data;
			});
		},
		async cacheTemplate(num) {
			if (this.canvas.isEmptyCanvas()) {
				this.$message.warning("画布是空的~~~");
				return false;
			}
			let specification = null;
			if (this.canvas.isSticker) {
				specification = JSON.stringify({
					width: this.canvas.stickerSize.w,
					height: this.canvas.stickerSize.h
				});
				if (this.sizeWError || this.sizeHError) {
					this.$message.error("尺寸格式错误");
					return false;
				}
			}
			this.loadingCache = true;
			let templateFile = this.canvas.getTemplateData();
			let templateImg = await this.canvas.getTemplatePicPath();
			stagingTemplate({
				id: num == 2 || num == 1 ? 0 : this.designData.tid || 0,
				types: this.designData.cId,
				templateFile: JSON.stringify(templateFile),
				specification,
				templateImg
			})
				.then(res => {
					if (res.code == "200") {
						setTimeout(() => {
							this.cacheId = res.data.id;
							TabCommunication1.sendMessage(
								JSON.stringify({
									type: "cache",
									id: res.data.id
								})
							);
							if (this.designData.tid && this.designData.relcache) {
								this.$message.success("缓存更新成功");
							} else if (this.designData.tid && !this.designData.relcache) {
								this.$message.success("更新成功");
							} else {
								this.$message.success("缓存成功");
							}
							if (num != 1 && num != 2) {
								this.$emit("closeDialog");
							}
							this.loadingCache = false;
						}, 5000);
					}
				})
				.finally(() => {
					// setTimeout(() => {
					//   this.$router.push({
					//     path: "/commodityManagement/systemManagement/templateManagement/templateLibrary",
					//     query: {
					//       active: 1,
					//       DefaultId: this.designData.cId,
					//       categoryId: this.designData.categoryId
					//     }
					//   })
					// }, 300)
				});
		},
		handleCloseDialogUserTemplate() {
			this.emailValue = "";
			this.templateName = "";
			this.proId = "";
			this.dialogUserTemplateVisible = false;
		},
		async saveTemplate() {
			if (this.canvas.isEmptyCanvas()) {
				this.$message.warning("画布是空的~~~");
				return false;
			}
			this.title = "Add more information for template";
			// this.loadingUpload = true;
			// this.loadingUpload = false;
			this.dialogVisible = true;
		},
		async saveUserTemplate() {
			if (this.canvas.isEmptyCanvas()) {
				this.$message.warning("画布是空的~~~");
				return false;
			}
			// this.loadingUpload = true;
			// this.loadingUpload = false;
			this.dialogUserTemplateVisible = true;
		},
		async saveUserTemplateForRequest() {
			if (this.emailValue && this.templateName && this.proId) {
				let specification = null;
				if (this.canvas.isSticker) {
					specification = JSON.stringify({
						width: this.canvas.stickerSize.w,
						height: this.canvas.stickerSize.h
					});
					if (this.sizeWError || this.sizeHError) {
						this.$message.error("尺寸格式错误");
						return false;
					}
				}
				let templateFile = this.canvas.getTemplateData();
				let templateImg = await this.canvas.getTemplatePicPath();
				let data = {
					categoryId: this.$store.state.design?.pageInfo?.id,
					templateName: this.templateName,
					userEmail: this.emailValue,
					proId: this.proId,
					specification: specification,
					templateFile: JSON.stringify(templateFile), // 模板文件
					templateUrl: templateImg
				};
				await editUserTemplatesAndSave(data).then(res => {
					if (res.code == "200") {
						this.$message.success("保存成功！");
					} else if (res.code == "500") {
						this.$message.error("保存失败！请检查输入是否正确！");
					}
					this.handleCloseDialogUserTemplate();
				});
			} else {
				this.$message.error("邮箱和模板名称以及平台皆不能为空！");
			}
		},
		handleClose(ruleForm) {
			// this.dialogVisible = false
		},
		changeUrl() {
			let str = JSON.parse(JSON.stringify(this.ruleForm.templateName));
			console.log(str);
			let special_chars = /[!#$%^&*()_+|}{":?><~`\[\]\';\/@\\]/g;
			let clean_str = str
				.replace(special_chars, "")
				.replace(/\s+/g, "-")
				.toLowerCase();
			this.ruleForm.templatesUrl = clean_str;
		},
		cellLabelSave(formName) {
			this.dialogVisible = false;
			this.loadingCache = false;
			if (this.templateId) {
				this.editBox(this.templateId);
			} else {
				this.$refs[formName].resetFields();
				this.clearRuleForm();
			}
		},
		// 新增、编辑标签保存
		async addLabelSave(formName) {
			let specification = null;
			if (this.canvas.isSticker) {
				specification = JSON.stringify({
					width: this.canvas.stickerSize.w,
					height: this.canvas.stickerSize.h
				});
				if (this.sizeWError || this.sizeHError) {
					this.$message.error("尺寸格式错误");
					return false;
				}
			}
			this.loadingUpload = true;
			let id = this.templateId;
			let templateFile = this.canvas.getTemplateData();
			let templateImg = await this.canvas.getTemplatePicPath();
			this.$refs[formName].validate(valid => {
				if (valid) {
					setTimeout(() => {
						this.loadingUpload = false;
					}, 100);
					if (this.ruleForm.resource == "添加新模板") {
						id = null;
					} else if (this.ruleForm.resource == "更新模板") {
						id = id;
					}
					let data = {
						id: id,
						templateName: this.ruleForm.templateName,
						templateCode: this.ruleForm.templatesNo,
						language: "en-us",
						templateFile: JSON.stringify(templateFile), // 模板文件
						templateImg: templateImg, // 模板图片
						seoState: this.ruleForm.seo,
						seoTitle: this.ruleForm.seoTitle,
						seoDescription: this.ruleForm.description,
						templateUrl: "/templates/" + this.ruleForm.templatesUrl,
						types: "", // 模板类型
						labels: "", // 标签id
						displayControl: this.ruleForm.displayControl,
						platforms: [],
						domains: [],
						languages: [],
						specification,
						state: this.ruleForm.seo == 1 ? 3 : 2
					};
					// 模板类型
					let list = [];
					let a = [];
					console.log(this.ruleForm.templateType);
					if (this.ruleForm.templateType.length > 0) {
						this.ruleForm.templateType.forEach(x => {
							// list.push(...x)
							a.push(x[x.length - 1]);
						});
					} else {
						a = Number(this.designData.cId);
					}
					data.types = a.toString();
					// 标签id
					let list1 = [];
					if (this.ruleForm.keyword.length > 0) {
						this.ruleForm.keyword.forEach(x => {
							list1.push(...x);
						});
					}
					let b = [...new Set(list1)];
					data.labels = b.toString();
					// 平台
					let list2 = [];
					this.ruleForm.platform.forEach(x => {
						list2.push({
							proType: x.id,
							proName: x.name
						});
					});
					data.platforms = list2;
					// 网站
					let list3 = [];
					this.ruleForm.domain.forEach(x => {
						list3.push({
							proId: x.id,
							domainUrl: x.name
						});
					});
					data.domains = list3;
					// 语言
					let list4 = [];
					this.ruleForm.lanuage.forEach(x => {
						list4.push({
							languageId: x.id,
							language: x.name
						});
					});
					data.languages = list4;
					console.log(data);
					sureSaveTemplate(data).then(res => {
                        if (this.ruleForm.soe == 1) {
                            this.$message.success("发布成功");
                        } else if (this.ruleForm.soe != 1 && this.designData.relcache) {
                            this.$message.success("保存成功");
                        }
                        this.cacheId = null;
                        this.dialogVisible = false;
					});
				} else {
					this.loadingUpload = false;
					console.log("error submit!!");
					return false;
				}
			});
		},
		getPlatformList(val) {
			console.log(val);
			this.ruleForm.platform = val;
			let list = JSON.parse(JSON.stringify(this.domainName));
			val.forEach(i => {
				if (!list.some(x => x.proType === i.id)) {
					list.push(...this.webOptions[i.id]);
				}
			});
			const filteredB = list.filter(itemB => val.some(itemA => itemA.id === itemB.proType));
			this.domainName = filteredB;
		},
		dataFilter(node, val) {
			if (!!~node.text.indexOf(val) || !!~node.text.toUpperCase().indexOf(val.toUpperCase())) {
				return true;
			}
		},
		getdomainNameList(val) {
			this.ruleForm.domain = val;
		},
		getLanuageList(val) {
			this.ruleForm.lanuage = val;
		},
		// 获取新增编辑可选项
		getSomeSelect() {
			// 获取labelList
			getLabelsType({ language: "en-us" }).then(res => {
				res.data.forEach(a => {
					a.labelsList.forEach(x => {
						x.labelTypeName = x.labelName;
					});
					this.labelList.push(a);
				});
			});
			// 获取网站信息
			getSystemProjectList({ type: 0, pageSize: 9999 }).then(res => {
				// this.webOptions = res.data
				console.log(res.data);
				for (let i in res.data) {
					res.data[i].forEach(a => {
						a.name = a.url;
					});
					this.webOptions[i] = res.data[i];
				}
			});
			// 获取语言
			getLanguageByProId().then(res => {
				// this.lanuageOptions = res.data
				let list = [];
				res.data.forEach((i, index) => {
					i.childList.forEach(x => {
						list.push({
							languageId: x.id,
							countryId: i.id,
							id: i.countryCode + "-" + x.language,
							country: i.country,
							img: i.img,
							name: x.remarks + "(" + i.countryCode + "-" + x.language + ")"
						});
					});
				});
				this.lanuageOptions = list;
			});
		},
		clearRuleForm() {
			this.ruleForm = {
				seo: 0,
				templateName: "",
				seoTitle: "",
				description: "",
				templatesUrl: "",
				templatesNo: "",
				templateType: [],
				keyword: [],
				according: false,
				platform: [],
				domainName: [],
				lanuage: [],
				domain: [],
				displayControl: 0,
				resource: "添加新模板"
			};
			this.platform = [];
			this.webSite = [];
			this.languages = [];
		}
	},
	created() {
		this.getSomeSelect();
		this.getAllTemplateTypeList(0);
		if (this.templateId) {
			setTimeout(() => {
				this.editBox(this.templateId);
			}, 2000);
		}
	},
	mounted() {
		if (this.$route.name == "templateLibrary") {
			this.myDesignShow = false;
		}
		getList({
			pageSize: 9999,
			type: 0
		}).then(res => {
			if (res.code == "200") {
				let data = Object.values(res.data);
				if (data.length > 3) {
					data.pop();
					this.options = [
						{
							id: "forUs",
							proName: "自营平台"
						},
						{
							id: "o2o",
							proName: "O2O经销商"
						},
						{
							id: "uspp",
							proName: "USPP加盟商"
						}
					];
					for (let i = 0; i < 3; i++) {
						this.$set(this.options[i], "children", data[i]);
					}
					console.log(this.options);
				}
			}
		});
	},
	beforeDestroy() {}
};
</script>

<style scoped lang="scss">
.bottom-area {
	position: absolute;
	left: 90px;
	right: 0;
	bottom: 0;
	background-color: #ffffff;
	height: 80px;
	box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.15);
}

.bottom-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.stickerSize {
		position: absolute;
		left: 10px;
		top: 50%;
		transform: translateY(-50%);
		display: flex;
		align-items: center;

		.size {
			margin-left: 10px;

			.c {
				margin: 0 10px;
			}

			.sizeSelect {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 24px;
				height: 24px;
				background: #ebebeb;
				border-radius: 50%;
				margin-left: 14px;

				b {
					font-weight: 700;
				}
			}
		}

		.sizeInput {
			width: 70px;
			height: 40px;
			background: #ffffff;
			border: 1px solid #dbdbdb;
			border-radius: 4px;
			padding: 0 10px;

			&.isError {
				border-color: red;
			}
		}
	}
}

.searchInput {
	position: absolute;
	top: 80px;
	left: 20%;
	width: 30%;
	min-width: 250px;

	::v-deep {
		.highlight {
			font-weight: 700;
		}
	}

	.inputSearch {
		width: 101%;
		height: 35px;
		font-size: 14px;
		background: #ebebeb;
		border-radius: 17px;
		padding: 0 0 0 14px;
		border: 1px solid transparent;
		transition: all 0.3s;

		&:focus {
			border: 1px solid #2a96fa;
			background-color: #ffffff;
		}
	}

	.suffix-inner {
		position: absolute;
		top: 18px;
		right: 10px;
		transform: translateY(-50%);
		cursor: pointer;

		b {
			color: #a6a6a6;
		}
	}
}

::v-deep .el-switch__label {
	position: absolute;

	display: none;
	color: #fff;
	font-size: 12px !important;
}

/*打开时文字位置设置*/
::v-deep .el-switch__label--right {
	z-index: 1;
}

/*关闭时文字位置设置*/
::v-deep .el-switch__label--left {
	z-index: 1;
	left: 20px;
	font-size: 12px !important;
}

/*显示文字*/
::v-deep .el-switch__label.is-active {
	display: block;
}

::v-deep .switch1 {
	.el-switch .el-switch__core,
	.el-switch .el-switch__label {
		width: 85px !important;
		font-size: 12px !important;
	}
}

::v-deep .switch2 {
	.el-switch .el-switch__core,
	.el-switch .el-switch__label {
		width: 58px !important;
		font-size: 12px !important;
	}
}

::v-deep .el_dialog {
	z-index: 9999 !important;
}
</style>