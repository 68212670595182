<template>
	<div class="side">
		<div class="side-item" :class="{active:item.designPostion===canvas.activePosition}" v-for="(item,index) in positionConfigs" :key="index" @click="changeSide(item)">
			<div class="side-img">
				<img v-show="item.url" :src="item.url" :alt="positionText[item.designPostion]"/>
			</div>
			<div class="side-text">
				{{ positionText[item.designPostion] }}
				<template v-if="item.positionCopywriting">
					<el-tooltip popper-class="dzToolTip" effect="light" :content="item.positionCopywriting" placement="bottom">
						<b class="icon-wenhao"></b>
					</el-tooltip>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import dzMixin from "@/mixins/dzMixin";

export default {
	mixins: [dzMixin],
	data() {
		return {
			positionText: {
				0: 'Front',
				1: 'Back'
			}
		}
	},
	computed: {
		positionConfigs() {
			return this.canvas.positionConfigs
		}
	},
	methods: {
		changeSide(item) {
			this.canvas.changePosition(item)
		}
	}
};
</script>

<style scoped lang="scss">
.side {
	padding-top: 60px;
	pointer-events: all;

	.side-item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 90px;
		height: 112px;
		padding: 5px;
		background: #cccccc;
		color: #999999;
		cursor: pointer;
		transition: all 0.3s;

		&:hover {
			background: #ffffff;
			color: #333333;
		}

		.side-img {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 78px;
			height: 78px;
			background: #d8d8d8;
			border-bottom: 2px solid transparent;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&.active {
			background: #ffffff;
			color: #333333;

			.side-img {
				border-color: #2A96FA;
			}
		}

		.side-text{
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 2px 0;

			b{
				margin-left: 4px;
				font-size: 14px;
				vertical-align: middle;
			}
		}
	}
}
</style>
