import layout from "@/views/layout/index.vue";

const Resume = () => import("@/views/resume/index.vue");
export default {
	path: "/resume",
	name: "resume",
	component: layout,
	meta: {
		title: "礼一简历",
		icon: "el-icon-tickets",
	},
	children: [
		{
			path: "liyiResume",
			component: Resume,
			name: "liyiResume",
			meta: {
				title: "礼一简历",
			},
		},
	],
};
