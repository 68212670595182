import layout from "@/views/layout/index.vue";
const customIndex = () => import("@/views/index.vue")
const completionRate = () => import('@/views/kpiManage/completionRate/index.vue')
const completionRateOne = () => import('@/views/kpiManage/completionRateOne/index.vue')

const repurchaseRate = () => import('@/views/kpiManage/repurchaseRate/index.vue')
const repurchaseRateOne = () => import('@/views/kpiManage/repurchaseRateOne/index.vue')

const commentRate = () => import('@/views/kpiManage/commentRate/index.vue')
const kpiRuleManagement = () => import('@/views/kpiManage/kpiRuleManagement/index.vue')

const comprehensiveHistoricalRanking = () => import('@/views/kpiManage/historicalRankingRecord/comprehensive/index.vue')
const individualHistoricalRankingRecord = () => import('@/views/kpiManage/historicalRankingRecord/individual/index.vue')

const commissionManagement = () => import('@/views/kpiManage/commissionManagement/index.vue')
const commissionList = () => import("@/views/kpiManage/commissionList/index.vue");

export default {
	path: "/kpiManage",
	name: "kpiManage",
	component: layout,
	meta: {
		title: "客服绩效管理",
		icon: "el-icon-document-checked"
	},
	children: [
		{
			path: "completionRate",
			name: "completionRate",
			component: customIndex,
			meta: {
				title: "成单绩效报表"
			},
			children: [
				{
					path: "completionRateAll",
					name: "completionRateAll",
					component: completionRate,
					meta: {
						title: "综合成单绩效报表"
					}
				},
				{
					path: "completionRateOne",
					name: "completionRateOne",
					component: completionRateOne,
					meta: {
						title: "个人成单绩效报表"
					}
				}
			]
		},
		{
			path: "repurchaseRate",
			name: "repurchaseRate",
			component: customIndex,
			meta: {
				title: "复购绩效报表"
			},
			children: [
				{
					path: "repurchaseRateAll",
					name: "repurchaseRateAll",
					component: repurchaseRate,
					meta: {
						title: "综合复购绩效报表"
					}
				},
				{
					path: "repurchaseRateOne",
					name: "repurchaseRateOne",
					component: repurchaseRateOne,
					meta: {
						title: "个人复购绩效报表"
					}
				}
			]
		},
		{
			path: "commentRate",
			name: "commentRate",
			component: commentRate,
			meta: {
				title: "评论率绩效报表"
			}
		},
		{
			path: "kpiRuleManagement",
			name: "kpiRuleManagement",
			component: kpiRuleManagement,
			meta: {
				title: "绩效规则管理"
			}
		},
		{
			path: "historicalRankingRecord",
			name: "historicalRankingRecord",
			component: customIndex,
			redirect: "/historicalRankingRecord/comprehensive",
			meta: {
				title: "历史排名记录表"
			},
			children: [
				{
					path: "comprehensive",
					name: "comprehensiveHistoricalRanking",
					component: comprehensiveHistoricalRanking,
					meta: {
						title: "综合历史排名记录表"
					}
				},
				{
					path: "individual",
					name: "individualHistoricalRanking",
					component: individualHistoricalRankingRecord,
					meta: {
						title: "个人历史排名记录表"
					}
				}
			]
		},
		{
			path: "commissionManagement",
			name: "commissionManagement",
			component: commissionManagement,
			meta: {
				title: "业务提成管理"
			}
		},
		{
			path: "commissionList",
			name: "commissionList",
			component: commissionList,
			meta: {
				title: "业务提成记录列表"
			}
		}
	]
};
