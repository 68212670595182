import request from '@/utils/request'

// 登录方法
export function logined(data) {
    return request({
        url: 'login',
        method: 'post',
        data: data,
    })
}
export function getManageUserInfo(data) {
    return request({
        url: '/user/getManageUserInfo',
        method: 'get',
        params: data
    })
}
export function updateUserPassword(data){
    return request({
        url:'/user/updateUserPassword',
        method:'post',
        data:data
    })
}

export function getTokenByUUid(data){
    return request({
        url:'/user/qyWx/getTokenByUUid?uuid='+data,
        method:'get'
    })
}

//账号绑定
export function verify(data){
    return request({
        url:'/user/qyWx/verify',
        method:'post',
        data:data
    })
}

//谷歌登录
export function googleLogin(data){
    return request({
        url:'/user/google/login',
        method:'post',
        data:data
    })
}

/**
 * 多个账号选择一个账号来登录
 * @param {Object} data
 * @param {string} data.oaUserId 后台用户id
 * @param {string} data.exUserId 企业微信用户id
 * @param {string} data.typeId 企业微信Id
 * @param {'gn'|'gw'} data.signOfGnGw 国内或国外标识
 */
export function selectAccountToLogin(data) {
	return request({
		url: '/user/qyWx/selectAccountToLogin',
		method: 'POST',
		data
	})
}

/**
 * 获取某个企业微信账号下的所有后台用户列表
 * @param {Object} data
 * @param {string} data.exUserId 企业微信用户Id
 * @param {string} data.typeId 企业微信Id
 */
export function getAccountListByExUserId(data) {
	return request({
		url: '/user/qyWx/listAccountByExUserId',
		method: 'get',
		params: data
	})
}

/**
 * 获取当前账号下绑定的其他后台用户列表
 */
export function getAccountListByOaUserId() {
	return request({
		url: '/user/listAccountByOaUserId',
		method: 'post'
	})
}
