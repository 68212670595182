// previewVideo.js
import PreviewVideoModal from "@/components/PreviewVideoModal.vue";

const PreviewVideoPlugin = {
	install(Vue) {
		Vue.prototype.$previewVideo = function(videoUrl) {
			Vue.prototype.$previewVideo = function(videoUrl) {
				const PreviewVideoComponent = Vue.extend(PreviewVideoModal);
				const videoId = "video" + Date.now();

				// 创建一个组件实例
				const previewVideoModal = new PreviewVideoComponent({
					propsData: {
						videoUrl: videoUrl,
						id: videoId
					}
				});

				// 将组件实例挂载到 DOM 中
				previewVideoModal.$mount();
				document.body.appendChild(previewVideoModal.$el);

				// 打开弹窗
				previewVideoModal.open();
			};
		};
	}
};

export default PreviewVideoPlugin;
