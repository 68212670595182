<template>
	<div class="base-dialog" v-if="value" @click.self="closeDialog">
		<div class="base-dialog-model-con" :style="{width:width}">
			<slot>

			</slot>
			<slot name="closeIcon">
				<div class="close" @click="closeDialog">
					<svg t="1670316329641" class="icon" viewBox="0 0 1024 1024" version="1.1"
						 xmlns="http://www.w3.org/2000/svg" p-id="5682" width="200" height="200">
						<path
							d="M557.312 513.248l265.28-263.904c12.544-12.48 12.608-32.704 0.128-45.248-12.512-12.576-32.704-12.608-45.248-0.128l-265.344 263.936-263.04-263.84C236.64 191.584 216.384 191.52 203.84 204 191.328 216.48 191.296 236.736 203.776 249.28l262.976 263.776L201.6 776.8c-12.544 12.48-12.608 32.704-0.128 45.248 6.24 6.272 14.464 9.44 22.688 9.44 8.16 0 16.32-3.104 22.56-9.312l265.216-263.808 265.44 266.24c6.24 6.272 14.432 9.408 22.656 9.408 8.192 0 16.352-3.136 22.592-9.344 12.512-12.48 12.544-32.704 0.064-45.248L557.312 513.248z"
							p-id="5683"></path>
					</svg>
				</div>
			</slot>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		value: {
			type: Boolean,
			default: false
		},
		width: {
			type: [String, Number],
			default: '50%'
		}
	},
	model: {
		prop: "value",
		event: "update", // onchange 事件
	},
	methods: {
		closeDialog() {
			this.$emit('update', false)
		}
	}
};
</script>
<style lang="scss" scoped>
.base-dialog {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2000;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	overflow: auto;
	white-space: nowrap;
	text-align: center;

	&:after {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.base-dialog-model-con {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		margin: 0 auto;
		background: #fff;
		border-radius: 6px;
		box-shadow: 1px 2px 20px 0 rgba(0, 0, 0, 0.2);
		text-align: left;
		white-space: normal;

		.close {
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: 700;
			position: absolute;
			top: 0;
			right: 0;
			transform: translate(50%, -50%);
			width: 40px;
			height: 40px;
			cursor: pointer;
			background: #FFFFFF;
			box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
			border-radius: 50%;
			z-index: 10;

			svg {
				width: 20px;
				height: 20px;
			}
		}

		@media screen and (max-width: 767px) {
			.close {
				width: 24px;
				height: 24px;
			}
		}
	}
}

</style>
