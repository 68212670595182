import { dataURLtoFile, generateUUID, mergeImg, urlAddVersion,analyzeImageColor } from '@/utils/utils';
import { fabric } from "fabric";
import { uploadFile } from "@/utils/oss";
import { colorRgb } from "@/utils/utils";
import initAligningGuidelines from "@/assets/js/fabricCore/initAligningGuidelines2";
import { initControlIcon } from "@/assets/js/fabricCore/initControlIcon";
import initHotkeys from "@/assets/js/fabricCore/initHotKey";
import initCurvedText from "@/assets/js/fabricCore/initCurveText";
import store from "@/store";
import {
	dieCut,
	ellipseTeXiao,
	rectTeXiao,
	roundedRectTeXiao
} from "@/assets/js/fabricCore/texiao";
const canvas = {
	isSticker: 0, //判断是否是贴纸设计系统
	stickerEffectType: 1,//贴纸设计系统形状
	isGetProperty: false,
	templateName:"",
	textProperty:{},
	imageProperty:{},
	c: "",
	electroplate: {},//当前选中的电镀色
	designDes: "",//设计系统描述
	pauseSave: false,
	mSelectId: "",
	mSelectIds: [],
	mSelectMode: "",
	mSelectOneType: "",
	workspace: "",
	workspaceEl: "",
	option: {
		width: 650,
		height: 650
	},
	stickerOption: {
		width: 600,
		height: 600
	},
	stickerSize: {
		w: 1,
		h: 1
	},
	designPostionToName: {
		0: 'frontData',
		1: 'backData'
	},
	config: {
		canvasState: [],
		currentStateIndex: -1,
		undoStatus: false,
		redoStatus: false,
		undoFinishedStatus: 1,
		redoFinishedStatus: 1,
		undoButton: false, //false表示禁用
		redoButton: false, //false表示禁用
	},
	activePosition: 0,
	hasBg:0, //是否存在底图
	positionConfigs: [],//设计系统side
	selectedImgColorList: [],
	updateMiniMapTimed: null,
	saveStateToHistoryTimed: null,
	initCanvas(id) {
		const workspaceEl = document.querySelector("#workspace");
		if (!workspaceEl) {
			throw new Error(this.langDesign.elementError);
		}
		this.workspaceEl = workspaceEl;
		let HideControls = {
			tl: false,
			tr: false,
			bl: false,
			br: false,
			ml: false,
			mt: false,
			mr: false,
			mb: false,
			mtr: false,
		};
		fabric.Object.prototype.set({
			borderColor: "#2C7DFA",
			borderDashArray: [0, 0],
			borderOpacityWhenMoving: 0.5,
			cornerSize: 25,
			padding: 0,
			objectCaching: false,
			statefullCache: false,
			centeredScaling: true
		});
		fabric.Object.prototype.setControlsVisibility(HideControls);
		fabric.Canvas.prototype.getAbsoluteCoords = function (object) {
			var bound = object.getBoundingRect();
			return {
				left: bound.left + this._offset.left,
				top: bound.top + this._offset.top + bound.height,
			};
		};
		const canvas = new fabric.Canvas(id, {
			preserveObjectStacking: true,
			useWebGL: true,
			fireRightClick: true, // 启用右键，button的数字为3
			stopContextMenu: true, // 禁止默认右键菜单
			controlsAboveOverlay: true, // 超出clipPath后仍然展示控制条
		});
		this.c = canvas;
		initAligningGuidelines(canvas);
		initControlIcon(canvas);
		initHotkeys(canvas);
		//初始化弯曲文本类
		initCurvedText(fabric);
		this._initBackground();
		this._initWorkspace();
		//如果是sticker网站
		if(this.isSticker){
			this.initStickerShape();
		}
		canvas.on({
			"object:added": () => {
				if (this.pauseSave) {
					return;
				}
				setTimeout(() => {
					this.saveStateToHistory();
					this.updateMiniMap();
				}, 0);
			},
			"object:modified": (e) => {
				if (this.pauseSave) {
					return;
				}
				setTimeout(() => {
					this.saveStateToHistory();
					this.updateMiniMap();
				}, 0);
			},
			"object:moving": event => {
				// window.requestAnimationFrame(() => {
				// 	const canvas = this.c;
				// 	let workspace = canvas.getObjects().find((item) => item.id === 'workspace');
				// 	let containerBounds = workspace.getBoundingRect();
				// 	let obj = event.target;
				// 	obj.setCoords();
				// 	let objBounds = obj.getBoundingRect();
				// 	let top = objBounds.top;
				// 	let left = objBounds.left;
				// 	let right = objBounds.left + objBounds.width;
				// 	let bottom = objBounds.top + objBounds.height;
				// 	// 边界检查
				// 	let cleft = obj.left;
				// 	let ctop = obj.top;
				// 	if (left < containerBounds.left) {
				// 		cleft = cleft + containerBounds.left - left;
				// 	}
				// 	if (top < containerBounds.top) {
				// 		ctop = ctop + containerBounds.top - top;
				// 	}
				// 	if (right > containerBounds.left + containerBounds.width) {
				// 		cleft = cleft - (left + objBounds.width - (containerBounds.left + containerBounds.width));
				// 	}
				// 	if (bottom > containerBounds.top + containerBounds.height) {
				// 		ctop = ctop - (top + objBounds.height - (containerBounds.top + containerBounds.height));
				// 	}
				// 	obj.set({ left: cleft, top: ctop });
				// })
			},
			"object:removed": (e) => {
				this.updateMiniMap();
			},
			"selection:created": (e) => {
				this.selected(e.selected);
			},
			"selection:updated": (e) => {
				this.selected(e.selected);
			},
			"selection:cleared": (e) => {
				this.selected(e.selected);
			},
		});
		return canvas
	},
	getObjectAttr(item) {
		const activeObject = this.c.getActiveObject();
		if (!activeObject) {
			return;
		}
		// 不是当前obj，跳过
		if (item && item !== activeObject) return;
		const textTypes = ["i-text", "text", "textbox", "curved-text"],
			imgTypes = ["image"];
		if (textTypes.includes(activeObject.type)) {
			let obj = {};
			obj.type = activeObject.type;
			obj.text = activeObject.get("text");
			obj.textAlign = activeObject.get("textAlign");
			obj.angle = Math.round(activeObject.get("angle"));
			obj.scale = Number(Number(activeObject.get("scaleX")).toFixed(2));
			obj.strokeWidth = activeObject.get("strokeWidth");
			obj.charSpacing = activeObject.get("charSpacing");
			obj.lineHeight = activeObject.get("lineHeight");
			obj.fill = activeObject.get("fill");
			obj.stroke = activeObject.get("stroke");
			obj.fontSize = activeObject.get("fontSize");
			obj.fontWeight = activeObject.get("fontWeight");
			obj.fontStyle = activeObject.get("fontStyle");
			obj.underline = activeObject.get("underline");
			obj.linethrough = activeObject.get("linethrough");
			obj.fontFamily = activeObject.get("fontFamily");
			if (activeObject.type === "curved-text") {
				obj.flipped = activeObject.get("flipped");
				obj.radian = activeObject.get("radian");
			}
			this.textProperty = Object.assign({}, this.textProperty, obj);
		}
		if (imgTypes.includes(activeObject.type)) {
			let obj = {};
			obj.IsMain = Number(activeObject.IsMain) || 0;
			obj.angle = Math.round(activeObject.get("angle"));
			obj.scale = Number(activeObject.get("scaleX").toFixed(3));
			obj.picPath = activeObject.getSrc();
			obj.singleColor=activeObject.get('singleColor')||false;
			obj.singleColorList=activeObject.get('singleColorList')||[];
			this.imageProperty = obj;
		}
	},
	analyzeImgColor(url) {
		let ignoreArr = [];
		return new Promise(resolve => {
			analyzeImageColor(urlAddVersion(url), {
				ignoreArr: ignoreArr,
				keepCount: 9,
				removeSimilarColors: true,
			}).then((res) => {
				this.selectedImgColorList = res;
				resolve(res);
			});
		})
	},
	parseColor(colorString) {
		const match = colorString.match(/(\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?/);
		const r = parseInt(match[1], 10);
		const g = parseInt(match[2], 10);
		const b = parseInt(match[3], 10);
		let a = null;

		if (match[4]) {
			a = parseFloat(match[4]);
		} else {
			a = 1;
		}

		return { r, g, b, a };
	},
	removeSimilarColors(colors) {
		const threshold = 30; // 设定阈值
		for (let i = 0; i < colors.length; i++) {
			const color1 = this.parseColor(colors[i].color);
			for (let j = i + 1; j < colors.length; j++) {
				const color2 = this.parseColor(colors[j].color);
				if (this.isSimilarColor(color1, color2, threshold)) {
					colors.splice(j, 1);
					j--;
				}
			}
		}
		return colors;
	},
	isSimilarColor(color1, color2, threshold) {
		const distance = Math.sqrt(
			Math.pow(color2.r - color1.r, 2) +
			Math.pow(color2.g - color1.g, 2) +
			Math.pow(color2.b - color1.b, 2)
		);

		return distance <= threshold;
	},
	getImgUrl(canvas, data, quantity = 1) {
		return new Promise(resolve => {
			canvas.clone(cloneObj => {
				cloneObj.loadFromJSON(data, () => {
					const workspace = cloneObj.getObjects().find((item) => item.id === 'workspace');
					workspace.set('fill', null);
					const { left, top, width, height } = workspace;
					const option = {
						name: 'New Image',
						format: 'png',
						quality: 1,
						width,
						height,
						left,
						top,
						multiplier: quantity,
					};
					cloneObj.setViewportTransform([1, 0, 0, 1, 0, 0]);
					cloneObj.renderAll();
					const dataUrl = cloneObj.toDataURL(option);
					resolve(dataUrl)
				})
			}, ['id'])
		})
	},
	updateMiniMap() {
		if (this.pauseSave) {
			return;
		}
		if (this.isSticker) {
			return;
		}
		if (this.updateMiniMapTimed) {
			clearTimeout(this.updateMiniMapTimed)
		}
		this.updateMiniMapTimed = setTimeout(() => {
			let positionConfigs = this.positionConfigs;
			let activePosition = this.activePosition;
			let data = this.getJson();
			this.getImgUrl(this.c, data, 0.4).then(url => {
				positionConfigs[activePosition].url = url;
			})
		}, 300)
	},
	initConfig() {
		this.config = {
			canvasState: [],
			currentStateIndex: -1,
			undoStatus: false,
			redoStatus: false,
			undoFinishedStatus: 1,
			redoFinishedStatus: 1,
			undoButton: false, //false表示禁用
			redoButton: false, //false表示禁用
		}
	},
	async changePosition(item) {
		store.commit('design/set_loading', true);
		this.pauseSave = true;
		//保存画布的设计到位置信息
		let data = this.positionConfigs[this.activePosition].templateData = this.getJson();
		//查看要切换的方位是否已保存过数据
		if (this.positionConfigs[item.designPostion].templateData) {
			//加载已保存过的数据
			await this.loadJson(this.positionConfigs[item.designPostion].templateData);
			//判断要切换的面是否展示线条,根据上一个面的参数来
			if (data.objects.length) {
				let workspace = data.objects.find(item => item.id === "workspace");
				if (workspace.fill && workspace.fill.type === "pattern") {
					await this.showGrid();
				} else {
					this.hideGrid()
				}
			}
		} else {
			this.clear();
		}
		this.activePosition = item.designPostion;
		this.pauseSave = false;
		//清空撤销回车数据
		this.initConfig();
		this.saveStateToHistory();
		store.commit('design/set_loading', false)
	},
	saveStateToHistory() {
		if (this.pauseSave) {
			return
		}
		if (this.saveStateToHistoryTimed) {
			clearTimeout(this.saveStateToHistoryTimed)
		}
		this.saveStateToHistoryTimed = setTimeout(() => {
			let _config = this.config;
			if (_config.undoStatus === false && _config.redoStatus === false) {
				let state = this.getJson();
				if (_config.currentStateIndex < _config.canvasState.length - 1) {
					let indexToBeInserted = _config.currentStateIndex + 1;
					_config.canvasState[indexToBeInserted] = state;
					let numberOfElementsToRetain = indexToBeInserted + 1;
					_config.canvasState = _config.canvasState.splice(0, numberOfElementsToRetain);
				} else {
					_config.canvasState.push(state);
				}
				_config.currentStateIndex = _config.canvasState.length - 1;
				_config.undoButton = true;
				if (_config.currentStateIndex === _config.canvasState.length - 1 && _config.currentStateIndex !== -1) {
					_config.redoButton = false;
				}
			}
			if (_config.canvasState.length > 20) {
				_config.canvasState.splice(0, 1);
				_config.currentStateIndex = _config.canvasState.length - 1;
			}
		}, 200)
	},
	undo() {
		let _config = this.config;
		let _canvasObject = this.c;
		let stateData = _config.canvasState[_config.currentStateIndex - 1];
		if (_config.undoFinishedStatus) {
			if (_config.currentStateIndex == -1) {
				_config.undoStatus = false;
			} else {
				if (_config.canvasState.length >= 1) {
					_config.undoFinishedStatus = 0;
					if (_config.currentStateIndex != 0) {
						_config.undoStatus = true;
						this.pauseSave = true;
						_canvasObject.loadFromJSON(stateData, () => {
							_config.undoStatus = false;
							_config.currentStateIndex -= 1;
							_config.undoButton = true;
							if (_config.currentStateIndex !== _config.canvasState.length - 1) {
								_config.redoButton = true;
							}
							_config.undoFinishedStatus = 1;
							this.pauseSave = false;
							if(this.isSticker){
								this.initStickerShape()
							}
							this.updateMiniMap();
						});
					} else {
						_config.undoFinishedStatus = 1;
						_config.undoButton = false;
						_config.redoButton = true;
					}
				}
			}
		}
	},

	redo() {
		let _config = this.config;
		let _canvasObject = this.c;
		let stateData = _config.canvasState[_config.currentStateIndex + 1];
		if (_config.redoFinishedStatus) {
			if (_config.currentStateIndex == _config.canvasState.length - 1 && _config.currentStateIndex != -1) {
				_config.redoButton = false;
			} else {
				if (_config.canvasState.length > _config.currentStateIndex && _config.canvasState.length != 0) {
					_config.redoFinishedStatus = 0;
					_config.redoStatus = true;
					this.pauseSave = true;
					_canvasObject.loadFromJSON(stateData, () => {
						_config.redoStatus = false;
						_config.currentStateIndex += 1;
						if (_config.currentStateIndex != -1) {
							_config.undoButton = true;
						}
						_config.redoFinishedStatus = 1;
						if (_config.currentStateIndex == _config.canvasState.length - 1 && _config.currentStateIndex != -1) {
							_config.redoButton = false;
						}
						this.pauseSave = false;
						if(this.isSticker){
							this.initStickerShape()
						}
						this.updateMiniMap();
					});
				}
			}
		}
	},
	isText() {
		const textTypes = ['i-text', 'text', 'textbox', 'curved-text'];
		return textTypes.includes(this.mSelectOneType);
	},
	isImage() {
		return this.mSelectOneType === 'image'
	},
	clone() {
		let canvas = this.c;
		const copyEl = this.c.getActiveObject();
		copyEl?.clone((clonedObj) => {
			canvas.discardActiveObject();
			clonedObj.set({
				left: clonedObj.left + 10,
				top: clonedObj.top + 10,
				id: generateUUID()
			});
			if (clonedObj.type === "activeSelection") {
				clonedObj.canvas = canvas;
				clonedObj.forEachObject(function (obj) {
					obj.set("id", generateUUID())
					canvas.add(obj);
				});
				clonedObj.setCoords();
			} else {
				canvas.add(clonedObj);
			}
			canvas.setActiveObject(clonedObj);
			canvas.requestRenderAll();
		});
	},
	unGroup() {
		let canvas = this.c;
		if (!canvas.getActiveObject()) {
			return;
		}
		if (canvas.getActiveObject().type !== 'group') {
			return;
		}
		canvas.getActiveObject().toActiveSelection();
		canvas.getActiveObject().getObjects().forEach((item) => {
			item.set('id', generateUUID());
		});
		canvas.discardActiveObject().requestRenderAll();
	},
	group() {
		let canvas = this.c;
		if (!canvas.getActiveObject()) {
			return;
		}
		if (canvas.getActiveObject().type !== 'activeSelection') {
			return;
		}
		const containGroup = (arr) => {
			if (arr.find(item => item.type === 'group')) {
				return true
			}
		}
		if (containGroup(canvas.getActiveObject().getObjects())) {
			return;
		}
		let newgroup = canvas.getActiveObject().toGroup();
		newgroup.set('id', generateUUID());
		canvas.discardActiveObject().requestRenderAll();
		canvas.setActiveObject(newgroup).requestRenderAll();
	},
	up() {
		const actives = this.c.getActiveObjects();
		if (actives && actives.length === 1) {
			const activeObject = this.c.getActiveObjects()[0];
			activeObject && activeObject.bringForward();
			this.c.renderAll();
			this._baseSendToBack();
			this._stickerShapeSendToBack();
			this._workspaceSendToBack();
		}
	},
	upTop() {
		const actives = this.c.getActiveObjects();
		if (actives && actives.length === 1) {
			const activeObject = this.c.getActiveObjects()[0];
			activeObject && activeObject.bringToFront();
			this.c.renderAll();
			this._baseSendToBack();
			this._stickerShapeSendToBack();
			this._workspaceSendToBack();
		}
	},
	down() {
		const actives = this.c.getActiveObjects();
		if (actives && actives.length === 1) {
			const activeObject = this.c.getActiveObjects()[0];
			activeObject && activeObject.sendBackwards();
			this.c.renderAll();
			this._baseSendToBack();
			this._stickerShapeSendToBack();
			this._workspaceSendToBack();
		}
	},
	downTop() {
		const actives = this.c.getActiveObjects();
		if (actives && actives.length === 1) {
			const activeObject = this.c.getActiveObjects()[0];
			activeObject && activeObject.sendToBack();
			this.c.renderAll();
			this._baseSendToBack();
			this._stickerShapeSendToBack();
			this._workspaceSendToBack();
		}
	},
	getWorkspace() {
		return this.c.getObjects().find((item) => item.id === 'workspace');
	},
	getBase() {
		return this.c.getObjects().find((item) => item.isBase === 1);
	},
	_baseSendToBack() {
		const base = this.getBase();
		base && base.sendToBack();
	},
	_stickerShapeSendToBack(){
		const shape = this.getStickerShape();
		shape && shape.sendToBack();
	},
	_workspaceSendToBack() {
		const workspace = this.getWorkspace();
		workspace && workspace.sendToBack();
	},
	getJson() {
		return this.c.toJSON(['id', 'artId', 'selectable', 'hasControls', 'evented', 'isBase', 'paltingShade', 'paltingSubShade', 'singleColor', 'singleColorList']);
	},
	changeElementProperty(value, key) {
		if(this.isGetProperty){
			return false;
		}
		const activeObject = this.c.getActiveObject();
		if (!activeObject || activeObject.type==='activeSelection') {
			return;
		}
		console.log('修改属性', key)
		//文字行高
		if(key === 'lineHeight'){
			activeObject
				.set({
					lineHeight:value
				})
				.setCoords();
			this.c.requestRenderAll();
			this.updateMiniMap();
			this.saveStateToHistory();
			return;
		}
		// 透明度特殊转换
		if (key === 'opacity') {
			activeObject && activeObject.set(key, value / 100);
			this.c.requestRenderAll();
			return;
		}
		// 旋转角度适配
		if (key === 'angle') {
			activeObject.rotate(value);
			this.c.requestRenderAll();
			this.updateMiniMap();
			this.saveStateToHistory();
			return;
		}
		if (key === 'scale') {
			activeObject
				.set({
					scaleX: value,
					scaleY: value,
				})
				.setCoords();
			this.c.requestRenderAll();
			this.updateMiniMap();
			this.saveStateToHistory();
			return;
		}
		if (key === 'stroke') {
			let strokeWidth = activeObject.get('strokeWidth')
			if (strokeWidth >= 0 && strokeWidth < 1) {
				activeObject && activeObject.set('strokeWidth', 1);
			}
		}
		activeObject && activeObject.set(key, value);
		this.c.requestRenderAll();
		this.updateMiniMap();
		this.saveStateToHistory();
	},
	flipX() {
		let selectedEle = this.c.getActiveObject();
		if (!selectedEle) {
			return false;
		}
		if (selectedEle) {
			selectedEle.get("flipX") ? selectedEle.set("flipX", false) : selectedEle.set("flipX", true);
			this.c.requestRenderAll();
		}
	},
	flipY() {
		let selectedEle = this.c.getActiveObject();
		if (!selectedEle) {
			return false;
		}
		if (selectedEle) {
			selectedEle.get("flipY") ? selectedEle.set("flipY", false) : selectedEle.set("flipY", true);
			this.c.requestRenderAll();
		}
	},
	flip() {
		this.flipX();
		this.flipY()
	},
	startDraw() {
		this.c.isDrawingMode = true;
	},
	stopDraw() {
		this.c.isDrawingMode = false;
	},
	changeDrawWidth(width) {
		this.c.freeDrawingBrush.width = width
	},
	changeDrawColor(color) {
		this.c.freeDrawingBrush.color = color
	},
	clear() {
		this.c.getObjects().forEach((obj) => {
			if (obj.id !== 'workspace' && obj.isBase != 1) {
				this.c.remove(obj);
			}
		});
		this.c.discardActiveObject();
		this.c.renderAll();
		this.updateMiniMap();
	},
	centerH(workspace, object) {
		return this.c._centerObject(
			object,
			new fabric.Point(workspace.getCenterPoint().x, object.getCenterPoint().y)
		);
	},

	center(workspace, object) {
		const center = workspace.getCenterPoint();
		return this.c._centerObject(object, center);
	},

	centerV(workspace, object) {
		return this.c._centerObject(
			object,
			new fabric.Point(object.getCenterPoint().x, workspace.getCenterPoint().y)
		);
	},

	position(name) {
		const alignType = ['centerH', 'center', 'centerV'];
		const activeObject = this.c.getActiveObject();
		if (alignType.includes(name) && activeObject) {
			const defaultWorkspace = this.c.getObjects().find((item) => item.id === 'workspace');
			if (defaultWorkspace) {
				this[name](defaultWorkspace, activeObject);
			}
			this.c.renderAll();
		}
	},
	mirrorElement() {
		let activeObject = this.c.getActiveObject();
		activeObject.set({
			flipX: !activeObject.get("flipX"),
		});
		activeObject.rotate(-activeObject.get("angle"));
		this.c.requestRenderAll();
	},
	big() {
		let zoomRatio = this.c.getZoom();
		zoomRatio += 0.05;
		const center = this.c.getCenter();
		this.c.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio);
	},
	// 缩小
	small() {
		let zoomRatio = this.c.getZoom();
		zoomRatio -= 0.05;
		const center = this.c.getCenter();
		this.c.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio < 0 ? 0.01 : zoomRatio);
	},
	auto() {
		const scale = this._getScale();
		this.setZoomAuto(scale);
	},
	// 1:1 放大
	one() {
		const scale = this._getScale();
		this.setZoomAuto(scale);
		this.c.requestRenderAll();
	},
	/**
	 * 设置画布中心到指定对象中心点上
	 * @param {Object} obj 指定的对象
	 */
	setCenterFromObject(obj) {
		const canvas = this.c;
		const objCenter = obj.getCenterPoint();
		const viewportTransform = canvas.viewportTransform;
		if (canvas.width === undefined || canvas.height === undefined || !viewportTransform) return;
		viewportTransform[4] = canvas.width / 2 - objCenter.x * viewportTransform[0];
		viewportTransform[5] = canvas.height / 2 - objCenter.y * viewportTransform[3];
		canvas.setViewportTransform(viewportTransform);
		canvas.renderAll();
	},
	setZoomAuto(scale, cb) {
		const workspace = this.getWorkspace();
		const workspaceEl = document.querySelector("#workspace");
		if (!workspaceEl) {
			return;
		}
		const width = workspaceEl.offsetWidth;
		const height = workspaceEl.offsetHeight;
		this.c.setWidth(width);
		this.c.setHeight(height);
		const center = this.c.getCenter();
		this.c.setViewportTransform(fabric.iMatrix.concat());
		this.c.zoomToPoint(new fabric.Point(center.left, center.top), scale);
		if (!workspace) return;
		this.setCenterFromObject(workspace);
		// 超出画布不展示
		if(!this.isSticker){
			workspace.clone((cloned) => {
				this.c.clipPath = cloned;
				this.c.requestRenderAll();
			});
		}
		if (cb) cb(workspace.left, workspace.top);
	},
	_getScale() {
		let option= this.isSticker?this.stickerOption:this.option;
		const viewPortWidth = this.workspaceEl.offsetWidth;
		const viewPortHeight = this.workspaceEl.offsetHeight;
		// 按照宽度
		if (viewPortWidth / viewPortHeight < option.width / option.height) {
			return viewPortWidth / option.width;
		} // 按照宽度缩放
		return viewPortHeight / option.height;
	},
	_initBackground() {
		this.c.setBackgroundColor('', this.c.renderAll.bind(this.c));
		this.c.backgroundImage = '';
		this.c.setWidth(this.workspaceEl.offsetWidth);
		this.c.setHeight(this.workspaceEl.offsetHeight);
	},
	getStickerShape(){
		return this.c.getObjects().find((item) => item.id === 'stickerShape');
	},
	initStickerShape(){
		this.pauseSave = true;
		const workspace = this.getWorkspace();
		const shape = this.getStickerShape();
		if(shape){
			this.c.remove(shape);
		}
		let type = this.stickerEffectType,
			shade,
			bgColor = '#d8d8d8',
			scale = this.stickerSize.w/this.stickerSize.h;
		switch (type) {
			case "1":
				shade = dieCut(workspace, scale, bgColor);
				break;
			case "2":
				shade = rectTeXiao(workspace, scale, bgColor);
				break;
			case "3":
				shade = ellipseTeXiao(workspace, scale, bgColor);
				break;
			case "4":
				shade = roundedRectTeXiao(workspace, scale, bgColor);
				break;
		}
		let clipPath = shade.group;
		this.c.add(clipPath);
		this.c.setActiveObject(clipPath);
		this.position('center');
		this.downTop();
		this.c.discardActiveObject();
		clipPath.clone((cloned) => {
			this.c.clipPath = cloned;
			this.c.requestRenderAll();
		});
		this.pauseSave = false;
	},
	_initWorkspace() {
		const {width, height} = this.isSticker ? this.stickerOption : this.option;
		const workspace = new fabric.Rect({
			fill: '',
			width,
			height,
			strokeWidth: 0, // 设置边框宽度
			stroke: '#ffffff ', // 设置边框颜色
			id: 'workspace',
		});
		workspace.set('selectable', false);
		workspace.set('hasControls', false);
		workspace.set('evented', false);
		this.c.add(workspace);
		this.c.renderAll();
		this.workspace = workspace;
		this.auto();
	},
	hideGrid() {
		if (this.isSticker) {
			return false;
		}
		const workspace = this.getWorkspace();
		workspace.set('fill', '');
		this.c.requestRenderAll();
	},
	showGrid() {
		if (this.isSticker) {
			return false;
		}
		return new Promise(resolve=>{
			let that = this;
			const workspace = this.getWorkspace();
			fabric.util.loadImage('https://static-oss.gs-souvenir.com/web/quoteManage/20230825/grid_202308258HJxrf.png', function (img) {
				workspace.set('fill', new fabric.Pattern({
					source: img,
					repeat: 'repeat'
				}));
				that.c.requestRenderAll();
				resolve();
			});
		})
	},
	getLayersArr() {
		let arr = this.c.getObjects().filter((o) => {
			return o.id !== "workspace" && o.isBase != 1 && o.id!== "stickerShape";
		}).reverse();
		arr.forEach(item => {
			if (item.type === 'group') {
				item.showChildren = true;
			}
		})
		return arr;
	},
	loadJson(data) {
		return new Promise(resolve => {
			this.c.loadFromJSON(data, (e) => {
				resolve();
			});
		})
	},
	addText(val, property) {
		let textValue = val || "CustomText";
		let Text = new fabric.Text(textValue, {
			fontSize: 50, // 字体大小
			fontWeight: "normal", // 字体粗细
			fill: "#000000", // 字体颜色
			stroke: "#da9f19", // 描边颜色
			strokeWidth: 0, // 描边宽度
			id: generateUUID()
		})
		this.c.add(Text);
		this.c.setActiveObject(Text);
		this.c.requestRenderAll();
		this.position('center');
		return Text
	},
	addCurveText(val,property){
		let textValue = val || "CustomText";
		let CurvedText = new fabric.CurvedText(textValue, {
			radian: 1000,
			fontSize: 50, // 字体大小
			fill: "#000000", // 字体颜色
			flipped: false,
		});
		this.c.add(CurvedText);
		this.c.setActiveObject(CurvedText);
		this.c.requestRenderAll();
		this.position('center');
		return CurvedText
	},
	loadImage(url) {
		return new Promise((resolve, reject) => {
			let img = new Image();
			img.onload = () => resolve(img);
			img.onerror = reject;
			img.src = urlAddVersion(url);
			img.crossOrigin = "Anonymous";
		});
	},
	async addImg(src, property) {
		let zoom = this.c.getZoom(),
			w = this.c.width / zoom,
			h = this.c.height / zoom;
		let img = await this.loadImage(src);
		let imgInstance = new fabric.Image(img, {
			left: 10,
			top: 10,
			perPixelTargetFind: true,
			singleColor: false,
			singleColorList: [],
			...property,
			id: generateUUID()
		});
		let scale = img.width >= img.height ? (w * 0.3) / img.width : (h * 0.3) / img.height;
		imgInstance.scale(scale).setCoords();
		this.c.add(imgInstance);
		this.c.setActiveObject(imgInstance);
		this.c.requestRenderAll();
		this.position('center');
		return imgInstance;
	},
	async replaceImg(src, property) {
		let img = await this.loadImage(src);
		let canvas = this.c,
			selectedEle = this.c.getActiveObject(),
			sw = selectedEle.width * selectedEle.scaleX,
			sh = selectedEle.height * selectedEle.scaleY;
		let ow = img.width,
			oh = img.height,
			scale,
			top,
			left;
		scale = Math.sqrt((sw * sw + sh * sh) / (ow * ow + oh * oh));
		top = sh - oh * scale;
		left = sw - ow * scale;
		selectedEle.setElement(img);
		selectedEle
			.set({
				scaleX: scale,
				scaleY: scale,
				top: selectedEle.top + top / 2,
				left: selectedEle.left + left / 2,
				singleColor: false,
				singleColorList: [],
				...property,
			})
			.setCoords();
		this.applyFilter(0,null);
		canvas.requestRenderAll();
		setTimeout(()=>{
			this.updateMiniMap();
		},0)
		return selectedEle
	},
	isEmptyCanvas() {
		let arr = this.c.getObjects().filter((o) => {
			return o.id !== "workspace" && o.id !== "stickerShape";
		})
		return arr.length <= 0;
	},

	copyTemplateData(){
		if (this.designDes === 'quote') {
			return this.getJson();
		} else {
			alert('half error')
		}
	},

	pasteTemplateData(data){
		if(!data){
			return false;
		}
		console.log(JSON.parse(data));
		try {
			this.loadJson(JSON.parse(data));
		} catch (error) {
			
		}
	},

	//获取模板数据
	getTemplateData() {
		if (this.designDes === 'quote') {
			let designPostionToName = this.designPostionToName
			let canvasData = {};
			//保存画布的设计到位置信息
			this.positionConfigs[this.activePosition].templateData = this.getJson();
			this.positionConfigs.forEach(item => {
				let key = designPostionToName[item.designPostion];
				let templateData = item.templateData;
				let findWorker = templateData && templateData.objects && templateData.objects.find(item => item.id === "workspace");
				if (findWorker) {
					findWorker.fill = null;
				}
				canvasData[key] = item.templateData
			})
			return canvasData
		} else {
			alert('half error')
		}
	},
	//获取模板图
	async getTemplatePicPath(quantity=1,isBase64=false) {
		let templateData = this.getTemplateData();
		let picArr = [];
		for (let i in templateData) {
			if(templateData[i]){
				if(this.isSticker){
					const stickerShape = JSON.stringify(this.getStickerShape());
					let findIndex = templateData[i].objects.findIndex(item=>{
						return item.id === "workspace"
					})
					if(findIndex>-1){
						templateData[i].objects.splice(findIndex+1,0, JSON.parse(stickerShape))
					}
				}
				picArr.push(await this.getImgUrl(this.c, templateData[i], quantity))
			}
		}
		let url = await mergeImg(picArr);
		if(isBase64){
			return url;
		}
		let file = dataURLtoFile(url, 1);
		url = await uploadFile(file,{
			systemName:'customOss'
		});
		return url;
	},
	applyFilter(index, filter) {
		let canvas = this.c
		let obj = canvas.getActiveObject();
		obj.filters[index] = filter;
		obj.applyFilters();
		canvas.renderAll();
	},
	selected(actives) {
		if (actives && actives.length === 1) {
			this.mSelectMode = "one";
			this.mSelectId = actives[0].id;
			this.mSelectOneType = actives[0].type;
			this.mSelectIds = actives.map((item) => item.id);
		} else if (actives && actives.length > 1) {
			this.mSelectMode = "multiple";
			this.mSelectId = "";
			this.mSelectIds = actives.map((item) => item.id);
		} else {
			this.mSelectId = "";
			this.mSelectIds = [];
			this.mSelectMode = "";
			this.mSelectOneType = "";
		}
	},
	setBase(data) {
		// if(!this.hasBg){
		// 	return data;
		// }
		let findBase = data.objects.find(item => item.type === "image");
		if (!findBase) {
			return data
		}
		findBase.selectable = true;
		findBase.hasControls = true;
		findBase.evented = true;
		findBase.isBase = 1;
		return data;
	},
	multiselectCenter(){
		let canvas = this.c;
		canvas.discardActiveObject();
		let sel = new fabric.ActiveSelection(canvas.getObjects().filter((o) => {
			return o.id !== "workspace";
		}), {
			canvas: canvas,
		});
		canvas.setActiveObject(sel);
		this.position('center');
		canvas.discardActiveObject();
		canvas.requestRenderAll();
	},
	async loadTemplate(data) {
		if (!data) {
			return false;
		}
		try {
			this.pauseSave = true;
			this.initConfig();
			let newData = JSON.parse(data);
			//兼容单面模板数据
			if (!newData.frontData) {
				let obj = {};
				obj.frontData = newData;
				newData = obj;
			}
			if(this.isSticker){
				delete newData.frontData.background;
				delete newData.frontData.clipPath;
				const workspace = newData.frontData.objects.find(item=>{
					return item.id === 'workspace'
				})
				if(workspace){
					workspace.width = this.stickerOption.width;
					workspace.height = this.workspace.height;
				}
			}
			let designPostionToName = this.designPostionToName;
			for (let i = 0; i < this.positionConfigs.length; i++) {
				let item = this.positionConfigs[i];
				let key = designPostionToName[item.designPostion];
				let keyData = newData[key];
				if (!keyData) {
					break;
				}
				item.templateData = this.setBase(keyData);
				item.url = await this.getImgUrl(this.c, item.templateData, 0.4);
			}

			await this.loadJson(this.positionConfigs[this.activePosition].templateData);
			// this.multiselectCenter();
			if(this.isSticker){
				this.initStickerShape();
			}
			this.pauseSave = false;
			await this.showGrid();
			this.saveStateToHistory();
		} catch (e) {
			console.log(e);
			this.pauseSave = false;
		}
	},
	async replaceBase(data) {
		if (!data) {
			return false;
		}
		try {
			this.pauseSave = true;
			let newData = JSON.parse(data);
			//兼容单面模板数据
			if (!newData.frontData) {
				let obj = {};
				obj.frontData = newData;
				newData = obj;
			}
			//保存画布的设计到位置信息
			this.positionConfigs[this.activePosition].templateData = this.getJson();
			let designPostionToName = this.designPostionToName;
			for (let i = 0; i < this.positionConfigs.length; i++) {
				let item = this.positionConfigs[i];
				let findBase = item.templateData.objects.find(item => item.type === "image");
				let key = designPostionToName[item.designPostion];
				let keyData = newData[key];
				let findReplaceBase = keyData.objects.find(item => item.type === "image");
				if (!findBase || !keyData || !findReplaceBase) {
					break;
				}
				findBase.src = findReplaceBase.src;
				findBase.width = findReplaceBase.width;
				findBase.height = findReplaceBase.height;
				findBase.scaleX = findReplaceBase.scaleX;
				findBase.scaleY = findReplaceBase.scaleY;
				findBase.paltingShade = colorRgb('#da9f19');
				findBase.paltingSubShade = colorRgb('#fcdc7d')
				item.url = await this.getImgUrl(this.c, item.templateData, 0.4);
			}
			await this.loadJson(this.positionConfigs[this.activePosition].templateData);
			this.pauseSave = false;
		} catch (e) {
			await this.loadTemplate(data)
			this.pauseSave = false;
		}
	},
	getArtId() {
		let activeEle = this.c.getActiveObject();
		if (!activeEle || activeEle.type!=='image') {
			return false;
		}
		return activeEle.get('artId');
	},
	getArtImg() {
		let activeEle = this.c.getActiveObject();
		if (!activeEle || activeEle.type!=='image') {
			return false;
		}
		return activeEle.getSrc();
	},
	arrToRgb(colorArray) {
		// 确保数组长度为 3
		if (colorArray.length !== 3) {
			throw new Error('Invalid color array length. Expected length is 3.');
		}
		// 将颜色数组的元素转换为字符串，并拼接为 RGB 颜色值
		let rgbColor = 'rgb(' + colorArray.join(', ') + ')';
		return rgbColor;
	},
	rgbToArr(rgbColor) {
		if (!rgbColor) {
			return false;
		}
		// 去除 RGB 颜色值中的空格
		let color = rgbColor.replace(/\s/g, '');

		// 提取颜色值的字符串部分，去除 "rgb(" 和 ")"
		let colorValues = color.substring(4, color.length - 1);

		// 将颜色值字符串分割为 R、G、B 三个部分
		let values = colorValues.split(',');

		// 将字符串转换为数字，并存储到数组中
		let colorArray = values.map(function (value) {
			return parseInt(value, 10);
		});
		return colorArray;
	},
	async setElectroplate(url){
		try {
			let color1 = this.electroplate.paltingShade;
			let color2 = this.electroplate.paltingSubShade;
			// 创建一个新的 Canvas 元素
			let canvas = document.createElement('canvas');
			let ctx = canvas.getContext('2d');
			let image = await this.loadImage(url);
			// 设置 Canvas 大小与图片尺寸相同
			canvas.width = image.width;
			canvas.height = image.height;
			// 在 Canvas 上绘制图片
			ctx.drawImage(image, 0, 0);
			// 获取图片的像素数据
			let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
			let data = imageData.data;
			// 替换颜色1
			let replaceColor1 = this.rgbToArr(colorRgb('#da9f19')); // 要替换的颜色1，例如红色
			let newColor1 = this.rgbToArr(colorRgb(color1)); // 替换后的颜色1，例如绿色
			// 替换颜色2
			let replaceColor2 = this.rgbToArr(colorRgb('#fcdc7d')); // 要替换的颜色2，例如蓝色
			let newColor2 = this.rgbToArr(colorRgb(color2)); // 替换后的颜色2，例如黄色
			let tolerance = 10 //容差
			// 修改图片的颜色
			for (let i = 0; i < data.length; i += 4) {
				let red = data[i];
				let green = data[i + 1];
				let blue = data[i + 2];
				// 替换颜色1
				// 计算颜色相似度
				let similarity = Math.abs(red - replaceColor1[0]) +
					Math.abs(green - replaceColor1[1]) +
					Math.abs(blue - replaceColor1[2]);

				// 如果颜色相似度在容差范围内，则替换颜色
				if (similarity <= tolerance) {
					data[i] = newColor1[0];
					data[i + 1] = newColor1[1];
					data[i + 2] = newColor1[2];
				}
				// 替换颜色2
				// 计算颜色相似度
				let similarity2 = Math.abs(red - replaceColor2[0]) +
					Math.abs(green - replaceColor2[1]) +
					Math.abs(blue - replaceColor2[2]);

				// 如果颜色相似度在容差范围内，则替换颜色
				if (similarity2 <= tolerance) {
					data[i] = newColor2[0];
					data[i + 1] = newColor2[1];
					data[i + 2] = newColor2[2];
				}
			}
			// 将修改后的像素数据放回 Canvas
			ctx.putImageData(imageData, 0, 0);
			return canvas.toDataURL()
		} catch (e) {
		}
	},
	async changeElectroplate() {
		try {
			let color1 = this.electroplate.paltingShade;
			let color2 = this.electroplate.paltingSubShade;
			if (!color1 || !color2) {
				return false;
			}
			this.pauseSave = true;
			//保存画布的设计到位置信息
			this.positionConfigs[this.activePosition].templateData = this.getJson();
			let positionConfigs = this.positionConfigs;
			for (let j = 0; j < positionConfigs.length; j++) {
				let item = positionConfigs[j];
				let templateData = item.templateData;
				let findBase = templateData.objects.find(item => item.isBase === 1);
				if (findBase) {
					// 创建一个新的 Canvas 元素
					let canvas = document.createElement('canvas');
					let ctx = canvas.getContext('2d');
					let image = await this.loadImage(findBase.src);
					// 设置 Canvas 大小与图片尺寸相同
					canvas.width = image.width;
					canvas.height = image.height;
					// 在 Canvas 上绘制图片
					ctx.drawImage(image, 0, 0);
					// 获取图片的像素数据
					let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
					let data = imageData.data;
					// 替换颜色1
					let replaceColor1 = this.rgbToArr(findBase.paltingShade) || this.rgbToArr(colorRgb('#da9f19')); // 要替换的颜色1，例如红色
					let newColor1 = this.rgbToArr(colorRgb(color1)); // 替换后的颜色1，例如绿色
					// 替换颜色2
					let replaceColor2 = this.rgbToArr(findBase.paltingSubShade) || this.rgbToArr(colorRgb('#fcdc7d')); // 要替换的颜色2，例如蓝色
					let newColor2 = this.rgbToArr(colorRgb(color2)); // 替换后的颜色2，例如黄色
					let tolerance = 0 //容差
					// 修改图片的颜色
					for (let i = 0; i < data.length; i += 4) {
						let red = data[i];
						let green = data[i + 1];
						let blue = data[i + 2];
						// 替换颜色1
						// 计算颜色相似度
						let similarity = Math.abs(red - replaceColor1[0]) +
							Math.abs(green - replaceColor1[1]) +
							Math.abs(blue - replaceColor1[2]);

						// 如果颜色相似度在容差范围内，则替换颜色
						if (similarity <= tolerance) {
							data[i] = newColor1[0];
							data[i + 1] = newColor1[1];
							data[i + 2] = newColor1[2];
						}
						// 替换颜色2
						// 计算颜色相似度
						let similarity2 = Math.abs(red - replaceColor2[0]) +
							Math.abs(green - replaceColor2[1]) +
							Math.abs(blue - replaceColor2[2]);

						// 如果颜色相似度在容差范围内，则替换颜色
						if (similarity2 <= tolerance) {
							data[i] = newColor2[0];
							data[i + 1] = newColor2[1];
							data[i + 2] = newColor2[2];
						}
					}
					// 将修改后的像素数据放回 Canvas
					ctx.putImageData(imageData, 0, 0);
					findBase.paltingShade = this.arrToRgb(newColor1);
					findBase.paltingSubShade = this.arrToRgb(newColor2);
					// findBase.src = canvas.toDataURL();
					let file = dataURLtoFile(canvas.toDataURL(), 1);
					findBase.src = await uploadFile(file,{
						systemName:'customOss'
					});
				}
				item.url = await this.getImgUrl(this.c, item.templateData, 0.4);
			}
			await this.loadJson(this.positionConfigs[this.activePosition].templateData)
			this.pauseSave = false;
		} catch (e) {
			this.pauseSave = false;
			console.log(e)
		}
	}
};
export default canvas
