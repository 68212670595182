import layout from "@/views/layout/index.vue";

const central = () => import("@/views/centralManage/index.vue");
export default {
    path: "/centralManage",
    component: layout,
    name: "centralManage",
    meta: {
        title: "平台切换中心管理",
        icon: "el-icon-refresh"
    },
    children: [
        {
            path: "",
            component: central,
            name: "central",
            meta: {
                title: "平台切换中心管理"
            }
        }
    ]
}