import layout from "@/views/layout/index.vue";
const developmenttasklist = () => import("@/views/developmentTaskList/index.vue");
const createrequirements = () => import("@/views/developmentTaskList/createRequirements.vue");
const taskdetail = () => import("@/views/developmentTaskList/taskDetail.vue");
const setting = () => import("@/views/developmentTaskList/setting.vue");

export default {
	path: "/developmentTaskList",
	name: "developmentTaskList",
	component: layout,
	meta: {
		title: "开发任务列表",
		icon: "el-icon-collection",
	},
	children: [
		{
			path: "developmenttasklist",
			component: developmenttasklist,
			name: "developmenttasklist",
			meta: {
				title: "开发任务列表",
			},
		},
		{
			path: "createrequirements",
			component: createrequirements,
			name: "createrequirements",
			meta: {
				title: "创建需求",
			},
		},
		{
			path: "taskdetail",
			component: taskdetail,
			name: "taskdetail",
			meta: {
				title: "任务详情",
			},
		},
		{
			path: "setting",
			component: setting,
			name: "setting",
			meta: {
				title: "设置",
			},
		},
	],
};
