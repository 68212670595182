import layout from "@/views/layout/index.vue";

const shipmentList = () => import("@/views/PackAndShipManage/shipmentList.vue");
export default {
	path: "/PackAndShipManage",
	name: "PackAndShipManage",
	component: layout,
	meta: {
		title: "包装出货管理",
		icon: "el-icon-box"
	},
	children: [
		{
			path: "shipmentList",
			component: shipmentList,
			name: "shipmentList",
			meta: {
				title: "出货单列表"
			}
		}
	]
};