<template>
	<div class="color-item" :class="{ active: isActive,showBorder:showHook,
	isWhiteBorder:colorRgb(colorItem.code)==='rgb(255,255,255)'}" @click="changeColor(colorItem)" :title="colorItem.pantone ? colorItem.pantone : colorItem.alt">
		<div class="color-inner-rect" :class="{isWhite:colorRgb(colorItem.code)==='rgb(255,255,255)'}" :style="{ backgroundColor: colorItem.code }">
			<b v-if="showHook" class="icon-yes"></b>
			<span v-else class="contentClass">{{colorItem.pantone}}</span>
		</div>
	</div>
</template>

<script>
	import {
		colorRgb,
	} from "@/utils/utils";
	export default {
		props: ["colorItem", "isActive", "showHook"],
		methods: {
			colorRgb(code) {
				return colorRgb(code);
			},
			changeColor(colorItem) {
				this.$emit("changeColor", colorItem);
			},
		},
	};
</script>

<style scoped lang="scss">
	.color-item {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 3.5rem;
		height: 2.75rem;
		aspect-ratio: 1;
		background: #ffffff;
		border-radius: 4px;
		cursor: pointer;
		font-size: 0;

		&.isWhiteBorder {
			border: 1px solid #CCCCCC;
		}

		&.showBorder {
			border: 1px solid #dcdfe6;
		}

		&.active {
			border: 1px solid #2996FB;

			b {
				display: block !important;
			}
		}

		&:hover {
			border: 1px solid #2996FB;

			b {
				display: block;
			}
		}

		.contentClass {
			width: 50px;
			text-align: center;
			vertical-align: middle;
			display: table-cell;
			font-size: 12px;
			font-family: Calibri;
			font-weight: 400;
			color: #ffffff;
		}

		.color-inner-rect {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 3.25rem;
			height: 2.5rem;
			border-radius: 4px;

			&.isWhite {

				b,
				.contentClass {
					color: #333333;
				}
			}

			b {
				display: none;
				color: #ffffff;
				font-size: 17px;
			}
		}
	}

	@media screen and (max-width: 768px) {
		.color-item {
			width: 38px;
			height: 34px;

			.color-inner-rect {
				width: 34px;
			}
		}
	}
</style>
