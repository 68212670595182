<template>
    <div class="content">
        <vue-scroll :ops="ops">
            <router-view></router-view>
        </vue-scroll>
    </div>
</template>

<script>
export default {
    name: "AppMain",
    data() {
        return {
            ops: {
                bar: {
                    background: '#c1c1c1',
                    opacity: 0.7,
                    onlyShowBarOnScroll: true,
                    backgroundColor:'ffe450'
                }
            }
        }
    }
};
</script>
<style scoped lang="scss">
.content {
    width: 100%;
    height: 100%;

    .contentScrollBar {
        padding: 20px;
    }
}
</style>
