import layout from "@/views/layout/index.vue";
const customCategory = () => import("@/views/custom/customcategory.vue");
const customAttributes = () => import("@/views/custom/customattributes.vue");
const customLink = () => import("@/views/custom/customlink.vue");
const customStock = () => import("@/views/custom/customstock.vue");
export default {
	path: "/custom",
	name: "custom",
	component: layout,
	meta: {
		title: "自定义产品",
		icon: "el-icon-goods"
	},
	children: [
		{
			path: "customcategory",
			name: "customcategory",
			component: customCategory,
			meta: {
				title: "自定义产品类别"
			}
		},
		{
			path: "customattributes",
			name: "customattributes",
			component: customAttributes,
			meta: {
				title: "自定义产品类别属性"
			}
		},
		{
			path: "customlink",
			name: "customlink",
			component: customLink,
			meta: {
				title: "链接产品"
			}
		},
		{
			path: "customstock",
			name: "customstock",
			component: customStock,
			meta: {
				title: "库存产品"
			}
		}
	]
};
