<template>
	<div class="ds-left-area">
		<div class="ds-left-bar">
			<div class="top-tab">
				<div class="tab-item tab-template" :class="{ active: status === 1 }" @click="showTab(1)">
					<div class="tab-icon">
						<b class="icon-a-icon-Templateszhuanhuan"></b>
					</div>
					<div class="tab-text">Templates</div>
				</div>
				<div class="tab-element">
					<div class="tab-item" :class="{ active: status === 2 }" @click="showTab(2)">
						<div class="tab-icon">
							<b class="icon-a-icon-Textzhuanhuan"></b>
						</div>
						<div class="tab-text">Add Text</div>
					</div>
					<div class="tab-item" :class="{ active: status === 3 }" @click="showTab(3)">
						<div class="tab-icon">
							<b class="icon-a-icon-Artzhuanhuan"></b>
						</div>
						<div class="tab-text">Add Cliparts</div>
					</div>
				</div>
				<div class="tab-item" :class="{ active: status === 4 }" @click="showTab(4)">
					<div class="tab-icon">
						<b class="icon-a-icon-Layerszhuanhuan-01"></b>
					</div>
					<div class="tab-text">Layers</div>
				</div>
				<div class="tab-item" :class="{ active: status === 5 }" @click="showTab(5)">
					<div class="tab-icon">
						<b class="icon-a-icon-Uploadzhuanhuan"></b>
					</div>
					<div class="tab-text">Upload</div>
				</div>
			</div>
			<div class="bottom-tab" v-if="myDesignShow">
				<div class="tab-item" :class="{ active: status === 7 }" @click="showUserDesign">
					<div class="tab-icon">
						<b class="icon-a-icon-Designzhuanhuan"></b>
					</div>
					<div class="tab-text">User Design</div>
				</div>
			</div>
		</div>
		<div class="ds-left-content">
			<div class="default-content content-box" v-show="status === 0">
				<h3>What’s Next?</h3>
				<div class="dfc-item-wrap">
					<div class="dfc-item" @click="showTab(5)">
						<b class="icon-a-icon-Uploadzhuanhuan"></b>
						<span>Upload</span>
					</div>
					<div class="dfc-item" @click="showTab(1)">
						<b class="icon-a-icon-Templateszhuanhuan"></b>
						<span>Templates</span>
					</div>
					<div class="dfc-item" @click="showTab(2)">
						<b class="icon-a-icon-Textzhuanhuan"></b>
						<span>Add Text</span>
					</div>
					<div class="dfc-item" @click="showTab(3)">
						<b class="icon-a-icon-Artzhuanhuan"></b>
						<span>Add Cliparts</span>
					</div>
				</div>
			</div>
			<div class="template-content content-box" v-show="status === 1">
				<topBar title="Offical Templates" :show-back-icon="showTemplateBackIcon" @back="templateCategoryBack"
					@close="showDefault"></topBar>
				<div style="padding: 10px">
					<search-input v-model="searchTemplateValue"
						:extend-list="templateState ? templateRecordList : templateExtendList" @input="inputTemplateValue"
						@search="searchTemplate" @focus="templateExtend"></search-input>
				</div>
				<div class="template-category" scrollbar v-show="templateStatus === 1 || templateStatus === 2">
					<div class="box-item" v-show="templateStatus === 1"
						:class="[{ active: templateCateId === null }, 'box-item-style' + templateDisplayControl]"
						@click="selectTemplateCategory({ id: null, name: 'All' })">
						<b class="icon-a-T-Allzhuanhuan"></b>
						<span class="item-name">All</span>
					</div>
					<div class="box-item"
						:class="[{ active: templateCateId === item.id }, 'box-item-style' + item.displayControl]"
						v-for="item in templateCategoryList" :key="item.id" @click="selectTemplateCategory(item)">
						<div class="imgWrap">
							<img v-if="item.displayControl === 1" loading="lazy" :src="item.icon"
								:alt="item.templateTypeName" />
							<img v-if="item.displayControl === 2" loading="lazy" :src="item.displayDrawing"
								:alt="item.templateTypeName" />
						</div>
						<span class="item-name">{{ item.templateTypeName }}</span>
					</div>
				</div>
				<noResult v-show="templateStatus === 3"></noResult>
			</div>
			<div class="text-content content-box" v-show="status === 2">
				<topBar :title="textContentTitle" :show-back-icon="showTextBackIcon" @back="textBack" @close="showDefault">
				</topBar>
				<div class="addText" v-show="textStatus === 1">
					<div class="con">
						<label class="textLabel">
							<textarea v-model="inputText" rows="4" placeholder="Enter text here"></textarea>
						</label>
						<div class="addTextBtnGroup">
							<button type="button" class="dzBtn addTextBtn curvedBtn" @click="addCurveText(inputText)">Add
								Curved Text</button>
							<button type="button" class="dzBtn addTextBtn" @click="addText(inputText)">Add Text</button>
						</div>
					</div>
				</div>
				<div class="editText" v-show="textStatus === 2">
					<div class="con" scrollbar>
						<label>
							<textarea :value="textProperty.text" rows="4" placeholder="Enter text here"
								@input="debounceChangeTextVal($event.target.value)"></textarea>
						</label>
						<div class="editWrap">
							<div class="edit-item edit-font" @click="textStatus = 3">
								<div class="edit-item-title">Font</div>
								<div class="edit-item-content">
									<div :style="{ fontFamily: textProperty.fontFamily }">
										{{ textProperty.fontFamily }}
									</div>
								</div>
								<div class="edit-item-chevron">
									<b class="icon-a-icon-arrow1zhuanhuan"></b>
								</div>
							</div>
							<div class="edit-item edit-color" @click="textStatus = 4">
								<div class="edit-item-title">Text Color</div>
								<div class="edit-item-content">
									<div class="color-name" v-show="activeTextColorName">
										{{ activeTextColorName }}
									</div>
									<div class="color-picker" :style="{ backgroundColor: textProperty.fill }"></div>
								</div>
								<div class="edit-item-chevron">
									<b class="icon-a-icon-arrow1zhuanhuan"></b>
								</div>
							</div>
							<div class="edit-item edit-angle">
								<div class="edit-item-title">Rotation</div>
								<div class="edit-item-content">
									<el-slider :show-tooltip="false" :min="-360" :max="360" :value="textProperty.angle"
										@input="changeElementProperty($event, 'angle')" show-input
										:show-input-controls="false"></el-slider>
								</div>
							</div>
							<div class="edit-item edit-spacing">
								<div class="edit-item-title">Spacing</div>
								<div class="edit-item-content">
									<el-slider :show-tooltip="false" :min="0" :max="1000" show-input :step="10"
										:value="textProperty.charSpacing"
										@input="changeElementProperty($event, 'charSpacing')"
										:show-input-controls="false"></el-slider>
								</div>
							</div>
							<div class="edit-item edit-size">
								<div class="edit-item-title">Line Height</div>
								<div class="edit-item-content">
									<el-slider :show-tooltip="false" :min="0" :max="10" :value="textProperty.lineHeight"
										@input="changeElementProperty($event, 'lineHeight')" show-input  :step="0.1"
										:show-input-controls="false"></el-slider>
								</div>
							</div>
							<!--							<div class="edit-item edit-shape" @click="textStatus = 5">-->
							<!--								<div class="edit-item-title">Text Shape</div>-->
							<!--								<div class="edit-item-content"></div>-->
							<!--								<div class="edit-item-chevron">-->
							<!--									<b class="icon-a-icon-arrow1zhuanhuan"></b>-->
							<!--								</div>-->
							<!--							</div>-->
							<div class="edit-item edit-size">
								<div class="edit-item-title">Text Size</div>
								<div class="edit-item-content">
									<el-slider :show-tooltip="false" :min="16" :max="200" :value="textProperty.fontSize"
										@input="changeElementProperty($event, 'fontSize')" show-input
										:show-input-controls="false"></el-slider>
								</div>
							</div>
							<div class="edit-item edit-outline" @click="textStatus = 6">
								<div class="edit-item-title">Outline</div>
								<div class="edit-item-content">
									<template v-if="textProperty.strokeWidth">
										<div class="color-name" v-show="activeTextOutlineColorName">
											{{ activeTextOutlineColorName }}
										</div>
										<div class="color-picker" :style="{ backgroundColor: textProperty.stroke }"></div>
										<div class="line"></div>
										<div>
											{{ outlineLabel }}
										</div>
									</template>
									<div v-else>None</div>
								</div>
								<div class="edit-item-chevron">
									<b class="icon-a-icon-arrow1zhuanhuan"></b>
								</div>
							</div>
							<div class="edit-item edit-curve" v-show="textProperty.type === 'curved-text'">
								<div class="edit-item-title">Text Curve</div>
								<div class="edit-item-content">
									<el-slider :show-tooltip="false" :value="textProperty.radian" :min="100" :max="2000"
										@input="changeElementProperty($event, 'radian')" show-input
										:show-input-controls="false"></el-slider>
								</div>
							</div>
							<div class="edit-item edit-align">
								<div class="edit-item-title">Text Alignment</div>
								<div class="edit-item-content">
									<div class="align-icon" :class="{ active: textProperty.textAlign === 'left' }"
										@click="changeElementProperty('left', 'textAlign')">
										<b class="icon-a-icon-A-leftzhuanhuan"></b>
									</div>
									<div class="align-icon" :class="{ active: textProperty.textAlign === 'center' }"
										@click="changeElementProperty('center', 'textAlign')">
										<b class="icon-a-icon-A-centerzhuanhuan"></b>
									</div>
									<div class="align-icon" :class="{ active: textProperty.textAlign === 'right' }"
										@click="changeElementProperty('right', 'textAlign')">
										<b class="icon-a-icon-A-rightzhuanhuan"></b>
									</div>
								</div>
							</div>
							<div class="edit-item edit-decoration">
								<div class="edit-item-title">Text Decoration</div>
								<div class="edit-item-content">
									<div class="align-icon" :class="{ active: textProperty.underline }"
										@click="changeElementProperty(!textProperty.underline, 'underline')">
										<b class="icon-a-icon-uzhuanhuan"></b>
									</div>
									<div class="align-icon" :class="{ active: textProperty.linethrough }"
										@click="changeElementProperty(!textProperty.linethrough, 'linethrough')">
										<b class="icon-a-icon-A-Uzhuanhuan"></b>
									</div>
								</div>
							</div>
						</div>
						<!--						<div class="applyAll">-->
						<!--							<el-button type="primary" @click="applyAllText">Apply to All Text Layers</el-button>-->
						<!--						</div>-->
					</div>
				</div>
				<div class="editTextFont" v-show="textStatus === 3">
					<div class="con">
						<div class="font-button-wrap">
							<div class="arrow-btn" @click="backFontPreview">
								<b class="icon-back"></b>
								Back to Previous
							</div>
							<div class="recent-btn" @click="showRecentUsedFont"
								v-show="!showFontFamilyHistoryStatus && fontFamilyListHistory.length">
								<b class="icon-recent"></b>
								Recently Used Fonts
							</div>
						</div>
						<div class="font-wrap" scrollbar>
							<div class="font-item" v-for="item in currentFontList" :key="item"
								@click="changeFontFamily(item)">
								<div class="font-text" :style="{ fontFamily: item }">
									{{ textProperty.text }}
								</div>
								<div class="font-name">
									{{ item }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="editTextColor" v-show="textStatus === 4">
					<div class="con">
						<div class="editColor-colorPicker">
							<div class="editColor-colorPicker-title">Select color</div>
							<div class="editColor-colorPicker-selected">
								<ColorPickerItem :colorItem="{
									code: textProperty.fill
								}"></ColorPickerItem>
								<span class="color-name">{{ activeTextColorName }}</span>
							</div>
							<div class="editColor-colorPicker-title">
								Default Raised Metal Color
							</div>
							<div class="editColor-colorPicker-selected2" scrollBar>
								<div class="metsil-color-div" @click="metalColor(item,'text')" v-for="item in defaultMetalColorList" :key="item.id">
									<div class="metsil-color" :style="{ backgroundColor: item.code }"></div>
								</div>
							</div>
							<div class="editColor-colorPicker-title">
								Raised Metal Color:
							</div>
							<div class="editColor-colorPicker-selected2" scrollBar>

								<div class="metsil-color-div" @click="metalColor(item,'text')"
									v-for="item in raisedMetalColorList" :key="item.id">
									<div class="recessed-metsil-color" :style="{ backgroundColor: item.code }" :title="item.alt"></div>
								</div>
							</div>
							<div class="editColor-colorPicker-title">
								Recessed Metal Color
							</div>
							<div class="editColor-colorPicker-selected2" scrollBar>
								<div class="metsil-color-div" @click="metalColor(item,'text')"
									v-for="item in metalColorList" :key="item.id">
									<div class="recessed-metsil-color" :style="{ backgroundColor: item.code }" :title="item.alt"></div>
								</div>
							</div>

							<div class="editColor-colorPicker-title" v-show="historyTextColorRecords.length != 0">
								Selected History</div>
							<div class="editColor-colorPicker-history" v-show="historyTextColorRecords.length != 0">
								<ColorPickerItem :colorItem="item" :isActive="item.code === textProperty.fill"
									v-for="(item, index) in historyTextColorRecords" :key="index"
									@changeColor="changeElementProperty($event.code, 'fill', $event, 'textColor')">
								</ColorPickerItem>
							</div>
							<div class="editColor-colorPicker-colorContainer" scrollbar>
								<div class="editColor-colorPicker-colors">
									<ColorPickerItem :colorItem="item" :isActive="item.code === textProperty.fill"
										v-for="(item, index) in colorList" :key="index"
										@changeColor="changeElementProperty($event.code, 'fill', $event, 'textColor')">
									</ColorPickerItem>
								</div>
							</div>
						</div>
						<div class="editTextColor-foot">
							<el-button type="primary" @click="textStatus = 2">Done</el-button>
						</div>
					</div>
				</div>
				<div class="editTextShape" v-show="textStatus === 5">
					<div class="con" scrollbar>
						<div class="title">
							Text Shape
						</div>
						<div class="textShapeList">
							<div class="shape-item" v-for="item in textShapeList" :key="item.name"
								@click="changeTextShape(item)">
								{{ item.name }}
							</div>
						</div>
						<div class="title">
							Choose shape settings
						</div>
						<div class="slider">
							<div class="slider-slider">
								<el-slider :show-tooltip="false" :min="0" :max="5" show-stops :step="1"></el-slider>
							</div>
							<div class="slider-label">
								<div>Down</div>
								<div>Up</div>
							</div>
						</div>
					</div>
				</div>
				<div class="editTextOutline" v-show="textStatus === 6">
					<div class="con">
						<div class="outline-width">
							<div class="outline-width-title">Choose outline thickness</div>
							<div class="outline-slider">
								<div class="slider-slider">
									<el-slider :show-tooltip="false" :min="0" :max="5" show-stops :step="1"
										:value="textProperty.strokeWidth"
										@input="changeElementProperty($event, 'strokeWidth')"></el-slider>
								</div>
								<div class="slider-label">
									<div>None</div>
									<div>Very Thick</div>
								</div>
							</div>
						</div>
						<div class="outline-color">
							<div class="outline-color-title">Choose outline color</div>
							<div class="outline-stroke-color">
								<div class="outline-stroke-color-selected">
									<ColorPickerItem :colorItem="{ code: textProperty.stroke }"></ColorPickerItem>
									<span class="color-name">{{ activeTextOutlineColorName }}</span>
								</div>
								<div class="outline-stroke-color-colorPicker" scrollbar>
									<ColorPickerItem :colorItem="item" :isActive="item.code === textProperty.stroke"
										v-for="(item, index) in colorList" :key="index"
										@changeColor="changeElementProperty($event.code, 'stroke')"></ColorPickerItem>
								</div>
							</div>
						</div>
						<div class="outline-btn">
							<el-button @click="removeOutline">Remove Outline</el-button>
							<el-button type="primary" @click="textStatus = 2">Done</el-button>
						</div>
					</div>
				</div>
			</div>
			<div class="art-content content-box" v-show="status === 3">
				<topBar :title="artContentTitle" :show-back-icon="showArtBackIcon" @back="artBack" @close="showDefault">
				</topBar>
				<div style="padding: 10px" v-show="artStatus === 1 || artStatus === 2 || artStatus === 4">
					<search-input v-model="searchArtValue" :extend-list="artState ? artRecordList : artExtendList"
						@input="inputArtValue" @search="searchArt" @focus="artExtend"></search-input>
				</div>
				<div class="art-category" v-show="artStatus === 1 || artStatus === 2">
					<div class="con" scrollbar>
						<div class="box-item" v-show="artStatus === 1"
							:class="[{ active: clipartTypeId === null }, 'box-item-style' + artDisplayControl]"
							@click="selectArtCategory({ id: null, name: 'All' })">
							<b class="icon-a-T-Allzhuanhuan"></b>
							<span class="item-name">All</span>
						</div>
						<div class="box-item"
							:class="[{ active: clipartTypeId === item.id }, 'box-item-style' + item.displayControl]"
							v-for="item in artCategoryList" :key="item.id" @click="selectArtCategory(item)">
							<div class="imgWrap">
								<img v-if="item.displayControl === 1" loading="lazy" :src="item.icon"
									:alt="item.clipartTypeName" />
								<img v-if="item.displayControl === 2" loading="lazy" :src="item.displayDrawing"
									:alt="item.clipartTypeName" />
							</div>
							<span class="item-name">{{ item.clipartTypeName }}</span>
						</div>
					</div>
				</div>
				<div class="editImg" v-show="artStatus === 3">
					<div class="con" scrollBar>
						<div class="currentPic">
							<div class="imgWrap linear-gradient-1">
								<img :src="imageProperty.picPath" alt="" />
							</div>
							<div class="imgReplaceArea">
								<div>Current Art</div>
								<el-button type="primary" @click="showReplaceDialog"><b
										class="icon-a-icon-Replacezhuanhuan"></b>
									Replace
								</el-button>
							</div>
						</div>
						<div class="editWrap">
							<div class="edit-item edit-angle">
								<div class="edit-item-title">Rotation</div>
								<div class="edit-item-content">
									<el-slider :show-tooltip="false" :min="-360" :max="360" :value="imageProperty.angle"
										@input="changeElementProperty($event, 'angle')" show-input
										:show-input-controls="false"></el-slider>
								</div>
							</div>
							<div class="edit-item edit-size">
								<div class="edit-item-title">Size</div>
								<div class="edit-item-content">
									<el-slider :show-tooltip="false" :min="0.01" :max="5" :step="0.01"
										:value="imageProperty.scale" @input="changeElementProperty($event, 'scale')"
										show-input :show-input-controls="false"></el-slider>
								</div>
							</div>
							<!--							<div class="edit-item edit-base">-->
							<!--								<div class="edit-item-title">As a base</div>-->
							<!--								<div class="edit-item-content">-->
							<!--									<el-switch v-model="imageProperty.IsMain" :active-value="1" :inactive-value="0"-->
							<!--											   @change="changeBaseImage"></el-switch>-->
							<!--								</div>-->
							<!--							</div>-->
							<div class="edit-item edit-color" @click="showEditImgColor = !showEditImgColor">
								<div class="edit-item-title">Edit Color</div>
								<div class="edit-item-content">
									<div class="color-picker" style="border-radius: 4px;width: 34px;height: 34px;"
										v-show="index < 4" v-for="(item, index) in selectedImgColorList" :key="item.color"
										:style="{ backgroundColor: item.color }"></div>
								</div>
								<div class="edit-item-chevron">
									<b class="icon-back" style="transform: rotate(-90deg)"></b>
								</div>
							</div>
							<div class="editImgColor" v-show="showEditImgColor" v-loading="loadChangeColor">
								<div class="editColor-colorPicker">
									<div class="editColor-colorPicker-title">
										<span>Choose Which Color to Change:</span>
										<!-- <div>
											<span>Single Color </span>
											<el-switch :value="imageProperty.singleColor" @change="changeSingleColor">
											</el-switch>
										</div> -->
									</div>
									<div class="editColor-colorPicker-selected" scrollBar>
										<dSColorPickerRectItem :colorItem="{ code: item.color }"
											:isActive="item.color === selectedImgColor" :showHook="true"
											v-for="item in imageProperty.singleColor ? imageProperty.singleColorList : selectedImgColorList"
											:key="item.color" @changeColor="selectImgColor(item.color)">
										</dSColorPickerRectItem>
									</div>
									<div class="editColor-colorPicker-title">
										Default Raised Metal Color
									</div>
									<div class="editColor-colorPicker-selected" scrollBar>
										<div class="metsil-color-div" @click="metalColor(item,'picture')" v-for="item in defaultMetalColorList" :key="item.id">
											<div class="metsil-color" :style="{ backgroundColor: item.code }"></div>
										</div>
									</div>
									<div class="editColor-colorPicker-title">
										Raised Metal Color:
									</div>
									<div class="editColor-colorPicker-selected" scrollBar>

										<div class="metsil-color-div" @click="metalColor(item,'picture')"
											v-for="item in raisedMetalColorList" :key="item.id">
											<div class="recessed-metsil-color" :style="{ backgroundColor: item.code }" :title="item.alt"></div>
										</div>
									</div>
									<div class="editColor-colorPicker-title">
										Recessed Metal Color:
									</div>
									<div class="editColor-colorPicker-selected" scrollBar>

										<div class="metsil-color-div" @click="metalColor(item,'picture')"
											v-for="item in metalColorList" :key="item.id">
											<div class="recessed-metsil-color" :style="{ backgroundColor: item.code }" :title="item.alt"></div>
										</div>
									</div>



									<!-- 父子级颜色选择区域 -->
									<div class="parentAndChildPanel" v-show="selectedImgColor">
										<div class="editColor-colorPicker-title"
											style="position: absolute;left:0;height:22px;">
											Choose Color Classification{{ selectedImgReplaceColor ? ': ' + selectedColorNow : '' }}
										</div>
										<div style="position: absolute;right:34px;" class="searchInput" ref="searchInput">
											<span class="suffix-inner">
												<b v-if="inputColorValue == ''" class="icon-a-uspp-sousuozhuanhuan"
													@click="selectedColorCategoryId = ''; colorCardList = colorList; showOops = false; showChild = true;"></b>
												<b v-else class="icon-guanbi"
													@click="inputColorValue = ''; selectedColorCategoryId = ''; colorCardList = colorList; showOops = false; showChild = true;"
													style="font-size:14px;"></b>
											</span>
											<input type="text" class="inputSearch" placeholder="Search Pantone Color"
												v-model="inputColorValue" @input="debouncedColorValue()">
										</div>
									</div>
									<div class="editColor-colorPicker-colorContainer" v-show="selectedImgColor">
										<!-- 父级颜色 -->
										<div class=" parentGrid">
											<dSColorPickerParentRectItem :colorItem="item" :showChild="showChild"
												:isActive="item.id === selectedColorCategoryId"
												v-for="(item, index) in colorType" :key="index"
												@changeColor="changeColorCategory($event.id)"></dSColorPickerParentRectItem>
										</div>
										<!-- 子级颜色 -->
										<div v-show="showChild" class="editColor-colorPicker-colors childrenPanel"
											scrollBar>
											<dSColorPickerRectItem :colorItem="item" :showHook="false"
												:isActive="item.code === selectedImgReplaceColor"
												v-for="(item, index) in colorCardList" :key="index"
												@changeColor="selectImgReplaceColor($event.code, $event)">
											</dSColorPickerRectItem>
										</div>
										<!-- 搜索为空展示oops -->
										<div v-show="showOops">
											<div class="imgWrapOops">
												<img src="../../assets/image/noresults.png" alt="">
											</div>
											<div class="tip">Sorry, no results, please enter another Pantone color number!
											</div>
										</div>
									</div>
									<div class="editColor-colorPicker-title" v-show="historyPicColorRecords.length != 0">
										Selected History</div>
									<div class="editColor-colorPicker-history" v-show="historyPicColorRecords.length != 0">
										
										<dSColorPickerRectItem :colorItem="item"
											:isActive="item.code === selectedImgReplaceColor"
											v-for="(item, index) in historyPicColorRecords" :key="index"
											@changeColor="selectImgReplaceColor($event.code, $event)">
										</dSColorPickerRectItem>
									</div>
									<!-- <div class="editColor-colorPicker-selected" scrollBar>
										<ColorPickerItem :colorItem="{ code: item.color }" :isActive="item.color === selectedImgColor"  v-for="item in imageProperty.singleColor ? imageProperty.singleColorList : selectedImgColorList" :key="item.color" @changeColor="selectImgColor(item.color)">
										</ColorPickerItem>
									</div>
									<div class="editColor-colorPicker-title" v-show="selectedImgColor">Choose the replaced color:</div>
									<div class="editColor-colorPicker-colorContainer" v-show="selectedImgColor">
										<div class="editColor-colorPicker-colors" scrollBar>
											<ColorPickerItem :colorItem="item" :isActive="item.code === selectedImgReplaceColor" v-for="(item, index) in colorList" :key="index" @changeColor="selectImgReplaceColor($event.code)"></ColorPickerItem>
										</div>
									</div> -->
								</div>
							</div>
						</div>
					</div>
				</div>
				<noResult v-show="artStatus === 4"></noResult>
			</div>
			<div class="layer-content content-box" v-show="status === 4">
				<topBar title="Layers" :show-back-icon="false" @close="showDefault"></topBar>
				<div class="con" scrollbar v-if="layersArr.length > 0">
					<draggable class="list-group" tag="div" :fallback-tolerance="1" :force-fallback="true" id="layerWrap"
						v-model="layersArr" v-bind="dragOptions" @change="changeLayer">
						<template v-for="item in layersArr">
							<div :key="item.id">
								<div class="layer-item" :draggable="true" @click="selectedItem(item)">
									<div class="layer-left">
										<img :src="item.getSrc()" alt="" v-if="item.type === 'image'" />
										<div class="layer-text"
											v-if="item.type === 'text' || item.type === 'curved-text' || item.type === 'i-text'">
											<b class="icon-a-icon-Textzhuanhuan"></b>
											{{ item.text }}
										</div>
										<div v-if="item.type === 'path'">
											{{ item.type }}
										</div>
										<div v-if="item.type === 'group'">
											<span style="cursor: pointer" @click.stop="showChildren(item)"><b
													class="icon-a-icon-arrow1zhuanhuan"
													:class="{ showChildren: item.showChildren }"></b></span>
											<span style="margin: 0 4px;"><b class="icon-a-icon-Groupzhuanhuan1"></b></span>
											<span>Group{{ item.count }}</span>
										</div>
									</div>
									<div class="layer-right">
										<div class="layer-icon" @click.stop="toggleViewElement(item)">
											<b :class="item.visible ? 'icon-view' : 'icon-view_off'"></b>
										</div>
										<div class="layer-icon" @click.stop="toggleLockElement(item)">
											<b
												:class="item.selectable ? 'icon-a-icon-szhuanhuan' : 'icon-a-icon-gbzhuanhuan'"></b>
										</div>
									</div>
								</div>
								<div v-if="item.type === 'group' && item.get('showChildren')">
									<div class="layer-item isChildren" :draggable="false" :key="citem.id"
										v-for="citem in item.getObjects()">
										<div class="layer-left">
											<img :src="citem.getSrc()" alt="" v-if="citem.type === 'image'" />
											<div class="layer-text"
												v-if="citem.type === 'text' || citem.type === 'curved-text' || citem.type === 'i-text'">
												<b class="icon-a-icon-Textzhuanhuan"></b>
												{{ citem.text }}
											</div>
											<div v-if="citem.type === 'path'">
												{{ citem.type }}
											</div>
										</div>
										<div class="layer-right">
											<div class="layer-icon" @click.stop="toggleViewElement(citem)">
												<b :class="citem.visible ? 'icon-view' : 'icon-view_off'"></b>
											</div>
										</div>
									</div>
								</div>
							</div>
						</template>
					</draggable>
				</div>
			</div>
			<div class="upload-content content-box" v-show="status === 5">
				<topBar title="Upload" :show-back-icon="false" @close="showDefault"></topBar>
				<div class="con" scrollbar>
					<div class="topArea">
						<div>Choose a file to upload</div>
						<div class="upload-area" v-loading="uploadLoading" id="uploadBox" @click="triggerUpload('upload1')"
							@drop="handleDrop" @dragover.prevent>
							<div class="upload-icon">
								<b class="icon-a-icon-Uploadzhuanhuan"></b>
							</div>
							<div class="upload-text">
								Drag the file here, or <span>browse</span><br />
								Max. File Size: 10MB
							</div>
							<div class="upload-tips">.JPG.JPEG.PNG.SVG.GIF.BMP.PDF.PSD.AI</div>
							<input type="file" ref="upload1" accept="image/*,.pdf,.psd,.ai" @change="uploadOnChange" />
						</div>
						<div class="tips">Remark: If you need to upload other types of images, please contact us. We will
							solve it for you in time.</div>
					</div>
					<div class="bottomArea" v-show="uploadHistoryList.length">
						<div class="title">
							<div class="title-left">Upload History</div>
							<div class="title-right" @click="deleteAllHistory">
								<b class="icon-shanchu"></b>
								<span>Clear All</span>
							</div>
						</div>
						<div class="upload-history" scrollbar>
							<div class="history-item linear-gradient-1" v-for="(item, index) in uploadHistoryList"
								:key="index">
								<img :src="item.picPath" alt="" />
								<div class="list-item-btn">
									<div title="add" @click="addImg(item.picPath)">
										<b class="icon-plus"></b>
									</div>
									<div title="delete" @click="delUploadHistory(item.id)">
										<b class="icon-shanchu"></b>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<base-dialog v-model="cropperDialog" v-if="device !== 'mb'" width="50%">
			<div class="title">Crop Area</div>
			<div class="cropper-icon">
				<el-button @click="changeScale(1)"><b class="icon-a-tgsc-addzhuanhuan"></b></el-button>
				<el-button @click="changeScale(-1)"><b class="icon-a-tgsc-jzhuanhuan"></b></el-button>
			</div>
			<div class="cropper">
				<vue-cropper ref="cropper" :img="imageSrc" :output-width="300" :output-height="300" :output-type="'png'"
					:guides="true" :viewMode="1" :dragMode="'move'" :background="false" :rotatable="true" :scalable="true"
					:zoomable="true" :cropBoxMovable="true" :cropBoxResizable="true" :aspect-ratio="2"
					:auto-crop="true"></vue-cropper>
			</div>
			<div class="cropper-btn">
				<el-button @click="cropperDialog = false">Cancel</el-button>
				<el-button type="primary" @click="cropImage">Continue</el-button>
			</div>
		</base-dialog>
	</div>
</template>
<script>
import { VueCropper } from 'vue-cropper'
import BaseDialog from "@/components/Quote/BaseDialog.vue";
import ColorPickerItem from "@/components/MyDzxt/ColorPickerItem.vue";
import dSColorPickerRectItem from "@/components/MyDzxt/dSColorPickerRectItem.vue";
import dSColorPickerParentRectItem from "@/components/MyDzxt/dSColorPickerParentRectItem.vue";
import draggable from "vuedraggable";
import { changeImgColor, colorRgb, dataURLtoFile, debounce, pdfToImg, urlAddVersion } from "@/utils/utils";
import {
	getColorCardByTypeId, getColorCardByColorName,
	addUploadHistory, getArtCateList, getUploadHistory, deleteUploadHistory, getTemplateTypeListFromApp, likeAllString, getAllTemplateNameAndTypeName
} from "@/api/newDzxt";
import { uploadFile } from "@/utils/oss";
import searchInput from "@/components/MyDzxt/searchInput.vue";
import topBar from "@/components/MyDzxt/topBar.vue";
import noResult from "@/components/MyDzxt/noResult.vue";
import dzMixin from "@/mixins/dzMixin";
import { generateUUID } from "@/utils/utils";

export default {
	mixins: [dzMixin],
	components: { ColorPickerItem, draggable, searchInput, topBar, noResult, BaseDialog, VueCropper, dSColorPickerRectItem, dSColorPickerParentRectItem },
	data() {
		return {
			defaultMetalColorList:[
				{
					id: 0,
					code: '#fcdc7d',
					alt:""
				}
			],
			raisedMetalColorList: [
				{
					id: 0,
					code: 'rgb(218, 159, 25)',
					alt:"Shiny Gold Finish"
				},
				{
					id: 1,
					code: 'rgb(170, 168, 159)',
					alt:"Shiny Silver Finish"
				},
				{
					id: 2,
					code: 'rgb(207, 140, 104)',
					alt:"Shiny Copper Finish"
				},
				{
					id: 3,
					code: 'rgb(86, 84, 81)',
					alt:"Shiny Black Nickel Finish"
				},
				{
					id: 4,
					code: 'rgb(170, 155, 122)',
					alt:"Antique Gold Finish"
				},
				{
					id: 5,
					code: 'rgb(116, 120, 113)',
					alt:"Antique Silver Finish"
				},
				{
					id: 6,
					code: 'rgb(126, 104, 92)',
					alt:"Antique Copper Finish"
				},
				{
					id: 7,
					code: 'rgb(176, 174, 132)',
					alt:"Antique Brass"
				}
			],
			metalColorList: [
				{
					id: 0,
					code: '#FCDC7D',
					alt:"Shiny Gold Finish"
				},
				{
					id: 1,
					code: '#D6D4C9',
					alt:"Shiny Silver Finish"
				},
				{
					id: 2,
					code: '#EAB6A5',
					alt:"Shiny Copper Finish"
				},
				{
					id: 3,
					code: '#999289',
					alt:"Shiny Black Nickel Finish"
				},
				{
					id: 4,
					code: '#ECD47D',
					alt:"Antique Gold Finish"
				},
				{
					id: 5,
					code: '#AAADA6',
					alt:"Antique Silver Finish"
				},
				{
					id: 6,
					code: '#B08D71',
					alt:"Antique Copper Finish"
				},
				{
					id: 7,
					code: '#808066',
					alt:"Antique Brass"
				}
			],
			myDesignShow: true,
			inputRefName: '',
			selectedColorCategoryId: -1,//选择的颜色分类
			showChild: false,
			showOops: false,
			debouncedColorValue: null,
			colorCardList: [],//子级色卡数组
			historyPicColorRecords: [],
			historyTextColorRecords: [],
			selectedColorNow: '',
			inputColorValue: '',
			imageSrc: "",
			cropperDialog: false,
			loadChangeColor: false,
			singleColor: false,
			artDisplayControl: 0,
			templateDisplayControl: 0,
			searchArtValue: "",
			myDesignDialog: false,
			status: 0,
			artStatus: 1,
			textStatus: 1,
			templateStatus: 1,
			topTabList: [
				{
					icon: "icon-a-icon-Templateszhuanhuan",
					status: 1,
					name: "Templates"
				},
				{
					icon: "icon-a-icon-Textzhuanhuan",
					status: 2,
					name: "Add Text"
				},
				{
					icon: "icon-a-icon-Artzhuanhuan",
					status: 3,
					name: "Add Cliparts"
				},
				{
					icon: "icon-a-icon-Layerszhuanhuan-01",
					status: 4,
					name: "Layers"
				},
				{
					icon: "icon-a-icon-Uploadzhuanhuan",
					status: 5,
					name: "Upload"
				}
			],
			searchTemplateValue: "",
			LoginDialogStatus: false,
			uploadHistoryList: [],
			selectedElement: "",
			textShapeList: [
				{
					name: "normal",
					url: "https://customed-center.oss-accelerate.aliyuncs.com/web/quoteManage/20221226/2060P7Y8DpeQ.jpg"
				},
				{
					name: "curve",
					url: "https://customed-center.oss-accelerate.aliyuncs.com/web/quoteManage/20221226/2060P7Y8DpeQ.jpg"
				},
				{
					name: "arch",
					url: "https://customed-center.oss-accelerate.aliyuncs.com/web/quoteManage/20221226/2060P7Y8DpeQ.jpg"
				},
				{
					name: "bridge",
					url: "https://customed-center.oss-accelerate.aliyuncs.com/web/quoteManage/20221226/2060P7Y8DpeQ.jpg"
				},
				{
					name: "valley",
					url: "https://customed-center.oss-accelerate.aliyuncs.com/web/quoteManage/20221226/2060P7Y8DpeQ.jpg"
				},
				{
					name: "pinch",
					url: "https://customed-center.oss-accelerate.aliyuncs.com/web/quoteManage/20221226/2060P7Y8DpeQ.jpg"
				},
				{
					name: "bulge",
					url: "https://customed-center.oss-accelerate.aliyuncs.com/web/quoteManage/20221226/2060P7Y8DpeQ.jpg"
				},
				{
					name: "perspective",
					url: "https://customed-center.oss-accelerate.aliyuncs.com/web/quoteManage/20221226/2060P7Y8DpeQ.jpg"
				},
				{
					name: "pointed",
					url: "https://customed-center.oss-accelerate.aliyuncs.com/web/quoteManage/20221226/2060P7Y8DpeQ.jpg"
				},
				{
					name: "downward",
					url: "https://customed-center.oss-accelerate.aliyuncs.com/web/quoteManage/20221226/2060P7Y8DpeQ.jpg"
				},
				{
					name: "upward",
					url: "https://customed-center.oss-accelerate.aliyuncs.com/web/quoteManage/20221226/2060P7Y8DpeQ.jpg"
				},
				{
					name: "cone",
					url: "https://customed-center.oss-accelerate.aliyuncs.com/web/quoteManage/20221226/2060P7Y8DpeQ.jpg"
				}
			],
			inputText: "",
			artForm: {
				page: 1,
				pageSize: 60,
				cateId: null,
				cateName: "",
				total: 0,
				pages: 1
			},
			debounceChangeTextVal: null,
			templateCategoryList: [],
			templateCategoryListCopy: [],
			outlineMarks: {
				0: "None",
				1: "Very Thin",
				2: "Thin",
				3: "Medium",
				4: "Thick",
				5: "Very Thick"
			},
			showFontFamilyHistoryStatus: false,
			fontFamilyListHistory: [],
			artCategoryList: [],
			artCategoryListCopy: [],
			showEditImgColor: false,
			selectedImgColor: "",
			selectedImgReplaceColor: "",
			layersArr: [],
			uploadLoading: false,
			templateCateId: null,
			templateCateName: "",
			clipartTypeId: null,
			clipartTypeName: "",
			artTagList: [],
			templateTagList: [],
			templateExtendList: [],
			templateRecordList: [],
			artRecordList: [],
			artExtendList: [],
			templateState: false,
			artState: false,
		};
	},
	watch: {
		isLogin() {
			this.getUploadHistory();
		}
	},
	computed: {
		textProperty() {
			return this.canvas.textProperty;
		},
		imageProperty() {
			return this.canvas.imageProperty;
		},
		selectedImgColorList() {
			return this.canvas.selectedImgColorList;
		},
		artContentTitle() {
			let obj = {
				1: "Add Cliparts",
				2: this.clipartTypeName,
				3: "Edit Cliparts"
			};
			return obj[this.artStatus];
		},
		showArtBackIcon() {
			let obj = {
				1: false,
				2: true,
				3: false,
				4: true
			};
			return obj[this.artStatus];
		},
		textContentTitle() {
			let obj = {
				1: "Add Text",
				2: "Edit Text",
				3: "Font",
				4: "Font Colors",
				5: "Text Shape",
				6: "Text Outline"
			};
			return obj[this.textStatus];
		},
		showTextBackIcon() {
			let obj = {
				1: false,
				2: false,
				3: true,
				4: true,
				5: true,
				6: true
			};
			return obj[this.textStatus];
		},
		showTemplateBackIcon() {
			let obj = {
				1: false,
				2: true,
				3: true
			};
			return obj[this.templateStatus];
		},
		isLogin() {
			return this.$store.getters.isLogin;
		},
		userId() {
			return this.$store.state.userInfo?.id;
		},
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost"
			};
		},
		outlineLabel() {
			return this.outlineMarks[this.textProperty.strokeWidth];
		},
		fontList() {
			return this.$store.getters["design/fontList"];
		},
		currentFontList() {
			return this.showFontFamilyHistoryStatus ? this.fontFamilyListHistory : this.fontList;
		},
		activeTextColorName() {
			let findColor = this.colorList.find(item => {
				return item.code === this.textProperty.fill;
			});
			return findColor ? findColor.pantone : "";
		},
		activeTextOutlineColorName() {
			let findColor = this.colorList.find(item => {
				return item.code === this.textProperty.stroke;
			});
			return findColor ? findColor.pantone : "";
		},
		colorList() {
			return this.$store.state.design.colorList;
		},
		colorType() {
			return this.$store.state.design.colorType;
		}
	},
	methods: {
		metalColor(item, type) {
			if (type === 'picture') {
				//图片金属色
				this.selectedImgReplaceColor = item.code;
				this.confirmChangeImgColor();
				const existingIndex = this.historyPicColorRecords.findIndex(record => record.code === item.code);
				if (existingIndex !== -1) {
					const duplicateRecord = this.historyPicColorRecords.splice(existingIndex, 1)[0];
					this.historyPicColorRecords.unshift(duplicateRecord);
				} else {
					this.historyPicColorRecords.unshift(item);
				}
				localStorage.setItem('historyPicColorRecords', JSON.stringify(this.historyPicColorRecords));
			} else if (type === 'text') {
				//文字金属色
				this.changeElementProperty(item.code, 'fill');
				const existingIndex = this.historyTextColorRecords.findIndex(record => record.code === item.code);
				if (existingIndex !== -1) {
					const duplicateRecord = this.historyTextColorRecords.splice(existingIndex, 1)[0];
					this.historyTextColorRecords.unshift(duplicateRecord);
				} else {
					this.historyTextColorRecords.unshift(item);
				}
				localStorage.setItem('historyTextColorRecords', JSON.stringify(this.historyTextColorRecords));
			}
		},


		//色卡模糊查询
		getColorCardByColorNameByRequest() {
			if (this.inputColorValue.trim() == '') {
				this.colorCardList = this.colorList;
				this.selectedColorCategoryId = -1;
				return;
			}
			getColorCardByColorName({
				colorName: this.inputColorValue
			}).then(res => {
				if (res.code == '200') {
					if (res.data.length == 0) {
						this.showChild = false;
						this.showOops = true;
					} else {
						this.showChild = true;
						this.showOops = false;
					}
					this.selectedColorCategoryId = -1;
					this.colorCardList = res.data;
				}
			})
		},
		handleDrop(event) {
			event.preventDefault();
			const files = event.dataTransfer.files;
			// 处理上传的文件
			this.uploadFileToDesign(files[0]);
		},
		changeSingleColor(val) {
			this.changeElementProperty(val, 'singleColor');
			if (val) {
				let color = this.selectedImgColorList[0].color;
				if (this.imageProperty.singleColorList.length == 0) this.changeElementProperty([{ color: color }], 'singleColorList');
				let filter = new fabric.Image.filters.BlendColor({
					color: this.imageProperty.singleColorList[0].color,
					mode: "tint",
				});
				let activeObj = this.canvas.c.getActiveObject();
				if (!activeObj) {
					return false;
				}
				this.canvas.applyFilter(0, filter);
				this.canvas.analyzeImgColor(activeObj.getSrc());
			} else {
				let activeObj = this.canvas.c.getActiveObject();
				if (!activeObj) {
					return false;
				}
				this.canvas.applyFilter(0, null);
				this.canvas.analyzeImgColor(activeObj.getSrc());
			}
		},
		showUserDesign() {
			this.$Bus.$emit("showMyDesign");
		},
		generateUUID,
		artBack() {
			this.artStatus = 1;
			this.artCategoryList = this.artCategoryListCopy;
		},
		textBack() {
			this.textStatus = 2;
		},
		templateCategoryBack() {
			this.templateStatus = 1;
			this.templateCategoryList = this.templateCategoryListCopy;
		},
		inputTemplateValue(val) {
			if (val && this.templateTagList.length > 0) {
				if (val.trim().length == 1) {
					this.templateExtend(true)
					return;
				}
				const regex = new RegExp(`^${val}`, "gi");
				this.templateExtendList = this.templateTagList
					.filter((item) => item.match(regex))
					.map((item) => {
						return {
							value: item,
							icon: false,
						};
					});
				this.templateState = false;
			} else {
				this.templateExtend(true)
				this.searchTemplate("");
				this.templateExtendList = [];
			}
		},
		searchTemplate(val) {
			//将提交搜索的存localStorage
			this.setLocalStorage("templateRecord", val);
			this.templateCateId = "";
			if (!val) {
				this.templateCateId = null;
			}
			this.$Bus.$emit("searchTemplate", val);
		},
		inputArtValue(val) {
			if (val && this.artTagList.length > 0) {
				if (val.trim().length == 1) {
					this.artExtend(true);
					return;
				}
				const regex = new RegExp(`^${val}`, "gi");
				this.artExtendList = this.artTagList
					.filter((item) => item.match(regex))
					.map((item) => {
						return {
							value: item,
							icon: false,
						};
					});
				this.artState = false;
			} else {
				this.artExtend(true);
				this.searchArt(val);
				this.artExtendList = [];
			}
		},
		searchArt(val) {
			this.setLocalStorage("artRecord", val);
			this.clipartTypeId = "";
			if (!val) {
				this.clipartTypeId = null;
			}
			this.$Bus.$emit("searchArt", val);
		},
		loadImage(url) {
			return new Promise((resolve, reject) => {
				let img = new Image();
				img.onload = () => resolve(img);
				img.onerror = reject;
				img.src = urlAddVersion(url);
				img.crossOrigin = "Anonymous";
			});
		},
		async addImg(src, property) {
			this.$store.commit("design/set_loading", true);
			let imgInstance = await this.canvas.addImg(src, { artId: property });
			this.activeItem(imgInstance);
			this.$store.commit("design/set_loading", false);
		},
		deleteAllHistory() {
			this.$confirm("Delete all history?", "Tips", {
				confirmButtonText: "confirm",
				cancelButtonText: "Cancel",
				type: "warning"
			})
				.then(() => {
					deleteUploadHistory(this.uploadHistoryList.map(item => item.id)).then(() => {
						this.getUploadHistory();
					});
				})
				.catch(() => { });
		},
		getUploadHistory() {
			getUploadHistory({
				userId: this.userId,
				language: "en-us"
			}).then(res => {
				this.uploadHistoryList = res.data;
			});
		},
		addToUploadHistory(urlArr) {
			console.log(urlArr, this.isLogin);
			if (!urlArr.length || !this.isLogin) {
				return false;
			}
			if (this.isLogin) {
				addUploadHistory({
					userId: this.userId,
					picPath: urlArr[0].picPath,
					language: "en-us"
				}).then(res => {
					this.getUploadHistory();
				});
			}
		},
		delUploadHistory(id) {
			deleteUploadHistory([id]).then(res => {
				this.getUploadHistory();
			});
		},
		beforeUpload(file) {
			const isLt10M = file.size / 1024 / 1024 < 10;
			const isUploadType = /\.(gif|jpg|jpeg|png|svg|psd|pdf|bmp|ai|)$/.test(file.name.toLowerCase());
			if (!isLt10M) {
				this.$message.error("The size of the uploaded profile picture should not exceed 2MB!");
				return isLt10M;
			}
			if (!isUploadType) {
				this.$message.error("Error uploading file type, Accepted File Types (JPG、JPEG、PNG、SVG、GIF、BMP、PDF、PSD、AI)");
				return isUploadType;
			}
			return true;
		},
		uploadFileToDesign(file) {
			let checkResult = this.beforeUpload(file);
			if (!checkResult) {
				this.$refs[this.inputRefName].value = "";
				return false;
			}
			this.uploadLoading = true;
			uploadFile(file,{
                systemName:'customOss'
            })
				.then(res => {
					this.addImg(res);
					this.addToUploadHistory([
						{
							picPath: res
						}
					]);
				})
				.finally(() => {
					this.$refs[this.inputRefName].value = "";
					this.uploadLoading = false;
				});
		},
		//图片放大缩小
		changeScale(num) {
			num = num || 1
			this.$refs.cropper.changeScale(num)
		},

		cropImage() {
			this.$store.commit("design/set_loading", true);
			this.$refs.cropper.getCropData(async data => {
				let file = dataURLtoFile(data, 1);
				this.uploadFileToDesign(file)
				this.$store.commit("design/set_loading", false);
			})
			this.cropperDialog = false;
		},

		uploadOnChange(e) {
			let file = e.target.files[0],
				name = file.name;
			if (/\.(pdf)$/.test(name.toLowerCase())) {
				let reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = async (e) => {
					let src = await pdfToImg(e.target.result);
					this.imageSrc = src;
				};
				this.cropperDialog = true;

			} else if (/\.(psd)/.test(name.toLowerCase())) {
				let reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = async (e) => {
					let PSD = require("@/assets/js/psd.js");
					let psd = await PSD.fromURL(e.target.result);
					let src = psd.image.toBase64();
					this.imageSrc = src;
				};
				this.cropperDialog = true;
			} else {
				let reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = async (e) => {
					this.imageSrc = e.target.result;
				};
				this.cropperDialog = true;
			}
		},

		triggerUpload(name) {
			this.inputRefName = name;
			this.$refs[name].click();
		},
		showChildren(item) {
			item.showChildren = !item.showChildren;
			this.$forceUpdate();
		},
		toggleViewElement(item) {
			item.visible = !item.visible;
			this.canvas.c.requestRenderAll();
		},
		toggleLockElement(item) {
			item.selectable = !item.selectable;
			item.evented = !item.evented;
			this.canvas.c.requestRenderAll();
		},
		selectedItem(item) {
			this.canvas.c.discardActiveObject();
			this.canvas.c.setActiveObject(item);
			this.canvas.c.requestRenderAll();
			this.activeItem(item);
			this.$Bus.$emit("hideTools");
		},
		activeItem(item) {
			let activeEle = this.canvas.c.getActiveObject();
			if (activeEle !== item) {
				this.showDefault();
				return false;
			}
			this.canvas.isGetProperty = true;
			this.canvas.getObjectAttr(item)
			if (item.type === "text" || item.type === "i-text" || item.type === "curved-text") {
				this.status = 2;
				this.textStatus = 2;
			} else if (item.type === "image") {
				this.status = 3;
				this.artStatus = 3;
			}
			this.$nextTick(() => {
				this.canvas.isGetProperty = false;
			})
		},
		changeLayer(e) {
			let oldIndex = e.moved.oldIndex;
			let newIndex = e.moved.newIndex;
			let num = Math.abs(newIndex - oldIndex);
			if (newIndex > oldIndex) {
				for (let i = 0; i < num; i++) {
					e.moved.element.sendBackwards();
				}
			} else {
				for (let i = 0; i < num; i++) {
					e.moved.element.bringForward();
				}
			}
			this.canvas.c.requestRenderAll();
		},
		cancelChangeImgColor() {
			this.selectedImgColor = "";
			this.selectedImgReplaceColor = "";
			this.showEditImgColor = false;
		},
		confirmChangeImgColor() {
			if (!this.selectedImgColor || !this.selectedImgReplaceColor) {
				this.$message.info('Please select the color you want to replace');
				return false;
			}
			if (this.imageProperty.singleColor) {
				this.changeElementProperty([{ color: this.selectedImgReplaceColor }], 'singleColorList');
				this.selectedImgColor = this.selectedImgReplaceColor;
				let filter = new fabric.Image.filters.BlendColor({
					color: this.selectedImgReplaceColor,
					mode: "tint",
				});
				this.canvas.applyFilter(0, filter);
				return
			}
			this.loadChangeColor = true;
			let ele = this.canvas.c.getActiveObject();
			let url = ele.getSrc();
			changeImgColor(
				url,
				{
					oldColor: this.selectedImgColor,
					newColor: this.selectedImgReplaceColor,
				},
				(base64) => {
					let file = dataURLtoFile(base64, 1);
					uploadFile(file,{
                        systemName:'customOss'
                    }).then(async (url) => {
						let img = await this.loadImage(url);
						ele.setElement(img);
						this.selectedImgColor = colorRgb(this.selectedImgReplaceColor);
						this.$Bus.$emit('synchroSelectedImgColor', this.selectedImgColor);//同步颜色
						this.canvas.analyzeImgColor(url);
						this.canvas.c.requestRenderAll();
						this.loadChangeColor = false;
					});
				}
			);
		},
		selectImgColor(color) {
			this.selectedImgColor = color;
			this.$Bus.$emit('synchroSelectedImgColor', color);//同步颜色
			this.selectedImgReplaceColor = '';
		},
		selectImgReplaceColor(color, colorFullMsg) {
			if (this.selectedImgReplaceColor == color) return;
			this.selectedImgReplaceColor = color;
			let result = this.confirmChangeImgColor();
			if (result === false) return;
			this.historyPicColorRecords = JSON.parse(localStorage.getItem('historyPicColorRecords'))
			console.log(this.historyPicColorRecords,"12313131231");
			if (this.historyPicColorRecords == null) {
				this.historyPicColorRecords = [];
				localStorage.setItem('historyPicColorRecords', JSON.stringify([]));
			}
			if (this.historyPicColorRecords && this.historyPicColorRecords.length == 8) {
				this.historyPicColorRecords.splice(7, 1);
			}
			let index = this.historyPicColorRecords.findIndex(item => item.code == color);
			if (index != -1) {
				this.historyPicColorRecords.splice(index, 1);
			}
			this.selectedColorNow = colorFullMsg.pantone;
			this.historyPicColorRecords.unshift(colorFullMsg);
			this.$Bus.$emit('getHistoryPicColorRecordsToToolTips', this.historyPicColorRecords);
			localStorage.setItem('historyPicColorRecords', JSON.stringify(this.historyPicColorRecords));
		},
		changeColorCategory(id) {
			this.selectedImgReplaceColor = '';
			this.showChild = true;
			this.showOops = false;
			this.selectedColorCategoryId = id;
			//展示子级分类
			getColorCardByTypeId({
				typeId: id
			}).then(res => {
				if (res.code == '200') {
					this.colorCardList = res.data;
				}
			})
		},
		showReplaceDialog() {
			this.$store.commit("design/set_Replace", true);
		},
		changeTextShape(item) {
			let name = item.name;
			let activeEle = this.canvas.c.getActiveObject();
			if (!activeEle) {
				return false;
			}
			switch (name) {
				case "normal":
					activeEle.set("path", null);
					break;
				case "curve":
					let eleWidth = activeEle.width;
					let r = (2 * eleWidth) / (2 * Math.PI);
					console.log(r);
					let path = new fabric.Path(`M 0,0 A 1 1 0 0 1 ${2 * r},0`, {
						fill: "transparent"
					});
					activeEle.set({
						path,
						pathAlign: "center"
					});
					break;
			}
			this.canvas.c.requestRenderAll();
		},
		changeFontFamily(item) {
			this.canvas.c.getActiveObject().set("fontFamily", item);
			this.canvas.c.requestRenderAll();
			if (!this.showFontFamilyHistoryStatus) {
				this.addFamilyListHistory(item);
			}
			this.canvas.getObjectAttr(this.canvas.c.getActiveObject());
		},
		addFamilyListHistory(data) {
			let ind,
				arr = this.fontFamilyListHistory;
			let boo = arr.some((item, index) => {
				if (item === data) {
					ind = index;
					return true;
				}
			});
			if (boo) {
				arr.splice(ind, 1);
				arr.unshift(data);
				return false;
			}
			arr.unshift(data);
		},
		showRecentUsedFont() {
			this.showFontFamilyHistoryStatus = true;
		},
		backFontPreview() {
			if (this.showFontFamilyHistoryStatus) {
				this.showFontFamilyHistoryStatus = false;
			} else {
				this.textStatus = 2;
			}
		},
		applyAllText() {
			let activeEle = this.canvas.c.getActiveObject();
			let fontFamily = activeEle.get("fontFamily"),
				fill = activeEle.get("fill"),
				strokeWidth = activeEle.get("strokeWidth"),
				stroke = activeEle.get("stroke");
			let textArr = this.canvas.c.getObjects().filter(item => ["text", "i-text", "curved-text"].includes(item.type));
			if (!textArr.length) {
				return false;
			}
			for (let i = 0; i < textArr.length; i++) {
				let textItem = textArr[i];
				textItem.set({
					fontFamily,
					fill,
					strokeWidth,
					stroke
				});
			}
			this.canvas.c.requestRenderAll();
		},
		changeElementProperty(val, property, colorMsg, flag) {
			this.canvas.changeElementProperty(val, property);
			this.canvas.getObjectAttr();
			if (flag == 'textColor') {
				this.historyTextColorRecords = JSON.parse(localStorage.getItem('historyTextColorRecords'));
				if (this.historyTextColorRecords == null) {
					this.historyTextColorRecords = [];
					localStorage.setItem('historyTextColorRecords', JSON.stringify([]));
				}
				if (this.historyTextColorRecords && this.historyTextColorRecords.length == 8) {
					this.historyTextColorRecords.splice(7, 1);
				}
				if (this.historyTextColorRecords) {
					let index = this.historyTextColorRecords.findIndex(item => item.code == val);
					if (index != -1) {
						this.historyTextColorRecords.splice(index, 1);
					}
				}
				this.historyTextColorRecords.unshift(colorMsg);
				this.$Bus.$emit('getHistoryTextColorRecordsToToolTips', this.historyTextColorRecords);//一修改就传给tooptip让其更新
				localStorage.setItem('historyTextColorRecords', JSON.stringify(this.historyTextColorRecords));
			}
		},
		
		removeOutline() {
			let activeEle = this.canvas.c.getActiveObject();
			if (!activeEle) {
				return false;
			}
			activeEle.set("strokeWidth", 0);
			activeEle.set("stroke", "transparent");
			this.canvas.c.requestRenderAll();
			this.canvas.getObjectAttr(activeEle);
			this.textStatus = 2;
		},
		changeTextVal(val) {
			let activeEle = this.canvas.c.getActiveObject();
			if (!activeEle) {
				return;
			}
			activeEle.set("text", val);
			this.canvas.c.requestRenderAll();
			this.canvas.getObjectAttr(activeEle);
		},
		addCurveText(val, property) {
			let Text = this.canvas.addCurveText(val, property);
			this.inputText = "";
			this.activeItem(Text);
		},
		/*添加文字*/
		addText(val, property) {
			let Text = this.canvas.addText(val, property);
			this.inputText = "";
			this.activeItem(Text);
		},
		showDefault() {
			this.status = 0;
			this.templateStatus = 1;
			this.textStatus = 1;
			this.artStatus = 1;
			this.selectedImgColor = "";
			this.selectedImgReplaceColor = "";
			this.selectedElement = "";
			this.showImgColorPopover = false;
			this.$store.commit("design/set_showTemplate", false);
			this.$store.commit("design/set_showArt", false);
		},
		showTab(val) {
			this.showDefault();
			this.status = val;
			this.$store.commit("design/set_showTemplate", false);
			this.$store.commit("design/set_showArt", false);
			this.canvas.c.discardActiveObject().requestRenderAll();
			this.$Bus.$emit('hideTools')
			switch (val) {
				case 1:
					this.$store.commit("design/set_showTemplate", true);
					this.templateCategoryList = this.templateCategoryListCopy;
					this.templateStatus = 1;
					break;
				case 2:
					this.textStatus = 1;
					break;
				case 3:
					this.$store.commit("design/set_showArt", true);
					this.artCategoryList = this.artCategoryListCopy;
					this.artStatus = 1;
					break;
				case 4:
					break;
				case 5:
					break;
				case 6:
					break;
				case 7:
					this.myDesignStatus = 1;
					this.myCustomUploadStatus = 0;
					this.myTemplateStatus = 1;
					this.initUserTemplateForm();
					if (this.isLogin) {
						this.getUserArtCate();
						//获取用户收藏的模板
						this.getUserTemplate("select");
						//获取用户创建的模板
						this.getUserCreationTemplate("select");
					}
					break;
			}
		},
		selectArtCategory(item) {
			this.searchArtValue = "";
			this.artExtendList = [];
			this.clipartTypeId = item.id;
			this.clipartTypeName = item.clipartTypeName;
			if (item.clipartTypeList && item.clipartTypeList.length) {
				this.artStatus = 2;
				this.artCategoryList = item.clipartTypeList;
			}
			this.$Bus.$emit("selectArtCategory", item);
		},
		selectTemplateCategory(item) {
			this.searchTemplateValue = "";
			this.templateExtendList = [];
			this.templateCateId = item.id;
			this.templateCateName = item.templateTypeName;
			if (item.children && item.children.length) {
				this.templateStatus = 2;
				this.templateCategoryList = item.children;
			}
			this.$Bus.$emit("selectTemplateCategory", item);
		},
		getArtCateList() {
			getArtCateList({
				id: 1,
				proId: this.$store.state.design.defaultProId,
				language: "en-us"
			}).then(res => {
				let list = res.data;
				this.artCategoryList = this.artCategoryListCopy = list;
				this.artDisplayControl = list[0].displayControl;
			});
		},
		getTemplateTypeListFromApp() {
			getTemplateTypeListFromApp({
				proId: this.$store.state.design.defaultProId,
				categoryId: this.$store.state.design?.pageInfo?.id,
				language: "en-us"
			}).then(res => {
				let list = res.data;
				this.templateCategoryList = this.templateCategoryListCopy = list;
				this.templateDisplayControl = list.length > 0 && list[0].displayControl;
			});
		},
		addArt(obj) {
			this.addImg(obj.src, obj.property);
		},
		getHistoryPicColorRecords(arr, selectedColorNow) {
			this.selectedColorNow = selectedColorNow;
			this.historyPicColorRecords = arr;
			this.selectedImgReplaceColor = this.historyPicColorRecords[0].code;
		},
		getHistoryTextColorRecords(arr) {
			this.historyTextColorRecords = arr;
			this.selectedImgReplaceColor = this.historyTextColorRecords[0].code;
		},
		synchroSelectedImgColorLeft(color) {
			this.selectedImgColor = color;
		},
	},
	created() {
		this.debounceChangeTextVal = debounce(this.changeTextVal, 300);
		this.debouncedColorValue = debounce(this.getColorCardByColorNameByRequest, 300);
		if (this.isLogin) {
			this.getUploadHistory();
		}
	},
	async mounted() {
		if (this.$route.name == 'templateLibrary') {
			this.myDesignShow = false;
		}
		this.getArtCateList();
		this.getTemplateTypeListFromApp();
		likeAllString({
			language: "en-us"
		}).then(res => {
			this.artTagList = res.data;
		});
		getAllTemplateNameAndTypeName({
			categoryId: this.$store.state.design?.pageInfo?.id,
			language: "en-us"
		}).then(res => {
			this.templateTagList = res.data;
		});
		this.$Bus.$on("triggerAddText", this.showTab);
		this.$Bus.$on("triggerActiveItem", this.activeItem);
		this.$Bus.$on("triggerAddArt", this.addArt);
		this.$Bus.$on('triggerUpload', this.triggerUpload);
		this.$Bus.$on('showDefault', this.showDefault);
		this.$Bus.$on('getHistoryPicColorRecords', this.getHistoryPicColorRecords);
		this.$Bus.$on('getHistoryTextColorRecords', this.getHistoryTextColorRecords);
		this.$Bus.$on('synchroSelectedImgColorLeft', this.synchroSelectedImgColorLeft);
		this.canvas.c.on({
			"mouse:down": e => {
				if (e.target && e.button === 1) {
					this.activeItem(e.target);
					if (e.target.type === 'image') {
						this.selectedImgColor = "";
						this.selectedImgReplaceColor = "";
						this.showEditImgColor = false;
					}
				} else {
					this.showDefault();
				}
			},
			"object:removed": e => {
				this.showDefault();
			},
			"after:render": () => {
				this.layersArr = this.canvas.getLayersArr();
				let count = 1;
				this.layersArr = this.canvas.getLayersArr();
				this.layersArr.forEach(item => {
					if (item.type === 'group') {
						item.count = count++;
					}
				})
			}
		});
		this.historyTextColorRecords = JSON.parse(localStorage.getItem('historyTextColorRecords'));
		this.historyPicColorRecords = JSON.parse(localStorage.getItem('historyPicColorRecords'))
		if (this.historyPicColorRecords == null) {
			this.historyPicColorRecords = [];
			localStorage.setItem('historyPicColorRecords', JSON.stringify([]));
		}
		if (this.historyTextColorRecords == null) {
			this.historyTextColorRecords = [];
			localStorage.setItem('historyTextColorRecords', JSON.stringify([]));
		}
	},
	beforeDestroy() {
		this.canvas.c.off();
		this.$Bus.$off("triggerAddText", this.showTab);
		this.$Bus.$off("triggerActiveItem", this.activeItem);
		this.$Bus.$off("triggerAddArt", this.addArt);
		this.$Bus.$off('triggerUpload', this.triggerUpload);
		this.$Bus.$off('showDefault', this.showDefault);
		this.$Bus.$off('getHistoryPicColorRecords', this.getHistoryPicColorRecords);
		this.$Bus.$off('getHistoryTextColorRecords', this.getHistoryTextColorRecords);
		this.$Bus.$off('synchroSelectedImgColorLeft', this.synchroSelectedImgColorLeft);
	}
};
</script>
<style scoped lang="scss">
@import "@/assets/css/dzxt_theme";

::v-deep .base-dialog-model-con {
	position: relative;

	.button {
		text-align: center;
	}

	.title {
		font-size: 20px;
		border-bottom: 1px solid;
		padding: 11px 20px;
		font-weight: bold;
	}

	.cropper-icon {
		display: flex;
		position: absolute !important;
		z-index: 10 !important;
		top: 70px;
		right: 25px;

		.el-button {
			border-radius: 50%;
			background-color: #909399;
			width: 20px;
			color: white;
			display: flex;
			justify-content: center;
			border: 1px solid #909399;
		}
	}

	.cropper-btn {
		text-align: right;
		padding: 20px;
	}

	.cropper {
		height: 600px !important;
		padding: 20px;

		.vue-cropper {
			background-repeat: initial !important;
		}
	}
}

.addTextBtn {
	width: 160px;
	height: 48px;
	background: #ffffff;
	border: 1px solid $dz-primary;
	border-radius: 6px;
	font-size: 18px;
	font-weight: bold;
	white-space: nowrap;
	color: $dz-primary;
}

.addTextBtn.curvedBtn {
	width: auto;
	padding: 0 10px;
	margin-right: 10px;
}

.editColor-colorPicker {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	flex: 1;

	.editColor-colorPicker-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.editColor-colorPicker-selected2{
		display: flex;
		align-items: center;
		justify-content: space-between;	
		margin: 12px 0;

		.color-name {
			margin-left: 8px;
		}

		.metsil-color-div{
			width: 2.875rem;
    		height: 2.875rem;
			border: 1px solid #DCDFE5;
			border-radius: 50%;
			padding: 1px;
			.metsil-color{
			    width: 80%;
				height: 80%;
				border-radius: 50%;
				background-color: rgb(252, 220, 125);
				margin: 4px auto;
			}
			.recessed-metsil-color{
				width: 80%;
    			height: 80%;
				border-radius: 50%;
				margin: 4px auto;
			}
		}
	}

	.editColor-colorPicker-selected2 {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 12px 0;

		.color-name {
			margin-left: 8px;
		}

		.metsil-color-div {
			width: 2.875rem;
			height: 2.875rem;
			border: 1px solid #DCDFE5;
			border-radius: 50%;
			padding: 1px;

			.metsil-color {
				width: 80%;
				height: 80%;
				border-radius: 50%;
				background-color: rgb(252, 220, 125);
				margin: 4px auto;
			}

			.recessed-metsil-color {
				width: 80%;
				height: 80%;
				border-radius: 50%;
				margin: 4px auto;
			}
		}
	}

	.editColor-colorPicker-selected,
	.editColor-colorPicker-history {
		display: flex;
		align-items: center;
		margin: 12px 0;

		.color-name {
			margin-left: 8px;
		}

		.metsil-color-div {
			width: 3.5rem;
			height: 2.75rem;
			border: 1px solid #DCDFE5;
			border-radius: 4px;
			padding: 1px;

			.metsil-color {
				width: 3.25rem;
				height: 2.5rem;
				border-radius: 4px;
				background-color: rgb(252, 220, 125);
			}

			.recessed-metsil-color {
				width: 3.25rem;
				height: 2.5rem;
				border-radius: 4px;
			}
		}
	}

	.parentAndChildPanel {
		position: relative;
		width: 100%;
		height: 30px;

		.searchInput {

			::v-deep {
				.highlight {
					font-weight: 700;
				}
			}

			.inputSearch {
				width: 116%;
				height: 24px;
				font-size: 14px;
				background: #EBEBEB;
				border-radius: 17px;
				padding: 0 0 0 14px;
				border: 1px solid transparent;
				transition: all .3s;

				&:focus {
					outline: 1px solid #3385d6;
					background-color: #ffffff;
				}
			}

			.suffix-inner {
				position: absolute;
				top: 50%;
				right: -16px;
				transform: translateY(-50%);
				cursor: pointer;

				b {
					color: #A6A6A6;
				}
			}
		}
	}

	.editColor-colorPicker-colorContainer {
		flex: 1;
		overflow: hidden auto;

		.editColor-colorPicker-colors {
			display: grid;
			grid-template-columns: repeat(auto-fill, 2.875rem);
			grid-column-gap: 10px;
			grid-row-gap: 10px;
		}

		.parentGrid {
			display: flex;
			height: 2.875rem;
			// grid-column-gap: 18px;
		}

		.childrenPanel {
			grid-column-gap: 13px;
			width: 100%;
			padding: 10px 0 10px 12px;
			margin-top: -1px;
			background: #F0F0F0;
			border: 1px solid #DBDBDB;
			border-top: none;
		}

		.imgWrapOops {
			margin-top: 25px;
			display: inline-block;
			width: 500px;
			height: 100px;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		.tip {
			margin: 10px 50px 15px;
			color: #999999;
			font-size: 16px;
		}
	}

	@media screen and (max-width: 768px) {
		.editColor-colorPicker-colorContainer {
			.editColor-colorPicker-colors {
				display: grid;
				grid-template-columns: repeat(auto-fill, 34px);
				grid-column-gap: 6px;
				grid-row-gap: 10px;
			}
		}
	}
}

.ds-left-area {
	display: grid;
	align-items: flex-start;
	height: 100%;
	min-height: 0;
	grid-template-columns: 90px 1fr;

	.ds-left-bar {
		overflow: hidden auto;
		height: 100%;
		min-width: 0;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		padding: 50px 0 0;
		background: #171719;
		color: #ffffff;
		font-size: 14px;

		.tab-item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
			height: 90px;
			text-align: center;
			cursor: pointer;
			transition: all 0.3s;

			&::before {
				display: none;
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				width: 2px;
				background-color: $dz-primary;
			}

			&:hover {
				background-color: #ffffff;
				color: #333333;

				&::before {
					display: block;
				}
			}

			b {
				margin-bottom: 4px;
				font-size: 26px;
			}
		}

		.tab-item.active {
			background-color: #ffffff;
			color: #333333;

			&::before {
				display: block;
			}
		}
	}

	.ds-left-content {
		overflow: hidden;
		min-width: 0;
		height: calc(100% - 80px);
		background: #ffffff;

		.default-content {
			height: 100%;
			padding: 40px 0;

			h3 {
				margin-bottom: 30px;
				text-align: center;
				font-size: 24px;
				font-weight: bold;
			}

			.dfc-item-wrap {
				display: grid;
				justify-content: center;
				grid-template-columns: 180px 180px;
				grid-column-gap: 10px;
				grid-row-gap: 10px;
			}

			.dfc-item {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				background: #f4f4f4;
				border-radius: 10px;
				aspect-ratio: 180/120;
				font-size: 14px;
				cursor: pointer;
				transition: all 0.3s;

				b {
					margin-bottom: 4px;
					font-size: 36px;
				}

				&:hover {
					color: $dz-primary;
				}
			}
		}

		.content-box {
			display: flex;
			flex-direction: column;
			height: 100%;

			.topBar {
				flex-shrink: 0;
			}

			.con {
				overflow: hidden auto;
				flex: 1;
				padding: 10px 0;
			}
		}

		.template-content {
			.template-category {
                overflow: hidden auto;
				display: grid;
				align-content: flex-start;
				justify-content: center;
				grid-template-columns: repeat(3, 1fr);
				grid-column-gap: 16px;
				grid-row-gap: 10px;
				padding: 10px;

				.box-item {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					aspect-ratio: 156/108;
					min-width: 0;
					background-color: #f5f5f5;
					border-radius: 10px;
					border: 1px solid transparent;
					transition: all 0.3s;
					cursor: pointer;

					.imgWrap {
						width: 34px;
						height: 34px;

						img {
							width: 100%;
							height: 100%;
							object-fit: contain;
						}
					}

					b {
						color: $dz-primary;
						font-size: 34px;
					}

					.item-name {
						margin-top: 10px;
						max-width: 95%;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					&.active {
						background: linear-gradient(0deg, #f8fcff 0%, #eff7ff 100%);
						box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.24);
						border-color: $dz-primary;
					}

					&:hover {
						background: linear-gradient(0deg, #f8fcff 0%, #eff7ff 100%);
						box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.24);
						border-color: $dz-primary;
					}
				}

				.box-item.box-item-style0 {
					aspect-ratio: 156/60;

					b {
						display: none;
					}

					.imgWrap {
						display: none;
					}

					.item-name {
						margin-top: 0;
					}
				}
			}
		}

		.text-content {
			display: flex;
			flex-direction: column;

			.con {
				padding: 10px;
			}

			.addText {
				flex: 1;
				height: calc(100% - 50px);

				label.textLabel {
					display: block;
					background: #f5f5f5;
					border-radius: 10px;
					width: 100%;

					textarea {
						border: 0;
						color: inherit;
						outline-width: 0;
						-webkit-overflow-scrolling: touch;
						padding: 1rem;
						resize: none;
						white-space: pre;
						width: 100%;
						background: #f5f5f5;
						border-radius: 10px;
					}
				}

				.addTextBtnGroup {
					display: flex;
					justify-content: flex-end;
					margin-top: 10px;
				}
			}

			.editText {
				flex: 1;
				height: calc(100% - 50px);
				display: flex;
				flex-direction: column;

				.con {
					overflow: hidden auto;
					flex: 1;

					.applyAll {
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 1rem;
					}
				}

				label {
					display: block;
					background: #f5f5f5;
					border-radius: 10px;
					width: 100%;
				}

				textarea {
					border: 0;
					color: inherit;
					outline-width: 0;
					-webkit-overflow-scrolling: touch;
					padding: 1rem;
					resize: none;
					white-space: pre;
					width: 100%;
					background: #f5f5f5;
					border-radius: 10px;
				}

				.editWrap {
					margin-top: 20px;

					.edit-item {
						position: relative;
						align-items: center;
						border-bottom: 1px solid rgb(216, 216, 216);
						display: flex;
						height: 3.5rem;
						flex-shrink: 0;
						font-size: 1rem;
						padding: 0 0.5rem;

						svg {
							width: 2rem;
							height: 2rem;
						}

						.edit-item-title {
							flex: 1 0 auto;
							min-width: 80px;
						}

						.edit-item-content {
							align-items: center;
							display: flex;
							flex: 0 1 auto;

							&>* {
								margin-left: 0.5rem;
							}
						}

						.edit-item-chevron {
							display: flex;
							padding-left: 0.5rem;
							flex-flow: column;
							justify-content: center;
							color: #9ea6ad;
						}
					}

					.edit-angle,
					.edit-spacing,
					.edit-size,
					.edit-curve {
						.edit-item-title {
							flex: 0 1 auto;
						}

						.edit-item-content {
							flex: 1 0 auto;

							.el-slider {
								margin-left: 1rem;
								width: 100%;
							}
						}
					}

					.edit-align,
					.edit-decoration {
						.align-icon {
							margin: 0 0.5rem;
							cursor: pointer;

							b {
								color: #666666;
							}

							&.active {
								color: $dz-primary;

								b {
									color: inherit;
								}
							}
						}
					}

					.edit-color,
					.edit-outline {
						.color-picker {
							width: 1.5rem;
							height: 1.5rem;
							border-radius: 2px;
							border: 1px solid #f2f2f2;
						}

						.line {
							background: #4a4a4a;
							width: 0.1rem;
							height: 1.5rem;
						}
					}

					.edit-font,
					.edit-color,
					.edit-shape,
					.edit-outline {
						cursor: pointer;

						&:hover {
							.edit-item-title {
								color: $dz-primary;
							}
						}
					}
				}
			}

			.editTextFont {
				flex: 1;
				height: calc(100% - 50px);
				display: flex;
				flex-direction: column;

				.con {
					display: flex;
					flex-direction: column;
					flex: 1;

					.font-button-wrap {
						display: flex;
						justify-content: space-between;
						align-items: center;
						height: 2rem;
						color: $dz-primary;

						.arrow-btn,
						.recent-btn {
							display: flex;
							cursor: pointer;

							b {
								margin-right: 0.1rem;
								font-size: 18px;
							}
						}
					}

					.font-wrap {
						overflow: hidden auto;
						flex: 1;

						.font-item {
							display: flex;
							flex-direction: column;
							align-items: center;
							padding: 1rem;
							width: 100%;
							overflow: hidden;
							cursor: pointer;
							transition: all 0.3s;

							.font-text {
								font-size: 1.2rem;
							}

							&:hover {
								background-color: #f2f2f2;

								.font-text {
									color: $dz-primary;
								}
							}
						}
					}
				}
			}

			.editTextColor {
				flex: 1;
				height: calc(100% - 50px);
				display: flex;
				flex-direction: column;

				.con {
					display: flex;
					flex-direction: column;
					flex: 1;
					overflow: hidden;

					.editColor-colorPicker-history {
						::v-deep .color-item {
							margin-right: 18px;
						}
					}

					.editTextColor-foot {
						display: flex;
						justify-content: flex-end;
						align-items: center;
						padding: 1rem;
					}
				}
			}

			.editTextShape {
				.title {
					margin-bottom: 1rem;
					font-size: 18px;
					font-weight: 600;
				}

				.textShapeList {
					display: grid;
					grid-template-columns: repeat(4, 1fr);
					margin-bottom: 20px;
					border-top: 1px solid #e6e6e6;
					border-left: 1px solid #e6e6e6;

					.shape-item {
						display: flex;
						justify-content: center;
						align-items: center;
						border-right: 1px solid #e6e6e6;
						border-bottom: 1px solid #e6e6e6;
						height: 64px;
						cursor: pointer;

						&:hover {
							background-color: #f2f2f2;
						}
					}
				}

				.slider {
					padding: 1rem;
					background-color: #f2f2f2;
					border-radius: 4px;

					.slider-slider {
						padding: 0 1rem;
					}

					.slider-label {
						display: flex;
						justify-content: space-between;
					}
				}
			}

			.editTextOutline {
				flex: 1;
				height: calc(100% - 50px);
				display: flex;
				flex-direction: column;

				.con {
					overflow: hidden;
					display: flex;
					flex-direction: column;
					flex: 1;

					.outline-slider {
						padding: 1rem;
						background-color: #f2f2f2;
						border-radius: 4px;

						.slider-slider {
							padding: 0 1rem;
						}

						.slider-label {
							display: flex;
							justify-content: space-between;
						}
					}

					.outline-width-title {
						margin-bottom: 1rem;
						font-size: 18px;
						font-weight: 600;
					}

					.outline-color-title {
						font-size: 18px;
						font-weight: 600;
					}

					.outline-color {
						overflow: hidden;
						display: flex;
						flex-direction: column;
						flex: 1;
						margin-top: 1rem;
						height: 0;

						.outline-stroke-color {
							display: flex;
							flex-direction: column;
							overflow: hidden;
							flex: 1;

							.outline-stroke-color-selected {
								display: flex;
								align-items: center;
								margin: 12px 0;

								.color-name {
									margin-left: 8px;
								}
							}

							.outline-stroke-color-colorPicker {
								display: grid;
								grid-template-columns: repeat(auto-fill, 2.875rem);
								grid-column-gap: 5px;
								grid-row-gap: 5px;
								flex: 1;
								overflow: hidden auto;
							}
						}
					}

					.outline-btn {
						display: flex;
						justify-content: center;
						align-items: center;
						padding: 1rem;

						button {
							width: 11rem;
						}
					}
				}
			}
		}

		.art-content {
			display: flex;
			flex-direction: column;

			.con {
				overflow: hidden auto;
				flex: 1;
				display: grid;
				align-content: flex-start;
				justify-content: center;
				grid-column-gap: 16px;
				grid-row-gap: 10px;
				padding: 10px;

				.box-item {
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #f5f5f5;
					border-radius: 10px;
					border: 1px solid transparent;
					transition: all 0.3s;
					cursor: pointer;

					&.active {
						border-color: $dz-primary;
						box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.24);
						background: linear-gradient(0deg, #F8FCFF 0%, #EFF7FF 100%);
					}

					&:hover {
						border-color: $dz-primary;
						box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.24);
						background: linear-gradient(0deg, #F8FCFF 0%, #EFF7FF 100%);
					}
				}
			}

			.art-category {
				flex: 1;
				height: 0;
				display: flex;
				flex-direction: column;

				.con {
					grid-template-columns: repeat(3, 1fr);
					grid-gap: 10px 16px;

					.box-item {
						display: flex;
						flex-direction: column;
						aspect-ratio: 156/108;
						min-width: 0;
						background-color: #F5F5F5;

						.imgWrap {
							width: 34px;
							height: 34px;
							margin-top: 0;

							img {
								width: 100%;
								height: 100%;
								object-fit: contain;
							}
						}

						b {
							color: $dz-primary;
							font-size: 34px;
						}

						.item-name {
							margin-top: 10px;
							max-width: 95%;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							text-align: center;
						}
					}

					.box-item.box-item-style0 {
						aspect-ratio: 156/60;

						b {
							display: none;
						}

						.imgWrap {
							display: none;
						}

						.item-name {
							margin-top: 0;
						}
					}
				}
			}

			.editImg {
				flex: 1;
				height: calc(100% - 50px);
				display: flex;
				flex-direction: column;

				.con {
					display: block;
					overflow: hidden auto;
					flex: 1;
				}

				.currentPic {
					display: flex;

					.imgWrap {
						display: flex;
						justify-content: center;
						align-items: center;
						flex: 0 0 160px;
						height: 160px;
						aspect-ratio: 1;
						padding: 10px;

						img {
							width: 100%;
							height: 100%;
							object-fit: contain;
						}
					}

					.imgReplaceArea {
						flex: 1;
						margin-left: 1rem;
						margin-top: 1rem;

						&>div {
							margin-bottom: 10px;
						}

						.el-button {
							width: 11rem;
						}
					}
				}

				.editWrap {
					margin-top: 20px;

					.edit-item {
						position: relative;
						align-items: center;
						border-bottom: 1px solid rgb(216, 216, 216);
						display: flex;
						height: 3.5rem;
						flex-shrink: 0;
						font-size: 1rem;
						padding: 0 0.5rem;

						svg {
							width: 2rem;
							height: 2rem;
						}

						.edit-item-title {
							flex: 1 0 auto;
							min-width: 80px;
						}

						.edit-item-content {
							align-items: center;
							display: flex;
							flex: 0 1 auto;

							&>* {
								margin-left: 0.5rem;
							}
						}

						.edit-item-chevron {
							display: flex;
							padding-left: 0.5rem;
							flex-flow: column;
							justify-content: center;
							color: #9ea6ad;
						}
					}

					.edit-angle,
					.edit-size {
						.edit-item-title {
							flex: 0 1 auto;
						}

						.edit-item-content {
							flex: 1 0 auto;

							.el-slider {
								margin-left: 1rem;
								width: 100%;
							}
						}
					}

					.edit-color {
						cursor: pointer;

						.color-picker {
							width: 1.5rem;
							height: 1.5rem;
							border-radius: 2px;
							border: 1px solid #f2f2f2;
						}

						&:hover {
							.edit-item-title {
								color: $dz-primary;
							}
						}
					}

					.editImgColor {
						display: flex;
						flex-direction: column;
						padding: 1rem 0;
						background-color: #fafafa;

						.editColor-colorPicker-selected,
						.editColor-colorPicker-history {
							display: grid;
							grid-template-columns: repeat(auto-fill, 2.875rem);
							grid-column-gap: 17.5px;
							grid-row-gap: 10px;
							max-height: 110px;
							overflow: hidden auto;
						}

						.editColor-colorPicker-colorContainer {
							margin-bottom: 10px;
						}

						.editColor-colorPicker-colors {
							max-height: 170px;
							overflow: hidden auto;
						}

						.editImgColor-foot {
							padding: 1rem;
							display: flex;
							justify-content: center;
							align-items: center;
						}
					}
				}
			}
		}

		.layer-content {
			.con {
				padding: 10px;
			}

			.layer-item {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 60px;
				border-bottom: 1px solid #e6e6e6;

				&.isChildren {
					padding-left: 20px;
				}

				&.moving {
					background: #f2f2f2;
					color: transparent;
				}

				.layer-left {
					flex: 1;
					color: #999999;

					.layer-text {
						max-width: 250px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						color: #999999;
					}

					.showChildren {
						transform: rotate(90deg);
					}

					img {
						max-width: 40px;
						max-height: 40px;
						object-fit: contain;
					}
				}

				.layer-right {
					display: flex;
					flex-shrink: 0;

					.layer-icon {
						position: relative;
						cursor: pointer;
						margin: 0 10px;

						&::before {
							content: "";
							position: absolute;
							top: -10px;
							right: -10px;
							bottom: -10px;
							left: -10px;
						}
					}

					b {
						font-size: 24px;
					}
				}
			}
		}

		.upload-content {
			.con {
				display: flex;
				flex-direction: column;
				padding: 10px;

				.topArea {
					margin-bottom: 30px;

					.upload-area {
						overflow: hidden;
						position: relative;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						aspect-ratio: 509/224;
						background: #fafbfc;
						border: 1px solid #dadcdf;
						border-radius: 10px;
						margin: 14px 0 22px 0;
						text-align: center;
						cursor: pointer;
						transition: all 0.3s;

						&:hover {
							border-color: $dz-primary;
						}

						&.dropActive {
							border-color: $dz-primary;
						}

						input[type="file"] {
							position: absolute;
							opacity: 0;
							z-index: -1;
						}

						.upload-icon {
							font-size: 50px;
							color: #cccccc;
						}

						.upload-text {
							margin: 10px 0;
							line-height: 1.4;

							span {
								color: $dz-primary;
							}
						}

						.upload-tips {
							color: #999999;
							font-size: 14px;
						}
					}

					.tips {
						font-size: 14px;
						color: #999999;
					}
				}

				.bottomArea {
					flex: 1;
					padding-top: 30px;
					border-top: 1px solid #e6e6e6;

					.title {
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-bottom: 20px;

						.title-right {
							color: #999999;
							cursor: pointer;

							b {
								margin-right: 4px;
							}
						}
					}

					.upload-history {
						display: grid;
						align-content: flex-start;
						grid-template-columns: repeat(3, 1fr);
						grid-row-gap: 16px;
						grid-column-gap: 16px;

						.history-item {
							overflow: hidden;
							display: flex;
							justify-content: center;
							align-items: center;
							position: relative;
							padding: 10px;
							background: #ffffff;
							border: 1px solid #e0e0e0;
							aspect-ratio: 1;
							cursor: pointer;

							img {
								object-fit: contain;
							}

							.list-item-btn {
								display: flex;
								justify-content: center;
								align-items: center;
								opacity: 0;
								content: "";
								position: absolute;
								left: 0;
								right: 0;
								top: 0;
								bottom: 0;
								background-color: rgba(0, 0, 0, 0.25);
								transition: all 0.3s;

								&>div {
									display: flex;
									justify-content: center;
									align-items: center;
									margin: 0 4px;
									width: 48px;
									height: 48px;
									background: $dz-primary;
									border-radius: 50%;
									border: none;

									b {
										color: #ffffff;
										font-size: 24px;
									}
								}

								&>div:last-child {
									background-color: #ffffff;

									b {
										color: #b3b3b3;
									}
								}
							}

							&:hover {
								.list-item-btn {
									opacity: 1;
								}
							}
						}
					}
				}
			}
		}

		.product-content {
			.con {
				display: grid;
				justify-content: center;
				align-content: flex-start;
				grid-template-columns: repeat(2, 1fr);
				grid-column-gap: 10px;
				grid-row-gap: 10px;
				padding: 10px;

				.box-item {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					cursor: pointer;

					&.active {
						.imgWrap {
							border-color: $dz-primary;
							box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.24);
						}
					}

					&:hover {
						.imgWrap {
							border-color: $dz-primary;
							box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.24);
						}
					}

					.imgWrap {
						width: 100%;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						aspect-ratio: 1;
						background: #f2f2f2;
						margin-bottom: 10px;
						border: 1px solid transparent;
						transition: all 0.3s;

						img {
							object-fit: cover;
						}
					}
				}
			}
		}

		.myDesign-content {
			.no-login {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin-bottom: 20px;
				padding: 40px 0;

				.tip-icon {
					margin-bottom: 10px;
				}

				.tip-text {
					color: #666666;
				}

				button {
					margin-top: 20px;
				}
			}

			.loginBox {
				position: relative;
				display: flex;
				flex-direction: column;
				height: 100%;

				.topTab {
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;
					font-size: 18px;
					height: 50px;
					border-bottom: 1px solid #ccc;

					.tab-item {
						display: flex;
						justify-content: center;
						align-items: center;
						min-width: 112px;
						height: 100%;
						margin: 0 40px -1px;
						font-size: 18px;
						font-weight: 700;
						cursor: pointer;
						border-bottom: 2px solid transparent;
						transition: all 0.3s;

						&.active {
							color: $dz-primary;
							border-color: $dz-primary;
						}

						&:hover {
							color: $dz-primary;
							border-color: $dz-primary;
						}
					}

					.close-icon,
					.back-icon {
						position: absolute;
						top: 50%;
						right: 10px;
						transform: translateY(-50%);
						cursor: pointer;

						&::before {
							content: "";
							position: absolute;
							top: -10px;
							right: -10px;
							bottom: -10px;
							left: -10px;
						}

						b {
							font-weight: 700 !important;
							cursor: pointer;
						}
					}

					.back-icon {
						right: auto;
						left: 10px;
					}
				}

				.myTemplate,
				.myPic {
					flex: 1;
					overflow: hidden auto;
				}

				.myTemplate {
					display: flex;
					flex-direction: column;

					.template-tab {
						display: flex;
						justify-content: center;
						align-items: center;
						height: 80px;
						flex-shrink: 0;
						cursor: pointer;

						.template-tab-item {
							width: 126px;
							height: 30px;
							line-height: 30px;
							background: #f2f2f2;
							border-radius: 15px;
							color: #333333;
							text-align: center;
							transition: all 0.3s;

							&:first-child {
								margin-right: -15px;
							}

							&.active {
								background: $dz-primary;
								color: #ffffff;
								z-index: 1;
							}

							&:hover {
								background: $dz-primary;
								color: #ffffff;
								z-index: 1;
							}
						}
					}

					.myFavoriteTemplate,
					.myCreationTemplate {
						display: grid;
						grid-template-columns: repeat(2, 1fr);
						flex: 1;
						align-content: flex-start;
						overflow: hidden auto;
						padding: 10px;
						grid-column-gap: 20px;
						grid-row-gap: 20px;

						.favorite-item {
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							position: relative;

							.template-name {
								margin: 10px 0 0;
							}

							.template-create-time {
								color: #999999;
							}

							.imgWrap {
								position: relative;
								width: 100%;
								aspect-ratio: 2/1;
								background: #f2f2f2;

								img {
									object-fit: contain;
								}

								.list-item-btn {
									display: flex;
									justify-content: center;
									align-items: center;
									opacity: 0;
									content: "";
									position: absolute;
									left: 0;
									right: 0;
									top: 0;
									bottom: 0;
									background-color: rgba(0, 0, 0, 0.4);
									transition: all 0.3s;

									&>div {
										display: flex;
										justify-content: center;
										align-items: center;
										margin: 0 4px;
										width: 48px;
										height: 48px;
										background: $dz-primary;
										border-radius: 50%;
										cursor: pointer;

										b {
											color: #ffffff;
											font-size: 24px;
										}
									}

									&>div:last-child {
										background-color: #ffffff;

										b {
											color: #b3b3b3;
										}
									}
								}
							}

							&:hover {
								.list-item-btn {
									opacity: 1;
								}
							}
						}
					}

					.myFavoriteTemplate {
						.favorite-item {
							.imgWrap {
								.list-item-btn {
									&>div:last-child {
										background-color: #ce3d3a;

										b {
											color: #ffffff;
										}
									}
								}
							}
						}
					}
				}

				.myPic {
					display: flex;
					flex-direction: column;
					padding: 10px;

					.upload-area {
						position: relative;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						height: 150px;
						background: #fafbfc;
						border: 1px dashed #dadcdf;
						border-radius: 10px;
						margin: 14px 0 22px 0;
						text-align: center;
						cursor: pointer;

						input[type="file"] {
							position: absolute;
							opacity: 0;
							z-index: -1;
						}

						.upload-icon {
							font-size: 50px;
							color: #cccccc;
						}

						.upload-text {
							margin: 10px 0;
							line-height: 1.4;

							span {
								color: $dz-primary;
							}
						}
					}

					.myUpload {
						overflow: hidden auto;
						flex: 1;
						height: 0;
						display: grid;
						grid-template-columns: repeat(2, 1fr);
						grid-column-gap: 10px;
						grid-row-gap: 10px;
						align-content: flex-start;

						.upload-item {
							min-width: 0;
							display: flex;
							flex-direction: column;
							cursor: pointer;

							.top {
								flex: 1;
								position: relative;
								display: flex;
								flex-wrap: wrap;
								justify-content: center;
								align-items: center;
								aspect-ratio: 1;
								border: 1px solid #e6e6e6;
								border-radius: 6px;
								padding: 5px;

								.imgWrap {
									flex-basis: calc(50% - 4px);
									flex-shrink: 0;
									flex-grow: 0;
									margin: 2px;
									aspect-ratio: 1;
									display: flex;
									justify-content: center;
									align-items: center;

									img {
										object-fit: cover;
									}
								}
							}

							.top.noImg {
								.imgWrap {
									width: 100%;
									height: 100%;

									img {
										width: 100px;
										height: 100px;
									}
								}
							}

							.bottom {
								display: flex;
								justify-content: space-between;
								align-items: center;
								height: 40px;

								.folder-name {
									flex: 1;
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
								}

								.more {
									flex-shrink: 0;
									padding: 4px;
									margin-left: 10px;
									background: #edf1f5;
									border-radius: 8px;
									cursor: pointer;
								}
							}
						}
					}
				}

				.myCustomUploadBox {
					display: flex;
					flex-direction: column;
					overflow: hidden;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: #ffffff;
					z-index: 2;

					.topBar {
						display: flex;
						align-items: center;
						justify-content: center;
						position: relative;
						font-size: 18px;
						height: 50px;
						border-bottom: 1px solid #ccc;

						.close-icon,
						.back-icon {
							position: absolute;
							top: 50%;
							right: 10px;
							transform: translateY(-50%);
							cursor: pointer;

							&::before {
								content: "";
								position: absolute;
								top: -10px;
								right: -10px;
								bottom: -10px;
								left: -10px;
							}

							b {
								font-weight: 700 !important;
								cursor: pointer;
							}
						}

						.back-icon {
							right: auto;
							left: 10px;
						}
					}

					.con {
						display: flex;
						flex-direction: column;
						overflow: hidden;
						flex: 1;
						padding: 10px;

						.controlTab {
							display: flex;
							justify-content: space-between;
							align-items: center;
							height: 40px;
							margin-bottom: 10px;
						}

						.boxWrap {
							overflow: hidden auto;
							flex: 1;
							display: grid;
							align-content: flex-start;
							grid-template-columns: repeat(3, 1fr);
							grid-column-gap: 10px;
							grid-row-gap: 10px;

							.box-item {
								display: flex;
								justify-content: center;
								align-items: center;
								position: relative;
								cursor: pointer;
								aspect-ratio: 1;

								.list-item-btn {
									display: flex;
									justify-content: center;
									align-items: center;
									opacity: 0;
									content: "";
									position: absolute;
									left: 0;
									right: 0;
									top: 0;
									bottom: 0;
									background-color: rgba(0, 0, 0, 0.25);
									transition: all 0.3s;

									&>div {
										display: flex;
										justify-content: center;
										align-items: center;
										margin: 0 4px;
										width: 48px;
										height: 48px;
										background: $dz-primary;
										border-radius: 50%;
										border: none;

										b {
											color: #ffffff;
											font-size: 24px;
										}
									}

									&>.del {
										background-color: #ffffff;

										b {
											color: #b3b3b3;
										}
									}

									&>.cancelLove {
										background-color: #ce3d3a;
									}
								}

								.checkbox {
									position: absolute;
									top: 10px;
									right: 10px;
								}

								&:hover {
									.list-item-btn {
										opacity: 1;
									}
								}
							}
						}

						.btnWrap {
							padding: 1rem;
							display: flex;
							justify-content: flex-end;
							align-items: center;
						}
					}
				}

				.myCustomUploadBox.isFavorite {
					.con {
						.controlTab {
							display: none;
						}

						.checkbox {
							display: none;
						}
					}
				}
			}
		}
	}
}</style>
