import layout from "@/views/layout/index.vue";
const purchaseSystem = () => import('@/views/purchaseManagement/purchaseSystem.vue')
const maintenancePage = () => import("@/views/purchaseManagement/maintenancePage.vue");

export default {
    path: "/purchaseManagement",
    name: "purchaseManagement",
    component: layout,
    meta: {
        title: "采购报价系统管理",
        icon: "el-icon-shopping-cart-full"
    },
    children: [
        {
            path: "purchaseSystem",
            name: "purchaseSystem",
            component: purchaseSystem,
            meta: {
                title: "采购报价系统"
            }
        },
        {
            path: "maintenancePage",
            name: "maintenancePage",
            component: maintenancePage,
            meta: {
                title: "维护界面"
            }
        },

    ]
}
