import layout from "@/views/layout/index.vue";
const statistics = () => import("@/views/recommend/statistics.vue");
const typeA = () => import("@/views/recommend/typeA.vue");
const typeB = () => import("@/views/recommend/typeB.vue");
const typeC = () => import("@/views/recommend/typeC.vue");
const taskInspect = () => import("@/views/recommend/taskInspect.vue");
const taskCheck = () => import("@/views/recommend/taskCheck.vue");
const integralManage = () => import("@/views/recommend/integralManage.vue");
const recommendLevel = () => import("@/views/recommend/recommendLevel.vue");
const integralWithdrawal = () => import("@/views/recommend/integralWithdrawal.vue");
export default 	{
    path: "/recommend",
    name: "recommend",
    component: layout,
    meta: {
        title: "转介绍",
        icon: "el-icon-s-promotion"
    },
    children: [
        {
            path: "statistics",
            component: statistics,
            name: "statistics",
            meta: {
                title: "转介绍统计"
            }
        },
        {
            path: "typeA",
            component: typeA,
            name: "typeA",
            meta: {
                title: "虚拟A"
            }
        },
        {
            path: "typeB",
            component: typeB,
            name: "typeB",
            meta: {
                title: "实际A"
            }
        },
        {
            path: "typeC",
            component: typeC,
            name: "typeC",
            meta: {
                title: "已结束"
            }
        },
        {
            path: "taskInspect",
            component: taskInspect,
            name: "taskInspect",
            meta: {
                title: "任务检查"
            }
        },
        {
            path: "taskCheck",
            component: taskCheck,
            name: "taskCheck",
            meta: {
                title: "任务考核"
            }
        },
        {
            path: "integralManage",
            component: integralManage,
            name: "integralManage",
            meta: {
                title: "转介绍配置管理"
            }
        },
        {
            path: "recommendLevel",
            component: recommendLevel,
            name: "recommendLevel",
            meta: {
                title: "转介绍等级管理"
            }
        },
        {
            path: "integralWithdrawal",
            component: integralWithdrawal,
            name: "integralWithdrawal",
            meta: {
                title: "积分提现"
            }
        }
    ]
}
