<template>
	<ul
		ref="mouseMenuRef"
		class="menu-wrap"
		:style="{
      visibility: show,
      left,
      top,
      zIndex,
    }"
		@click="handleMenu"
	>
		<menuItem v-for="menu in menuList" :key="menu.activeName" :nodeInfo="menu"/>
	</ul>
</template>

<script>
import menuItem from './menuItem.vue';
import dzMixin from "@/mixins/dzMixin";

export default {
	mixins: [dzMixin],
	data() {
		return {
			canvasDom: null,
			show: 'hidden',
			left: 0,
			top: 0,
			zIndex: -100,
			menu: null,
			menuList: [
				// 菜单
				{
					type: 'copy',
					activeName: 'copy',
					text: 'Copy',
					subText: 'Copy',
				},
				{
					type: 'group',
					activeName: 'group',
					text: 'Group',
					subText: 'Group',
				},
				// 对齐
				{
					type: 'center',
					activeName: 'center',
					text: 'Center',
					subText: 'Center',
				},
				// 排序
				{
					type: 'sort',
					activeName: '',
					text: 'Layer',
					subText: '',
					children: [
						{
							type: 'sort',
							activeName: 'up',
							text: 'Bring to Front',
							subText: 'Bring to Front',
						},
						{
							type: 'sort',
							activeName: 'down',
							text: 'Send to Back',
							subText: 'Send to Back',
						},
						{
							type: 'sort',
							activeName: 'upTop',
							text: 'Bring Forward',
							subText: 'Bring Forward',
						},
						{
							type: 'sort',
							activeName: 'downTop',
							text: 'Send Backward',
							subText: 'Send Backward',
						},
					],
				},
				// 删除
				{
					type: 'delete',
					activeName: 'delete',
					text: 'Delete',
					subText: 'Delete',
				},
			],
		};
	},
	components: {
		menuItem,
	},
	mounted() {
		this.$nextTick(() => {
			this.canvasDom = document.getElementById('fabricCanvas') || null;
			this.menu = this.$refs.mouseMenuRef;
			this.menu && (this.menu.oncontextmenu = (e) => e.preventDefault());
			this.init();
		});
		window.addEventListener('click', this.clickHide, true);
	},

	beforeMount() {
		window.removeEventListener('click', this.clickHide, true);
	},

	methods: {
		init() {
			this.canvas.c.on('mouse:down', this.handleMouseUp);
		},

		handleMouseUp(opt) {
			try {
				const canvas = this.canvas.c;
				const activeObject = canvas.getActiveObjects();
				if (!activeObject.length) return this.hideMenu();
				if (opt.button === 3 && opt.target && opt.target.id !== 'workspace') {
					// 当前鼠标位置
					let pointX = opt.e.clientX;
					let pointY = opt.e.clientY;
					this.showMenu(pointX, pointY);
				} else {
					this.hideMenu();
				}
			} catch (error) {
				console.log(error);
			}
		},

		showMenu(x, y) {
			this.show = 'visible';
			this.left = `${x}px`;
			this.top = `${y}px`;
			this.zIndex = 100;
		},

		hideMenu() {
			this.show = 'hidden';
			this.left = 0;
			this.top = 0;
			this.zIndex = -100;
		},

		clickHide(e) {
			if (e.target !== this.canvasDom && this.show === 'visible') {
				this.hideMenu();
			}
		},
		handleMenu(e) {
			const active = e.target.dataset.active || e.srcElement.dataset.active;
			if (!active) return this.hideMenu();
			const canvas = this.canvas.c;
			const activeObject = canvas.getActiveObjects();
			switch (active) {
				case 'copy':
					this.canvas.clone();
					break;
				case 'delete':
					activeObject && activeObject.map((item) => canvas.remove(item));
					canvas.requestRenderAll();
					canvas.discardActiveObject();
					break;
				case 'center':
					this.canvas.position('center');
					break;
				case 'group':
					this.canvas.group();
					break;
				case 'unGroup':
					this.canvas.unGroup();
					break;
				case 'up':
					this.canvas.up();
					break;
				case 'down':
					this.canvas.down();
					break;
				case 'upTop':
					this.canvas.upTop();
					break;
				case 'downTop':
					this.canvas.downTop();
					break;
				default:
					break;
			}
			this.hideMenu();
		},
	},
};
</script>

<style lang="scss" scoped>
.menu-wrap {
	width: 196px;
	padding: 8px 0;
	position: fixed;
	border: 1px solid #e8eaec;
	left: 0;
	top: 0;
	border-radius: 4px;
	visibility: hidden;
	list-style: none;
	/* 隐藏菜单 */
	z-index: -100;
	box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.08);
	background: #fff;

	& > li {
		color: #33383e;
		cursor: pointer;
		padding: 6px 10px;

		span {
			float: right;
			color: #bdbdbd;
		}

		border-bottom: 1px solid #e8eaec;

		&:hover {
			background-color: #f1f3f4;
		}

		&:last-child {
			border-bottom: none;
		}
	}

	.del {
		color: red;
	}
}
</style>
