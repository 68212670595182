<template>
    <div id="tags-view-container" class="tags-view-container">
        <vue-scroll :ops="ops">
            <router-link
                @click.native="handleLink(tag.name)"
                v-for="tag in visitedViews"
                :ref="handleTagNodes"
                :key="tag.path"
                :class="isActive(tag)?'active':''"
                :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
                tag="span"
                class="tags-view-item"
            >
                {{ $t(tag.title) }}
                <span v-if="!isAffix(tag)" class="el-icon-close" @click.prevent.stop="closeSelectedTag(tag)"/>
            </router-link>
        </vue-scroll>
    </div>
</template>

<script>
import path from 'path'
export default {
    name: "TagsView",
    data() {
        return {
            affixTags: [],
            tagNodes: [],
            ops: {
                bar: {
                    background: '#c1c1c1',
                    opacity: 0.7,
                    onlyShowBarOnScroll: false,
                    scrollingY:false,
                    scrollingX:false,
                }
            }
        }
    },
    computed: {
        routes() {
            return this.$store.getters.permission_routes
        },
        visitedViews() {
            return this.$store.getters.visitedViews
        }
    },
    watch: {
        $route() {
            this.addTags()
            this.moveToCurrentTag()
        }
    },
    methods: {
        handleLink(name){
            if(name==='memberManage'){
                this.$store.commit('app/set_showUserDetail',false)
            }
        },
        handleTagNodes(el) {
            if (!this.tagNodes.includes(el)) {
                this.tagNodes.push(el)
            }
        },
        isActive(rt) {
            return rt.path === this.$route.path
        },
        isAffix(tag) {
            return tag.meta && tag.meta.affix
        },
        filterAffixTags(routes, basePath = '/') {
            let tags = []
            routes.forEach(route => {
                if (route.meta && route.meta.affix) {
                    const tagPath = path.resolve(basePath, route.path)
                    tags.push({
                        fullPath: tagPath,
                        path: tagPath,
                        name: route.name,
                        meta: { ...route.meta }
                    })
                }
                if (route.children) {
                    const tempTags = this.filterAffixTags(route.children, route.path)
                    if (tempTags.length >= 1) {
                        tags = [...tags, ...tempTags]
                    }
                }
            })
            return tags
        },
        initTags() {
            const affixTags = this.affixTags = this.filterAffixTags(this.routes)
            for (const tag of affixTags) {
                // Must have tag name
                if (tag.name) {
                    this.$store.dispatch('tagsView/addVisitedView', tag)
                }
            }
        },
        toLastView(visitedViews, view) {
            const latestView = visitedViews.slice(-1)[0];
            if (latestView) {
                this.$router.push(latestView.fullPath)
            } else {
                // now the default is to redirect to the home page if there is no tags-view,
                // you can adjust it according to your needs.
                if (view.name === 'dashboard') {
                    // to reload home page
                    this.$router.replace({path: '/redirect' + view.fullPath})
                } else {
                    this.$router.push('/')
                }
            }
        },
        closeSelectedTag(view) {
            this.$store.dispatch('tagsView/delView', view).then(({visitedViews}) => {
                if (this.isActive(view)) {
                    this.toLastView(visitedViews, view)
                }
            })
        },
        addTags() {
            const {name} = this.$route;
            if (name) {
                this.$store.dispatch('tagsView/addView', this.$route)
            }
            return false
        },
        moveToCurrentTag() {
            const tags = this.tagNodes;
            this.$nextTick(() => {
                for (const tag of tags) {
                    if (tag && tag.to.path === this.$route.path) {
                        if (tag.to.fullPath !== this.$route.fullPath) {
                            store.dispatch('tagsView/updateVisitedView', this.$route)
                        }
                        break
                    }
                }
            })
        }
    },
    beforeUpdate() {
        this.tagNodes = [];
    },
    mounted() {
        this.initTags();
        this.addTags();
    }
};
</script>
<style scoped lang="scss">
.tags-view-container {
    height: 34px;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #d8dce5;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12), 0 0 3px 0 rgba(0, 0, 0, .04);
    white-space: nowrap;
    user-select: none;
    .tags-view-item {
        display: inline-block;
        position: relative;
        cursor: pointer;
        height: 26px;
        line-height: 26px;
        border: 1px solid #d8dce5;
        color: #495060;
        background: #fff;
        padding: 0 8px;
        font-size: 12px;
        margin-left: 5px;
        margin-top: 4px;

        &:first-of-type {
            margin-left: 15px;
        }

        &:last-of-type {
            margin-right: 15px;
        }

        &.active {
            background-color: #42b983;
            color: #fff;
            border-color: #42b983;

            &::before {
                content: '';
                background: #fff;
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                position: relative;
                margin-right: 2px;
            }
        }
    }

    .contextmenu {
        margin: 0;
        background: #fff;
        z-index: 3000;
        position: absolute;
        list-style-type: none;
        padding: 5px 0;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #333;
        box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);

        li {
            margin: 0;
            padding: 7px 16px;
            cursor: pointer;

            &:hover {
                background: #eee;
            }
        }
    }
}
</style>

<style lang="scss">
.tags-view-item {
    .el-icon-close {
        width: 16px;
        height: 16px;
        vertical-align: 2px;
        border-radius: 50%;
        text-align: center;
        transition: all .3s cubic-bezier(.645, .045, .355, 1);
        transform-origin: 100% 50%;

        &:before {
            transform: scale(.6);
            display: inline-block;
            vertical-align: -3px;
        }

        &:hover {
            background-color: #b4bccc;
            color: #fff;
        }
    }
}
</style>
