<template>
	<div class="color-item" :class="{ active: isActive }" @click="changeColor(colorItem)" :title="colorItem.pantone">
		<div class="color-inner-circle" :class="{isWhite:colorItem.code==='#ffffff'}" :style="{ backgroundColor: colorItem.code }">
			<b class="icon-yes"></b>
		</div>
	</div>
</template>

<script>
export default {
	props: ["colorItem", "isActive"],
	methods: {
		changeColor(colorItem) {
			this.$emit("changeColor", colorItem);
		},
	},
};
</script>

<style scoped lang="scss">
.color-item {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2.875rem;
	height: 2.875rem;
	aspect-ratio: 1;
	background: #ffffff;
	border: 1px solid #e6e6e6;
	border-radius: 50%;
	cursor: pointer;
	font-size: 0;

	&.active {
		border-color: #2A96FA;

		b {
			display: block !important;
		}
	}

	&:hover {
		border-color: #2A96FA;

		b {
			display: block;
		}
	}

	.color-inner-circle {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 80%;
		height: 80%;
		border-radius: 50%;

		&.isWhite{
			b{
				color: #333333;
			}
		}

		b {
			display: none;
			color: #ffffff;
			font-size: 14px;
		}
	}

	@media screen and (max-width: 768px) {
		width: 34px;
		height: 34px;
	}
}
</style>
