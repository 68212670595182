import request from "@/utils/request";

// 类别数据
export function getAllType (data) {
  return request({
    url: '/quote/cate/getAll',
    method: 'post',
    data,
  })
}
// 新增编辑色卡
export function addEditColorCard (data) {
  return request({
    url: 'design/platingColorChart/edit',
    method: 'post',
    data,
  })
}
// 查询所有色卡
export function getAllColorCardList (data) {
  return request({
    url: 'design/platingColorChart/list',
    method: 'post',
    data,
  })
}
// 停用/启用
export function changeState (params) {
  return request({
    url: 'design/platingColorChart/enable',
    params,
  })
}
// 删除色卡
export function delById (params) {
  return request({
    url: 'design/platingColorChart/delById',
    params,
  })
}
// 色卡拖拽排序
export function colorCardSort (data) {
  return request({
    url: 'design/platingColorChart/sort',
    method: 'post',
    data,
  })
}
// 
export function quotePlatingList (params) {
  return request({
    url: 'quote/cate/quotePlatingList',
    params,
  })
}