<template>
  <div class="wrap">
     <div class="top">
        <el-input placeholder="搜索类目ID或类目名称" v-model="inputCategoryIdOrName"
        @keyup.enter.native="getCategoryListRequest"
        class="input-with-select">
          <el-button slot="append" icon="el-icon-search" @click="getCategoryListRequest"></el-button>
        </el-input>
        <el-button class="primary" type="primary" icon="el-icon-plus" style="width: 167px;margin-left: 21px;font-weight: bold;" @click="newDesignCategoryShow">新建设计类目</el-button>
        <el-button v-if="!flagMove" class="btn_all" @click="clickMerge"
        type="primary"
        style="width: 111px;margin-left: 21px;background: #7F9DB5;border: #7F9DB5;"
        >拖拽排序</el-button>
        <el-button v-else type="primary" @click="SaveClickMerge"
        style="width: 111px;margin-left: 21px;background: #7F9DB5;border: #7F9DB5;"
        >保存拖拽</el-button>
        <el-button style="width:80px" v-if="flagMove"  @click="cancel">取 消</el-button>
     </div>
     <div :style="flagMove ? 'cursor:move ' : ''">
    <el-table class="customTable" border header-cell-class-name="customTable-head-cell"
      row-key="id"
      :data="categoryList" stripe style="width: 98%;margin-left: 12px;">
        <el-table-column label="排序" width="120" align='center'>
                <template slot-scope="scope">
                  <template v-if="!flagMove">{{ scope.$index + 1 }}</template>
                  <template v-else>
                    <!-- <div style="height: 20px;font-size: 20px;"><b class="icon-drag_indicator_black_24dp"></b></div> -->
                     <div style="display: flex;width: 100%;height:100%; justify-content: center;">
                        <div  class="dragItem">
                          <span class="dragIcon">
                            <b class="icon-a-zhiding1"  @click="tableToTop(scope.row, scope.$index)" v-if="scope.$index !== 0"></b>
                          </span>
                          <span class="dragIcon">
                            <b class="moveDome icon-drag_indicator_black_24dp"></b>
                          </span>
                        </div>
                      </div>
                  </template>
                </template>
              </el-table-column>
        <el-table-column label="类目名称" prop="categoryName" >
        </el-table-column>
        <el-table-column label="类目编号" width="280"  prop="categoryCode" >
        <template slot-scope="scope">
          <span :class="{ blueText: scope.row.productType == '半定制' }">
          {{ scope.row.categoryCode }}</span>
          </template>
        </el-table-column>
        <el-table-column label="产品属性" prop="productType" >
        <template slot-scope="scope">
            <span :class="{ blueText: scope.row.productType == 1 }">
            {{ productTypeFormatter(scope.row.productType) }}</span>
            </template>
        </el-table-column>
        <el-table-column label="对应报价类别" prop="quoteCateName">
        </el-table-column>
        <el-table-column label="类目路径" prop="categoryUrl">
        </el-table-column>
        <el-table-column label="状态" prop="categoryState" >
        <template slot-scope="scope">
              <span :class="{ redText: scope.row.categoryState == 0 }">
              {{ categoryStateFormatter(scope.row.categoryState) }}</span>
              </template>
        </el-table-column>
        <el-table-column label="更新时间" prop="updatetime" >
        </el-table-column>
        <el-table-column label="操作" width="240">
          <template slot-scope="scope">
            <el-link type="primary" @click="setCategoryDesignShow(scope.row)">配置设计功能</el-link>
            <el-link style="margin-left: 26px;"  @click="editDesignCategoryShow(scope.row)">编辑</el-link>
            <el-link type="danger" style="margin-left: 26px;" @click="deleteCategory(scope.row)">删除</el-link>
          </template>
        </el-table-column>
       </el-table></div>
      <!-- 新建设计类目按钮对话框 -->
      <div class="myDialog dialogSpecial" >
       <el-dialog title="新建设计类目" :visible.sync="newDesignCategoryVisible"
       top="6vh" width="70%" :before-close="handleNewBeforeClose">
          <div class="borderBottom"></div>
        <el-form :model="newDesignCategoryForm" style="width: 95%;" :rules="rules"
        ref="newDesignCategoryForm">
          <el-form-item label="类目名称:" :label-width="formLabelWidth" prop="categoryName">
            <el-input  placeholder="请输入设计系统类目英文关键词名称；注意：名称不能重复"
            v-model="newDesignCategoryForm.categoryName"></el-input>
          </el-form-item>
          <el-form-item label="类目编号:" :label-width="formLabelWidth" prop="categoryCode">
              <el-input  placeholder="请输入编号"  v-model="newDesignCategoryForm.categoryCode" ></el-input>
            </el-form-item>
          <el-form-item label="产品属性:" :label-width="formLabelWidth" prop="productType">
            <el-radio-group v-model="newDesignCategoryForm.productType" @input="customChange">
              <el-radio  :label="0" fill="#ffffff">全定制 <span style="color: #0089F5;">(需要配置类目外形)</span></el-radio>
               <el-radio :label="1" fill="#ffffff">半定制<span style="color: #737373;">(无需配置类目外形)</span></el-radio>
              </el-radio-group>
             </el-form-item>
          <el-form-item label="对应报价类别:" :label-width="formLabelWidth" prop="quoteCateId">
              <el-select placeholder="请选择需绑定的报价类别"
              v-model="newDesignCategoryForm.quoteCateId" >
                 <el-option
                      v-for="item in quoteCates"
                      :key="item.id"
                      :label="newDesignCategoryForm.productType=='0'?item.cateName: item.name"
                      :value="item.id">
                    </el-option>
              </el-select>
            </el-form-item>
          <el-form-item label="类目路径:" :label-width="formLabelWidth" >
              <el-input style="width: 16%;" value="/design/"  disabled></el-input>
              <el-input style="width: 84%;margin-left: -1px;color: #8D8D8D;" placeholder="根据所选类别Type-id自动生成"  v-model="path" @blur="checkPath"></el-input>
            </el-form-item>
          <el-form-item label="类目状态:" :label-width="formLabelWidth">
              <el-switch v-model="newDesignCategoryForm.categoryState"
                  active-value="1" :width="50" inactive-color="#767676"
                  inactive-value="0">
                </el-switch>
              <span class="switchText checkedText" @click="newDesignCategoryForm.categoryState='0'"
              :class="{checkedSwitch: newDesignCategoryForm.categoryState =='1'}">启用</span>
              <span class="switchText uncheckedText" @click="newDesignCategoryForm.categoryState = '1'"
              :class="{ checkedSwitch: newDesignCategoryForm.categoryState == '0'|| newDesignCategoryForm.categoryState == null }">停用</span>
            </el-form-item>
          <el-form-item label="Title标签:" :label-width="formLabelWidth" prop="categoryTitle">
              <el-input v-model="newDesignCategoryForm.categoryTitle"  ></el-input>
            </el-form-item>
          <el-form-item label="Keywords:" :label-width="formLabelWidth" prop="categoryKeywords">
              <el-input v-model="newDesignCategoryForm.categoryKeywords"  ></el-input>
            </el-form-item>
          <el-form-item label="Descriptions:" :label-width="formLabelWidth" prop="categoryDescription">
              <el-input
                  type="textarea"
                  :rows="3" style="resize:none"
                  v-model="newDesignCategoryForm.categoryDescription">
              </el-input>
            </el-form-item>
          <el-form-item label="页面简介:" :label-width="formLabelWidth" prop="categoryIntroduction">
              <el-input
                    type="textarea"
                    :rows="3"
                    v-model="newDesignCategoryForm.categoryIntroduction">
                </el-input>
            </el-form-item>
        </el-form>
    <div slot="footer" class="dialog-footer" >
      <el-button class="primary" @click="newDesignCategorySubmit" style="width: 135px;">保存</el-button>
      <el-button  @click="resetForm('newDesignCategoryForm');newDesignCategoryVisible = false" style="width: 135px;margin-right: 62px;" plain>取消</el-button>
    </div>
  </el-dialog>
      </div>
      <!-- 编辑设计类目对话框 -->
      <div class="myDialog dialogSpecial" >
       <el-dialog title="编辑设计类目" :visible.sync="editDesignCategoryVisible"
       top="6vh" width="70%" :before-close="handleEditBeforeClose" >
          <div class="borderBottom"></div>
        <el-form :model="editDesignCategoryForm" ref="editDesignCategoryForm"
        style="width: 95%;" :rules="rules">
          <el-form-item label="类目名称:" :label-width="formLabelWidth" prop="categoryName">
            <el-input  placeholder="请输入设计系统类目英文关键词名称；注意：名称不能重复"
            v-model="editDesignCategoryForm.categoryName" ></el-input>
          </el-form-item>
          <el-form-item label="类目编号:" :label-width="formLabelWidth" prop="categoryCode">
              <el-input  placeholder="请输入编号" v-model="editDesignCategoryForm.categoryCode"
              ></el-input>
            </el-form-item>
          <el-form-item label="产品属性:" :label-width="formLabelWidth" prop="productType">
            <el-radio-group v-model="editDesignCategoryForm.productType" @input="customChange">
              <el-radio :label="0" fill="#ffffff">全定制 <span style="color: #0089F5;">(需要配置类目外形)</span></el-radio>
               <el-radio :label="1" fill="#ffffff">半定制<span style="color: #737373;">(无需配置类目外形)</span></el-radio>
              </el-radio-group>
             </el-form-item>
          <el-form-item label="对应报价类别:" :label-width="formLabelWidth" prop="quoteCateId">
              <el-select placeholder="请选择需绑定的报价类别"
              v-model="editDesignCategoryForm.quoteCateId">
                 <el-option
                      v-for="item in quoteCates"
                      :key="item.id"
                      :label="editDesignCategoryForm.productType == '0' ? item.cateName : item.name"
                      :value="item.id">
                    </el-option>
              </el-select>
            </el-form-item>
          <el-form-item label="类目路径:" :label-width="formLabelWidth" >
              <el-input style="width: 16%;" value="/design/"  disabled ></el-input>
              <el-input style="width: 84%;margin-left: -1px;" placeholder="根据所选类别Type-id自动生成"  v-model="path" @blur="checkPath"></el-input>
            </el-form-item>
          <el-form-item label="类目状态:" :label-width="formLabelWidth">
              <el-switch v-model="editDesignCategoryForm.categoryState"
                  :active-value="1" :width="50" inactive-color="#767676"
                  :inactive-value="0">
                </el-switch>
              <span class="switchText checkedText" @click="editDesignCategoryForm.categoryState = '0'"
              :class="{checkedSwitch: editDesignCategoryForm.categoryState=='1'}">启用</span>
              <span class="switchText uncheckedText" @click="editDesignCategoryForm.categoryState = 1"
              :class="{ checkedSwitch: editDesignCategoryForm.categoryState ==  '0' || editDesignCategoryForm.categoryState == null }">停用</span>
            </el-form-item>
          <el-form-item label="Title标签:" :label-width="formLabelWidth" prop="categoryTitle">
              <el-input v-model="editDesignCategoryForm.categoryTitle"  ></el-input>
            </el-form-item>
          <el-form-item label="Keywords:" :label-width="formLabelWidth" prop="categoryKeywords">
              <el-input v-model="editDesignCategoryForm.categoryKeywords"  ></el-input>
            </el-form-item>
          <el-form-item label="Descriptions:" :label-width="formLabelWidth" prop="categoryDescription">
              <el-input
                  type="textarea"
                  :rows="3" style="resize:none"
                  v-model="editDesignCategoryForm.categoryDescription">
              </el-input>
            </el-form-item>
          <el-form-item label="页面简介:" :label-width="formLabelWidth" prop="categoryIntroduction">
              <el-input
                    type="textarea"
                    :rows="3"
                    v-model="editDesignCategoryForm.categoryIntroduction">
                </el-input>
            </el-form-item>
        </el-form>
    <div slot="footer" class="dialog-footer" >
      <el-button class="primary" @click="editDesignCategorySubmit(0)" style="width: 135px;">保存</el-button>
      <el-button  type="primary" @click="editDesignCategorySubmit(1)" style="width: 158px;">保存并同步多语言</el-button>
      <el-button  @click="resetForm('editDesignCategoryForm');editDesignCategoryVisible = false" style="width: 135px;margin-right: 62px;" plain>取消</el-button>
    </div>
  </el-dialog>
      </div>
      <!-- 配置类目设计功能 -->
      <div class="myDialog" >
         <el-dialog title="配置类目设计功能" :visible.sync="setCategoryDesignVisible"
         top="6vh" width="46%" :before-close="handleBeforeClose">
          <el-form :model="setCategoryDesignForm" style="width: 95%;"
            ref="setCategoryDesignForm">
            <!-- 蓝色区域 -->
            <div style="background: #E4F2FF;margin-bottom: 5px;overflow: hidden;">
              <!-- 行内表单 -->
                 <el-form :inline="true" :model="setCategoryDesignForm"
                 class="demo-form-inline" ref="setCategoryDesignForm">
                <el-form-item label="类目名称:" prop="categoryName"
                :label-width="formLabelWidth" style="width: 48%;float: left;">
                  {{ setCategoryDesignForm.categoryName }}
                </el-form-item>
                <el-form-item label="类目编号:" :label-width="formLabelWidth" :class="{ blueText: setCategoryDesignForm.productType == 1 }"
                 prop="categoryCode" style="width: 48%;float: right;">
                {{ setCategoryDesignForm.categoryCode }}
                </el-form-item>
                </el-form>
              <!-- 行内表单 -->
                <el-form :inline="true" prop="productType" ref="setCategoryDesignForm"
                :model="setCategoryDesignForm" class="demo-form-inline" >
                <el-form-item label="产品属性:" style="width: 48%;float: left;"
                :label-width="formLabelWidth" :class="{ blueText: setCategoryDesignForm.productType ==1 }">
                {{ productTypeFormatter(setCategoryDesignForm.productType) }}
                </el-form-item>
                  <el-form-item label="对应报价类别:"  :label-width="formLabelWidth"
                  prop="quoteCateName" style="width: 48%;float: right;">
                {{ setCategoryDesignForm.quoteCateName }}
                  </el-form-item>
                  </el-form>
            </div>
            <!-- 行内表单 -->
             <el-form :inline="true" :model="setCategoryDesignForm" :rules="rules"
             class="demo-form-inline" ref="setCategoryDesignForm1">
              <el-form-item label="切换产品功能:" :label-width="formLabelWidth" prop="isChangeProduct">
                <el-switch v-model="setCategoryDesignForm.isChangeProduct"
                    :active-value="1" :width="50" inactive-color="#767676"
                    :inactive-value="0">
                  </el-switch>
                <span class="switchText checkedText" @click="setCategoryDesignForm.isChangeProduct = 0"
                :class="{ checkedSwitch: setCategoryDesignForm.isChangeProduct == '1' }">启用</span>
                <span class="switchText uncheckedText" @click="setCategoryDesignForm.isChangeProduct = 1"
                :class="{ checkedSwitch: setCategoryDesignForm.isChangeProduct == '0'|| setCategoryDesignForm.isChangeProduct ==null }">停用</span>
              </el-form-item>
              <el-form-item label="激活模板库产品品类:" label-width="175px"
              style="margin-left: 147px;" prop="isDisplayInTemplate">
                <el-switch v-model="setCategoryDesignForm.isDisplayInTemplate"
                    :active-value="1" :width="50" inactive-color="#767676"
                    :inactive-value="0">
                  </el-switch>
                <span class="switchText checkedText" @click="setCategoryDesignForm.isDisplayInTemplate = 0"
                :class="{ checkedSwitch: setCategoryDesignForm.isDisplayInTemplate == '1' }">启用</span>
                <span class="switchText uncheckedText" @click="setCategoryDesignForm.isDisplayInTemplate = 1"
                :class="{ checkedSwitch: setCategoryDesignForm.isDisplayInTemplate == '0'|| setCategoryDesignForm.isDisplayInTemplate == null }">关闭</span>
              </el-form-item>
            </el-form>
            <!-- 行内表单 -->
               <el-form :inline="true" :model="setCategoryDesignForm" ref="setCategoryDesignForm2"
               class="demo-form-inline" :rules="rules" style="margin-bottom: 8px;">
                <el-form-item label="类目主图:" :label-width="formLabelWidth" prop="categoryMainImg">
                  <span class="picTip">注:<br>展示在设计系统产品选择弹窗中</span>
                  <!-- 上传图片 -->
                    <FileUpload single style="zoom: 0.7;margin-top: 14px;"
                      :file-list="setCategoryDesignForm.categoryMainImg"
                      @updateList="updateCategoryImg" class="fileUploadClass">
                    </FileUpload>
                </el-form-item>
                <el-form-item label="金属产品:" label-width="175px" style="margin-left: 70px;" prop="isMetal">
                 <el-radio-group v-model="setCategoryDesignForm.isMetal" style="margin-top: 10px;">
                    <el-radio  :label="1">是
                      <span style="color: #0089F5;">(激活金属色选项)</span></el-radio><br>
                    <el-radio  :label="0" style="margin-top: 20px;">否
                      <span style="color: #737373;">(不激活金属色选项)</span></el-radio></el-radio-group>
                </el-form-item>
              </el-form>

              <el-form-item label="是否有底图:" :label-width="formLabelWidth" prop="hasBg" :rules="[
          { required: true, message: '请选择是否有底图！', trigger: 'blur' },
           ]">
                <el-radio-group v-model="setCategoryDesignForm.hasBg">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="是否为贴纸:" :label-width="formLabelWidth" prop="isSticker" :rules="[
          { required: true, message: '请选择是否为贴纸！', trigger: 'blur' },
           ]">
                <el-radio-group v-model="setCategoryDesignForm.isSticker">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="效果类型:" :label-width="formLabelWidth" prop="stickerEffectType" :rules="[
          { required: true, message: '请选择效果类型！', trigger: 'blur' },
           ]">
                <el-radio-group v-model="setCategoryDesignForm.stickerEffectType">
                  <el-radio :label="item.label" v-for="(item,index) in typeList" :key="index">{{ item.name }}</el-radio>
                </el-radio-group>
              </el-form-item>
       


            <el-form-item label="类目设计位置:" v-if="setCategoryDesignForm.productType == 0"
            :rules="[
          { required: true, message: '类目设计位置至少选择一个！', trigger: 'blur' },
           ]"
            :model="setCategoryDesignForm.positionConfigs"
            :label-width="formLabelWidth" prop="positionConfigs" class="checkRight">
               <div >
                  <el-checkbox v-model="frontCheck" label="Front" border
                  style="width: 200px;" :style="{ background: frontCheck == false ? '#D8D8D8' : '#E4F2FF' }"
                  ></el-checkbox>
                  <el-checkbox v-model="backCheck" label="Back" border
                  style="width: 200px;" :style="{ background: backCheck == false ? '#D8D8D8' : '#E4F2FF' }"
                  ></el-checkbox>
                </div>
              </el-form-item>
            <el-form-item label="类目设计位置:" v-else
              :model="setCategoryDesignForm.positionConfigs"
              :label-width="formLabelWidth" prop="positionConfigs">
                  <el-input
                      placeholder="系统自动提取半定制商品库产品的印刷位置数据"
                      :disabled="true">
                    </el-input>
                </el-form-item>
            <el-form-item label="位置文案说明:" :label-width="formLabelWidth" >
                <div v-if="setCategoryDesignForm.productType == 0">
                  <el-input
                      type="textarea" placeholder="请输入对设计系统位置的颜色说明文案"
                      :rows="2" style="width: 200px;resize:none"
                      v-model="front.positionCopywriting" @blur="positionCheck('front')">
                  </el-input>
                  <el-input
                        type="textarea" placeholder="请输入对设计系统位置的颜色说明文案"
                        :rows="2" style="margin-left: 40px; width: 200px;resize:none"
                        v-model="back.positionCopywriting" @blur="positionCheck('back')">
                    </el-input>
                </div>
                <el-input v-else
                      placeholder="系统自动提取半定制商品库产品的印刷位置数据"
                      :disabled="true">
                    </el-input>
              </el-form-item>
              <el-form-item label="位置颜色限制:" :label-width="formLabelWidth" >
                   <div v-if="setCategoryDesignForm.productType == 0">
                    <el-select v-model="front.positionColorLimitation" placeholder="请选择对应报价步骤" style="width: 200px;">
                      <el-option
                          v-for="item in quoteCates"
                          :key="item.quoteCateId"
                          :label="item.quoteCateName"
                          :value="item.quoteCateName">
                    </el-option>
                    </el-select>
                    <el-select v-model="back.positionColorLimitation" placeholder="请选择对应报价步骤" style="margin-left: 40px; width: 200px;">
                        <el-option
                            v-for="item in quoteCates"
                            :key="item.quoteCateId"
                            :label="item.quoteCateName"
                            :value="item.quoteCateName">
                        </el-option>
                      </el-select>
                   </div>
                   <el-input v-else
                      placeholder="系统自动提取半定制商品库产品的印刷位置数据"
                      :disabled="true">
                    </el-input>
              </el-form-item>
                <el-form-item label="位置默认外形:" :label-width="formLabelWidth" >
                  <div v-if="setCategoryDesignForm.productType == 0" style="width: 440px;">
                    <el-select v-model="setCategoryDesignForm.positionCalmAppearence" placeholder="请选择" :popper-append-to-body="false"
                    class="checkPicture"  @change="appearenceChange($event)" >
                        <el-option class="optionClass"
                          v-for="item in picSelect"
                          :key="item.id" ref="optArr"
                          :label="item.name"
                          :value="item.id">
                          <el-radio
                          :value="setCategoryDesignForm.positionCalmAppearence" :label="item.id" border
                          class="radioClass">
                          <div class="selectText" >{{ item.name }}</div>
                            <div class="picWidth">
                              <img :src="item.imgLeft" alt="">
                              <img :src="item.imgRight" alt="">
                            </div>
                           </el-radio>
                        </el-option>
                      </el-select>
                        <!-- 上传图片 -->
                      <div>
                        <FileUpload single style="width: 200px;float: left;"
                          :file-list="imgLeft"
                          @updateList="updateLeftCategoryImg">
                        </FileUpload>
                        <FileUpload single style="width: 20px;float: left;margin-left: 60px;"
                            :file-list="imgRight"
                            @updateList="updateRightCategoryImg">
                          </FileUpload>
                      </div>
                  </div>
                  <el-input v-else
                      placeholder="系统自动提取半定制商品库产品的印刷位置数据"
                      :disabled="true">
                    </el-input>
                </el-form-item>
                <el-form-item label="样式配置:" :label-width="formLabelWidth">
                  <el-radio-group v-model="setCategoryDesignForm.styleConfiguration">
                    <el-radio :label="3">一排三个</el-radio>
                    <el-radio :label="5">一排五个</el-radio>
                  </el-radio-group>
                </el-form-item>
          </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="primary" @click="setCategoryDesignSubmit" style="width: 135px;">保存</el-button>
        <el-button  @click=" setCategoryDesignVisible = false; resetForm('setCategoryDesignForm');" style="width: 135px;margin-right: 62px;" plain>取消</el-button>
      </div>
    </el-dialog>
        </div>
  </div>
</template>
<script>
import { getCategoryList , getCategoryById,updateCategoryConfig,
  deleteCategoryById ,changeCategorySort, updateCategoryBasic, addCategoryBasic,
  getAllCustomCategory, getSemiCustomCategory
} from '@/api/designCenter/interfaceManagement.js';
import FileUpload from "@/components/FileUpload.vue";
// import {mapGetters} from 'vuex';
import messageBoxTitle from "../../../customManagement/message_color.vue";
import Sortable from "sortablejs";
export default {
  name:'bCManagement',
  components: {
    FileUpload
  },
  // computed:{
  //   ...mapGetters(["language"]),
  // },
  data() {
     var validateCategoryName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入类目名称'));
      } else {
        if (value.indexOf('/') != -1) {
          callback(new Error("类目名称不可以有'/'！"));
        } else {
          setTimeout(() => {
            if (this.flag == 'new') {
              this.$refs.newDesignCategoryForm.validateField('categoryName');
              callback();
            } else if (this.flag == 'edit') {
              this.$refs.editDesignCategoryForm.validateField('categoryName');
              callback();
            }
          })
        }
      }
    };
    var validateCategoryCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请选择类目编号'));
      } else {
        if (value.indexOf('/') != -1) {
          callback(new Error("类目编号不可以有'/'！"));
        } else {
          setTimeout(() => {
            if (this.flag == 'new') {
              this.$refs.newDesignCategoryForm.validateField('categoryName');
              callback();
            } else if (this.flag == 'edit') {
              this.$refs.editDesignCategoryForm.validateField('categoryName');
              callback();
            }
          })
        }
      }
    };
    return {
      typeList:[
        {
          label:"1",
          name:"模切"
        },
        {
          label:"2",
          name:"矩形"
        },
        {
          label:"3",
          name:"圆形"
        },
        {
          label:"4",
          name:"圆角矩形"
        }
      ],
      flagMove:false,
      newData:[],//排序后的数组
      formLabelWidth: '120px',
      inputCategoryIdOrName:'',
      language:'en-us',
      categoryList:[
        {
          id:1,
          categoryName: '这里是死数据',
          categoryCode:'Full-Design-LP-001',
          productType:'全定制',
          quoteCateName:'Quote-LP-001',
          categoryUrl:'/design/type-id/lapel-pins',
          categoryState:'启用中',
          updatetime: '2023-07-01 09:26:04',
        }, {
          id: 2,
          categoryName: '找后端朋友中',
          categoryCode: 'Full-Design-LP-001',
          productType: '全定制',
          quoteCateName: 'Quote-LP-001',
          categoryUrl: '/design/type-id/lapel-pins',
          categoryState: '启用中',
          updatetime: '2023-07-01 09:26:04',
        },
      ],
      value:'',
      newDesignCategoryVisible:false,
      editDesignCategoryVisible:false,
      setCategoryDesignVisible:false,
      frontCheck:false,
      backCheck:false,
      operaId:-1,//正在操作的id
      //表单类目位置
      front: {
        designPostion: 0,
        positionColorLimitation: '',
        positionCopywriting: ''
      },
      back: {
        designPostion: 1,
        positionColorLimitation: '',
        positionCopywriting: ''
      },
      setCategoryDesignForm:{
        styleConfiguration:"",
        categoryName: '666',
        categoryCode: '',
        productType: '',
        quoteCateName: '',
        isChangeProduct:0,
        isDisplayInTemplate:0,
        categoryMainImg:'',
        isMetal:'',
		  hasBg: 0,
        positionConfigs:[],
        positionCalmAppearence:'',
        stickerEffectType:'',
        isSticker:0,
      },
      flag:'',
      //新建设计类目表单
      newDesignCategoryForm:{
        categoryName:'',
        categoryCode:'',
        productType:'',
        quoteCateId: '',
        quoteCateName:'',
        categoryUrl:'/design/',
        categoryTitle:'',
        categoryKeywords:'',
        categoryDescription:'',
        categoryIntroduction:'',
        categoryState:'1',
        categoryMainImg:'',
        positionConfigs:'',
        quoteCateUrl:''
      },
      //编辑表单
      editDesignCategoryForm: {
        categoryName: '',
        categoryCode: '',
        productType: '',
        quoteCateId: '',
        quoteCateName: '',
        categoryUrl: '/design/',
        categoryTitle: '',
        categoryKeywords: '',
        categoryDescription: '',
        categoryIntroduction: '',
        categoryState: '',
        categoryMainImg: '',
        positionConfigs: '',
        quoteCateUrl: ''
      },
      hideUploadBtn:0,
      rules: {
        categoryName: [
          { required: true, message: '请输入类目名称', trigger: 'blur' },
          { validator: validateCategoryName, message: "类目名称不可以有' / '！", trigger: 'blur' }
        ],
        categoryCode: [
          { required: true, message: '请选择类目编号', trigger: 'blur' },
          { validator: validateCategoryCode,trigger: 'blur' }
        ],
        productType: [
         { required: true, message: '请选择产品属性', trigger: 'blur' }
        ],
        quoteCateId: [
         { required: true, message: '请选择对应报价类别', trigger: 'blur' }
        ],
        categoryTitle: [
          { required: true, message: '请填写title标签', trigger: 'blur' }
        ],
        categoryKeywords: [
          { required: true, message: '请填写Keywords', trigger: 'blur' }
        ],
        categoryDescription: [
          { required: true, message: '请填写Description', trigger: 'blur' }
        ],
        categoryMainImg: [
          { required: true, message: "请上传类别展示图", trigger: "blur" },
        ],
        isMetal:[
          { required: true, message: "请选择是否激活金属色", trigger: "blur" },
        ],
        categoryIntroduction:[
          { required: true, message: "请填写页面简介", trigger: "blur" },
        ],
      },
      allQuoteCates:[],//全定制报价类别
      semiQuoteCates:[],//半定制报价类别
      quoteCates: [{
        quoteCateId:1,
        quoteCateName: '黄金糕'
      }, {
        quoteCateId: 2,
        quoteCateName: '马蹄糕'
      },],
      path:'',
      picSelect: [{
        id: 1,
        name: 'Regular Shape03 RS-003',
        imgLeft: require('../../../../../assets/image/404_cloud.png'),
        imgRight: require('../../../../../assets/image/defaultPicture.png')
      }, {
        id: 2,
        name: '222222222222222',
        imgLeft: require('../../../../../assets/image/angle1.png'),
        imgRight: require('../../../../../assets/image/angle1.png')
      }, {
        id: 3,
        name: '333333333333',
        imgLeft: require('../../../../../assets/image/angle1.png'),
        imgRight: require('../../../../../assets/image/angle1.png')
      },],
      //目前不需要
      imgLeft:[],
      imgRight:[],
    };
  },
  watch:{
    editDesignCategoryVisible(newVal){
      if(!newVal) this.editDesignCategoryForm = this.$options.data()
    },
    "newDesignCategoryForm.quoteCateId": {
      deep: true,
      handler (val) {
        let productType = this.newDesignCategoryForm.productType
        this.handleQuoteCateName(val, this.flag, productType)
      }
    },
    "editDesignCategoryForm.quoteCateId": {
      deep: true,
      handler (val) {
        let productType = this.editDesignCategoryForm.productType 
        this.handleQuoteCateName(val, this.flag, productType)

      }
    }
  },
  methods:{
    checkPath () {
      const reg = /[^\x00-\xff]/g;
      if(reg.test(this.path)){
        this.$message.error("类目路径不可以有中文字符和全角字符以及大写英文字母！");
        return false;
      }else if(/\s/g.test(this.path)){
        this.$message.error("类目路径不可以有空格！");
        return false;
      }else if(/[A-Z]/g.test(this.path)){
        this.$message.error("类目路径不可以有大写英文字母！");
        return false;
      }else if(this.path.indexOf('/')!=this.path.length-1|| this.path.indexOf('/')==-1||this.path.indexOf('/')==0){
        this.$message.error("类目路径里不能有'/'！类目路径结束必须有且仅有一个'/'");
        return false;
      }
      return true;
    },
    positionCheck(flag){//相关说明若填写的话应提示勾选相应的位置
      if(flag=='front'&&this.frontCheck==false){
        this.$message.warning('请勾选相应位置！')
      }else if(flag=='back'&&this.backCheck==false){
        this.$message.warning('请勾选相应位置！')
      }
    },
    getCategoryListRequest(){
      getCategoryList({
        language: this.language,
        categoryNameCode: this.inputCategoryIdOrName,
      }).then(res => {
        if(res.code=='200'){
          this.categoryList = res.data;
        }else{
          this.$message.error('获取数据请求失败！');
        }
      })
    },
    getAllCustomCategoryRequest(){//获取全定制报价类别
      getAllCustomCategory({
        pid: 0
      }).then(res=>{
        if(res.code=='200'){
          this.allQuoteCates=res.data;
        }else{
          this.$message.error('全定制报价数据获取失败！');
        }
      })
    },
    getSemiCustomCategoryRequest(){//获取半定制报价类别
      getSemiCustomCategory({
        id: 0,
        language: this.language
      }).then(res=>{
        if(res.code=='200'){
          this.semiQuoteCates=res.data;
        }else{
          this.$message.error('半定制报价数据获取失败！');
        }
      })
    },
    editDesignCategoryShow(row){
      this.path='';
      this.flag = 'edit';
      this.operaId=row.id;
      getCategoryById({
        id: this.operaId,
        language: this.language,
      }).then(res => {
        if (res.code == '200') {
          this.editDesignCategoryForm = res.data;
          if(this.editDesignCategoryForm.categoryUrl){
            let str= this.editDesignCategoryForm.categoryUrl;
            let index=str.search("/design/");
            index += 8;
            str = str.slice(index);
            index = str.indexOf('/')+1;
            str = str.slice(0, index);
            this.path=str;
          }
          this.quoteCates =this.editDesignCategoryForm.productType=='0'?
          this.allQuoteCates:this.semiQuoteCates;
          let quoteCate=this.quoteCates.find(obj => obj.id == this.editDesignCategoryForm.quoteCateId)
          if(!quoteCate) this.editDesignCategoryForm.quoteCateId='';
          this.editDesignCategoryVisible = true;
        }else{
          this.$message.error('获取类目失败！');
        }
      })
    },
    editDesignCategorySubmit(isMultiLanguage){
      this.$refs['editDesignCategoryForm'].validate((valid) => {
        if(!this.checkPath())return false;
        if (valid) {
          let { id, categoryName, categoryCode, productType,
            quoteCateId, quoteCateName, categoryState,
            categoryTitle, categoryKeywords, categoryDescription,
            categoryIntroduction, quoteCateUrl } = this.editDesignCategoryForm;
          let editFormcategoryUrl =
            '/design/' + this.path;
          updateCategoryBasic({
            categoryUrl: editFormcategoryUrl,
            isMultiLanguage, id,
            language: this.language, categoryName, categoryCode, productType,
            quoteCateId, quoteCateName, categoryState,
            categoryTitle, categoryKeywords, categoryDescription,
            categoryIntroduction, quoteCateUrl
          }).then(res => {
            if (res.code == '200') {
              this.$message({
                type: 'success',
                message: '编辑成功!'
              });
              this.getCategoryListRequest();
              this.editDesignCategoryVisible = false;
              this.resetForm('editDesignCategoryForm');
            }else{
              this.$message.error('编辑类目失败！');
            }
          })
        } else {
          this.$message.error('输入信息验证失败！请确保已填写必选项并保证正确格式！');
          return false;
        }
      });
    },
    newDesignCategoryShow () {
      this.path = '';
      this.flag='new';
      this.newDesignCategoryVisible = true;
    },
    newDesignCategorySubmit(){
      this.$refs['newDesignCategoryForm'].validate((valid) => {
        if(!this.checkPath())return false;
        if (valid) {
          let { categoryName, categoryCode, productType,
            quoteCateId, quoteCateName, categoryState,
            categoryTitle, categoryKeywords, categoryDescription,
            categoryIntroduction , quoteCateUrl } = this.newDesignCategoryForm;
          let newFormUrl =
            this.newDesignCategoryForm.categoryUrl + this.path;
          addCategoryBasic({
            language: this.language,
            isMultiLanguage: 1,
            categoryUrl: newFormUrl,
            categoryName, categoryCode, productType,
            quoteCateId, quoteCateName, categoryState,
            categoryTitle, categoryKeywords, categoryDescription,
            categoryIntroduction, quoteCateUrl
          }).then(res => {
            if (res.code == '200') {
              this.$message({
                type: 'success',
                message: '新增成功!'
              });
              this.getCategoryListRequest();
              this.newDesignCategoryVisible = false;
              this.resetForm('newDesignCategoryForm');
            }else{
              this.$message.error('新增类目失败！');
            }
          })
        } else {
          this.$message.error('输入信息验证失败！请确保已填写必选项并保证正确格式！');
          return false;
        }
      });
    },
    productTypeFormatter(value){
      return value==0?'全定制':value==1?'半定制':'无';
    },
    categoryStateFormatter(value){
      return value==1?'启用中':'已停用';
    },
    setCategoryDesignShow(row){
      console.log(row,"row");
      
      this.operaId= row.id;
      getCategoryById({
        id: row.id,
        language: this.language,
      }).then(res => {
        if (res.code == '200') {
          this.setCategoryDesignForm = res.data;
          this.setCategoryDesignForm.styleConfiguration = JSON.parse(row.styleConfiguration);
          if (this.setCategoryDesignForm.positionConfigs) {
            this.front = this.setCategoryDesignForm.positionConfigs.find(obj => obj.designPostion === 0);
            this.back = this.setCategoryDesignForm.positionConfigs.find(obj => obj.designPostion === 1);
            this.frontCheck = this.front ? true : false;
            this.backCheck = this.back ? true : false;
            this.front = this.frontCheck ? this.front : {
              designPostion: 0,
              positionColorLimitation: '',
              positionCopywriting: ''
            };
            this.back = this.backCheck ? this.back : {
              designPostion: 1,
              positionColorLimitation: '',
              positionCopywriting: ''
            };
          } else {
            this.frontCheck = false;
            this.backCheck = false;
          }
          this.setCategoryDesignVisible = true;
        }else{
          this.$message.error('获取类目失败！');
        }
      })
    },
    setCategoryDesignSubmit(){
      this.$refs['setCategoryDesignForm2'].validate((valid) => {
          if (valid&&((this.frontCheck || this.backCheck)||
          this.setCategoryDesignForm.productType=='1')) {
            this.setCategoryDesignForm.categoryUrl =
              this.setCategoryDesignForm.categoryUrl + this.path;
            this.setCategoryDesignForm.positionConfigs = [];
            if (this.frontCheck) {
              this.setCategoryDesignForm.positionConfigs.push(this.front);
            }
            if (this.backCheck) {
              this.setCategoryDesignForm.positionConfigs.push(this.back);
            }
            let { language, categoryMainImg, isMetal, isChangeProduct,
              isDisplayInTemplate, shapeId, positionConfigs,hasBg,isSticker,stickerEffectType } = this.setCategoryDesignForm;
          
              updateCategoryConfig({
              id: this.operaId,
              language,
              categoryMainImg,
              hasBg,
              isSticker,
              stickerEffectType,
              isMetal: isMetal == null ? ' ' : isMetal,
              isChangeProduct: isChangeProduct==null?0: isChangeProduct,
              isDisplayInTemplate: isDisplayInTemplate == null ? ' ' : isDisplayInTemplate,
              shapeId: shapeId == null ? ' ' : shapeId,
              positionConfigs,
              styleConfiguration:JSON.stringify(this.setCategoryDesignForm.styleConfiguration)
            }).then(res => {
              if(res.code=='200'){
                this.getCategoryListRequest();
                this.$message({
                message: '配置设计功能成功！',
                type: 'success'
                });
                this.resetForm('setCategoryDesignForm');
              }else{
                this.$message.error('配置设计功能失败！');
              }
            });
            this.setCategoryDesignVisible = false;
          } else {
            this.$message.error('请填写必选项！')
            return false;
          }
        });
    },
    tableToTop (row, index) {
      this.categoryList.splice(0, 0, this.categoryList.splice(index, 1)[0])
      // this.tableData.splice(index, 1)
      // this.tableData.unshift(row)
      this.newData = [].concat(this.categoryList.map((item, index) => ({
        id: item.id,
        // sortNum: index + 1,
      })));

    },
    initSortable (disabled) {
      console.log(this.flagMove, 'this.flag');
      const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
      // const el = document.querySelector('.el-table__body-wrapper tbody')
      const sortable = new Sortable(el, {
        disabled: disabled,
        scroll: true,
        animation: 180,
        dragClass: "dragClass", //设置拖拽样式类名
        ghostClass: "ghostClass", //设置拖拽停靠样式类名
        chosenClass: "chosenClass",
        onEnd: (evt) => { // 监听拖动结束事件
          const currRow = this.categoryList.splice(evt.oldIndex, 1)[0]
          this.categoryList.splice(evt.newIndex, 0, currRow)
          // const newData = []
          this.categoryList.forEach((item, index) => {
            this.newData[index] = {
              id: item.id,
              // sortNum: index + 1
            }
          })

        }
      })
      this.sortable = sortable
    },
    clickMerge () {
      this.$notify({
        message: '可按住开始拖拽',
        type: 'success'
      });
      this.flagMove = true
      this.initSortable(false)
    },
    SaveClickMerge () {
      if (this.newData.length == 0) {
        // this.$message.warning('还没有拖拽任何信息哦！')
        this.$notify({
          message: '还没有拖拽任何信息哦！',
          type: 'warning'
        });
        return
      }else{
        changeCategorySort(this.newData).then(res=>{
          if(res.code=='200'){
            this.getCategoryListRequest();
            this.$notify({
              message: '批量拖拽保存成功',
              type: 'success'
            });
          }else{
            this.$message.error('保存失败！');
          }
          this.flagMove = false;
          this.newData = []
        })
      }
    },
    cancel () {
      this.getCategoryListRequest();
      this.flagMove = false;
      this.sortable.destroy();
      this.newData = [];

    },
    appearenceChange(id){
     let selectNow=this.picSelect.find(item=>item.id==id);
     this.imgLeft= selectNow.imgLeft;
     this.imgRight=selectNow.imgRight;
    },
    updateCategoryImg (list) {
      this.setCategoryDesignForm.categoryMainImg = list[0].response;
    },
    updateLeftCategoryImg (list) {
      this.imgLeft = list[0].response;
    },
    updateRightCategoryImg (list) {
      this.imgRight = list[0].response;
    },
    deleteCategory (row){
        this.$confirm('确认后，此类目的所有信息将被清楚，无法恢复。<div style="font-size:12px;color:#ACACAC">注：若已在外形/模板库中使用此类目，则无法执行删除操作</div> ',
        '确认删除此类目？', {
             dangerouslyUseHTMLString:true,
             confirmButtonText: '确定',
             cancelButtonText: '取消',
             cancelButtonClass:'cancelButtonY',
             confirmButtonClass:'confirmButtonY',
             center: true
        }).then(() => {
        deleteCategoryById({
          id: row.id
        }).then(res => {
          if (res.code == '200') {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getCategoryListRequest();
          }else{
            this.$message.error('删除失败！');
            }
          })
         })
    },
    handleBeforeClose(done){
      this.resetForm('setCategoryDesignForm');
      done();
    },
    handleNewBeforeClose (done) {
      this.resetForm('newDesignCategoryForm');
      done();
    },
    handleEditBeforeClose (done) {
      this.resetForm('editDesignCategoryForm');
      done();
    },
    resetForm(formName) {
      if(formName=='setCategoryDesignForm'){
        this.$refs['setCategoryDesignForm1'].resetFields();
        this.$refs['setCategoryDesignForm2'].resetFields();
        this.$refs[formName].resetFields();
        this.front= {
          designPostion: 0,
          positionColorLimitation: '',
          positionCopywriting: ''
        };
        this.back= {
          designPostion: 1,
          positionColorLimitation: '',
          positionCopywriting: ''
        };
        this.imgLeft = '';
        this.imgRight = '';
      }else{
        this.$refs[formName].resetFields();
        this.quoteCates=[];
      }
    },
    customChange(productType){
      this.newDesignCategoryForm.quoteCateId='';
      this.editDesignCategoryForm.quoteCateId = '';
      if(productType=='0'){//获取全定制对应报价类别
        this.quoteCates=this.allQuoteCates;
      }else if(productType=='1'){//获取半定制对应报价类别
        this.quoteCates=this.semiQuoteCates;
      }else{//报价类别为空
        this.quoteCates=[];
      }
    },
    handleQuoteCateName(quoteCateId,str,productType){//通过id获取报价名称赋值给表单的quoteCateName
       let quoteCate=this.quoteCates.find(obj => obj.id == quoteCateId);
       if(quoteCate){
        let quoteCateName =productType == '0' ?  quoteCate.cateName: quoteCate.name;
        let quoteCateUrl =productType == '0' ?  quoteCate.quoteRoutingName : quoteCate.customRouting;
        this.path=quoteCateName.replace(/\s+/g,"-")+'/';
        this.path = this.path.toLocaleLowerCase();
        if(str=='new'){
          this.newDesignCategoryForm.quoteCateName=quoteCateName;
          this.newDesignCategoryForm.quoteCateUrl= quoteCateUrl;
        }else{
          this.editDesignCategoryForm.quoteCateName = quoteCateName;
          this.editDesignCategoryForm.quoteCateUrl = quoteCateUrl;
        }
       }
    }
  },
  mounted(){
    this.getCategoryListRequest();
    //获取所有报价类别
    this.getAllCustomCategoryRequest();
    this.getSemiCustomCategoryRequest();
  }
};
</script>

<style scoped lang="scss">
.selectWidth{
  width: 100%;
}
.blueText {
  color: #0089F5;
}
.redText {
  color: #FF0000;
}
::v-deep {
  .dialogSpecial {
    .el-form-item {
          margin-bottom: 16px!important;
      }
  }
  .myDialog {
    position: relative;
    overflow-y: visible;
    .borderBottom{
      position: absolute;
      top: 60px;
      left: 20px;
      width: 91.5%;
      height: 1px;
      border-bottom: 1px solid #BBBBBB;
    }
    .el-dialog {
      // max-height: 140vh!important;
      overflow-y: visible;
      .el-form-item {
          margin-bottom: 6px;
      }
      .checkRight {
        margin-bottom: 16px;
        .el-checkbox__inner {
          left:165px;
        }
        .el-checkbox__label{
          margin-left: -16px;
        }
      }
      .checkPicture {
           margin-bottom: 12px;
          .el-select-dropdown {
          width: 23%;
          }

          .optionClass {
            padding: 0 5px;
            margin-bottom: 4px;
            float: left;
            height: 120px;
            width: 50%!important;
            .radioClass{
              padding: 0;
              width: 100%;
              height: 100%;

              .selectText {
                padding: 4px 6px;
                width: 100%;
                height: 20px;
                margin-top: -13px;
                background-color: #F6F6F6;
              }
              .el-radio__input {
                position: absolute;
                top: 5px;
                right: 5px;
              }
              .picWidth {
                width: 94%;
                height: 70%;
                margin: 5px auto;
                border: 1px solid #D8D8D8;
                img {
                  width: 49%;
                  object-fit: contain;
                  &:nth-of-type(1){
                    margin-right: 6px;
                  }
                }
              }
            }
          }
          .el-select-dropdown__item.hover,
          .el-select-dropdown__item:hover{
            background-color: #fff;
              .selectText {
              background-color: #E4F2FF;
            }
          }
          .is-checked {
            .selectText {
              background-color: #E4F2FF!important;
            }
          }
      }
      .el-radio__input.is-checked + .el-radio__label {
         color: #3D3D3D!important;
       }
       .el-select {
        width: 100%;
       }
      .el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
        background-color: #E4F2FF;
      }
       .switchText{
        display: none;
        color: #fff;
        position: absolute;
        margin-top: 1px;
        cursor: pointer;
        user-select: none;
       }
       .picTip{
        position: absolute;
        width: 60px;
        line-height: 20px;
        font-size: 12px;
        color: #A8A8A8;
        top: 30px;
        left: -72px;
       }
       .el-switch.is-checked .el-switch__core {
        background-color: #0089F5;
       }
       .checkedText {
        margin-left: -47px;
       }
       .uncheckedText {
        margin-left: -32px;
       }
       .checkedSwitch{
        display: inline;
       }
       .fileUploadClass {
        .avatar-uploader {
          height: 180px;
        }
       }
      .el-dialog__body {
        padding: 20px 30px 0px;
      }
      .dialog-footer{
        padding-top: 0px;
      }
    }
    .el-textarea__inner {
      resize:none;
      border-radius: 0px;
      border: 1px solid #939090;
    }
    .el-input {
      height: 45px;
      line-height: 45px;
      vertical-align: top;
    }
    .el-input__inner {
      height: 45px!important;
      border-radius: 0px;
      border: 1px solid #939090;
    }
     .hide {
        .el-upload--picture-card {
            display: none !important;
    }

  }
    }
    .el-button {
          height: 45px;
          border-radius: 5px;
          font-size: 16px;
       }
     .primary {
          color: #fff;
          background: #0089F5;
          border: 1px solid #0089F5;
     }
     .el-table--striped .el-table__body tr.el-table__row--striped td {
	      background: #F3F9FF!important;
     }

}
// 拖拽
::v-deep .dragClass {
  background: rgba($color: #e1f1ff, $alpha: 0.5) !important;
}

// 停靠
::v-deep .ghostClass {
  background: rgba($color: #6cacf5, $alpha: 0.5) !important;
}

// 选择
::v-deep .chosenClass:hover > td {
  background: rgba($color: #6cacf5, $alpha: 0.5) !important;
}
.dragIcon{
  display:inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
}
.dragItem{
  width: 50%;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top {
  height: 94px;
  width: 100%;
  padding: 27px 25px 22px;
  ::v-deep {
    .el-input {
      width: 406px;
      border: 1px solid #0089F5;
      border-radius: 5px;
      font-size: 16px;
      .el-input__inner{
      height: 45px;
      border-right: none;
      }
      .el-icon-search {
          font-size: 20px;
      }
      .el-button {
        margin-right: -22px;
        width: 74px;
        border-radius: 5px;
        color: #fff;
        background: #0089F5;
        }
    }
    .el-icon-plus:before ,.el-icon-search:before{
      font-weight: bold;
    }
  }
}
</style>
<style lang="scss">
.cancelButtonY {
   float: right;
   width: 120px;
   margin-right: 60px!important;
  }
.confirmButtonY {
   float: left;
   width: 120px;
   margin-left: 60px!important;
   border-color: #D61B3F!important;
   background-color: #D61B3F!important;
}
</style>
