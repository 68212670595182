import request from '@/utils/request'

//获取列表
export function getTypeCateList(data) {
  return request({
    url: '/quote/websiteTypeCate/list',
    method: 'post',
    data,
  })
}

//获取二级级联
export function getProjectListByProType(data) {
  return request({
    url: '/systemProject/list',
    method: 'post',
    data,
  })
}

//价格绑定历史
export function getWebsitePrice(data) {
  return request({
    url: '/quote/websiteIncreasePrice/getWebsitePrice',
    method: 'post',
    data,
  })
}

//编辑
export function editWebsitePrice(data) {
  return request({
    url: '/quote/websiteIncreasePrice/editWebsitePrice',
    method: 'post',
    data,
  })
}

//查看
export function getWebsitePriceLog(data) {
  return request({
    url: '/quote/websitePriceLog/getWebsitePriceLog',
    method: 'post',
    data,
  })
}

//价格浮动总览
export function getWebsiteProductCatePriceList(data) {
  return request({
    url: '/quote/websiteTypeCate/getWebsiteProductCatePriceList',
    method: 'post',
    data,
  })
}

//平台产品下拉框接口
export function getWebsitePriceByProIdAndProTypeAndCateId(data) {
  return request({
    url: '/quote/websiteIncreasePrice/getWebsitePriceByProIdAndProTypeAndCateId',
    method: 'post',
    data,
  })
}

//添加绑定全定制和半定制

export function getAllQuoteCateAndHalfProductCate(data) {
  return request({
    url: '/quote/cate/getAllQuoteCateAndHalfProductCate',
    method: 'post',
    data,
  })
}

//添加绑定

export function add(data) {
  return request({
    url: '/quote/websiteTypeCate/add',
    method: 'post',
    data,
  })
}