<template>
  <div class="page" ref="page">
    <div class="pageTop">
      <el-input placeholder="搜索元素id或元素名称" v-model="searchInput" clearable @clear=clearSearchInputValue
        style="width: 400px;">
        <el-button slot="append" icon="el-icon-search" @click="btn_search"></el-button>
      </el-input>
      <el-button type="primary" icon="el-icon-plus" @click="btn_add">新增电镀</el-button>

      <template v-if="!isdrag">
        <el-button type="info" @click="btn_openDrag()" class="dragBtn">拖拽排序</el-button>
      </template>
      <template v-else>
        <el-button type="primary" @click="btn_saveDrag()">保存</el-button>
        <el-button type="primary" plain @click="btn_cancel()">取消</el-button>
      </template>
    </div>

    <!--  -->
    <div class="cardBox" id="sortBox">

      <div v-if="Array.isArray(cardList)&& cardList.length==0"  class="noDataText">暂无数据</div>
      <div v-else class="card" v-for="(item, index) in cardList" :key="item.id" ref="cardRef" :id="item.id">

        <div class="top">
          <span>{{ item.platingName }}</span>
          <el-popover popper-class="cardMenu" placement="bottom" trigger="click" :visible-arrow=false>
            <p class="menuBtn btn1" @click="editCard(item)">编辑</p>
            <p class="menuBtn  " :class="{ menuRed :item.state==1}" @click="changeState(item)">{{   item.state == 0 ?'启用':'停用' }}</p>
            <p class="menuBtn  menuRed" @click="delCard(item.id)">删除</p>
            <i slot="reference" class="el-icon-more"></i>
          </el-popover>
        </div>

        <div class="content">

          <div class="content_left">
            <p>适用的类型：{{ item.fList.join(',') }}</p>
            <p>适用的工艺：{{ item.sList.join(',') }}</p>
          </div>
          <div class="content_right">
            <custom-image :src="item.paltingImg" alt="" />
          </div>

          <div class="shade" v-if="item.state == 0">
            <!-- <h2>此样式暂未启用</h2>-->
            <div class="shadeContent"></div>
          </div>
        </div>

        <!--  拖拽遮罩样式 -->
        <div class="drag_shade" v-if="isdrag">
          <b class="icon-a-zhiding1" v-if="index !== 0" @click="cardToTop(index)"></b>
          <i class="el-icon-rank moveDome"></i>
        </div>

      </div>

    </div>


    <!-- 新增/修改弹窗 -->
    <el-dialog class="dialogVisibleClass" :title="`${dialogTitle}电镀色`" :visible.sync="dialogVisible" width="50%"
      :before-close="(done) => {
        done();
      }">
      <!-- 552412 
       -->
      <el-form ref="ref_addEditform" :model="addEditform" label-width="130px" :rules="rules">
        <el-form-item label="电镀名称：" prop="platingName">
          <el-input v-model="addEditform.platingName" placeholder="请填写电镀名称，不能重复"></el-input>
        </el-form-item>
        <el-form-item label="电镀主色(凸起)：" prop="paltingShadeConvex">
          <el-input v-model="addEditform.paltingShadeConvex" placeholder="请填写电镀色号，不能重复"></el-input>
        </el-form-item>
        <el-form-item label="电镀主色(凹下)：" prop="paltingShadeConcave">
          <el-input v-model="addEditform.paltingShadeConcave" placeholder="请填写电镀色号，不能重复"></el-input>
        </el-form-item>
        <el-form-item label="电镀次色(凸起)：">
          <el-input v-model="addEditform.paltingSubShadeConvex" placeholder="请填写电镀色号"></el-input>
        </el-form-item>
        <el-form-item label="电镀次色(凹下)：">
          <el-input v-model="addEditform.paltingSubShadeConcave" placeholder="请填写电镀色号"></el-input>
        </el-form-item>

        <el-form-item label="上传电镀图：" prop="paltingImg">
          <template slot="label">
            <span>上传电镀图：</span>
            <div class="uploadPicLabel">
              <p>注：</p>
              <p> 请上传不带模板或元素的Blank外形图</p>
            </div>
          </template>
          <FileUpload single :file-list="addEditform.paltingImg" @updateList="updateFormDataImg" uploadText="上传图片"
            accept="image/jpeg,image/jpg,image/gif,image/png">
          </FileUpload>
        </el-form-item>

        <el-form-item label="类目状态：">
          <el-switch v-model="addEditform.state" active-color="#0089F5" inactive-color="#6D7074" active-text="启用"
            inactive-text="停用" :active-value="1" :inactive-value="0">
          </el-switch>
        </el-form-item>

        <el-form-item label="适用的类别：" prop="typeList">
          <el-select v-model="addEditform.typeList" multiple placeholder="请选择电镀适用的类别（可多选）" style="width: 100%;" @change="chooseType">
            <el-option v-for="item in options" :key="item.id" :label="item.cateName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="适用的工艺：" prop="arts" required>
          <div class="artsBox artsBox1" v-if="addEditform.typeList.length == 0">
            <p style="color: #BFBFBF;">根据选的适用类别，回填可选的工艺选项，再做选择</p>
          </div>
          <div class="artsBox" v-else v-for="(fItem, fIndex) in addEditform.arts" :key="fIndex">
            <!-- <span class="arts_left">{{ fItem.name }}</span> -->
            <span class="arts_left" v-if="options.find(i => i.id == fItem.quoteId)">{{
              options.find(i => i.id == fItem.quoteId).cateName }}</span>
            <div class="arts_right">
              <div class="box" v-if="addEditform.arts.length > 0">
                <!-- <div class="gary" v-for="(sItem, sIndex) in fItem.list" @click="itemClick(fItem, sItem)"
                  :class="{ chooseColor: fItem.chooseList.indexOf(sItem) != -1 }"><span>{{ sItem.cateName }}</span></div> -->
                <!-- <div class="gary" v-for="(sItem, sIndex) in fItem.list" @click="itemClick(fItem, sItem)"
                  :class="{ chooseColor: fItem.chooseList.indexOf(sItem) != -1 }">{{ sItem.cateName }}</div> -->

                <!-- <div class="gary" v-for="(sItem, sIndex) in fItem.list" @click="itemClick(fItem, sItem)"
                  :class="{ chooseColor: fItem.techList.indexOf(sItem.id) != -1 }">{{ sItem.cateName }}</div> -->
                <!-- <div class="gary" v-for="(sItem, sIndex) in fItem.list" @click="itemClick(fItem, sItem)"
                  :class="{ chooseColor: fItem.techList.indexOf(sItem) != -1 }">{{ sItem.cateName }}</div> -->
                <!-- <div class="gary" v-for="(sItem, sIndex) in fItem.list" @click="itemClick(fItem, sItem)"
                  :class="{ chooseColor: fItem.techList.find(i=>i.id== sItem.id) || fItem.techList.find(i => i.techId == sItem.id) }">{{ sItem.cateName }}    </div> -->
                <div class="gary" v-for="(sItem, sIndex) in fItem.list" @click="itemClick(fItem, fIndex, sItem, sIndex)"
                  :class="{ chooseColor: fItem.techList.find(i=>i.id== sItem.id)}">{{ sItem.cateName }}&nbsp;
                  <span @click.stop="itemChooseBtn(fItem,fIndex, sItem, sIndex)" style="color: #003ef5;">
                    <i class="el-icon-rank"></i> 
                    <!-- {{ sItem.quotePlatingName || $chainable(fItem.techList[sIndex], '.quotePlatingName') }}  -->
                    <!-- {{ sItem.quotePlatingName }}  -->
                    {{ $chainable(fItem.techList.find(i => i.id == sItem.id), '.quotePlatingName')}}
                  </span>  
                </div>
              </div>
            </div>
          </div>
        </el-form-item>

        <el-form-item class="button">
          <el-button type="primary" @click="formSubmit">保存</el-button>
          <el-button @click="formCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog class="dialogVisibleClass" title="请选择" :visible.sync="dialogVisible2" width="50%"
        :before-close="(done) => {
          done();
        }"> 
        <div class="table_box">
          <el-table
            :data="chooseTableData"
            style="width: 100%;margin-bottom: 20px;"
            row-key="id"
            border
            default-expand-all
            :tree-props="{ children: 'childList' }"
            :default-expand-all="true"
            >
            <el-table-column
              prop="paramName"
              label="name"
               >
            </el-table-column>

            <el-table-column label="操作"
              width="300">
              <!--<template slot-scope="scope"  v-if="scope.row.hasOwnProperty('priceInfo') && scope.row.priceInfo!=null && scope.row.childList.length === 0 ">
                <el-button type="primary"
                  @click="choose(scope.row)">choose
                </el-button>
                <!~~ <i class="el-icon-circle-check" v-if="addEditform.arts[fIndex].techList[sIndex]['quotePlatingId']==scope.row.id"></i> ~~>
              </template>-->
              <template  slot-scope="scope"  v-if="scope.row.parentId!== 0">
                <el-button type="primary"
                  @click="choose(scope.row)">choose
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        
    </el-dialog>

    <messageBoxTitle :hidden="this.isShowDeleteMessageBox" title="确认删除此电镀色？" content_text="确认后，此电镀色的所有信息将被清除，无法恢复。"
        @popup_sub="deleteMessageBoxsubmit" @popup_clo="deleteMessageBoxCancel" @clo_box="deleteMessageBoxCancel"
        sendBtnColor="#D61B3F"></messageBoxTitle>

  </div>
</template>
<script>
import { getAllType, addEditColorCard, getAllColorCardList , changeState,delById , colorCardSort , quotePlatingList } from "@/api/drawingDesign/colorChart.js";
import FileUpload from "@/components/FileUpload.vue";
import Sortable from "sortablejs";
import messageBoxTitle from "@/views/commodityManagement/customManagement/message_color.vue";
export default {
  name: 'colorCard',
  components: { FileUpload , messageBoxTitle },
  data () {
    var artsRules = (rule, value, callback) => {
      // console.log(value, "value");

      // value.forEach((it,i)=>{
      //   if(it.techList.length<2){
      //     callback(new Error('至少选择两种'));
      //   }else{
      //     callback();
      //   }
      // })
      var newVal = value.filter(it=>it.list.length>0) //过滤掉本身没有child的
      const allChooseValid = newVal.every(it => it.techList.length >= 1);
      if (allChooseValid) {
        callback();
      } else {
        callback(new Error('每条都至少选择一种'));
      }
    };
    return {
      cardList: [
        // { name: "aaa", id: 1, },
        // { name: "bbb", id: 2, },
      ],
      befoDragData: [],//拖拽前的数据
      searchInput: '',
      isdrag: false,
      dragNeedList: [],
      // 
      dialogTitle: '新增',
      dialogVisible: false,
      rules: {
        platingName: [
          { required: true, message: '请输入电镀名称', trigger: 'blur' },
        ],
        paltingShadeConvex: { required: true, message: '请输入电镀主色', trigger: 'blur' },
        paltingShadeConcave: { required: true, message: '请输入电镀主色', trigger: 'blur' },
        paltingImg: { required: true, message: '请上传电镀图', trigger: 'blur' },
        typeList: { required: true, message: '请至少选择一种适用类别', trigger: 'blur' },
        arts: { validator: artsRules, trigger: 'blur' },

      },
      options: [
        // { value: 1, label: 'aaaaa', arr: [1, 2, 3, 4, 5] },
        // { value: 2, label: 'bbbbb', arr: [11, 22, 33, 44, 55] },
        // { value: 3, label: 'ccccc', arr: [111, 222, 333, 444, 555] },
        // { value: 4, label: 'ddddd', arr: [11111, 22222, 33333, 44444, 55555] },
        // { value: 5, label: 'eeeee', arr: [111111, 222222, 333333, 444444, 555555] }
      ],
      addEditform: {
        id: null,
        platingName: "",
        // paltingShade: "",
        // paltingSubShade: "",
        paltingShadeConvex: "",//主色(凸起)
        paltingShadeConcave: "",//主色(凹下)
        paltingSubShadeConvex: "",//次色(凸起)
        paltingSubShadeConcave: "",
        paltingImg: "",
        typeList: [],
        arts: [],
        // arts: [{ list: [] }],
        state: 1,
      },
      outTypeList:[],
      isShowDeleteMessageBox: false,
      deleteId: null,
// -----------------新需求
      dialogVisible2 :false,
      chooseTableData:[],
      fIndex:null,
      sIndex:null,
      fItem:[],
      sItem:[],
    };
  },
  computed: {

  },
  watch: {
    isShowDeleteMessageBox (newVal) {
      if (!newVal) {
        this.deleteId = null
      }
    },
    dialogVisible (newVal) {
      if (!newVal) {
        this.$refs["ref_addEditform"].resetFields();
        this.addEditform = this.$options.data().addEditform
        // this.getAllColorCardList()
      }
    },
    'addEditform.typeList': {
      deep: true,
      handler (val) {
        // 使用 map 函数重建 arts 数组
        this.addEditform.arts = val.map((item, index) => {
          let findObj = this.options.find(it => it.id == item);
          let findTechListIndex= this.addEditform.arts.findIndex(i=>i.quoteId== item)
          // 初始化一个对象，确保它包含 name 属性
          // let newArt = {
          //   name: findObj.label,
          //   list: findObj.arr,
          //   chooseList: []
          // };
          let newArt = {
            quoteName: findObj.cateName,
            quoteId: findObj.id,
            list: findObj.childList,
            techList: this.addEditform.arts[findTechListIndex]?.techList || []
          };

          return newArt;
          // this.addEditform.arts[index]= newArt
        });
      }
    },
  },
  methods: {
    choose(row){
      // this.addEditform.arts[this.fIndex].techList[this.sIndex]["quotePlatingId"] = row.priceInfo?.id //暂时都改成第二层id 不用pirceInfoId
      // this.fItem.list[this.sIndex].quotePlatingId = row.id
      // this.fItem.list[this.sIndex].quotePlatingName = row.paramName
      this.sItem.quotePlatingId = row.id
      this.sItem.quotePlatingName = row.paramName
      let findIndex= this.fItem.techList.findIndex(i=>i.id== this.sItem.id)
      console.log('findIndex', findIndex);
      if(findIndex!=-1){
        console.log('this.fItem', this.fItem, this.sIndex);
        this.fItem.techList[findIndex] = this.sItem
      }else{
        this.fItem.techList.push(this.sItem);
      }
      this.dialogVisible2 = false
    },
    itemChooseBtn(fItem, fIndex, sItem, sIndex ){
      // if (!fItem.list[sIndex]) return this.$message('请先选择')
      this.fIndex = fIndex
      this.sIndex = sIndex
      this.fItem = fItem
      this.sItem = sItem
      this.dialogVisible2 = true
      this.getChooseTableData(fItem.quoteId, sItem.id)
    },
    async getChooseTableData (quoteId, techId) {
      let res = await quotePlatingList({
        quoteId,
        techId
      })
      this.chooseTableData = res.data
    },
    async deleteMessageBoxsubmit () {
      await delById({ id: this.deleteId })
      this.$message.success("删除成功")
      this.getAllColorCardList()
    },
    deleteMessageBoxCancel () {
      this.isShowDeleteMessageBox = false
    },
    delCard (id) {
      this.isShowDeleteMessageBox = true
      this.deleteId = id
    },
    chooseType(){
      this.$forceUpdate() //解决编辑时适用类别选中dom不更新的问题
    },
    editCard(item){
      console.log(item);
      this.dialogTitle = '编辑'
      this.dialogVisible = true

      let need = JSON.parse(JSON.stringify(item))
      // this.addEditform = JSON.parse(JSON.stringify(item))
      this.addEditform.id = need.id
      this.addEditform.platingName = need.platingName
      // this.addEditform.paltingShade = need.paltingShade
      // this.addEditform.paltingSubShade = need.paltingSubShade
      this.addEditform.paltingShadeConvex = need.paltingShadeConvex
      this.addEditform.paltingShadeConcave = need.paltingShadeConcave
      this.addEditform.paltingSubShadeConvex = need.paltingSubShadeConvex
      this.addEditform.paltingSubShadeConcave = need.paltingSubShadeConcave
      this.addEditform.paltingImg = need.paltingImg
      this.addEditform.state = need.state
      this.addEditform.typeList= item.quoteTechesList.map(fList => (fList.quoteId))
      this.addEditform.arts = JSON.parse(JSON.stringify(item.quoteTechesList))
    },
    async changeState(item){
      await changeState(
        {
          id: item.id,
          state:item.state?0:1
        }
      )
      this.$message.success("更新状态成功！")
      this.$refs['page'].click()  //关闭Popover
      this.getAllColorCardList()
    },
    async getAllColorCardList () {
      let res = await getAllColorCardList(
        {
          language: "en-us",
          platingName: this.searchInput
        }
      )

      // this.cardList = res.data

      this.cardList = res.data.map((fIt, fIndex) => ({
        id: fIt.id,
        platingName: fIt.platingName,
        paltingShadeConvex: fIt.paltingShadeConvex, //主色
        paltingShadeConcave: fIt.paltingShadeConcave,  
        paltingSubShadeConvex: fIt.paltingSubShadeConvex,  
        paltingSubShadeConcave: fIt.paltingSubShadeConcave,  
        paltingImg: fIt.paltingImg,
        sort: fIt.sort,
        state: fIt.state,
        fList: fIt.quoteTechesList.map(fList=>(fList.quoteName)),
        // sList: fIt.quoteTechesList.map(sList =>sList.techList.map()),
        sList: fIt.quoteTechesList.flatMap((child) => child.techList.map((son) => son.techName)),
        quoteTechesList:fIt.quoteTechesList,
      }))
    },
    async getAllType () {
      let res = await getAllType()
      this.options = res.data
    },
    itemClick (fItem, fIndex, sItem, sIndex) {
      this.fIndex = fIndex
      this.sIndex = sIndex

      this.fItem = fItem
      this.sItem = sItem

      const chooseList = fItem.techList;
      if (chooseList.find(i=>i.id == sItem.id)) {
        chooseList.splice(chooseList.findIndex(i=>i.id == sItem.id), 1);
        delete sItem.quotePlatingId
        delete sItem.quotePlatingName
        this.$forceUpdate();
      } else {
        this.dialogVisible2 = true
        // console.log("chooseList", chooseList);
        // chooseList.push(sItem);
        // this.$forceUpdate();
        this.getChooseTableData(fItem.quoteId, sItem.id)
      }

    },
    updateFormDataImg (list) {
      this.addEditform.paltingImg = list[0].response;
    },
    formCancel () { this.dialogVisible = false },
    formSubmit () {
      // 552412  techList
      this.$refs["ref_addEditform"].validate(async (valid) => {
        if (valid) {
          let need = JSON.parse(JSON.stringify(this.addEditform))
          delete need.arts;
          delete need.typeList;

          let arts = JSON.parse(JSON.stringify(this.addEditform.arts))
          arts.forEach(item => {
            delete item.list
            item.techList = item.techList.map((mapItem, mapIndex) => ({
              techId: mapItem.id,
              techName: mapItem.cateName || mapItem.techName,
              quotePlatingId: mapItem.quotePlatingId,
              quotePlatingName: mapItem.quotePlatingName
            }))
          })
          let need2 = { quoteTechesList: arts }
          let res = await addEditColorCard({ ...need, ...need2 })
          this.$message.success(res.message)
          this.dialogVisible = false

          this.getAllColorCardList()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // -------------------------------------------
    createSrcRectMap (domList) {
      return domList.reduce((prev, card) => {
        const rect = card.getBoundingClientRect()
        const { left, top } = rect
        prev[card.id] = { left, top, card }
        return prev
      }, {})
    },
    scheduleAnimation (update) {
      // 获取旧位置
      const prevCard = this.$refs.cardRef.slice()
      const prevCardRectMap = this.createSrcRectMap(prevCard)
      // 更新数据
      update()
      // DOM更新后
      this.$nextTick(() => {
        const currentSrcRectMap = this.createSrcRectMap(prevCard)
        Object.keys(prevCardRectMap).forEach((item) => {
          const currentRect = currentSrcRectMap[item]
          const prevRect = prevCardRectMap[item]
          const invert = {
            left: prevRect.left - currentRect.left,
            top: prevRect.top - currentRect.top,
          }
          const keyframes = [
            {
              transform: `translate(${invert.left}px, ${invert.top}px)`,
            },
            { transform: "translate(0, 0)" },
          ]
          const options = {
            duration: 300,
            easing: "cubic-bezier(0,0,0.32,1)",
          }
          const animation = currentRect.card.animate(keyframes, options)
        })
      })
    },
    // -------------------------------------------
    cardToTop (index) {

      // this.cardList.splice(index, 1)
      // this.cardList.unshift(row)

      // const cardElement = document.querySelector('.card');
      // const cardElement = this.$refs[`cardRef${index}`]
      // console.log('cardElement', cardElement);
      // cardElement.classList.add('cardAnimation');

      this.scheduleAnimation(() => {
        this.cardList.splice(0, 0, this.cardList.splice(index, 1)[0])
      })
      this.dragNeedList = [].concat(this.cardList.map((item, index) => ({
        id: item.id,
        sort: index + 1,
      })));

    },
    btn_search () {
      this.getAllColorCardList()
    },
    btn_add () {
      this.dialogTitle = '新增'
      this.dialogVisible = true
    },
    clearSearchInputValue () {
     this.getAllColorCardList()
    },
    btn_openDrag () {
      this.isdrag = true
      this.befoDragData = JSON.parse(JSON.stringify(this.cardList))
      this.drag()
    },
    btn_cancel () {
      this.isdrag = false
      this.scheduleAnimation(() => {
        this.cardList = this.befoDragData
      })
    },
    async btn_saveDrag () { 
    if (JSON.stringify(this.befoDragData) == JSON.stringify(this.cardList)) {
        // this.isdrag = false
        return this.$message("未拖拽")
      }
      console.log('dragNeedList', this.dragNeedList);
      let res = await colorCardSort(this.dragNeedList)
      this.$message.success(res.message)
      this.isdrag = false
      this.getAllColorCardList()//refresh
    },
    drag () {
      const _this = this;
      const el = document.querySelector('#sortBox')
      Sortable.create(el, {
        disabled: false, // boolean 定义是否此sortable对象是否可用，为true时sortable对象不能拖放排序等功能，为false时为可以进行排序，相当于一个开关；
        handle: ".moveDome", // 格式为简单css选择器的字符串，使列表单元中符合选择器的元素成为拖动的手柄，只有按住拖动手柄才能使列表单元进行拖动
        animation: 150, // 拖拽延时
        onEnd ({ newIndex, oldIndex } = {}) {
          if (newIndex == oldIndex) return;
          _this.cardList.splice(
            newIndex,
            0,
            _this.cardList.splice(oldIndex, 1)[0]
          );
          var newArray = JSON.parse(JSON.stringify(_this.cardList));
          _this.$nextTick(function () {
            let needList = newArray.map((item, index) => ({
              id: item.id,
              sort: index + 1,
            }))
            console.log(needList);
            _this.dragNeedList = [].concat(needList);
          });
        },
      });
    },
  },
  mounted () {
    this.getAllColorCardList()
    this.getAllType()
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-switch__label * {
  line-height: 1;
  font-size: 10px;
  display: inline-block;
}

::v-deep .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
  font-size: 10px !important;
}

::v-deep .el-switch__label--right {
  z-index: 1;
  right: 20px !important;
  margin-left: 0px;
}

::v-deep .el-switch__label--left {
  z-index: 1;
  left: 20px !important;
  margin-right: 0px;
}

::v-deep .el-switch__label.is-active {
  display: block;
}

::v-deep .el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 50px !important;
}

::v-deep {
  .el-tag.el-tag--info{
    background-color: #C8DAFF;
    color: #0089F5;
    border-color: #C8DAFF;
  }
  .el-select .el-tag__close.el-icon-close{
    color: #0089F5;
    background-color: #C8DAFF;
    font-size: 14px;
  }
  .pageTop {
    .el-input-group {
      margin-right: 18px;
    }

    .el-input-group__append {
      background: #0089F5;
      border: 1px solid transparent;
      color: #FFF;
      width: 74px;
      text-align: center;
      font-size: 20px;
    }

    .el-input-group--append .el-input__inner {
      height: 40px;
    }

    .el-button--primary {
      display: flex;
      align-items: center;

      i {
        font-size: 16px;
      }
    }
  }

  .el-button--primary {
    background: #0089F5;
    border-color: #0089F5;
  }

  .el-button--info {
    background: #7F9DB5;
    border-color: #7F9DB5;
  }

  .el-dialog__header {
    border-bottom: 1px solid #D8D8D8;
  }

  .el-checkbox__input {
    // display: flex;
    // align-items: center;
    position: relative;

    &::after {
      position: absolute;
      content: '。' !important;
      top: 0;
    }

    .el-checkbox__inner {
      display: none;
    }
  }

  .el-checkbox__input.is-checked {
    position: relative;

    &::after {
      position: absolute;
      content: '>' !important;
      top: 0;
    }
  }
}

.page {
  height: 100%;
  padding: 20px 30px;
}

.pageTop {
  width: 50%;
  display: flex;
}

.cardBox {
  // overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  height: calc(100% - 40px);
}
.noDataText{
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ACACAC;
  font-size: 24px;
}

.card {
  flex: 0 0 calc(25% - 10px); //一行显示四个 减去间距
  margin-right: 13px;
  /* margin-bottom: 10px; */
  margin-top: 12px;
  height: 189px;
  box-shadow: 0px 2px 8px 0px rgba(196, 196, 196, 0.3);
  position: relative;

  .top {
    height: 30px;
    line-height: 30px;
    background-color: #E4F2FF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    position: relative;

    &::before {
      // font-family: element-icons;
      // content: "\e6d8";
      // transform: rotate(90deg);
      content: "";
      position: absolute;
      height: 12px;
      width: 2px;
      background: #0089F5;
      ;
      left: 12px;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 30px);
    display: flex;
    justify-content: space-between;
    padding: 15px 13.5px 13px 20px;
    position: relative;

    .content_left {
      width: 46%;

      p {
        font-size: 12px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
    }

    .content_right {
      width: 40.5%;
      height: 132px;
      border: 1px solid #D8D8D8;
      img{
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .shade {
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(121, 121, 121, 0.5);

      .shadeContent {
        position: absolute;
        width: 135px;
        height: 35px;
        border-radius: 4px;
        z-index: 2;
        background: rgba(0, 0, 0, 0.5);
        // opacity: 0.5;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
          content: '此电镀已停用';
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }

  .drag_shade {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: #e4f2ff91;
    box-shadow: 2px 4px 5px 2px #a6d0f1, -2px 0 6px #a6d0f1;
    // opacity: 0.7;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;

    b,
    i {
      cursor: pointer;
      color: #000;
      font-size: 28px;
    }
  }
}

/* 使用选择器重置最后一列格子右边距为0 */
.card:nth-child(4n) {
  margin-right: 0;
}

/* 重置第一行格子的上边距为0 */
// .card:nth-child(-n+4) {
//   margin-top: 0; 
// }


.menuBtn {
  height: 27px;
  line-height: 27px;
  margin: 0;
}

  .menuRed{
    color: #FF0000;
  }

.menuBtn:hover {
  cursor: pointer;
  background-color: #E1F1FF;
}


.el-icon-more {
  cursor: pointer;
  transform: rotate(90deg);
  color: #0089F5;
  font-size: 13px;
}


.uploadPicLabel {
  font-size: 12px;
  color: #A8A8A8;
  text-align: left;
  line-height: 1.5;
  padding-left: 24px;
  margin-top: 18px;

  >p {
    margin: 0 !important;
    /* word-wrap: normal; */
    /* word-wrap: break-word */
    /* white-space: pre; */
    word-break: normal;
  }
}

.artsBox {
  min-height: 50px;
  border: 1px dotted #B9B7B7;
  display: flex;
  box-sizing: border-box;
  padding: 8px;
  padding-bottom: 3px;
  margin-bottom: 5px;

  .arts_left {
    // min-width: 60px;
    white-space: nowrap;
    margin-right: 10px;
  }
}

.artsBox1 {
  min-height: 40px !important;
  line-height: 40px;
  padding: 0 20px;

  p {
    margin: 0;
  }
}

// =============================
.box {
  display: flex;
  flex-wrap: wrap;
}

.gary {
  // width: 100px;
  min-width: 50px;
  height: 30px;
  // line-height: 50px;
  background-color: #EAEAEA;

  margin-right: 7px;
  margin-bottom: 7px;
  text-align: center;
  user-select: none;
  // 
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
  cursor: pointer;

  &::before {
    display: inline-block;
    // width: 40%;
    width: 15px;
    content: '•';
  }
}

.chooseColor {
  box-sizing: border-box;
  color: #0089F5;
  background: #E4F2FF;
  display: flex;
  align-items: center;
  padding: 10px;

  &::before {
    display: inline-block;
    // width: 40%;
    width: 15px;
    content: '✔';
  }
}

.table_box{
  height: 500px;
  overflow-y: auto;
}
</style>
<style >
.el-popover.cardMenu {
  min-width: 64px;
  padding: 5px 0;
  text-align: center;
  z-index: 20 !important;
}

.el-popper.cardMenu[x-placement^=bottom] {
  margin-top: 5px;
}

</style>