import store from "@/store";
import axios from "axios";
import resetMessage from "./resetMessage";
import {getToken} from "@/utils/auth";



let count = 0;
function dataType(data) {
    return Object.prototype.toString
        .call(data)
        .slice(8, -1)
        .toLowerCase();
}

// 创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 1000 * 100,
    transformRequest: [
        data => {
            if (dataType(data) === "formdata") {
                return data;
            }
            data = JSON.stringify(data);
            return data;
        }
    ],
    headers: {
        get: {
            "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
        },
        post: {
            "Content-Type": "application/json;charset=utf-8"
        }
    }
});
// 调用隐藏全局loading方法 ---接口方法里多传递一个参数 hideLoading: true 跟url同级
// 接口参数不去空方法 --- notRemoveEmptyParams: true 用法同上
// 接口不校验code码方法（一般用于下载）--- 不用管了(拦截器已处理)
service.interceptors.request.use(
    config => {
        const token = getToken();
        if (token) {
            if (!config?.hideLoading) {
                count++;
                store.commit("setGlobalLoading", true);
            }
            config.headers["token"] = token;
        }
        if (config.baseUrl) {
            config.url = config.baseUrl + config.url;
        }
        if (config?.notRemoveEmptyParams) {
            for (let key in config.data) {
                if (!config.data[key] && config.data[key] !== 0 && config.data[key] !== false) config.data[key] = "";
                // 连续空格串处理
                if (typeof config.data[key] === "string" && config.data[key].trim() === "") config.data[key] = "";
            }
        } else {
            // 参数去空
            for (let key in config.data) {
                if (!config.data[key] && config.data[key] !== 0 && config.data[key] !== false) delete config.data[key];
            }
        }
        return config;
    },
    error => {
        count = 0;
        store.commit("setGlobalLoading", false);
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    res => {
        // count减到0 接口全部请求完成，清除loading
        count--;
        if (count <= 0) {
            count = 0;
            store.commit("setGlobalLoading", false);
        }
        //whiteArrUrl里面 接口返回完整数据
        let whiteArrUrl = [
            "/excel/purchase/exportPurchaseOrderPriceConfirm",
            "/coupon/getCouponByCode",
            "/order/manage/uploadTheDrawings",
            "/order/manage/addOrder",
            "/customLinks/edit",
            "/products/getProductByCateIdAndSku",
            "/newTask/designerGroup/addBacker"
        ];
        if (whiteArrUrl.includes(res.config.url)) {
            return res?.data || res;
        } else {
            let code = res.data.code;
            if (code) {
                if (code === 401) {
                    resetMessage({
                        message: "登录过期,请重新登录",
                        type: "error",
                        duration: 1000,
						onClose: () => {
							store.dispatch("auth/logout").then(() => {
                                const loginSource = store.state.auth.loginSource || 'ly'
								location.replace(`/${loginSource}/login`); // 为了重新实例化vue-router对象 避免bug
							});
						}
                    });
                } else if (code !== 200) {
                    resetMessage.error({
                        message: res.data.message
                    });
                    return Promise.reject(res.data.message);
                } else {
                    return res.data;
                }
            } else {
                //文件流兼容
                if (res.config.responseType === "blob" || res.config.responseType === 'arraybuffer') {
                    return res.data;
                } else {
                    resetMessage.error({
                        message: "没有code码"
                    });
                    return Promise.reject(res.data.message);
                }
            }
        }
    },
    error => {
        count = 0;
        store.commit("setGlobalLoading", false);
        if (axios.isCancel(error)) {
            console.log("repeated request");
        } else {
            error.data = {};
            error.data.message = "请求超时或服务器异常，请检查网络或联系管理员！";
            resetMessage.error({
                message: error.data.message
            });
        }
        return Promise.reject(error);
    }
);

export default service;
