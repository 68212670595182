<template>
	<img
		v-show="customSrc"
		class="customImage-inner"
		:loading="lazyText"
		v-bind="$attrs"
		v-on="$listeners"
		@click="clickHandler"
		:src="customSrc"
		:style="imageStyle"
		:class="{
			'customImage-preview': preview,
		}"
	/>
</template>

<script>
/*
	demo:
	import CustomImage from "@/components/CustomImage.vue";
	<CustomImage
		:src="https ://static-oss.gs-souvenir.com/web/public/picStore/20230826/AI_20230826BwmhiM.png"
		:isPreview="isPreview" 						//解释
		:previewSrcList="previewSrcList" 	//解释
		:previewOptions="previewOptions" 	//解释
		:title="isPreview ? 'View' : ''" 	//解释
		v-on="$listeners" 								//解释
		v-bind="$attrs" 									//解释
	/>
*/
import { replacePicPrefix } from "@/utils/utils";
export default {
	name: "customImage",
	inheritAttrs: false,
	props: {
		replaceUrlPrefix: {
			type: Boolean,
			default: true,
		},
		src: String,
		fit: {
			type: String,
			default: "contain",
		},
		isPreview: Boolean,
		lazy: Boolean,
		scrollContainer: {},
		previewSrcList: {
			type: Array,
			default: () => [],
		},
		previewOptions: {
			type: Object,
			default: () => {
				return {
					initialViewIndex: 0,
				};
			},
		},
	},
	computed: {
		lazyText() {
			return this.lazy ? "lazy" : "eager";
		},
		imageStyle() {
			const { fit } = this;
			return { "object-fit": fit };
		},
		preview() {
			const { customPreviewSrcList, isPreview } = this;
			return (Array.isArray(customPreviewSrcList) && customPreviewSrcList.length > 0) || isPreview;
		},
		customSrc() {
			return this.replaceUrlPrefix ? replacePicPrefix(this.src) : this.src;
		},
		customPreviewSrcList() {
			let { previewSrcList, previewOptions } = this;
			if (previewSrcList.length === 0) {
				return [];
			}
			return previewSrcList.map((item) => {
				if (previewOptions.url) {
					return replacePicPrefix(item[previewOptions.url]);
				} else {
					return replacePicPrefix(item);
				}
			});
		},
	},
	methods: {
		clickHandler() {
			if (!this.preview) {
				return false;
			}
			if (this.customPreviewSrcList && this.customPreviewSrcList.length > 0) {
				this.$viewerApi({
					images: this.customPreviewSrcList,
					options: this.previewOptions,
				});
				return false;
			}
			if (this.isPreview) {
				this.$viewerApi({
					images: [this.customSrc],
				});
			}
		},
	},
};
</script>
<style scoped lang="scss">
.customImage {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 100%;
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
	}
}

.customImage-preview {
	cursor: pointer;
}

.customImage-error {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	vertical-align: middle;
	width: 100%;
	height: 100%;
	background-color: #eeeeee;
}

.customImage-placeholder {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	vertical-align: middle;
	width: 100%;
	height: 100%;
	background-color: #eeeeee;
}
</style>
