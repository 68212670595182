import layout from "@/views/layout/index.vue";
const emailCenter = () => import('@/views/emailManage/emailCenter.vue')
const emailGroup = () => import("@/views/emailManage/emailGroup.vue");
const emailList = () => import("@/views/emailManage/emailList.vue");
const emailSign = () => import("@/components/emailManage/emailSign");
const emailDetail = () => import("@/components/emailManage/emailDetail");
const mailTemplate = () => import("@/views/emailManage/mailTemplate.vue");
// const emailSetting=()=>import("@/components/emailManage/emailSetting")
export default {
	path: "/emailManage",
	name: "emailManage",
	component: layout,
	meta: {
		title: "邮件管理",
		icon: "el-icon-message"
	},
	children: [
		{
			path: "emailCenter",
			name: "emailCenter",
			component: emailCenter,
			meta: {
				title: "邮件中心"
			}
		},
		{
			path: "emailGroup",
			name: "emailGroup",
			component: emailGroup,
			meta: {
				title: "邮件分组"
			}
		},
		{
			path: "emailSign",
			name: "emailSign",
			component: emailSign,
			meta: {
				title: "邮件签名"
			}
		},
		// {
		// 	path: "emailSetting",
		// 	name: "emailSetting",
		// 	component: emailSetting,
		// 	meta: {
		// 		title: "邮件设置"
		// 	}
		// },
		{
			path: "emailDetail",
			name: "emailDetail",
			component: emailDetail,
			meta: {
				title: "邮件详情"
			}
		},
		{
			path: "emailList",
			name: "emailList",
			component: emailList,
			meta: {
				title: "邮件重发列表"
			}
		},
		{
			path: "mailTemplate",
			name: "mailTemplate",
			component: mailTemplate,
			meta: {
				title: "邮件模版"
			}
		}
	]
};
