import request from '@/utils/request'

//获取所有询盘
export function getAllInquiryList(data) {
	return request({
		url: '/inquiry/getAllInquiryList',
		method: 'post',
		data,
	})
}

//删除询盘
export function delById(data) {
	return request({
		url: '/inquiry/delById',
		method: 'post',
		data,
	})
}

export function getInquiryRead(data) {
	return request({
		url: '/inquiry/getInquiryRead',
		method: 'post',
		data:data,
	})
}

//获取询盘详情
export function getById(data) {
	return request({
		url: '/inquiry/getById',
		method: 'post',
		data,
	})
}

export function getPrintInfoById(data){
	return request({
		url: '/inquiry/getPrintInfoById',
		method: 'post',
		data,
	})
}

export function changeInquiryStatus(data) {
	return request({
		url: '/inquiry/changeInquiryStatus',
		method: 'post',
		data,
	})
}

//修改询盘
export function editInquiry(data) {
	return request({
		url: '/inquiry/editInquiry',
		method: 'post',
		data,
		hideLoading: true,
		notRemoveEmptyParams: true
	})
}


//编辑用户信息
export function editUser(data) {
	return request({
		url: '/member/editUser',
		method: 'post',
		data,
		hideLoading: true,
		notRemoveEmptyParams: true
	})
}

//获取信息列表
export function getMessageListByInquiryId(data) {
	return request({
		url: '/inquiry/message/getMessageListByInquiryId',
		method: 'post',
		data,
	})
}

//获取业务员列表
export function getAllSalesMan() {
	return request({
		url: '/allot/allotConf/getMemberListByLoginUser',
		method: 'post',
	})
}

//获取链接信息
export function getByUrl(data) {
	return request({
		url: '/inquiry/getByUrl',
		method: 'get',
		params:data,
	})
}

export function getLinkProductByInquiryId(data) {
	return request({
		url: '/customLinks/getLinkProductByInquiryId',
		method: 'post',
		data:data,
	})
}

export function getUrlById(data) {
	return request({
		url: '/inquiry/getUrlById',
		method: 'post',
		data,
	})
}



//sendSms
export function sendSms(data) {
	return request({
		url: '/inquiry/sendSms',
		method: 'get',
		params:data,
	})
}

//editEmail
export function editEmail(data) {
	return request({
		url: '/order/manage/changeUserEmail',
		method: 'post',
		data,
	})
}

//editEmail
export function getOrderReason(data) {
	return request({
		url: '/inquiry/getNoPlaceOrderReason',
		method: 'get',
		data,
	})
}

//editEmail
export function getFdOrderReason(data) {
	return request({
		url: '/order/noPlaceReason/getListByTypeAndProId',
		method: 'post',
		data,
	})
}

//getAllCurrency
export function getAllCurrency(data) {
	return request({
		url: '/quote/currency/getAllCurrency',
		method: 'post',
		data,
	})
}
//
export function updatePrintInfo (data) {
	return request({
		url: '/inquiry/inquiryPrintInfo/update',
		method: 'post',
		data,
		notRemoveEmptyParams: true
	})
}

//
export function getPrintByInquiryId (data) {
	return request({
		url: '/inquiry/inquiryPrintInfo/getByInquiryId',
		method: 'post',
		data,
	})
}

//
export function setTopping (data) {
	return request({
		url: '/inquiry/setTopping',
		method: 'post',
		data,
	})
}
//
export function getCityZipCode (data) {
	return request({
		url: '/address/countryStates/getCityZipCode',
		method: 'get',
		params:data,
		hideLoading: true
	})
}
//
export function getCountryRate (data) {
	return request({
		url: '/address/countryStates/getCountryRate',
		method: 'get',
		params:data,
		hideLoading: true,
		notRemoveEmptyParams: true
	})
}

//获取订单列表
export function editInquiryMark(data) {
	return request({
		url: '/inquiry/editMark',
		method: 'post',
		data,
		hideLoading:true
	})
}

//列表
export function getNoPlaceOrderReason(params) {
	return request({
		url: '/order/manage/getNoPlaceOrderReason',
		params,
	})
}
//编辑
export function editOrderNoPlaceReason(data) {
	return request({
		url: '/order/manage/editOrderNoPlaceReason',
		method: 'post',
		data,
	})
}
//编辑
export function changeInquiryIsCustomer(data) {
	return request({
		url: '/inquiry/changeInquiryIsCustomer',
		method: 'post',
		data,
		hideLoading:true
	})
}
