<template>
    <div class="list-item" :title="item.templateName">
        <img :src="templateImg" loading="lazy" :alt="item.templateName" :title="item.templateName" @click="selectTemplate(item)"/>
        <div class="list-item-btn">
            <div class="control">
                <div title="edit" @click="selectTemplate(item)" v-throttle>
                    <b class="icon-a-T-editzhuanhuan"></b>
                </div>
            </div>
            <div class="control">
                <div title="download" @click.stop="downloadTemplate(templateImg, templateId)" v-throttle>
                    <i class="el-icon-download"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {newDownFile ,replacePicPrefix} from "@/utils/utils";
export default {
    props: ['item','templateImg', 'isFavourite','templateId'],
    data() {
        return {
        }
    },
    computed: {
        lang() {
            return this.$store.getters.lang?.design;
        },
		isLogin() {
			return this.$store.getters.isLogin;
		}
    },
    methods: {
        selectTemplate(item) {
            this.$emit("selectTemplate", item)
        },
        toggleLoveTemplate(item) {
			if (!this.isLogin) {
				this.$store.commit('setMask', 'login')
				return false;
			}
            this.$emit("toggleLoveTemplate", item)
        },
        returnName (item) {
         let name = item.substring(item.lastIndexOf("/") + 1)
         return name
        },
        downloadTemplate(src,property){
            newDownFile(replacePicPrefix(src), this.returnName(src))
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/dzxt_theme";

.list-item {
    position: relative;
    padding: 10px;
    // background: #ffffff;
    border: 1px solid #e0e0e0;
    cursor: pointer;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .list-item-btn {
        display: block;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.25);
        transition: all 0.3s;
        opacity: 0;

        .control {
            display: flex;
            justify-content: center;
            align-items: center;

            &>div {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 4px;
                width: 48px;
                height: 48px;
                background: $dz-primary;
                border-radius: 50%;
                border: none;

                b ,i{
                    color: #ffffff;
                    font-size: 24px;
                }
            }

            &>div:last-child {
                background-color: #ce3d3a;
            }
        }

        .name {
            margin-top: 20px;
            padding: 5px 10px;
            background: #FFFFFF;
            border-radius: 4px;
            font-size: 14px;
        }
    }

    .love-btn {
        display: none;
    }

    &:hover {
        .list-item-btn {
            opacity: 1;
        }
    }
}

.list-item {
    aspect-ratio: 382/215;
}
</style>
