import layout from "@/views/layout/index.vue";
const couponSpecifications = () =>
    import("@/views/promotion/couponSpecifications.vue");
const coupon = () => import("@/views/promotion/coupon.vue");
export default {
    path: "/promotion",
    name: "promotion",
    component: layout,
    meta: {
        title: "优惠管理",
        icon: "el-icon-s-ticket"
    },
    children: [{
        path: "couponSpecifications",
        component: couponSpecifications,
        name: "couponSpecifications",
        meta: {
            title: "优惠券规格"
        }
    },
        {
            path: "coupon",
            component: coupon,
            name: "coupon",
            meta: {
                title: "用户优惠券列表"
            }
        }
    ]
}