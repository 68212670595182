import layout from "@/views/layout/index.vue";
const applicationForOrderSubmission = () =>
    import("@/views/affiliate/applicationForOrderSubmission.vue");
const basicInformationEntry = () =>
    import("@/views/affiliate/basicInformationEntry.vue");
const classicCaseApplication = () =>
    import("@/views/affiliate/classicCaseApplication.vue");
const statisticalQuery = () => import("@/views/affiliate/statisticalQuery.vue");
const sponsoredLinks = () => import("@/views/affiliate/sponsoredLinks.vue");
const bloggerManage = () => import("@/views/affiliate/bloggerManage.vue");
const resourceManagement = () => import("@/views/affiliate/resourceManagement.vue");
export default 	{
    path: "/affiliate",
    name: "affiliate",
    component: layout,
    meta: {
        title: "联盟营销外链管理",
        icon: "el-icon-connection"
    },
    children: [{
        path: "basicInformationEntry",
        name: "basicInformationEntry",
        component: basicInformationEntry,
        meta: {
            title: "基础信息录入"
        }
    },
        {
            path: "bloggerManage",
            name: "bloggerManage",
            component: bloggerManage,
            meta: {
                title: "博主管理"
            }
        },
        {
            path: "classicCaseApplication",
            name: "classicCaseApplication",
            component: classicCaseApplication,
            meta: {
                title: "经典案例申请"
            }
        },
        {
            path: "applicationForOrderSubmission",
            name: "applicationForOrderSubmission",
            component: applicationForOrderSubmission,
            meta: {
                title: "订单提交申请"
            }
        },
        {
            path: "sponsoredLinks",
            name: "sponsoredLinks",
            component: sponsoredLinks,
            meta: {
                title: "赞助链接管理"
            }
        },
        {
            path: "statisticalQuery",
            name: "statisticalQuery",
            component: statisticalQuery,
            meta: {
                title: "统计查询"
            }
        },

        {
            path: "resourceManagement",
            name: "resourceManagement",
            component: resourceManagement,
            meta: {
                title: "资源管理"
            }
        }
    ]
}
