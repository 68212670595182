import request from '@/utils/request'

//获取订单列表
export function getOrderList(data) {
	return request({
		url: '/order/manage/getOrderList',
		method: 'post',
		data,
	})
}

//获取订单列表
export function getMarks(data) {
	return request({
		url: '/order/manage/getMarks',
		method: 'get',
		params:data,
	})
}

//获取订单列表
export function editMark(data) {
	return request({
		url: '/order/manage/editMark',
		method: 'post',
		data,
		hideLoading:true
	})
}


//获取订单详情
export function getOrderInfo(data) {
	return request({
		url: '/order/manage/getOrderInfo',
		method: 'post',
		data,
	})
}

//扭转订单状态
export function orderStatusFlow(data) {
	return request({
		url: '/order/manage/orderStatusFlow',
		method: 'post',
		data,
	})
}

//获取各状态数量
export function getOrderQtyByStatus(data) {
	return request({
		url: '/order/manage/getOrderQtyByStatus',
		method: 'post',
		data: data,
	})
}

//删除订单
export function orderDelete(data) {
	return request({
		url: '/order/manage/orderDelete',
		method: 'post',
		data: data,
	})
}

//修改订单折扣
export function modifyOrderDiscount(data) {
	return request({
		url: '/order/manage/modifyOrderDiscount',
		method: 'post',
		data: data,
	})
}

//修改订单备注
export function editOrderRemark(data) {
	return request({
		url: '/order/manage/editOrderRemark',
		method: 'post',
		data: data,
		hideLoading: true,
		notRemoveEmptyParams: true
	})
}

//获取订单消息列表
export function getMessageListByOrderId(data) {
	return request({
		url: '/order/manage/getMessageListByOrderId',
		method: 'post',
		data: data,
	})
}

//扭转支付
export function paymentStatusFlow(data) {
	return request({
		url: '/order/manage/paymentStatusFlow',
		method: 'post',
		data: data,
	})
}

//修改订单地址
export function retailerEditOrderAddress(data) {
	return request({
		url: '/order/manage/editOrderAddress',
		method: 'post',
		data: data,
	})
}

// 上传图稿
export function uploadToDraft(data) {
	return request({
		url: '/order/manage/uploadTheDrawings',
		method: 'post',
		data: data,
	})
}

//添加子订单
export function getAddChildOrder(data) {
	return request({
		url: '/order/manage/addChildOrder',
		method: 'post',
		data:data,
	})
}

//删除子订单
export function subOrderDelete(data) {
	return request({
		url: '/order/manage/subOrderDelete',
		method: 'post',
		data:data,
	})
}

//获取快递方式
export function getShippingList(data) {
	return request({
		url: '/shipping/list',
		method: 'get',
		params:data,
	})
}
//编辑订单业务员
export function editOrderManageSalesman(data) {
	return request({
		url: '/order/manage/editOrderManageSalesman',
		method: 'post',
		data:data,
	})
}

//获取getOfficeAddress
export function getOfficeAddress(data) {
	return request({
		url: '/app/systemProject/getOfficeAddress',
		method: 'post',
		params:data,
	})
}

//sendSms
export function sendSms(data) {
	return request({
		url: '/inquiry/sendSms',
		method: 'get',
		params:data,
	})
}


//获取未读
export function getUnreadCount(data) {
	return request({
		url: '/order/manage/getOrderAndInquiryUnreadCount',
		method: 'post',
		data:data,
	})
}

//editEmail
export function editEmail(data) {
	return request({
		url: '/order/manage/changeUserEmail',
		method: 'post',
		data,
	})
}
//获取订单shopper评论链接
export function getOrderReviewLink (data) {
	return request({
		url: '/order/manage/getOrderReviewLink',
		method: 'post',
		data,
	})
}

export function getDefaultLanguage(data) {
	return request({
		url: '/language/getDefaultLanguageByProId',
		method: 'get',
		params: data
	})
}

export function setTopping(data) {
	return request({
		url: '/order/manage/setOrderTop',
		method: 'post',
		data
	})
}
//获取内部问题数量
export function getUnreadQty (data) {
	return request({
		url: '/user/internalProblems/getUnreadQty',
		method: 'get',
		params: data
	})
}

export function getAllQuote(data) {
	return request({
		url: '/quote/cate/getAll',
		method: 'post',
		data
	})
}


//获取产品列表
export function getQuoteCateList(data) {
	return request({
		url: '/quote/cate/getQuoteCateListByProIdAndProType?language=en-us',
		method: 'get',
		params: data
	})
}

export function getPayLaterStatus(data) {
	return request({
		url: '/invoice/getPaymentLaterStatusList',
		method: 'get',
		params: data
	})
}

//获取具体地址
export function getGoogleAddress(data) {
	return request({
		url: "/app/language/getGoogleAddress",
		method: "get",
		params:data,
		hideLoading: true,
		notRemoveEmptyParams: true
	});
}

//获取国家省市区
export function getGoogleAddressDetail(data) {
	return request({
		url: "/app/language/getGoogleAddressDetail",
		method: "get",
		params:data,
		hideLoading: true
	});
}

//获取国家省市区
export function addEmailCopyRecord(data) {
	return request({
		url: "/user/addEmailCopyRecord?email=" + data,
		method: "get",
	});
}


export function editUser(data) {
	return request({
		url: '/member/editUser',
		method: 'post',
		data,
		hideLoading: true,
		notRemoveEmptyParams: true
	})
}

export function disputeResolve(data) {
	return request({
		url: '/order/manage/disputeResolve',
		method: 'post',
		data,
		hideLoading: true
	})
}

export function markOrderAsSampleOrder(data) {
	return request({
		url: '/order/manage/markOrderAsSampleOrder',
		method: 'post',
		data
	})
}


export function getNoPlaceOrderReason() {
	return request({
		url: '/inquiry/getNoPlaceOrderReason',
		method: 'get',
	})
}

export function getUserInfoById(data) {
	return request({
		url: '/member/getInfoById',
		method: 'post',
		data
	})
}

export function getListAsTree(data) {
	return request({
		url: '/order/cateGrading/listAsTree?pCode=P00&enabled=1',
		method: 'get',
	})
}

//地址校验
export function googleVerifyAddress(data) {
	return request({
		url: '/app/language/googleVerifyAddress',
		method: 'post',
		data,
		hideLoading: true,
		notRemoveEmptyParams: true
	})
}
