import { render, staticRenderFns } from "./myDesign.vue?vue&type=template&id=6100465e&scoped=true"
import script from "./myDesign.vue?vue&type=script&lang=js"
export * from "./myDesign.vue?vue&type=script&lang=js"
import style0 from "./myDesign.vue?vue&type=style&index=0&id=6100465e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6100465e",
  null
  
)

export default component.exports