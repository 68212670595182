import layout from "@/views/layout/index.vue";

const treeLikeStructure = () => import("@/views/treeData/index.vue");
export default {
	path: "/treeData",
	name: "treeData",
	component: layout,
	meta: {
		title: "树状结构",
		icon: "el-icon-box"
	},
	children: [
		{
			path: "treeLikeStructure",
			component: treeLikeStructure,
			name: "treeLikeStructure",
			meta: {
				title: "树状结构"
			}
		}
	]
};