<template>
  <div class="wrap">
    <div class="search">
      <el-input placeholder="搜索平台" v-model="form.keyword" class="input-with-select">
        <el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
      </el-input>
      <el-button type="primary" class="addBtn" @click="addPlatformRestriction">
        <i class="el-icon-plus"></i>&nbsp;&nbsp;
        新建平台限制
      </el-button>
    </div>
    <el-table :data="tableData" style="width: 100%" :header-cell-style="{ background: '#E1F1FF', color: '#000000' }">
      <el-table-column label="排序" width="80" type="index" align="center">
      </el-table-column>
      <el-table-column prop="proType" label="平台类型" width="160" align="center">
        <template slot-scope="scope">
          <div>{{ getProType(scope.row.proType) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="domainUrl" label="平台域名" width="160" align="center">
      </el-table-column>
      <el-table-column prop="templatePublishLimitation" label="模板发布限制" width="160" align="center">
        <template slot-scope="scope">
          <div :style="scope.row.templatePublishLimitation ? 'color:red' : 'color:#333'">
            {{ scope.row.templatePublishLimitation === 0 ? '未限制' : '已限制' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="platformLimitationList" label="展示类目限制" width="160" align="center">
        <template slot-scope="scope">
          <div>
            <span
              v-if="scope.row.platformLimitationList.length > 0 && scope.row.platformLimitationList.every(item => item.category)"
              style="color:red">已限制</span>
            <span v-else style="color:#333">未限制</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="platformLimitationList" label="限制类目明细" align="center">
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.platformLimitationList" :key="index">
            <span v-if="item.category">
              {{ scope.row.platformLimitationList ?
                item.category.categoryCode + (index === (scope.row.platformLimitationList.length - 1) ? '' : ',') :
                item.category.categoryCode }}
            </span>
            <span v-else>未限制</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="state" label="状态" width="110" align="center">
        <template slot-scope="scope">
          <div :style="scope.row.state === 0 ? 'color:red' : 'color:#333'">
            {{ scope.row.state === 0 ? '已停用' : '启用中' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" width="180" align="center">
        <template slot="header">
          <div>
            更新时间
            <span @click="upClick" :style="isColorUp ? 'color:#0089F5' : 'color:#333'">
              <b class="icon-icon_up"></b>
            </span>
            <span @click="downClick" :style="isColorDown ? 'color:#0089F5' : 'color:#333'">
              <b class="icon-icon_down"></b>
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-button type="text" class="xiugai" @click="edit(scope.row)">修改</el-button>
          <el-button type="text" class="del" @click="del(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 新增修改弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="45%" :before-close="handleClose">
      <el-form ref="form" :model="form" label-width="120px" :rules="rules" class="dialog">
        <el-form-item label="平台类型：" prop="platformType">
          <el-select v-model="form.platformType" placeholder="请选择活动区域" @change="platformChange">
            <el-option :label="item.label" :value="item.value" v-for="(item, index) in platformOptions" :key="index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="平台域名：" prop="domainNameId">
          <el-select v-model="form.domainNameId" placeholder="请选择活动区域" filterable>
            <el-option :label="ditem.proName" :value="ditem.id" v-for="(ditem, dindex) in domainNameList" :key="dindex">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态：">
          <el-switch v-model="form.state" active-color="#0089F5" inactive-color="#6D7074" active-text="启用"
            inactive-text="停用" :active-value="true" :inactive-value="false"></el-switch>
        </el-form-item>
        <el-form-item label="模板发布限制：">
          <el-checkbox-group v-model="form.publicationRestriction">
            <el-checkbox label="取消设计模板发布功能" name="type"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="展示类目限制：">
          <div class="group_All">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"
              class="checkbox">
              All Types
            </el-checkbox>
            <el-checkbox-group v-model="form.checkedCities" @change="handleCheckedCitiesChange" class="group_div">
              <el-checkbox v-for="item in cities" :label="item.id" :key="item.id">
                {{ item.categoryName }}
              </el-checkbox>
            </el-checkbox-group>
          </div>

        </el-form-item>
        <el-form-item class="dialogBtn">
          <el-button type="primary" @click="onSubmit">保存</el-button>
          <el-button @click="handleClose">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 删除 -->
    <el-dialog :visible.sync="delDialogVisible" width="25%" :before-close="handleClose">
      <div class="delDialog">
        <div>确认删除此平台限制？</div>
        <div>确认后，此平台限制的所有信息将被清除，无法恢复。</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="delConfirm">确 认</el-button>
        <el-button @click="handleClose">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getAllPlatformLimitation,
  editPlatformLimitation,
  deletePlatformLimitation,
  getSystemProjectList,
  getCategoryList
} from "@/api/designSystem/pFManagement"
export default {
  data() {
    return {
      sort: "",
      dialogTitle: "",
      cities: [],
      tableData: [],
      domainNameList: [],
      isColorDown: false,
      isColorUp: false,
      checkAll: false, //全选
      isIndeterminate: false,
      dialogVisible: false,
      delDialogVisible: false,
      form: {
        id: 0,
        keyword: "",
        platformType: '', //平台类型
        domainNameId: "", //平台域名
        state: true, //状态
        publicationRestriction: "",  //模板发布限制
        checkedCities: [], //类目限制
      },
      rules: {
        platformType: [
          { required: true, message: '请选择平台类型', trigger: 'change' }
        ],
        domainNameId: [
          { required: true, message: '请选择平台域名', trigger: 'change' }
        ]
      },
      platformOptions: [{
        value: 0,
        label: '自营平台',
      }, {
        value: 1,
        label: 'O2O经销商',
      }, {
        value: 2,
        label: 'USPP加盟商',
      }],
    };
  },
  methods: {
    downClick() {
      this.sort = "DESC";
      this.isColorDown = true;
      this.isColorUp = false;
      this.getList();
    },
    upClick() {
      this.sort = "ASC";
      this.isColorUp = true;
      this.isColorDown = false;
      this.getList();
    },
    getProType(proType) {
      if (proType === 0) {
        return '自营平台'
      } else if (proType === 1) {
        return 'O2O经销商'
      } else if (proType === 2) {
        return 'USPP加盟商'
      }
    },
    handleCheckAllChange(value) {
      if (value) {
        this.form.checkedCities = value ? [...this.cities].map(item => item.id) : []
      } else {
        this.form.checkedCities = [];
      }
    },
    handleCheckedCitiesChange(value) {
      const totalCities = this.cities.length;
      const checkedCount = value.length;

      this.checkAll = checkedCount === totalCities;
      this.isIndeterminate = checkedCount > 0 && checkedCount < totalCities;
    },

    //添加、修改确定按钮
    onSubmit() {
      let list = [], domainName = "";
      this.form.checkedCities.forEach((i) => {
        list.push({
          categoryId: i,
        })
      })
      let find = this.domainNameList.find((ii) => ii.id == this.form.domainNameId);
      if (find) {
        domainName = find.proName
      }
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let params = {
            proId: this.form.domainNameId,
            domainUrl: domainName,
            proType: this.form.platformType,
            state: this.form.state === true ? 1 : 0,
            templatePublishLimitation: this.form.publicationRestriction === true ? 1 : 0,
            platformLimitationList: list
          }
          if (this.form.id) params['id'] = this.form.id;
          editPlatformLimitation(params).then((res) => {
            this.$message.success("success")
            this.dialogVisible = false;
            this.handleClose();
            this.getList();
          })
        }
      });
    },
    //修改
    edit(row) {
      row = JSON.parse(JSON.stringify(row));
      this.dialogTitle = "修改平台限制";
      this.dialogVisible = true;
      // console.log(row.platformLimitationList.length, this.cities.length,"this.form.checkedCities.length === this.cities.length");
      this.checkAll = row.platformLimitationList.length === this.cities.length;
      this.isIndeterminate = row.platformLimitationList.length > 0 && row.platformLimitationList.length < this.cities.length;
      this.form.id = row.id;
      this.form.platformType = row.proType;

      this.form.domainNameId = row.proId;
      this.form.state = row.state === 1 ? true : false;
      this.form.publicationRestriction = row.templatePublishLimitation === 1 ? true : false;
      this.form.checkedCities = row.platformLimitationList.map(item => item.categoryId);

      this.$nextTick(() => {
        this.handleDomainNameList();
      })
    },
    //删除
    del(row) {
      this.form.id = row.id,
        this.delDialogVisible = true;
    },
    delConfirm() {
      deletePlatformLimitation({
        id: this.form.id
      }).then((res) => {
        this.$message.success("success")
        this.delDialogVisible = false;
        this.getList();
      })
    },
    //取消
    handleClose() {
      this.dialogVisible = false;
      this.delDialogVisible = false;
      this.form.platformType = "";
      this.form.domainNameId = "";
      this.form.state = true;
      this.checkAll = false;
      this.form.publicationRestriction = "";  //模板发布限制
      this.form.checkedCities = [];
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      })
      this.getList();
    },
    //新建
    addPlatformRestriction() {
      this.dialogVisible = true;
      this.dialogTitle = "新建平台限制";
    },

    //获取所有类目
    getCategory() {
      getCategoryList({
        language: "en-us"
      }).then((res) => {
        this.cities = res.data;
      })
    },

    //选择平台类型
    platformChange() {
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      })
      this.form.domainNameId = "";
      this.handleDomainNameList();
    },

    handleDomainNameList() {
      console.log(this.form.platformType, "this.form.platformType");
      let originDomainNameList = this.originDomainNameList
      if (this.form.platformType == 0) {
        this.domainNameList = originDomainNameList[0]
      } else if (this.form.platformType == 1) {
        this.domainNameList = originDomainNameList[1]
      } else if (this.form.platformType == 2) {
        this.domainNameList = originDomainNameList[2]
      }
    },

    //获取平台
    getPlatform() {
      getSystemProjectList({
        page: 1,
        pageSize: 9999
      }).then((res) => {
        this.originDomainNameList = res.data;
        this.handleDomainNameList();
      })
    },

    //获取列表
    getList() {
      getAllPlatformLimitation({
        keyword: this.form.keyword,
        timeSort: this.sort,
      }).then((res) => {
        this.tableData = res.data;
      })
    }
  },
  created() {
    this.getList();
    this.getPlatform();
    this.getCategory();
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-table__body-wrapper .el-table__cell:nth-of-type(6) {
  .cell {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

::v-deep .el-switch__label * {
  line-height: 1;
  font-size: 10px;
  display: inline-block;
}

::v-deep .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
  font-size: 10px !important;
}

::v-deep .el-switch__label--right {
  z-index: 1;
  right: 20px !important;
  margin-left: 0px;
}

::v-deep .el-switch__label--left {
  z-index: 1;
  left: 20px !important;
  margin-right: 0px;
}

::v-deep .el-switch__label.is-active {
  display: block;
}

::v-deep .el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 50px !important;
}

::v-deep {
  .el-tag.el-tag--info {
    background-color: #C8DAFF;
    color: #0089F5;
    border-color: #C8DAFF;
  }

  .el-select .el-tag__close.el-icon-close {
    color: #0089F5;
    background-color: #C8DAFF;
    font-size: 14px;
  }

  .pageTop {
    .el-input-group {
      margin-right: 18px;
    }

    .el-input-group__append {
      background: #0089F5;
      border: 1px solid transparent;
      color: #FFF;
      width: 74px;
      text-align: center;
      font-size: 20px;
    }

    .el-input-group--append .el-input__inner {
      height: 40px;
    }

    .el-button--primary {
      display: flex;
      align-items: center;

      i {
        font-size: 16px;
      }
    }
  }

  .el-button--primary {
    background: #0089F5;
    border-color: #0089F5;
  }

  .el-button--info {
    background: #7F9DB5;
    border-color: #7F9DB5;
  }

}

.wrap {
  padding: 20px 25px;

  b {
    flex-direction: row-reverse;
    font-weight: bold !important;

    &::before {
      transform: rotateY(180deg);
    }
  }

  span:nth-of-type(2) {
    position: absolute;
    right: 51px;
  }

  .search {
    display: flex;

    .el-input {
      width: 406px;
      border: 1px solid #0089f5;
      border-radius: 5px;
      margin-right: 23px;

      ::v-deep .el-input__inner {
        height: 45px;
        border: 0;
        font-size: 16px;
      }

      .el-input-group__append {
        border: 0;
      }

      .el-button {
        width: 74px;
        height: 45px;
        position: relative;
        left: 1px;
        background: #0089f5;
        color: #fff;
        font-size: 21px;
      }
    }

    .addBtn {
      background: #0089f5;
      border: 1px solid #0089f5;
      font-family: Source Han Sans CN;
      font-size: 18px;
      font-weight: bold;
      height: 45px;
    }
  }

  .tableHeaderClass th {
    background: #0089f5;
  }

  .el-table {
    margin-top: 18px;

    .del {
      color: red;
      text-decoration: underline;
    }

    .xiugai {
      color: #333;
      text-decoration: underline;
    }
  }

  .delDialog {
    text-align: center;
    font-size: 14px;

    div:nth-of-type(1) {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 26px;
    }
  }

  ::v-deep .el-dialog__footer {
    text-align: center !important;

    .el-button {
      width: 120px;
      height: 40px;
      border-radius: 0;
      margin-bottom: 10px;
    }

    .el-button:nth-of-type(1) {
      background: #D61B3F;
    }
  }

  ::v-deep .el-dialog__body {
    padding: 0 20px 30px 20px;
  }

  .dialog {
    border-top: 1px solid #ccc;
    padding-top: 20px;

    .el-select {
      width: 97%;
    }

    .group_All {
      width: 97%;
      border: 1px solid #ccc;

      .checkbox {
        margin-left: 15px;
        margin-top: 5px;
      }

      .group_div {
        border-top: 1px solid #ccc;
        padding-top: 5px;
        max-height: 165px;
        overflow: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 10px;

        .el-checkbox {
          margin-left: 15px;
        }
      }
    }

    .dialogBtn {
      text-align: right;
      margin-right: 10px;
      margin-top: 40px;

      .el-button {
        width: 135px;
        height: 40px;
        font-size: 16px;
      }
    }
  }
}
</style>
