import request from "@/utils/request";

//编辑模板名称
export function updateNameById(data) {
	return request({
		url: "/design/user/updateNameById",
		method: "post",
		data:data
	});
}

//保存至用户模板
export function editUserTemplatesAndSave(data) {
	return request({
		url: "/design/user/editUserTemplates",
		method: "post",
		data:data
	});
}

//根据类目和邮箱查询用户收藏模板元素
export function getUserClipartTemplatesByEmail(data) {
	return request({
		url: "/design/user/getUserClipartTemplatesByEmail",
		method: "post",
		data:data
	});
}

//获取proid
export function getList(data) {
	return request({
			url: '/systemProject/list',
			method: 'post',
			data: data,
	})
}

export function getAllColorCard(data) {
	return request({
		url: "/app/design/color/getAllColorCard",
		method: "post",
		data:data
	});
}

//获取色卡分类（父级）
export function getAllColorType (params) {
	return request({
		url: "/design/color/getAllColorType",
		method: "get",
		params
	});
}

//根据id获取子类色卡
export function getColorCardByTypeId (params) {
	return request({
		url: "/design/color/getColorCardByTypeId",
		method: "get",
		params
	});
}

//模糊查询颜色色卡
export function getColorCardByColorName (params) {
	return request({
		url: "/design/color/getColorCardByColorName",
		method: "get",
		params
	});
}

//获取元素分类
export function getArtCateList(data) {
	return request({
		url: "/app/design/clipartType/list",
		method: "post",
		data
	});
}

//获取元素
export function getArtList(data) {
	return request({
		url: "/app/design/clipart/list",
		method: "post",
		data
	});
}

//根据id 获取相似元素替换列表
export function replaceClipart(data) {
	return request({
		url: "/app/design/clipart/replaceClipart",
		method: "post",
		data: data
	});
}

//收藏或者取消收藏元素
export function favoriteClipart(data) {
	return request({
		url: "/app/designUser/favoriteClipart",
		method: "post",
		data
	});
}

//收藏或者取消收藏模版
export function favoriteTemplate(data) {
	return request({
		url: "/app/designUser/favoriteTemplate",
		method: "post",
		data
	});
}

//获取收藏模版
export function getFavoriteTemplateList(data) {
	return request({
		url: "/app/designUser/getFavoriteTemplateList",
		method: "post",
		data
	});
}

//获取默认上传模版
export function getDefaultTemplateList(data) {
	return request({
		url: "/app/designUser/getDefaultTemplateList",
		method: "post",
		data
	});
}


//上传用户元素
export function uploadUserClipart(data) {
	return request({
		url: "/app/designUser/uploadUserClipart",
		method: "post",
		data
	});
}

//添加编辑文件
export function editUserClipartCategory(data) {
	return request({
		url: "/app/designUser/editUserClipartCategory",
		method: "post",
		data
	});
}

//删除元素文件夹
export function deleteUserClipartCategory(data) {
	return request({
		url: "/app/designUser/deleteUserClipartCategory",
		method: "post",
		data
	});
}


export function addTemplateHistory(data) {
	return request({
		url: "/app/design/uploadHistory/addTemplateHistory",
		method: "post",
		data
	});
}

export function getTemplateHistory(data) {
	return request({
		url: "/app/design/uploadHistory/getTemplateHistory",
		method: "post",
		data
	});
}

export function deleteUploadHistory(data) {
	return request({
		url: "/app/design/uploadHistory/deleteUploadHistory",
		method: "post",
		data
	});
}



export function addUploadHistory(data) {
	return request({
		url: "/app/design/uploadHistory/addUploadHistory",
		method: "post",
		data
	});
}

export function getUploadHistory(data) {
	return request({
		url: "/app/design/uploadHistory/getUploadHistory",
		method: "post",
		data
	});
}

//获取所有元素文件夹
export function getClipartCategoryList(data) {
	return request({
		url: "/app/designUser/getClipartCategoryList",
		method: "post",
		data
	});
}

//获取上传文件夹
export function getUserUploadClipartList(data) {
	return request({
		url: "/app/designUser/getUserUploadClipartList",
		method: "post",
		data
	})
}

//根据url获取信息
export function getByUrlAndProId(data) {
	return request({
		url: "/app/design/platformLimitation/getByUrlAndProId",
		method: "post",
		data
	});
}

//分页获取默认文件夹上传
export function getDefaultClipartList(data) {
	return request({
		url: "/app/designUser/getDefaultClipartList",
		method: "post",
		data
	});
}
//获取模板类型
export function getTemplateTypeListFromApp(data) {
	return request({
		url: "/app/design/template/getTemplateTypeListFromApp",
		method: "get",
		params:data
	});
}

//分页获取收藏元素
export function getFavoriteClipartList(data) {
	return request({
		url: "/app/designUser/getFavoriteClipartList",
		method: "post",
		data
	})
}

//查询模板
export function getTemplateListFormApp(data) {
	return request({
		url: "/app/design/template/getTemplateListFormApp",
		method: "get",
		params:data
	});
}

//匹配符合条件的元素名 元素分类名
export function likeString(data) {
	return request({
		url: "/app/design/clipart/likeString",
		method: "get",
		params:data
	});
}

//模糊查询 标签名 元素名 元素分类名
export function likeQuery(data) {
	return request({
		url: "/app/design/clipart/likeQuery",
		method: "get",
		params: data
	});
}

//移动用户上传元素
export function moveUploadClipart(data) {
	return request({
		url: "/app/designUser/moveUploadClipart",
		method: "post",
		data
	})
}

//获取电镀
export function getAllPlatingColorChart(data) {
	return request({
		url: "/app/design/platingColorChart/list",
		method: "post",
		data
	});
}

//用户上传更新模版
export function editUserTemplates(data) {
	return request({
		url: "/app/designUser/editUserTemplates",
		method: "post",
		data
	});
}

//删除用户模版
export function deleteUserTemplates(data) {
	return request({
		url: "/app/designUser/deleteUserTemplates",
		method: "post",
		data
	});
}

//查询所有元素名 元素分类名
export function likeAllString(data) {
	return request({
		url: "/app/design/clipart/likeAllString",
		method: "get",
		params: data
	});
}

export function getAllTemplateNameAndTypeName(data) {
	return request({
		url: "/app/design/template/getAllTemplateNameAndTypeName",
		method: "get",
		params: data
	});
}

//判断模版名是否存在
export function templateNameExist(data) {
	return request({
		url: "/app/designUser/templateNameExist",
		method: "get",
		params: data
	});
}

export function getTemplateById(data) {
	return request({
		url: "/app/design/template/getTemplateById",
		method: "get",
		params: data
	});
}

export function getTemplateByName(data) {
	return request({
		url: "/app/design/template/getTemplateByName",
		method: "get",
		params: data
	});
}

export function getFirstBlankByCategoryId(data) {
	return request({
		url: "/app/design/template/getFirstBlankByCategoryId",
		method: "get",
		params: data
	});
}

//分享模板
export function addDesignTemplateShare(data) {
	return request({
		url: "/app/design/designTemplateShare/addDesignTemplateShare",
		method: "post",
		data: data,
	});
}

//获取分析模板
export function getDesignTemplateShare(data) {
	return request({
		url: "/app/design/designTemplateShare/getDesignTemplateShare",
		method: "post",
		data: data,
	});
}

//缓存模板
export function stagingTemplate(data) {
	return request({
		url: "/design/template/stagingTemplate",
		method: "post",
		data: data,
	});
}


//模版-上传-发布-新增/更新
export function saveTemplate(data) {
	return request({
		url: "/design/template/saveTemplate",
		method: "post",
		data: data,
	});
}

//根据ID查询单个类目信息
export function getById(data) {
	return request({
		url: "/design/category/getById",
		method: "get",
		params: data,
	});
}

//根据ID查询单个类目信息
export function manageGetTemplateById(data) {
	return request({
		url: "/design/template/getTemplateById",
		method: "get",
		params: data,
	});
}




