import request from "@/utils/request";

//查询所有类目
export function getCategoryList (params) {
    return request({
      url: `/design/category/list`,
      method: "get",
      params
    });
};

//根据ID查询单个类目
export function getCategoryById (params) {
  return request({
    url: `/design/category/getById`,
    method: "get",
    params
  });
};

//配置设计功能
export function updateCategoryConfig (data) {
  return request({
    url: `/design/category/updateCategoryConfig`,
    method: "post",
    data
  });
};

//根据ID删除单个类目
export function deleteCategoryById (params) {
  return request({
    url: `/design/category/delById`,
    method: "get",
    params
  });
};

//更新排序
export function changeCategorySort (data) {
  return request({
    url: `/design/category/changeCategorySort`,
    method: "post",
    data
  });
};

//编辑类目
export function updateCategoryBasic (data) {
  return request({
    url: `/design/category/updateCategoryBasic`,
    method: "post",
    data
  });
};

//新增类目
export function addCategoryBasic (data) {
  return request({
    url: `/design/category/addCategoryBasic`,
    method: "post",
    data
  });
};


//获取半定制对应报价类别
export function getSemiCustomCategory (data) {
  return request({
    url: `/category/listAllCategoryByParentId`,
    method: "post",
    data
  });
};

//获取全定制对应报价类别
export function getAllCustomCategory (params) {
  return request({
    url: `/quote/cate/getByPId`,
    method: "get",
    params
  });
};
 