<template>
	<div class="select-pop">
		<el-dialog
			:visible.sync="openStatus"
			:title="showTypeChildList ? '选择网站' : '选择平台'"
			:before-close="cleanStatus"
		>
			<div v-if="!showTypeChildList">
				<div class="productType">选择运营平台</div>
				<div class="productTypeList" style="margin-top: 0">
					<div
						v-for="(item, index) in options"
						class="typeLabel"
						:class="{ active: activeGroup === index }"
						@click="selectParentType(item, index)"
					>
						<span>{{ item.label }}</span>
					</div>
				</div>
			</div>
			<div class="childType" v-if="showTypeChildList">
				<div class="cursor" style="display: flex; justify-content: space-between">
					<div @click="backWebTop()">
						<i class="el-icon-back cursor" style="color: rgb(248, 148, 18)"></i>
						<span style="text-decoration: underline; vertical-align: text-bottom; color: rgb(248, 148, 18)"
							>返回上一步</span
						>
					</div>
					<div
						class="cursor mr-5 d-flex align-center"
						style="text-align: right; margin-bottom: 0px; margin-right: 1em"
						v-if="!isUploadTg && showAll"
					>
						<el-checkbox
							size="medium"
							class="mr-2"
							v-model="selectAll"
							@change="selectAllType(selectAll)"
							label="全选"
						></el-checkbox>
					</div>
				</div>
				<div class="d-flex justify-space-between ml-6 mt-4"></div>
				<div class="childTypeList">
					<div
						class="childTypeLabel"
						v-for="(citem, idx) in showList"
						:class="{ active: selectedChildIndex.includes(citem.value) || selectOther == citem.value }"
						@click="selectChildType(citem, idx)"
					>
						<div v-if="citem.children">
							{{ citem.label }}
						</div>
						<div v-else>
							<img
								v-if="citem.proLogoBack || citem.proLogo"
								:src="citem.proLogoBack ? citem.proLogoBack : citem.proLogo"
								:title="citem.label"
							/>
							<div class="icon" v-if="selectedChildIndex.includes(citem.value)">
								<img
									:src="'https://oss-static-cn.liyi.co/web/quoteManage/20240312/%E5%9B%BE%E6%A0%87_20240312J6NZce.png'"
								/>
							</div>
						</div>
					</div>
				</div>
				<div
					class="d-flex justify-end mt-8"
					style="text-align: right; margin-right: 1em; margin-top: 1em"
					v-if="!isUploadTg && showAll"
				>
					<v-btn color="#ff934c" dark width="116" height="42" @click="submitType()" style="margin-right: 1em">
						提交
					</v-btn>
					<v-btn color="#c3c3c3" width="116" height="42" @click="cleanStatus" style="color: #3c3c3c">
						取消
					</v-btn>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: "CustomSelectPop",
	props: {
		uploadInquiry: {
			type: Boolean,
			default: false,
		},
		uploadOrder: {
			type: Boolean,
			default: false,
		},
		isUploadTg: {
			type: Boolean,
			default: false,
		},
		openStatus: {
			type: Boolean,
			default: false,
		},
		options: {
			type: Array,
			default: [],
		},
		originList: {
			type: Array,
			default: () => [],
		},
		clearPt: {
			type: Boolean,
			default: false,
		},
		isSampleOrder: {
			type: Boolean,
			default: false,
		},
		samplesType: {
			type: Number, // 1 订单  2 FD订单
			default: null,
		},
	},
	data() {
		return {
			modalKey: 6,
			index: 3,
			proType: "",
			selectAll: false,
			showTypeChildList: false,
			selectedIndex: -1,
			selectOther: -1, // 其他下选中的o2o  uspp
			selectedChildIndex: [],
			searchNum: "",
			ptSelect: JSON.parse(sessionStorage.getItem("ptSelect")) || [],
			selectedChildId: "",
			showList: [],
			activeGroup: -1,
			showOther: false,
			showAll: false,
		};
	},
	watch: {
		clearPt: {
			handler(val) {
				if (val) {
					this.clearPtList();
				}
			},
			immediate: true,
			deep: true,
		},
	},
	methods: {
		cleanStatus() {
			this.selectOther = -1;
			this.showOther = false;
			this.showList = false;
			this.showTypeChildList = false;
			// this.uploadOrder = false;
			this.$emit("update:uploadOrder", false);
			(this.activeGroup = -1), this.$emit("update:openStatus", false);
		},
		clearPtList() {
			this.cleanStatus();
			this.proType = "";
			this.selectedChildIndex = [];
			this.showTypeChildList = false;
			this.selectAll = false;
			this.$emit("submitList", []);
			//更新导航栏
			sessionStorage.removeItem("ptSelect");
			// 更新数据便于订单查询
			this.$store.commit("manage/updatePtSelected", []);
			this.$store.commit("manage/clearPtSelected", false);
			this.$emit("changeClearPt", false);
		},
		backWebTop() {
			this.selectAll = false;
			// this.selectedChildIndex = [];
			//
			if (this.showOther) {
				// 回到o2o uspp
				this.showTypeChildList = true;
				this.showList = this.options[3].children;
				this.showOther = false;
			} else {
				// 返回大类
				this.ptSelect = [];
				this.showTypeChildList = false;
				this.ptSelect.push([this.proType]);
			}
		},
		submitType() {
			this.$emit("submitList", this.ptSelect);
		},
		selectAllType(val) {
			if (val) {
				// 先清空已选的再全选 避免重复
				this.ptSelect = [];
				this.ptSelect.push([this.proType]);
				this.selectedChildIndex = [];
				this.originList[this.selectedIndex].children.forEach((e) => {
					this.selectedChildIndex.push(e.value);
					this.ptSelect.push([this.proType, e.value]);
					this.modalKey++;
				});
			} else {
				this.selectedChildIndex = [];
				this.ptSelect = [];
				this.ptSelect.push([this.proType]);
				this.modalKey++;
			}
		},
		selectParentType(item, index) {
			this.activeGroup = index;
			// 上传订单做单选
			// 大类 item.value : 0自营平台   1FD  2POP   3其他 选fd pop 直接传proType0
			if ((item.value == 1 || item.value == 2) && !this.showTypeChildList) {
				this.proType = "0";
				if (this.uploadOrder) {
					// 上传订单
					this.selectedChildId = item.value == 1 ? 9 : 10;
					// val 选FD POP还是proType传0，只是在页面上从自营平台分离出来，实际还是属于自营平台0
					this.goUpload(this.proType);
				} else {
					// 导航栏筛选	 this.ptSelect[0] [大类value],  this.ptSelect[1] [大类value,小类id]
					this.ptSelect = [this.proType, [this.proType, item.value == 1 ? 9 : 10]];
					this.submitType();
				}
				if (this.isUploadTg && (item.value == 1 || item.value == 2) && !this.showTypeChildList) {
					this.$emit("selectPtAndSite", {
						proType: "0",
						proId: item.value == 1 ? 9 : 10,
					});
				}
				return;
			}
			if (index == 3) {
				this.showAll = false;
			}
			this.selectedIndex = index;
			this.proType = item.value;

			// 切换不同大类清空ptSelect，否则回填当前缓存的
			if (
				(this.ptSelect && this.ptSelect[0] && this.ptSelect[0][0] == 0 && index == 3) ||
				!this.ptSelect.length
			) {
				this.ptSelect = [];
				this.ptSelect.push([this.proType]);
				this.selectedChildIndex = [];
			}
			this.modalKey++;
			this.showList = this.options[this.selectedIndex].children;
			setTimeout(() => {
				// 自营平台隐藏FD  POP
				if (item.value == 0) {
					this.options[index].children = this.options[index].children.filter(
						(child) => child.value !== "9" && child.value !== "10",
					);
				}
				this.showTypeChildList = true;
			}, 300);
		},

		selectChildType(item, index) {
			this.showAll = true;
			if (item.children) {
				this.selectOther = item.value;
				this.selectedIndex = item.value;
				this.proType = item.value;
				// 切换不同大类清空ptSelect，否则回填当前缓存的
				if (this.ptSelect && this.ptSelect[0] && this.ptSelect[0][0] !== item.value) {
					this.ptSelect = [];
					this.ptSelect.push([this.proType]);
					this.selectedChildIndex = [];
				}
				this.modalKey++;
				this.showOther = true;
				// o2o uspp
				this.showList = item.children;
				setTimeout(() => {
					this.showTypeChildList = true;
				}, 300);
			} else {
				console.log("selectChildType", this.ptSelect, this.selectedChildIndex);
				// 自营平台
				this.selectedIndex = item.proType;
				this.proType = item.proType;
				this.showOther = false;
				if (this.isUploadTg) {
					this.$emit("selectPtAndSite", {
						proType: item.proType,
						proId: item.value,
					});
					return false;
				}
				if (this.selectedChildIndex.includes(item.value)) {
					let delIndex = this.selectedChildIndex.findIndex((e) => {
						return e === item.value;
					});
					this.selectedChildIndex.splice(delIndex, 1);

					let del = this.ptSelect.findIndex((ele) => {
						return ele[1] && ele[1] === item.value;
					});
					this.ptSelect.splice(del, 1);

					this.modalKey++;
					this.selectAll = false;
					if (this.selectedChildIndex.length <= 0) {
						this.ptSelect = [];
						this.ptSelect.push([this.proType]);
					}
				} else {
					// 上传订单做单选
					if (this.uploadOrder) {
						this.selectedChildId = item.value;
						this.goUpload(item.proType);
					} else {
						console.log(444444444444, this.ptSelect, this.selectedChildIndex);
						this.selectedChildIndex.push(item.value);
						this.ptSelect.push([this.proType, item.value]);

						this.modalKey++;
						if (this.selectedChildIndex.length === this.showList.length) {
							this.selectAll = true;
						}
					}
				}

				console.log(3333333, this.ptSelect, this.selectedChildIndex);
			}
		},
		goUpload(proType) {
			if (this.samplesType) {
				this.$router.push({
					path: "/InquiryOrderManage/sampleOrderUpload",
					params: {},
					query: {
						proId: this.selectedChildId,
						type: "order",
						samplesType: this.samplesType,
					},
				});
			} else {
				this.$router.push({
					// name: "uploadDraft",
					path: this.$route.path.includes("FdInquiryOrderManage")
						? "/FdInquiryOrderManage/order/uploadDraft"
						: "/InquiryOrderManage/order/uploadDraft",
					params: {},
					query: {
						proId: this.selectedChildId,
						type: "order",
						proType: proType,
						orderType: 2,
						uploadType: true,
					},
				});
			}
		},
	},
	mounted() {
		console.log(2222555222, this.samplesType);
		this.selectedChildIndex = this.ptSelect.filter((item) => item.length > 1).map((item) => item[1]);
		if (this.ptSelect && this.ptSelect[0] && this.ptSelect[0][0]) {
			if (this.ptSelect[0][0] == 0) {
				this.activeGroup = 0;
				if (this.ptSelect[1][1] == 7) {
					this.activeGroup = 1;
				} else {
					this.activeGroup = 2;
				}
			}
			if (this.ptSelect[0][0] == 1 || this.ptSelect[0][0] == 2) {
				this.selectOther = this.ptSelect[0][0];
				this.activeGroup = 3;
			}
		}
	},
};
</script>

<style scoped lang="scss">
.select-pop {
	position: relative;
	z-index: 3000;
	::v-deep {
		.el-dialog {
			background: unset;
			background-image: url("https://oss-static-cn.liyi.co/web/quoteManage/20240308/background_20240308BZ3CY3.png");
			background-size: 100% 100%;
		}
		.el-dialog__header {
			padding: 20px 10px 0 10px;
			text-align: center;
			.el-dialog__title {
				color: #fff;
			}
		}

		.el-dialog__body {
			padding: 0 10px 40px 10px;
			box-shadow: none;
			border-radius: 10px;
		}

		.el-dialog__close {
			color: white;
		}
	}
	.el-icon-back {
		font-size: 22px;
		font-weight: 700;
	}

	.cursor {
		cursor: pointer;
		color: #0256ff;
		//margin-bottom: 1vw;
	}
	.productType {
		display: flex;
		color: rgb(248, 148, 18);
		justify-content: flex-start;
		margin-left: 1em;
	}
	.productTypeList {
		font-size: 18px;
		color: rgb(248, 148, 18);
		grid-template-columns: repeat(4, 1fr) !important;
		justify-content: center !important;
		.typeLabel:last-child {
			width: 5vw;
			height: 3.5vw;
		}
	}

	.productTypeList,
	.childTypeList {
		display: grid;
		place-items: center;
		grid-template-columns: repeat(6, 1fr);
		max-height: 52vh;
		overflow: auto;
		margin-top: 1em;

		&::-webkit-scrollbar {
			width: 4px;
			height: 3px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background-color: rgba($color: #ccc, $alpha: 0.2);
		}

		.typeLabel,
		.childTypeLabel {
			cursor: pointer;
			width: 9vw;
			height: 5.4vw;
			margin: 2em;
			padding: 0 8px;
			border-radius: 5px;
			color: #3d3d3d;
			background: #ffffff;
			box-sizing: border-box;
			border: 1px solid #000000;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;

			&.active {
				//background: rgba(192, 214, 245, 0.44);
				border: 2px solid rgb(248, 148, 18);
			}

			.icon {
				position: absolute;
				top: -15%;
				right: -5%;
				> b {
					font-size: 16px;
					color: rgb(248, 148, 18);
				}
			}
		}

		.childTypeLabel {
			width: 7.4vw;
			height: 4.2vw;
			margin: 0.5em;
			> div {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
			}
		}
	}
}
</style>
