
import request from "@/utils/request";
// ⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇  标签库  ⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇
// 新增/编辑标签分类
export function editLabelType(data) {
  return request({
    url: '/design/labelsType/edit',
    method: 'post',
    data: data,
  })
}

//删除分类
export function delLabelsType(data) {
  return request({
    url: '/design/labelsType/delById',
    method: 'get',
    params: data,
  })
}

// 查询标签分类
export function getlabelsType(data) {
  return request({
    url: '/design/labelsType/list',
    method: 'get',
    params: data,
  })
}

// 新增/编辑标签
export function editLabel(data) {
  return request({
    url: '/design/labels/edit',
    method: 'post',
    data: data,
  })
}

// 删除标签
export function delLabels(data) {
  return request({
    url: 'design/labels/delById',
    method: 'get',
    params: data,
  })
}

// 拖拽排序保存
export function sortLabels(data) {
  return request({
    url: '/design/labels/sort',
    method: 'post',
    data: data,
  })
}

// ⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆  标签库  ⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆


// ⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇  模板库  ⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇

// 模板分类管理-左侧
export function getTemplateTypeOnDisplay(data) {
  return request({
    url: '/design/template/getTemplateTypeOnDisplay',
    method: 'get',
    params: data,
  })
}

// 模板分类管理-右侧

export function getTemplateTypeList(data) {
  return request({
    url: 'design/template/getTemplateTypeList',
    method: 'get',
    params: data,
  })
}

// 新增模板分类
export function addTemplateType(data) {
  return request({
    url: '/design/template/addTemplateType',
    method: 'post',
    data: data,
  })
}

//模板分类更新
export function updateTemplateType(data) {
  return request({
    url: '/design/template/updateTemplateType',
    method: 'post',
    data: data,
  })
}

// 模板列表排序

export function changeTemplateTypeSort(data) {
  return request({
    url: '/design/template/changeTemplateTypeSort',
    method: 'post',
    data: data,
  })
}

//模板分类删除
export function delTemplateTypeById(data) {
  return request({
    url: '/design/template/delTemplateTypeById',
    method: 'get',
    params: data,
  })
}


// 获取模板管理右侧列表
export function getTemplateList(data) {
  return request({
    url: '/design/template/getTemplateList',
    method: 'get',
    params: data,
  })
}

// 模板管理-查询单个模板数据
export function getTemplateById(data) {
  return request({
    url: '/design/template/getTemplateById',
    method: 'get',
    params: data,
  })
}

// 修改模板管理启用状态
export function changeTemplateActive(data) {
  return request({
    url: '/design/template/changeTemplateActive',
    method: 'post',
    data: data,
  })
}
// 删除模板信息
export function delTemplateById(data) {
  return request({
    url: '/design/template/delTemplateById',
    method: 'get',
    params: data,
  })
}
// 模板管理拖拽排序
export function changeTemplateSort(data) {
  return request({
    url: '/design/template/changeTemplateSort',
    method: 'post',
    data: data,
  })
}

// 查询模板日志
export function getTemplateLogListById(data) {
  return request({
    url: '/design/template/getTemplateLogListById',
    method: 'get',
    params: data,
  })
}

// 模板新增
export function sureSaveTemplate(data) {
  return request({
    url: '/design/template/saveTemplate',
    method: 'post',
    data: data,
  })
}

// 查询所有模板类型
export function getAllTemplateTypeList(data) {
  return request({
    url: '/design/template/getAllTemplateTypeList',
    method: 'get',
    params: data,
  })
}

// 批量更新
export function bulkEditTypeAndLabel(data) {
  return request({
    url: '/design/template/bulkEditTypeAndLabel',
    method: 'post',
    data: data,
  })
}


// ⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆  模板库  ⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆



// ⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇  元素库  ⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇+

// 111111111  分类管理  11111111111
// 获取分类管理左侧列表
export function getTypeList(data) {
  return request({
    url: '/design/clipartType/typeList',
    method: 'get',
    params: data,
  })
}

// 获取分类管理列表
export function getList(data) {
  return request({
    url: '/design/clipartType/list',
    method: 'post',
    data: data,
  })
}

// 获取所有父分类
export function getAllParent(data) {
  return request({
    url: '/design/clipartType/getAllParent',
    method: 'get',
    params: data,
  })
}

// 新增、编辑元素分类管理
export function editClipartType(data) {
  return request({
    url: '/design/clipartType/edit',
    method: 'post',
    data: data,
  })
}

// 删除分类管理
export function delClipartType(data) {
  return request({
    url: '/design/clipartType/delById',
    method: 'get',
    params: data,
  })
}

// 元素管理父分类排序
export function clipartSort(data) {
  return request({
    url: '/design/clipartType/sort',
    method: 'post',
    data: data,
  })
}

// 222222222 元素管理 22222222222
// 获取元素列表
export function getClipartsList(data) {
  return request({
    url: '/design/clipart/list',
    method: 'post',
    data: data,
  })
}

// Select Clipart Types
export function selectClipartTypes(data) {
  return request({
    url: '/design/clipart/getClipartType',
    method: 'get',
    params: data,
  })
}

//  Select Labels
export function getLabelsType(data) {
  return request({
    url: '/design/labelsType/getLabelsType',
    method: 'get',
    params: data,
  })
}

// 新增编辑元素
export function editCliparts(data) {
  return request({
    url: '/design/clipart/edit',
    method: 'post',
    data: data,
  })
}

// 获取展示平台设置
export function getSystemProjectList(data) {
  return request({
    url: '/systemProject/list',
    method: 'post',
    data,
  })
}

// 获取语言
export function getLanguageByProId(data) {
  return request({
    url: '/language/getLanguageByProId?proId=0',
    method: 'get',
    data,
  })
}

// 删除元素
export function delClipartById(data) {
  return request({
    url: '/design/clipart/delById',
    method: 'get',
    params: data,
  })
}

// 启用\停用元素

export function updateEnableState(data) {
  return request({
    url: '/design/clipart/updateEnable',
    method: 'post',
    data,
  })
}

// 拖拽排序

export function clipartsSort(data) {
  return request({
    url: '/design/clipart/sort',
    method: 'post',
    data,
  })
}

// 根据Id查询元素具体信息
export function selectGetById(data) {
  return request({
    url: '/design/clipart/getById',
    method: 'get',
    params: data,
  })
}

// 获取元素日志
export function getLogByClipartId(data) {
  return request({
    url: '/design/clipart/getLogByClipartId',
    method: 'get',
    params: data,
  })
}
// 批量更新
export function batchUpdateType(data) {
  return request({
    url: '/design/clipart/batchUpdateType',
    method: 'post',
    data: data,
  })
}

// 判断元素名是否重复
export function getSameClipartName(data) {
	return request({
		url: '/design/clipart/getSameClipartName',
		method: 'get',
		params: data,
	})
}

// ⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆  元素库  ⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆// 批量更新
//批量添加
export function batchAddClipart(data) {
	return request({
		url: '/design/clipart/batchAddClipart',
		method: 'post',
		data: data,
	})
}
