<template>
	<header class="header">
		<div>

		</div>
		<div class="close" @click="closeDialog">
			<el-button type="primary" icon="el-icon-switch-button">退出系统</el-button>
		</div>
	</header>
</template>

<script>
export default {
	data(){
		return{

		}
	},
	methods:{
		closeDialog(){
			this.$confirm('确认退出系统吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$emit('closeDialog')
			}).catch(() => {

			});
		}
	}
}
</script>

<style scoped lang="scss">
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
	background: #171719;
	color: #ffffff;
	font-size: 14px;
	padding: 0 20px;
	.close{
		i{
			cursor: pointer;
			font-size: 30px;
		}
	}
}
</style>
