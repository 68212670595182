import layout from "@/views/layout/index.vue";
const freebie = () => import("@/views/commodityManagement/freebieManagement/freebie.vue");
const quoteProjectManage = () => import("@/views/commodityManagement/quoteManage/projectManage.vue");
const productCategory = () => import("@/views/commodityManagement/quoteManage/productCategory");
const fdProductCategory = () => import("@/views/commodityManagement/quoteManage/fdProductCategory");
const fdCodeManagement = () => import("@/views/commodityManagement/quoteManage/fdCodeManagement");
const priceList = () => import("@/views/commodityManagement/quoteManage/priceList.vue");
const colorCard = () => import("@/views/commodityManagement/quoteManage/colorCard.vue");
const picStorage = () => import("@/views/commodityManagement/quoteManage/picStorage.vue");
const picStore = () => import("@/views/commodityManagement/visualManagement/picStore/index.vue");
const pictureResourceLibrary = () => import("@/views/commodityManagement/visualManagement/pictureResourceLibrary.vue");
const surcharge = () => import("@/views/commodityManagement/quoteManage/surcharge.vue");
const commodityLibrary = () => import("@/views/commodityManagement/commodityCenter/commodityLibrary.vue");
const addProduct = () => import("@/views/commodityManagement/commodityCenter/addProduct.vue");
const subjectManagement = () => import("@/views/commodityManagement/subjectManagement/index.vue");
// 半定制管理
const classify = () => import("@/views/commodityManagement/customManagement/classify.vue");
const color = () => import("@/views/commodityManagement/customManagement/color.vue");
const attributes = () => import("@/views/commodityManagement/customManagement/attributes/index.vue");

const category = () => import("@/views/commodityManagement/customManagement/category/index.vue");
import electroplatedColor from "@/views/commodityManagement/designCenter/colorChart/electroplatedColor/index.vue";
import colorChart from "@/views/commodityManagement/designCenter/colorChart/index.vue";

import ptColor from "@/views/commodityManagement/designCenter/colorChart/ptColor/index.vue";
import bCManagement from "@/views/commodityManagement/designCenter/interfaceManagement/bCManagement/index.vue";
import cConfiguration from "@/views/commodityManagement/designCenter/interfaceManagement/cConfiguration/index.vue";
import cTConfiguration from "@/views/commodityManagement/designCenter/interfaceManagement/cTConfiguration/index.vue";
import interfaceManagement from "@/views/commodityManagement/designCenter/interfaceManagement/index.vue";
import pFManagement from "@/views/commodityManagement/designCenter/interfaceManagement/pFManagement/index.vue";
import categoryLibrary from "@/views/commodityManagement/designCenter/templateManagement/categoryLibrary/index.vue";
import templateManagement from "@/views/commodityManagement/designCenter/templateManagement/index.vue";
import tagLibrary from "@/views/commodityManagement/designCenter/templateManagement/tagLibrary/index.vue";
import templateLibrary from "@/views/commodityManagement/designCenter/templateManagement/templateLibrary/index.vue";
const customIndex = () => import("@/views/index.vue");
export default {
	path: "/commodityManagement",
	component: layout,
	name: "commodityManagement",
	meta: {
		title: "商品管理",
		icon: "el-icon-goods",
	},
	children: [
		{
			path: "quoteManage",
			name: "quoteManage",
			component: customIndex,
			meta: {
				title: "全定制商品管理",
			},
			children: [
				{
					path: "quoteProjectManage",
					name: "quoteProjectManage",
					component: quoteProjectManage,
					meta: {
						title: "项目管理",
					},
				},
				{
					path: "productCategory",
					name: "productCategory",
					component: productCategory,
					meta: {
						title: "产品类别",
					},
				},
				{
					path: "fdProductCategory",
					name: "fdProductCategory",
					component: fdProductCategory,
					meta: {
						title: "Fd产品类别",
					},
				},
				{
					path: "fdCodeManagement",
					name: "fdCodeManagement",
					component: fdCodeManagement,
					meta: {
						title: "Fd折扣码管理",
					},
				},
				{
					path: "priceList",
					name: "priceList",
					component: priceList,
					meta: {
						title: "价格表管理",
					},
				},
				{
					path: "colorCard",
					name: "colorCard",
					component: colorCard,
					meta: {
						title: "色卡",
					},
				},
				{
					path: "picStorage",
					name: "picStorage",
					component: picStorage,
					meta: {
						title: "图标库",
					},
				},
				{
					path: "surcharge",
					name: "surcharge",
					component: surcharge,
					meta: {
						title: "附加费管理",
					},
				},
			],
		},
		{
			path: "customManagement",
			name: "customManagement",
			component: customIndex,
			meta: {
				title: "半定制商品管理",
			},
			children: [
				{
					path: "classify",
					name: "classify",
					component: classify,
					meta: {
						title: "半定制分类",
					},
				},
				{
					path: "color",
					name: "color",
					component: color,
					meta: {
						title: "色卡库",
					},
				},
				{
					path: "attributes",
					name: "attributes",
					component: attributes,
					meta: {
						title: "属性库",
					},
				},
				{
					path: "category",
					name: "category",
					component: category,
					meta: {
						title: "类目库管理",
					},
				},
				{
					path: "commodityLibrary",
					component: commodityLibrary,
					name: "commodityLibrary",
					meta: {
						title: "商品库",
					},
				},
				{
					path: "addProduct",
					component: addProduct,
					name: "addProduct",
					meta: {
						title: "新增/修改商品",
					},
					hidden: true,
				},
				{
					path: "subjectManagement",
					component: subjectManagement,
					name: "subjectManagement",
					meta: {
						title: "主题管理",
					},
				},
			],
		},
		{
			path: "systemManagement",
			name: "systemManagement",
			component: customIndex,
			meta: {
				title: "设计系统管理",
			},
			children: [
				{
					path: "interfaceManagement",
					name: "interfaceManagement",
					component: interfaceManagement,
					meta: {
						title: "界面管理",
					},
					redirect: {
						name: "bCManagement",
					},
					children: [
						{
							path: "bCManagement",
							name: "bCManagement",
							component: bCManagement,
							hidden: true,
							meta: {
								title: "基础类目管理",
							},
						},
						{
							path: "cConfiguration",
							name: "cConfiguration",
							component: cConfiguration,
							hidden: true,
							meta: {
								title: "类目外形管理",
							},
						},
						{
							path: "cTConfiguration",
							name: "cTConfiguration",
							component: cTConfiguration,
							hidden: true,
							meta: {
								title: "类目模板配置",
							},
						},
						{
							path: "pFManagement",
							name: "pFManagement",
							component: pFManagement,
							hidden: true,
							meta: {
								title: "平台功能管理",
							},
						},
					],
				},
				{
					path: "templateManagement",
					name: "templateManagement",
					component: templateManagement,
					meta: {
						title: "模板管理",
					},
					redirect: {
						name: "tagLibrary",
					},
					children: [
						{
							path: "tagLibrary",
							name: "tagLibrary",
							component: tagLibrary,
							hidden: true,
							meta: {
								title: "标签库",
							},
						},
						{
							path: "templateLibrary",
							name: "templateLibrary",
							component: templateLibrary,
							hidden: true,
							meta: {
								title: "模板库",
							},
						},
						{
							path: "categoryLibrary",
							name: "categoryLibrary",
							component: categoryLibrary,
							hidden: true,
							meta: {
								title: "类目库",
							},
						},
					],
				},
				{
					path: "colorChart",
					name: "colorChart",
					component: colorChart,
					meta: {
						title: "色卡管理",
					},
					redirect: {
						name: "electroplatedColor",
					},
					children: [
						{
							path: "ptColor",
							name: "ptColor",
							component: ptColor,
							hidden: true,
							meta: {
								title: "潘通色卡",
							},
						},
						{
							path: "electroplatedColor",
							name: "electroplatedColor",
							component: electroplatedColor,
							hidden: true,
							meta: {
								title: "金属产品电镀色卡",
							},
						},
					],
				},
			],
		},
		{
			path: "VisualManagement",
			name: "VisualManagement",
			component: customIndex,
			meta: {
				title: "视图资料库管理",
			},
			children: [
				{
					path: "picStore",
					component: picStore,
					name: "picStore",
					meta: {
						title: "共享资源库",
					},
				},
				{
					path: "pictureResourceLibrary",
					component: pictureResourceLibrary,
					name: "pictureResourceLibrary",
					meta: {
						title: "图片资源库",
					},
				},
			],
		},
		{
			path: "freebieManagement",
			name: "freebieManagement",
			component: customIndex,
			meta: {
				title: "赠品资料管理",
			},
			children: [
				{
					path: "freebie",
					component: freebie,
					name: "freebie",
					meta: {
						title: "赠品管理",
					},
				},
			],
		},
	],
};
