import router from "@/router";
import store from "@/store";
import Vue from 'vue'
import {
  deepClone
} from "@/utils/utils";
import {
  Sortable
} from 'sortablejs';
// Sortable.mount(new MultiDrag());

export const permission = {
  inserted(el, binding) {
    let permission = {
      name: binding.value,
      list: router.currentRoute.meta.authBtnList
    };
    if (store.getters.isAdmin) {
      return;
    }
    if (!permission.list.includes(permission.name)) {
      el.parentNode && el.parentNode.removeChild(el);
    }
  }
};

export const horizontalDragable = {
  bind(el, binding) {
    let startX = 0; // el的scroll横向初始位置
    let gapX = 0; // 鼠标点击时的横向初始位置
    let startY = 0; // el的scroll纵向向初始位置
    let gapY = 0; // 鼠标点击时的纵向初始位置
    el.addEventListener("mousedown", start);
    el.addEventListener("mouseleave", stop); // 移除时解除事件

    function start(event) {
      // 判断是否点击鼠标左键
      if (event.button == 0) {
        gapX = event.clientX;
        gapY = event.clientY;
        startX = el.scrollLeft;
        startY = el.scrollTop;
        el.addEventListener("mousemove", move); // document
        el.addEventListener("mouseup", stop);
      }
      // event.preventDefault(); // 阻止默认事件或冒泡 如拖拽时选中文本
      return false;
    }
    function move(event) {
      // 移动时的坐标 - 鼠标左键点击时的坐标 = 鼠标移动的相对距离
      var left = event.clientX - gapX;
      var top = event.clientY - gapY;
      // 滚动条初始坐标 - 移动的相对距离 = 应该滚动后的坐标
      el.scrollTo(startX - left, startY - top); // 横向 纵向
      return false;
    }
    function stop() {
      // 鼠标松开，解除绑定
      el.removeEventListener("mousemove", move, false);
      el.removeEventListener("mouseup", stop, false);
    }
  }
};

export const permissionEdit = {
  inserted(el, binding) {
    let permission = {
      name: binding.value,
      list: router.currentRoute.meta.authBtnList
    };
    if (store.getters.isAdmin) {
      return;
    }
    if (permission.list.includes(permission.name)) {
      el.style.pointerEvents = "none";
      el.setAttribute("disabled", "disabled");
    }
  }
};

export const permissionEdit2 = {
  inserted(el, binding) {
    let permission = {
      name: binding.value,
      list: router.currentRoute.meta.authBtnList
    };
    if (store.getters.isAdmin) {
      return;
    }
    if (!permission.list.includes(permission.name)) {
      el.style.pointerEvents = "none";
      el.setAttribute("disabled", "disabled");
    }
  }
};

export const loadmore = {
  inserted: (el, bind, vnode) => {
    let distance = el.getAttribute("infinite-scroll-distance") || 100;
    el.addEventListener("scroll", function () {
      let disabled = el.getAttribute("infinite-scroll-disabled");
      if (disabled) {
        return;
      }
      if (el.scrollTop + el.clientHeight > el.scrollHeight - distance) {
        if(typeof bind.value === 'function'){
          bind.value();
        }else{
          bind.value.value(bind.value.arg);
        }
      }
    });
  }
}

export const throttle = {
  bind: (el, binding) => {
    let throttleTime = binding.value;
    if (!throttleTime) {
      throttleTime = 1000;
    }
    let cbFun;
    el.addEventListener(
      "click",
      (event) => {
        if (!cbFun) {
          // 第一次执行
          cbFun = setTimeout(() => {
            cbFun = null;
          }, throttleTime);
        } else {
          event && event.stopImmediatePropagation();
        }
      },
      true
    );
  },
}

export const dragUpload = {
  bind: (el, binding) => {
    el.ondrop = (e) => {
      e.stopPropagation();
      e.preventDefault();
      let files = e.dataTransfer.files;
      binding.value(files)
    };
    el.ondragenter = (e) => {
      console.log(e)
      e.stopPropagation();
      e.preventDefault();
    };
    el.ondragover = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };
  },
}

export const preventReClick =  {
  inserted: function (el, binding, vNode, oldVnode) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        el.style.backgroundColor = '#ccc'
        el.style.border = 'none'
        setTimeout(() => {
          el.disabled = false
          el.style.backgroundColor = '#0066CC'
          el.style.border = '1px solid #0066CC'
        }, 2000)
      }
    })
  }
}
export const preventReClick2 = {
  inserted: function (el, binding, vNode, oldVnode) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        el.style.backgroundColor = '#ccc'
        el.style.border = 'none'
        setTimeout(() => {
          el.disabled = false
          el.style.backgroundColor = '#e6f0fa'
          el.style.border = '1px solid #DCDFE6'
        }, 500)
      }
    })
  }
}
export const tableDrag = {
  bind(el, binding) {
    const {
      value,
      arg
    } = binding;
    if (!el) {
      return;
    }
    const {
      elementSeletor,
      onEnd,
      ...config
    } = arg || {};
    const bindEls = elementSeletor ? el.querySelector(elementSeletor) : el;
    el.sortable = Sortable.create(bindEls, {
      multiDrag: true,
      selectedClass: 'mselected',
      fallbackTolerance: 2,
      animation: 150,
      ghostClass: "blue-background-class",
      ...config,
      onEnd: (evt) => {
        let cloneValue = null;
        if (Array.isArray(value)) {
          const {
            oldIndex,
            newIndex
          } = evt;
          cloneValue = deepClone(value);
          value.splice(newIndex, 0, ...value.splice(oldIndex, 1));
          //  const currRow = value.splice(oldIndex, 1)[0]
          //  cloneValue.splice(newIndex, 0, currRow)
        }

        onEnd && onEnd(evt, cloneValue, value);
      },
      onMove(e) {
        return e.related.className.indexOf("filtered") === -1;
      }
    });
    el.tableData = value;
  },

  update(el, {
    value
  }) {
    el.tableData = value;
  },

  unbind: function (el) {
    el.sortable.destroy();
    el.sortable = null;
  }
};

export const partLoading = {
  bind(el, binding) {
    // 创建 loading 实例
    el.__loadingInstance__ = Vue.prototype.$loading({
      lock: true,
      background: 'rgba(255,255,255,1)',
      target: el
    });
  },
  update(el, binding) {
    if (binding.value !== binding.oldValue) {
      // 当绑定值为 true 时，创建 loading 实例
      if (binding.value) {
        if (!el.__loadingInstance__) {
          el.__loadingInstance__ = Vue.prototype.$loading({
            lock: true,
            background: 'rgba(255,255,255,1)',
            target: el
          });
        }
      } else {
        // 当绑定值为 false 时，关闭 loading 实例
        if (el.__loadingInstance__) {
          el.__loadingInstance__.close();
          el.__loadingInstance__ = null;
        }
      }
    }
  },
  unbind(el) {
    // 清理 loading 实例
    if (el.__loadingInstance__) {
      el.__loadingInstance__.close();
      el.__loadingInstance__ = null;
    }
  }
}
export const removeAriaHidden = {
	bind(el, binding) {
		let ariaEls = el.querySelectorAll('.el-radio__original');
		ariaEls.forEach((item) => {
			item.removeAttribute('aria-hidden');
		});
	}
}
