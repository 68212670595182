import koLocale from 'element-ui/lib/locale/lang/en'
export default {
	Invoice: {
		PleaseAttention:'주의',
		MoldSetupFee:'몰드 / 설치비',

		invoice:"송장",
		PONumber:"PO 번호",
		reference:"참조",
		HSCODE:"HS 코드",
		PURCHASEINVOICE:"구매 송장",
		productPrintNo:"제품인쇄 아니요",
		BILLTO:"청구 대상",
		SHIPTO:"배송지",
		ProductDescription:"제품 설명",
		total:"총",
		PaymentStatus:"지불 상태",
		checkInfo:"정보 확인",
		bankInfo:"은행 정보",
		payOnline:"온라인 결제",
		checkPay:"확인:결제 후 아래 정보로 메일을 보내주세요.",
		attentionPlease:"기억하세요",
		directorOperations:"운영 이사",
		signature:"서명",
		nodesText:"은행을 통해 결제하는 경우에는 반드시 귀하의 이름을 포함해 주십시오.",
		nodesText2:"메모에.",
		thankbusiness:"귀하의 비즈니스에 감사드립니다!",
		paid:"유급의",
		unpaid:"미지급",
		payInstall:"부분결제, 잔액을 기다립니다.",
		remember:" 외국 고객이 우리 은행에 송금하는 데 사용하는 해외 계좌 및 외화 계좌의 경우 자금 사용은 '상품 대금 지급'이거나 공백으로 남겨두어야 합니다.",
		extraText:[
			"",
			"긴급 수수료",
			"실크스크린 인쇄 요금",
			"백 몰드 수수료",
			"인쇄된 요금",
			"아연 다이캐스팅 재료 비용",
			"추가 화물",
			"라이선스 제품 수수료",
			"수량"
		],
		TaxPrice:"세금 가격",
		discountedAmount:"할인금액",


		card:"1. 신용카드 : VISA / Mastercard / AMEX, Discover",
		bankNote:"참고: EUR 이외의 다른 통화로 결제하려면 고객 서비스 담당자에게 문의하세요.",
		checkFollow:"2. 수표 정보는 다음과 같습니다:",
		mention:"결제시 송장번호를 꼭 기재해주세요. 감사합니다!",
		topic:"주제: 금융 조언",
		invoiceDate:"송장 날짜",
		invoiceNumber:"송장 번호",
		excluding:"소계 제외",
		phone: "핸드폰",
		email: "이메일",
		from: "에서",
		to: "에게",
		attn: "Attn",
		lNVOICE: "송장",
		PROFORMA:"견적 송장",
		invoiceNo: "송장 번호",
		date: "날짜",
		paymentMethod: "지불 방법",
		terms:"자귀",
		title: "제목",
		no: "아니요",
		artwork: "삽화",
		item: "안건",
		description: "설명",
		qty: "수량",
		unitPrice: "단가",
		moldFee: "금형 수수료",
		extraFee: "추가 비용",
		amount: "양",
		subtotal: "소계",
		apoShippingFee: "아포 배송비",
		taxPrice: "세금 가격",
		couponAdditionalCosts:"추가 비용",
		firstOrderCodeDisc: "첫 주문 할인",
		promoCode: "프로모션 코드",
		specialCode:"특별 그룹 제안",
		custompensCode:"20개 펜에 $1",
		additionalCode:"$75 바우처에 $1",
		pointsDeduct: "포인트 차감",
		points:"포인트들",
		giftVouchers: "상품권",
		couponDiscount: "쿠폰 할인",
		orderDiscount: "주문 할인",
		extraDiscount: "추가 할인",
		finalPrice: "최종 가격",
		finalTotalPrice: "최종 총 가격",
		estimatedShipDate: "예상 배송 날짜",
		notes: "노트",
		shipTo: " 배송지",
		name: "이름",
		check: "확인하다",
		APOFPOAddress: "APO/FPO 주소",
		Address: "주소",
		GrandTotal: "총계",
		AmountPaid: "금액 지급",
		BalanceDue: "차감 부족액",
		UserLevelDiscount: "사용자 등급 할인"
	},
	Quotation: {
		free:"무료",
		company:"회사",
		off:"끄다",
		additionalCosts:"추가 비용",
		firstOrderDiscount:"첫 주문 할인",
		specialGroup:"특별 그룹 제안",
		pointsDeduct:"포인트 차감",
		giftVouchers:"상품권",
		couponDiscount:"쿠폰할인",
		goldVipMember:"골드 VIP 회원",
		extraDiscount:"추가 할인",
		userLevelDiscount:"사용자 등급 할인",
		points:"포인트들",
		title: "인용",
		to: "에게",
		from: "에서",
		name: "이름",
		email: "이메일",
		telephone: "전화",
		country: "국가",
		oid: "주문 아이디",
		date: "견적 날짜",
		valid: "까지 유효하다",
		contacts: "콘택트 렌즈",
		currency: "통화",
		no: "아니요.",
		item: "안건",
		artwork: "삽화",
		description: "설명",
		qty: "수량",
		unitPrice: "단가",
		moldFee: "금형 수수료",
		extraFee: "추가 비용",
		apoShippingFee: "아포 배송비",
		taxPrice: "세금 가격",
		amount: "양",
		remarks: "비고",
		total: "총",
		titleTerms: "약관:",
		first: "1. 미국/캐나다/유럽으로 무료 삽화 및 무료 배송.",
		second: "2. 상기 정보는 청구서가 아니며 위에서 설명한 서비스/상품의 견적일 뿐입니다.",
		third: "3. 생산은 귀하의 증빙 승인 및 지불을 받는 날짜에 시작됩니다. 승인된 증빙을 기반으로 주문에 대해 이 링크를 지불하십시오. (결제 링크:",
		end: "거래해주셔서 감사합니다!",
		tel: "전화",
		hours: "근무 시간",
		chat: "라이브 채팅",
		address: "미국 주소",
		hoursItem: "월요일 - 금요일 오전 8:30 - 오후 5:30 태평양 표준시(PT)",
		chatItem: "월요일부터 금요일까지 24시간",
		addressItem: "20829 밸리 블러바드 월넛, CA 91789"
	},
	member:{
		member1:"실버회원",
		member4:"골드 VIP 회원",
		member5:"플래티넘 VIP 회원",
	},
	...koLocale
}
