import hiLocale from 'element-ui/lib/locale/lang/en'
export default {
	Invoice: {
		PleaseAttention:'कृपया ध्यान',
		MoldSetupFee:'मोल्ड/सेटअप फी',

		invoice:"चालान",
		PONumber:"पीओ नंबर",
		reference:"संदर्भ",
		HSCODE:"एचएस कोड",
		PURCHASEINVOICE:"खरीद चालान",
		productPrintNo:"productPrint नंबर",
		BILLTO:"बिल प्राप्तकर्ता",
		SHIPTO:"यहां भेजें",
		ProductDescription:"उत्पाद वर्णन",
		total:"कुल",
		PaymentStatus:"भुगतान की स्थिति",
		checkInfo:"जानकारी जांचें",
		bankInfo:"बैंक जानकारी",
		payOnline:"ऑनलाइन भुगतान करें",
		checkPay:"जाँचें: भुगतान करें और नीचे दी गई जानकारी पर मेल करें",
		attentionPlease:"याद रखें",
		directorOperations:"संचालन निदेशक",
		signature:"हस्ताक्षर",
		nodesText:"यदि आप बैंक के माध्यम से भुगतान कर रहे हैं, तो कृपया अपना विवरण अवश्य शामिल करें।",
		nodesText2:"ज्ञापन में कहा गया है।",
		thankbusiness:"आपके व्यवसाय के लिए धन्यवाद!",
		paid:"चुकाया गया",
		unpaid:"अवैतनिक",
		payInstall:"आंशिक भुगतान, शेष राशि की प्रतीक्षा करें।",
		remember:" अपतटीय खाते और विदेशी मुद्रा खाते के लिए, जिसका उपयोग विदेशी ग्राहकों द्वारा हमारे बैंक में धन भेजने के लिए किया जाता है, धन का उपयोग 'माल के लिए भुगतान' होना चाहिए, या इसे खाली छोड़ दें।",
		extraText:[
			"",
			"रश शुल्क",
			"सिल्कस्क्रीन मुद्रित शुल्क",
			"बैक मोल्ड शुल्क",
			"मुद्रित शुल्क",
			"जिंक डाई कास्टिंग सामग्री की लागत",
			"अतिरिक्त भाड़ा",
			"लाइसेंस प्राप्त उत्पाद शुल्क",
			"मात्रा"
		],
		TaxPrice:"कर मूल्य",
		discountedAmount:"छूट राशि",

		card:"1. क्रेडिट कार्ड: वीज़ा/मास्टरकार्ड/एएमईएक्स, और डिस्कवर",
		bankNote:"नोट: यदि आप EUR के अलावा किसी अन्य मुद्रा में भुगतान करना चाहते हैं, तो कृपया हमारे ग्राहक सेवा प्रतिनिधि से संपर्क करें।",
		checkFollow:"2. कृपया हमारी चेक जानकारी इस प्रकार प्राप्त करें:",
		mention:"जब आप भुगतान करें तो कृपया चालान संख्या का उल्लेख करें। धन्यवाद!",
		topic:"विषय: वित्त सलाह",
		invoiceDate:"चालान की तारीख",
		invoiceNumber:"बीजक संख्या",
		excluding:"उप योग को छोड़कर",
		phone: "फ़ोन",
		email: "ईमेल",
		from: "से",
		to: "को",
		attn: "ध्यान दें",
		lNVOICE: "चालान",
		PROFORMA:"प्रोफार्मा चालान",
		invoiceNo: "चालान नंबर",
		date: "तारीख",
		paymentMethod: "भुगतान विधि",
		terms:"शर्तें",
		title: "शीर्षक",
		no: "नहीं",
		artwork: "कलाकृति",
		item: "वस्तु",
		description: "विवरण",
		qty: "मात्रा",
		unitPrice: "यूनिट मूल्य",
		moldFee: "मोल्ड शुल्क",
		extraFee: "अतिरिक्त शुल्क",
		amount: "मात्रा",
		subtotal: "उप-योग",
		apoShippingFee: "एपीओ शिपिंग शुल्क",
		taxPrice: "कर मूल्य",
		couponAdditionalCosts:"अतिरिक्त दाम",
		firstOrderCodeDisc: "प्रथम ऑर्डर कोड डिस्क",
		promoCode: "प्रचार कोड",
		specialCode:"विशेष समूह ऑफर",
		custompensCode:"20 पीस पेन के लिए $1",
		additionalCode:"$75 वाउचर के लिए $1",
		pointsDeduct: "अंक काटे गए",
		points:"अंक",
		giftVouchers: "गिफ्ट वाउचर",
		couponDiscount: "कूपन छूट",
		orderDiscount: "छूट का आदेश करें",
		extraDiscount: "अतिरिक्त छूट",
		finalPrice: "अंतिम कीमत",
		finalTotalPrice: "पहले ऑर्डर पर छूट",
		estimatedShipDate: "जहाज की अनुमानित तारीख",
		notes: "टिप्पणियाँ",
		shipTo: " यहां भेजें",
		name: "नाम",
		check: "जाँच करना",
		APOFPOAddress: "एपीओ/एफपीओ पता",
		Address: "पता",
		GrandTotal: "कुल योग",
		AmountPaid: "राशि का भुगतान",
		BalanceDue: "शेष राशि",
		UserLevelDiscount: "उपयोगकर्ता स्तर पर छूट"
	},
	Quotation: {
		free:"मुक्त",
		company:"कंपनी",
		off:"बंद",
		additionalCosts:"अतिरिक्त लागत",
		firstOrderDiscount:"पहले ऑर्डर पर छूट",
		specialGroup:"विशेष समूह ऑफर",
		pointsDeduct:"अंक काटे गए",
		giftVouchers:"गिफ्ट वाउचर",
		couponDiscount:"कूपन छूट",
		goldVipMember:"गोल्ड वीआईपी सदस्य",
		extraDiscount:"अतिरिक्त छूट",
		userLevelDiscount:"उपयोगकर्ता स्तर पर छूट",
		points:"अंक",
		title: "उद्धरण",
		to: "को",
		from: "से",
		name: "नाम",
		email: "ईमेल",
		telephone: "टेलीफ़ोन",
		country: "देश",
		oid: "आदेश कामतत्व",
		date: "बोली की तारीख",
		valid: "तब तक वैध",
		contacts: "संपर्क",
		currency: "मुद्रा",
		no: "नहीं।",
		item: "वस्तु",
		artwork: "कलाकृति",
		description: "विवरण",
		qty: "मात्रा",
		unitPrice: "यूनिट मूल्य",
		moldFee: "मोल्ड शुल्क",
		extraFee: "अतिरिक्त शुल्क",
		apoShippingFee: "एपीओ शिपिंग शुल्क",
		taxPrice: "कर मूल्य",
		amount: "मात्रा",
		remarks: "टिप्पणियां",
		total: "कुल",
		titleTerms: "नियम एवं शर्तें:",
		first: "1. मुफ़्त कलाकृति और यूएसए/कनाडा/यूरोप में मुफ़्त शिपिंग।",
		second: "2. उपरोक्त जानकारी कोई चालान नहीं है और केवल ऊपर वर्णित सेवाओं/वस्तुओं का अनुमान है।",
		third: "3.उत्पादन उस तारीख से शुरू होगा जब हमें आपका प्रमाण अनुमोदन और भुगतान प्राप्त होगा, कृपया अनुमोदित प्रमाण के आधार पर अपने आदेश के लिए इस लिंक पर भुगतान करें। (भुगतान लिंक:",
		end: "आपके व्यवसाय के लिए धन्यवाद!",
		tel: "टेलीफोन",
		hours: "कार्यालय अवधि",
		chat: "सीधी बातचीत",
		address: "यूएसए पता",
		hoursItem: "सोमवार-शुक्रवार सुबह 8:30 बजे से शाम 5:30 बजे तक प्रशांत समय (पीटी)",
		chatItem: "सोमवार से शुक्रवार 24 घंटे",
		addressItem: "20829 वैली ब्लव्ड। अखरोट, सीए 91789"
	},
	member:{
		member1:"रजत सदस्य",
		member4:"गोल्ड वीआईपी सदस्य",
		member5:"प्लैटिनम वीआईपी सदस्य",
	},
	...hiLocale
}
