/**
 * 同域名下标签页通信
 */
class TabCommunication {
    constructor(channelName) {
        this.channel = new BroadcastChannel(channelName);
    }

    sendMessage(message) {
        this.channel.postMessage(message);
    }

    onMessageReceived(callback) {
        this.channel.onmessage = function(event) {
            let message = event.data;
            callback(message);
        }
    }
}

export default TabCommunication