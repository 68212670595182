import Vue from "vue";
Vue.directive("clickoutside", {
  bind(el, binding) {
    function documentHandler(e) {
      if (el.contains(e.target)) {
        return false;
      }
      if (binding.expression) {
        binding.value(e);
      }
    }
    el.__vueClickOutside__ = documentHandler;
    document.addEventListener("click", documentHandler);
  },
  unbind(el) {
    document.removeEventListener("click", el.__vueClickOutside__); // 不要用click，解决拖动点旁边问题
    delete el.__vueClickOutside__;
  },
});
