import layout from "@/views/layout/index.vue";
const customsDeclarationList = () => import("@/views/shipmentsManagementCenter/customsDeclarationList.vue");
const deliveryParameter = () => import("@/views/shipmentsManagementCenter/deliveryParameter.vue");
const shippingList = () => import("@/views/shipmentsManagementCenter/shippingList.vue");
const importCountry = () => import("@/views/shipmentsManagementCenter/ImporterBinding/importCountry.vue");
const ImporterWebsite = () => import("@/views/shipmentsManagementCenter/ImporterBinding/ImporterWebsite.vue");
const shipmentStatus = () => import("@/views/shipmentsManagementCenter/shipmentStatus.vue");
const customIndex = () => import("@/views/index.vue")
export default 	{
    path: "/shipmentsManagementCenter",
    name: "shipmentsManagementCenter",
    component: layout,
    meta: {
        title: "发货管理中心",
        icon: "el-icon-truck"
    },
    children: [{
        path: "shippingList",
        component: shippingList,
        name: "shippingList",
        meta: {
            title: "发货单列表"
        }
    },
        {
            path: "customsDeclarationList",
            component: customsDeclarationList,
            name: "customsDeclarationList",
            meta: {
                title: "报关单列表"
            }
        },
        {
            path: "deliveryParameter",
            component: deliveryParameter,
            name: "deliveryParameter",
            meta: {
                title: "发货参数管理"
            }
        },
        {
            path: "ImporterBinding",
            component: customIndex,
            name: "ImporterBinding",
            meta: {
                title: "进口商绑定"
            },
            children: [{
                path: "importCountry",
                component: importCountry,
                name: "importCountry",
                meta: {
                    title: "国家"
                }
            },
                {
                    path: "ImporterWebsite",
                    component: ImporterWebsite,
                    name: "ImporterWebsite",
                    meta: {
                        title: "网站"
                    }
                }
            ]
        },
        {
            path: "shipmentStatus",
            component: shipmentStatus,
            name: "shipmentStatus",
            meta: {
                title: "Shipment Status"
            }
        },
    ]
}
