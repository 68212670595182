import layout from "@/views/layout/index.vue";
const PaymentCenter = () => import("@/views/payment/PaymentCenter.vue");
const central = () => import("@/views/centralManage/index.vue");
export default {
    path: "/payment",
    name: "payment",
    component: layout,
    meta: {
        title: "支付中心",
        icon: "el-icon-s-finance"
    },
    children: [{
        path: "PaymentCenter",
        component: PaymentCenter,
        name: "PaymentCenter",
        meta: {
            title: "支付中心管理"
        }
    }]
}
