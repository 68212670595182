<template>
  <div class="wrap">
    <div class="tab_menu">
      <div class="menu_item" v-for="(item, index) in tabOptions" :key="item.value"
        :class="{ 'z_active': index == active }" @click="tab_choose(index)">{{ item.label }}</div>
    </div>
    <template v-if="active == 0">
      <classification></classification>
    </template>
    <template v-if="active == 1">
      <templateManage></templateManage>
    </template>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabOptions: [
        { label: '1.分类管理', value: 0 },
        { label: '2.模板管理', value: 1 },
        // { label: '3.模板统计', value: 3 },
      ],
      active: 0
    };
  },
  created(){
    this.active = sessionStorage.getItem('templateData') ? 1 : 0
  },
  components: {
    classification: () => import('../components/classification.vue'),
    messageBox: () => import('../components/messageBox.vue'),
    templateManage: () => import('../components/templateManage.vue')
  },
  methods: {
    tab_choose(no) {
      this.active = no
    },
  }
};
</script>

<style scoped lang="scss">
.tab_menu {
  display: flex;
  padding-top: 12px;
  padding-left: 40px;
  background: #efefef;
  .menu_item {
    width: 125px;
    height: 44px;
    text-align: center;
    line-height: 44px;
    background: #dcdcdc;
    margin-right: 20px;
    border: 2px solid transparent;
    transition: all 0.4s;
    cursor: pointer;
    // &:hover {
    //   background: #e3e8f0;
    // }
  }

  // .menu_item:hover {
  .z_active {
    background: #fff;
    border: 2px solid #0089f5;
    color: #0089f5;
    border-bottom-color: transparent;
    transform: scale(1.1, 1);
  }
}
</style>