import layout from "@/views/layout/index.vue";
const customIndex = () => import("@/views/index.vue");
const dmsPage = () => import("@/views/dmsManage/dmsPage.vue");
const valuePackage = () => import("@/views/dmsManage/valuePackage.vue");
const extraValuePackage = () => import("@/views/dmsManage/extraValuePackage.vue");
const otoPermission = () => import("@/views/dmsManage/otoPermission.vue");
const otoRole = () => import("@/views/dmsManage/otoRole.vue");
const productIdeas = () => import("@/views/dmsManage/productIdeas.vue");
const newsCenter = () => import("@/views/dmsManage/newsCenter.vue");
export default {
    path: "/dmsManage",
    name: "dmsManage",
    component: layout,
    meta: {
        title: "经销商管理",
        icon: "el-icon-s-shop"
    },
    children: [
        {
            path: "dmsPage",
            name: "dmsPage",
            component: dmsPage,
            meta: {
                title: "oto页面管理"
            }
        },
        {
            path: "valuePackage",
            name: "valuePackage",
            component: valuePackage,
            meta: {
                title: "套餐管理"
            }
        },
        {
            path: "extraValuePackage",
            name: "extraValuePackage",
            component: extraValuePackage,
            meta: {
                title: "额外套餐管理"
            }
        },

        {
            path: "otoPermission",
            name: "otoPermission",
            component: otoPermission,
            meta: {
                title: "经销商权限管理"
            }
        },
        {
            path: "otoRole",
            name: "otoRole",
            component: otoRole,
            meta: {
                title: "经销商角色管理"
            }
        },
        {
            path: "productIdeas",
            name: "productIdeas",
            component: productIdeas,
            meta: {
                title: "产品意见管理"
            }
        },
        {
            path: "newsCenter",
            name: "newsCenter",
            component: newsCenter,
            meta: {
                title: "新闻内容管理"
            }
        }
    ]
}