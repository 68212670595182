<template>
	<div class="topBar">
		<strong class="title">{{title}}</strong>
		<div class="close-icon" @click="close">
			<b class="icon-a-icon-qxzhuanhuan"></b>
		</div>
		<div class="back-icon" @click="back" v-if="showBackIcon">
			<b class="icon-back"></b>
		</div>
	</div>
</template>

<script>
export default {
	props:['showBackIcon','title'],
	data() {
		return {
		};
	},

	methods: {
		close(){
			this.$emit('close')
		},
		back(){
			this.$emit('back')
		}
	},
};
</script>

<style scoped lang="scss">
.topBar {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	font-size: 18px;
	height: 50px;
	border-bottom: 1px solid #ccc;

	.close-icon,
	.back-icon {
		position: absolute;
		top: 50%;
		right: 10px;
		transform: translateY(-50%);
		cursor: pointer;

		&::before {
			content: "";
			position: absolute;
			top: -10px;
			right: -10px;
			bottom: -10px;
			left: -10px;
		}

		b {
			cursor: pointer;
		}
	}

	.back-icon {
		right: auto;
		left: 10px;
	}
}
</style>
