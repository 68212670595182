import {getToken} from "@/utils/auth";
import Vue from "vue";

let token = getToken();
export const connectWs = () => {
    try {
        if (token) {
            const ws = new WebSocket(process.env.VUE_APP_WS_URL + '?token=' + token);
            ws.onopen = () => {
                console.log('WebSocket connection established');
            }
            ws.onmessage = (response) => {
                console.log('Received message from server:', response);
                let data = JSON.parse(response.data)
                if (data.type === 1) {
                    Vue.prototype.$notify({
                        dangerouslyUseHTMLString:true,
                        title: data.message.announcementTitle,
                        message: `<div><div>${data.message.announcementContent}</div><a href="javascript:location.reload();">刷新页面</a></div>`,
                        duration: 0,
                        type: data.message.messageType
                    });
                }
            }
        }
    }catch (e) {

    }
}