//模切
const shadeScale = 0.9;
export function dieCut(workspace, scale) {
	let w = workspace.width;
	let wrap, xian, wrap_w, wrap_h, xian_w, xian_h
	if (scale >= 1) {
		wrap_w = w * shadeScale;
		wrap_h = w * shadeScale / scale;
		xian_w = w * shadeScale - 20;
		xian_h = w * shadeScale / scale - 20;
	} else {
		wrap_w = w * shadeScale * scale;
		wrap_h = w * shadeScale;
		xian_w = w * shadeScale * scale - 20;
		xian_h = w * shadeScale - 20;
	}

	wrap = new fabric.Rect({
		width: wrap_w,
		height: wrap_h,
		fill: '#fff',
		shadow: 'rgba(0,0,0,0.3) 0px 0px 10px'
	})

	xian = new fabric.Rect({
		left: 10,
		top: 10,
		width: xian_w,
		height: xian_h,
		fill: 'transparent',
		stroke: '#ccc',
		strokeDashArray: [10],
		strokeWidth: 2,
		strokeUniform: true
	})

	let groupArr = [wrap]
	let group = new fabric.Group(groupArr, {
		id: "stickerShape",
		perPixelTargetFind: true,
		selectable: false,
		evented: false,
		isShade: true,
		excludeFromExport: true //不导出到序列化
	})
	return {
		group
	}
}

export function ellipseTeXiao(workspace, scale) {
	let w = workspace.width;
	let wrap, xian, wrap_rx, wrap_ry, xian_rx, xian_ry
	if (scale >= 1) {
		wrap_rx = w * shadeScale / 2
		wrap_ry = w * shadeScale / 2 / scale
		xian_rx = (w * shadeScale - 20) / 2
		xian_ry = (w * shadeScale - 20) / 2 / scale
	} else {
		wrap_rx = (w * shadeScale / 2) * scale
		wrap_ry = w * shadeScale / 2
		xian_rx = (w * shadeScale - 20) / 2 * scale
		xian_ry = (w * shadeScale - 20) / 2
	}

	wrap = new fabric.Ellipse({
		rx: wrap_rx,
		ry: wrap_ry,
		fill: '#fff',
		shadow: 'rgba(0,0,0,0.3) 0px 0px 10px'
	})

	xian = new fabric.Ellipse({
		left: (wrap_rx - xian_rx),
		top: (wrap_ry - xian_ry),
		rx: xian_rx,
		ry: xian_ry,
		fill: 'transparent',
		stroke: '#ccc',
		strokeDashArray: [10],
		strokeWidth: 2,
		strokeUniform: true
	})
	let groupArr = [wrap, xian]
	let group = new fabric.Group(groupArr, {
		id: "stickerShape",
		perPixelTargetFind: true,
		selectable: false,
		evented: false,
		isShade: true,
		excludeFromExport: true //不导出到序列化
	})
	return {
		group
	}
}

//矩形
export function rectTeXiao(workspace, scale) {
	let w = workspace.width;
	let wrap, xian, wrap_w, wrap_h, xian_w, xian_h
	if (scale >= 1) {
		wrap_w = w * shadeScale;
		wrap_h = w * shadeScale / scale;
		xian_w = w * shadeScale - 20;
		xian_h = w * shadeScale / scale - 20;
	} else {
		wrap_w = w * shadeScale * scale;
		wrap_h = w * shadeScale;
		xian_w = w * shadeScale * scale - 20;
		xian_h = w * shadeScale - 20;
	}

	wrap = new fabric.Rect({
		width: wrap_w,
		height: wrap_h,
		fill: '#fff',
		shadow: 'rgba(0,0,0,0.3) 0px 0px 10px'
	})

	xian = new fabric.Rect({
		left: (wrap_w - xian_w)/2,
		top: (wrap_h - xian_h)/2,
		width: xian_w,
		height: xian_h,
		fill: 'transparent',
		stroke: '#ccc',
		strokeDashArray: [10],
		strokeWidth: 2,
		strokeUniform: true
	})

	let groupArr = [wrap, xian]
	let group = new fabric.Group(groupArr, {
		id: "stickerShape",
		perPixelTargetFind: true,
		selectable: false,
		evented: false,
		isShade: true,
		excludeFromExport: true //不导出到序列化
	})
	return {
		group
	}
}

//圆角矩形
export function roundedRectTeXiao(workspace, scale) {
	let w = workspace.width;
	let wrap,
		xian,
		wrap_w,
		wrap_h,
		xian_w,
		xian_h,
		r = w / 10
	if (scale >= 1) {
		wrap_w = w * shadeScale;
		wrap_h = w * shadeScale / scale;
		xian_w = w * shadeScale - 20;
		xian_h = w * shadeScale / scale - 20;
	} else {
		wrap_w = w * shadeScale * scale;
		wrap_h = w * shadeScale;
		xian_w = w * shadeScale * scale - 20;
		xian_h = w * shadeScale - 20;
	}

	wrap = new fabric.Rect({
		width: wrap_w,
		height: wrap_h,
		fill: '#fff',
		rx: r, //圆角半径
		ry: r, //圆角半径
		shadow: 'rgba(0,0,0,0.3) 0px 0px 10px'
	})

	xian = new fabric.Rect({
		left: (wrap_w - xian_w)/2,
		top: (wrap_h - xian_h)/2,
		width: xian_w,
		height: xian_h,
		fill: 'transparent',
		stroke: '#ccc',
		strokeDashArray: [10],
		strokeWidth: 2,
		strokeUniform: true,
		rx: r, //圆角半径
		ry: r //圆角半径
	})

	let groupArr = [wrap, xian]
	let group = new fabric.Group(groupArr, {
		id: "stickerShape",
		perPixelTargetFind: true,
		selectable: false,
		evented: false,
		isShade: true,
		excludeFromExport: true //不导出到序列化
	})
	return {
		group
	}
}
