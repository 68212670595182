import jaLocale from 'element-ui/lib/locale/lang/en'
export default {
	Invoice: {
		PleaseAttention:'注意してください',
		MoldSetupFee:'金型/取付費',

		invoice:"請求書",
		PONumber:"発注番号",
		reference:"参照",
		HSCODE:"HSコード",
		PURCHASEINVOICE:"購入請求書",
		productPrintNo:"製品印刷番号",
		BILLTO:"請求先",
		SHIPTO:"送り先",
		ProductDescription:"製品説明",
		total:"合計",
		PaymentStatus:"支払い状況",
		checkInfo:"情報を確認する",
		bankInfo:"銀行情報",
		payOnline:"オンラインで支払う",
		checkPay:"小切手: 下記の情報に支払い、郵送してください",
		attentionPlease:"注意",
		directorOperations:"オペレーションディレクター",
		signature:"サイン",
		nodesText:"銀行でお支払いの場合は、必ず",
		nodesText2:"メモに記載されています。",
		thankbusiness:"お買い上げくださってありがとうございます！",
		paid:"有料",
		unpaid:"未払い",
		payInstall:"一部支払い、残額をお待ちください。",
		remember:" 海外の顧客が当行に送金するために使用するオフショア口座および外貨口座の場合、資金の用途は「商品の支払い」にするか、空白のままにしてください。",
		extraText:[
			"",
			"急ぎ料金",
			"シルクスクリーン印刷料金",
			"バックモールド料金",
			"印刷された料金",
			"亜鉛ダイカスト材料コスト",
			"追加運賃",
			"ライセンス製品料金",
			"数量"
		],
		TaxPrice:"税抜価格",
		discountedAmount:"割引額",

		card:"1. クレジットカード：VISA / Mastercard / AMEX、Discover",
		bankNote:"注: ユーロ以外の別の通貨で支払いたい場合は、当社のカスタマー サービス担当者にご連絡ください。",
		checkFollow:"2. 当社の小切手情報は次のとおりです。",
		mention:"お支払いの際は、請求書番号をお知らせください。ありがとう！",
		topic:"トピック: 財務上のアドバイス",
		invoiceDate:"請求書の日付",
		invoiceNumber:"請求書番号",
		excluding:"除く小計",
		phone: "電話",
		email: "Eメール",
		from: "から",
		to: "に",
		attn: "注意",
		lNVOICE: "請求書",
		PROFORMA:"見積請求書",
		invoiceNo: "請求書番号",
		date: "日にち",
		paymentMethod: "支払方法",
		terms:"条項",
		title: "タイトル",
		no: "いいえ",
		artwork: "アートワーク",
		item: "アイテム",
		description: "説明",
		qty: "数量",
		unitPrice: "単価",
		moldFee: "型代",
		extraFee: "追加料金",
		amount: "額",
		subtotal: "小計",
		apoShippingFee: "アポ配送料",
		taxPrice: "税額",
		couponAdditionalCosts:"追加費用",
		firstOrderCodeDisc: "初回注文割引",
		promoCode: "プロモーションコード",
		specialCode:"特別グループオファー",
		custompensCode:"ペン20本で1ドル",
		additionalCode:"1 ドルで 75 ドルのバウチャー",
		pointsDeduct: "減点",
		points:"ポイント",
		giftVouchers: "ギフト券",
		couponDiscount: "クーポン割引",
		orderDiscount: "注文割引",
		extraDiscount: "追加割引",
		finalPrice: "最終価格",
		finalTotalPrice: "最終的な合計価格",
		estimatedShipDate: "発送予定日",
		notes: "ノート",
		shipTo: " 送り先",
		name: "名前",
		check: "チェック",
		APOFPOAddress: "APO/FPOアドレス",
		Address: "住所",
		GrandTotal: "総計",
		AmountPaid: "払込金額",
		BalanceDue: "未払い残高",
		UserLevelDiscount: "ユーザーレベル割引"
	},
	Quotation: {
		free:"無料",
		company:"会社",
		off:"オフ",
		additionalCosts:"追加費用",
		firstOrderDiscount:"初回注文割引",
		specialGroup:"特別グループオファー",
		pointsDeduct:"減点",
		giftVouchers:"ギフト券",
		couponDiscount:"クーポン割引",
		goldVipMember:"ゴールドVIP会員",
		extraDiscount:"追加割引",
		userLevelDiscount:"ユーザーレベル割引",
		points:"ポイント",
		title: "引用",
		to: "に",
		from: "から",
		name: "名前",
		email: "Eメール",
		telephone: "電話",
		country: "国",
		oid: "注文ID",
		date: "見積日",
		valid: "まで有効",
		contacts: "連絡先",
		currency: "通貨",
		no: "いいえ。",
		item: "アイテム",
		artwork: "アートワーク",
		description: "説明",
		qty: "量",
		unitPrice: "単価",
		moldFee: "型代",
		extraFee: "追加料金",
		apoShippingFee: "アポ配送料",
		taxPrice: "税額",
		amount: "額",
		remarks: "備考",
		total: "合計",
		titleTerms: "利用規約:",
		first: "1. 無料のアートワークと米国/カナダ/ヨーロッパへの送料無料。",
		second: "2. 上記の情報は請求書ではなく、上記のサービス/商品の見積もりにすぎません。",
		third: "3. 生産は、校正刷りの承認と支払いを受け取った日に開始されます。承認された校正刷りに基づいて、このリンクから注文の支払いを行ってください。 (支払いリンク:",
		end: "お買い上げくださってありがとうございます！",
		tel: "電話番号",
		hours: "営業時間",
		chat: "ライブチャット",
		address: "米国住所",
		hoursItem: "月曜～金曜 午前 8 時 30 分～午後 5 時 30 分 (太平洋時間 (PT))",
		chatItem: "月曜から金曜まで24時間",
		addressItem: "20829 Valley Blvd.ウォルナット、カリフォルニア州 91789"
	},
	member:{
		member1:"シルバー会員",
		member4:"ゴールドVIP会員",
		member5:"プラチナVIP会員",
	},
	...jaLocale
}
