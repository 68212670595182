<template>
    <el-container class="pageWrap">
      <div style="height: 100%;position: relative">
        <el-aside class="left-aside" :class="{isCollapse:isCollapse}">
          <Sidebar></Sidebar>
        </el-aside>
        <div class="fold-icon" @click="changeNav">
          <i class="el-icon-caret-left" :class="{arrow: stopNavHover}"></i>
        </div>
      </div>

        <el-container>
            <el-header height="auto">
                <Navbar></Navbar>
<!--                <TagsView></TagsView>-->
            </el-header>
            <div class="loadingBox" :class="{isCollapse:isCollapse}" v-if="$store.state.globalLoading && !$store.state.design.designDialog" v-loading="$store.state.globalLoading"></div>
            <el-main class="contentWrap">
                <AppMain></AppMain>
            </el-main>
        </el-container>
        <div class="uploadList" v-show="imgProgress.showProgress && imgProgress.uploadList.length>0">
          <div class="upload-item" v-for="item in imgProgress.uploadList">
            <div class="name">
              {{item.name}}
            </div>
            <div style="flex:1;">
              <el-progress :percentage="item.percent"></el-progress>
            </div>
          </div>
        </div>
    </el-container>
</template>

<script>
import {Sidebar, Navbar, AppMain, TagsView} from './components'

export default {
    name: "Layout",
    components: {
        Sidebar,
        Navbar,
        AppMain,
        TagsView
    },
  computed:{
    navBar(){
      return this.$store.state.manage.navBar
    },
    stopNavHover(){
      return this.$store.state.manage.stopBarHover
    },
    imgProgress(){
      return this.$store.state.app.imgProgress
    },
    isCollapse(){
      return this.$store.state.manage.navBar
    },
  },
  methods:{
    changeNav(){
      this.$store.commit('manage/changeNavBar', !this.navBar);
      this.$store.commit('manage/stopBarHover', !this.stopNavHover);

      if(localStorage.getItem('lefNavBar') === 'true'){
        localStorage.setItem('lefNavBar','false')
      }
      else {
        localStorage.setItem('lefNavBar','true')
      }

      if(localStorage.getItem('stopBarHover') === 'true'){
        localStorage.setItem('stopBarHover','false')
      }
      else {
        localStorage.setItem('stopBarHover','true')
      }
    },
  },
};
</script>
<style scoped lang="scss">
.loadingBox{
  z-index: 99999;
  position:absolute;
  bottom: 0;
  right: 0;
  background: #ffffff;
  opacity: 0.8;
  //height: calc(100vh - 50px);
  //width: calc( 100% - 250px);
	height: 100%;
	width: 100%;
  &.isCollapse{
    width: calc( 100% - 64px);
  }
}
.pageWrap {
  position: relative;
    height: 100%;
  .uploadList{
    overflow: hidden auto;
    position: fixed;
    right: 10px;
    bottom: 10px;
    max-height: 300px;
    z-index: 1000;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    min-width: 300px;
    box-shadow: 2px 2px 2px 2px rgba(0,0,0,.15);
    .upload-item{
      display: flex;
      align-items: center;
      .name{
        max-width: 80px;
        margin-right: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

    .left-aside {
        overflow: hidden;
        position: relative;
        width: 250px !important;
      height: 100%;
        &.isCollapse{
          width: auto !important;
        }
    }

  .fold-icon{
    position: absolute;
    height: 230px;
    top: 50%;
    transform: translateY(-50%);
    right: -9px;
    width: 9px;
    background: #dedbdb;
    display: flex;
    align-items: center;
    justify-content: center;
    //border-radius: 0 8px 8px 0;
    z-index: 1;
    cursor: pointer;
    i{
      font-size: 10px;
      color: #ffffff;
      &.arrow{
        transform: rotate(180deg);
      }
    }
  }


  .el-header {
        background-color: #242F42;
        padding: 0;
    }

    .contentWrap {
        padding: 0;
    }
}


</style>
