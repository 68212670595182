<template>
	<div class="color-item" :class="{ activeChild:spanIndex.includes(index),}" 
	:title="colorItem.typeName" @click="addMove(colorItem,index)" :index="index">
		<div class="color-inner-rect" :class="{isWhite:colorRgb(colorItem.code)==='rgb(255,255,255)'}" 
		:style="{ backgroundColor: colorItem.code }">
			<span class="contentClass">{{colorItem.pantone}}</span>
		</div>
	</div>
</template>

<script>
  import {
  colorRgb,
  } from "@/utils/utils";
	export default {
		props: ["colorItem",  'moveCategory', 'index', 'spanIndex'],
		data() {
			return {}
		},
		methods: {
			colorRgb(code){
			return colorRgb(code);
			},
			addMove(colorItem, index) {
				if (this.moveCategory) {
					let arrIndex = this.spanIndex.indexOf(index);
					if (arrIndex > -1) {
						this.spanIndex.splice(arrIndex, 1);
					} else {
						this.spanIndex.push(index);
					}
					this.$bus.$emit('addMove', colorItem);
				}
			}
		},
		mounted() {}
	};

</script>

<style scoped lang="scss">
	.color-item {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 7rem;
		height: 2.5rem;
		aspect-ratio: 1;
		background: #ffffff;
		border: 1px solid #e6e6e6;
		border-radius: 4px;
		cursor: pointer;
		font-size: 0;

		&:hover {
			border: 1px solid #2A96FA;
			box-shadow: 0 0 1px 1px #2A96FA;
		}

		&.activeChild {
			box-shadow: 0 0 2px 2px #2A96FA;
			border: 1px solid #2A96FA;
		}


		.color-inner-rect {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 98%;
			height: 96%;
			border-radius: 4px;
			
			.contentClass {
			width: 60px;
			text-align: center;
			font-size: 14px;
			color: #ffffff;
			}

			&.isWhite {
				.contentClass {
					color: #333333;
				}
			}


			.handleIcon {
				color: #2A96FA;
				font-size: 20px;
			}

		}

		@media screen and (max-width: 768px) {
			width: 34px;
			height: 34px;
		}
	}

</style>
