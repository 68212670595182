<template>
	<div>
		<div class="changeElement" v-if="device !== 'mb'" @click.self="closeDialog()">
			<div class="updateEleDialog" :style="{ width: width, maxWidth: width }">
				<slot name="closeIcon">
					<i class="el-icon-close" @click.stop="closeDialog()"></i>
				</slot>
				<div class="diaLogHead">
					<div class="allCliparts" :class="{ active: showBtn }" @click="showCliparts">Cliparts Gallery</div>
					<div class="myClipart" :class="{ active: !showBtn }" v-show="isLogin" @click="showMyCli">My Cliparts</div>
				</div>
				<div class="replaceDialogCon">
					<div style="padding: 10px" v-show="showBtn">
						<search-input v-model="searchArtValue" :extend-list="artState ? artRecordList : artExtendList" @input="inputArtValue" @search="searchArt" @focus="artExtend"> </search-input>
					</div>
					<div class="artContent" v-show="showBtn" v-loadmore="loadMoreArt" :infinite-scroll-disabled="disabledLoadReplaceArt">
						<div class="upload-box" v-loading="loadReplaceUpload">
							<div class="plus">
								<b class="icon-a-icon-addzhuanhuan"></b>
							</div>
							<span>Upload & Replace </span>
							<input type="file" ref="userFileInput" v-loading="uploadLoading" accept="image/jpeg,image/png" @change="handleReplaceFileUpload" />
						</div>
						<div class="art-item" v-for="(item, index) in replaceArtList" :key="index">
							<cliparts
								:item="item"
								:clipartId="item.id"
								:showLoveBtn="1"
								:isCollection="item.isCollection"
								:clipartImage="item.clipartImage"
								:clipartName="item.elementName"
								@addImg="replaceImg"
								@addArtFavorite="addArtFavorite"
							>
							</cliparts>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-drawer v-else :visible.sync="internalDialog" :with-header="false"  direction="btt" custom-class="templateCategoryDrawerWrap drawerWrap">
			<div class="diaLogHead">
				<div class="allCliparts" :class="{ active: showBtn }" @click="showCliparts">Cliparts Gallery</div>
				<div class="myClipart" :class="{ active: !showBtn }" v-show="isLogin" @click="showMyCli">My Cliparts</div>
				<i class="el-icon-close" @click.stop="internalDialog = false"></i>
			</div>
			<div class="replaceDialogCon">
				<div class="artContent" v-show="showBtn" v-loadmore="loadMoreArt" :infinite-scroll-disabled="disabledLoadReplaceArt" scrollbar>
					<div class="upload-box" v-loading="loadReplaceUpload">
						<div class="plus">
							<b class="icon-a-icon-addzhuanhuan"></b>
						</div>
						<span>Upload & Replace</span>
						<input type="file" ref="userFileInput" v-loading="uploadLoading" accept="image/jpeg,image/png" @change="handleReplaceFileUpload" />
					</div>
					<div class="art-item" v-for="(item, index) in replaceArtList" :key="index">
						<cliparts
								:item="item"
								:clipartId="item.id"
								:showLoveBtn="1"
								:isCollection="item.isCollection"
								:clipartImage="item.clipartImage"
								:clipartName="item.elementName"
								@addImg="replaceImg"
								@addArtFavorite="addArtFavorite"
							>
							</cliparts>
					</div>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import cliparts from "@/components/MyDzxt/cliparts.vue"
import { replaceClipart, favoriteClipart, likeQuery, likeAllString, getArtList } from "@/api/newDzxt";
import searchInput from "@/components/MyDzxt/searchInput.vue";
import { uploadFile } from "@/utils/oss";
import dzMixin from "@/mixins/dzMixin";
export default {
	name: "changeElement",
	components: { cliparts,searchInput},
	mixins: [dzMixin],
	props: {
		width: {
			type: [String, Number],
			default: "50%",
		},
	},
	data() {
		return {
			showBtn: true,
			loadReplaceUpload: false,
			loadingReplaceArt: false,
			drawerHeight: '50%',
			uploadLoading: false,
			internalDialog: this.$store.state.design.showReplaceDialog,
			artId: -1,
			replaceArtList: [],
			replaceArtForm: {
				page: 1,
				pageSize: 20,
				total: 0,
				pages: 1,
			},
			artForm: {
				proId:this.$store.state.design.defaultProId,
				language:"en-us",
				page: 1,
				pageSize: 60,
				clipartTypeId: null,
				total: 0,
				pages: 1,
				likeQuery: "",
			},
			loadingArt: false,
			clipartTypeId: null,
			searchArtValue: "",
			artExtendList: [],
			artRecordList:[],
			artState:false,
			artTagList: [],
			artList: [],
			repOrArt: true,
		};
	},
	watch: {
		'$store.state.design.showReplaceDialog'(newValue) {
			if (this.device == "mb") {
				this.internalDialog = newValue;
			}
    },
		internalDialog(newValue, oldValue) {
			if (this.device == "mb") {
				if (oldValue == true && newValue == false) {
					this.closeDialog();
				}
			}
		},
	},
	computed: {
		userId() {
			return this.$store.state.userInfo?.id;
		},
		isLogin() {
			return this.$store.getters.isLogin;
		},
		device() {
			return this.$store.state.device;
		},
		isMobile() {
			return this.$store.state.device === "mb";
		},
		noMoreReplaceArt() {
			return this.replaceArtForm.page >= this.replaceArtForm.pages;
		},
		noMoreArt() {
			return this.artForm.page >= this.artList.pages;
		},
		disabledLoadReplaceArt() {
			return this.repOrArt ? this.loadingReplaceArt || this.noMoreReplaceArt : this.loadingArt || this.noMoreArt;
		},
		// showReplaceDialog(){
		// 	return this.$store.state.design.showReplaceDialog
		// }
	},
	methods: {
		closeDialog() {
			this.showBtn = true;
			this.replaceArtForm = {
				page: 1,
				pageSize: 20,
				total: 0,
				pages: 1,
			};
			this.$store.commit("design/set_Replace", false);
		},
		handleReplaceFileUpload(e) {
			let file = e.target.files[0];
			this.loadReplaceUpload = true;
			uploadFile(file,{
                systemName:'customOss'
            })
				.then(async (res) => {
					this.$store.commit("design/set_loading", true);
					this.replaceImg({src:res, property:0});
					this.$store.commit("design/set_loading", false);
				})
				.finally(() => {
					this.loadReplaceUpload = false;
				});
		},

		async replaceImg({src, property}) {
			this.$store.commit("design/set_loading", true);
			this.closeDialog();
			let selectedEle = await this.canvas.replaceImg(src, { artId: property });
			this.$Bus.$emit("triggerActiveItem", selectedEle);
			this.canvas.saveStateToHistory();
			this.$store.commit("design/set_loading", false);
		},
		addArtFavorite(item) {
			if (!this.isLogin) {
				this.$store.commit("setMask", "login");
				return false;
			}
			favoriteClipart({
				clipartId: item.id,
				language: "en-us"
			}).then((res) => {
				item.isCollection = item.isCollection ? false : true;
			});
		},
		showCliparts() {
			this.showBtn = true;
		},
		showMyCli() {
			this.showBtn = false;
		},
		//获取可替换元素列表
		getReplaceArtList(type) {
			this.repOrArt = true;
			this.loadingReplaceArt = true;
			replaceClipart(
				Object.assign({}, this.replaceArtForm, {
					id: this.artId,
					userId:this.userId,
					language: "en-us"
				})
			).then((res) => {
				if (type === "scroll") {
					this.replaceArtList = this.replaceArtList.concat(res.data.records);
					this.replaceArtForm.total = res.data.total;
					this.replaceArtForm.pages = res.data.pages;
					this.loadingReplaceArt = false;
				} else if (type === "select") {
					this.replaceArtList = res.data.records;
					this.replaceArtForm.total = res.data.total;
					this.replaceArtForm.pages = res.data.pages;
					this.loadingReplaceArt = false;
				}
				if (this.replaceArtList.length == 0) {
					this.repOrArt=false
					this.artList = [];
					this.artForm.page = 1;
					this.artForm.clipartTypeId = null;
					this.artForm.likeQuery = "";
					this.getArtList();
				}
			});
		},
		loadMoreArt() {
			if (!this.artId||!this.repOrArt) {
				this.artForm.page++;
				this.getArtList("scroll");
			} else {
				this.replaceArtForm.page++;
				this.getReplaceArtList("scroll");
			}
		},
		getArtList(type = "select") {
			this.loadingArt = true;
			this.repOrArt = false;
			if (this.artForm.likeQuery) {
				likeQuery(
					Object.assign({}, this.artForm, {
						userId: this.userId,
					})
				).then((res) => {
					if (type === "scroll") {
						let list = res.data.collectionList;
						this.artList = this.artList.concat(list);
						this.artForm.total = res.data.total;
						this.artForm.pages = res.data.pages;
						this.loadingArt = false;
					} else if (type === "select") {
						this.artList = res.data.collectionList;
						this.artForm.total = res.data.total;
						this.artForm.pages = res.data.pages;
						this.loadingArt = false;
					}
					this.replaceArtList = this.artList;
				});
			} else {
				getArtList(
					Object.assign({}, this.artForm, {
						userId: this.userId,
					})
				).then((res) => {
					if (type === "scroll") {
						let list = res.data.records;
						this.artList = this.artList.concat(list);
						this.artForm.total = res.data.total;
						this.artForm.pages = res.data.pages;
						this.loadingArt = false;
					} else if (type === "select") {
						this.artList = res.data.records;
						this.artForm.total = res.data.total;
						this.artForm.pages = res.data.pages;
						this.loadingArt = false;
					}
					if (!this.artId||!this.repOrArt) {
						this.replaceArtList = this.artList;
					}
				});
			}
		},
		inputArtValue(val) {
			if (val && this.artTagList.length > 0) {
				if (val.trim().length == 1) {
					this.artExtend(true);
					return;
				}
				const regex = new RegExp(`^${val}`, "gi");
				this.artExtendList = this.artTagList
					.filter((item) => item.match(regex))
					.map((item) => {
						return {
							value: item,
							icon: false,
						};
					});
				this.artState = false;
			} else {
				this.artExtend(true);
				this.searchArt(val)
				this.artExtendList = [];
			}
		},
		searchArt(val) {
			this.setLocalStorage("artRecord", val);
			if (!val && this.artId) {
				this.replaceArtForm.page = 1;
				this.getReplaceArtList("select");
				return;
			}
			this.artList = [];
			this.artForm.page = 1;
			this.artForm.clipartTypeId = null;
			this.artForm.likeQuery = val;
			this.getArtList();
		},
	},
	mounted() {
		likeAllString().then((res) => {
			this.artTagList = res.data;
		});
		this.artId = this.canvas.getArtId();
		console.log(this.artId);
		if (!!this.artId) {
			this.getReplaceArtList("select");
		} else {
			this.getArtList("select");
		}
	},
};
</script>

<style scoped lang="scss">
@import "@/assets/css/dzxt.scss";
</style>
<style scoped lang="scss">
.changeElement {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
	z-index: 2000;
	text-align: center;
	white-space: nowrap;
	overflow: auto;
	&:after {
		content: "";
		height: 100%;
		display: inline-block;
		vertical-align: middle;
	}
	.updateEleDialog {
		width: 50%;
		min-height: 300px;
		display: inline-block;
		vertical-align: middle;
		position: relative;
		margin: 0 auto;
		background: #fff;
		border-radius: 6px;
		box-shadow: 1px 2px 20px 0 rgba(0, 0, 0, 0.2);
		border: 1px solid transparent;
		.el-icon-close {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 24px;
			font-weight: 700;
			position: absolute;
			top: 8px;
			right: 8px;
			width: 40px;
			height: 40px;
			cursor: pointer;
			// background: #ffffff;
			// box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
			border-radius: 50%;
			z-index: 10;
		}

		@media screen and (max-width: 767px) {
			.el-icon-close {
				top: 20px;
				width: 24px;
				height: 24px;
				font-size: 22px;
			}
		}
	}
	.diaLogHead {
		cursor: pointer;
		position: relative;
		height: 56px;
		background: #f4f4f4;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		// gap: 10px;
		.allCliparts,
		.myClipart {
			position: relative;
			width: 40%;
			height: 46px;
			background: transparent;
			border-radius: 10px 10px 0px 0px;
			text-align: center;
			line-height: 46px;
			font-size: 18px;
			color: #333333;

			&:hover {
				background-color: #fff;
			}
			&::after {
				display: none;
				content: "";
				position: absolute;
				left: 50%;
				bottom: 5px;
				width: 62px;
				height: 2px;
				background-color: #2a96fa;
				transform: translateX(-50%);
			}
			&.active {
				font-weight: 700;
				background: #ffffff !important;
				&::after {
					display: block;
				}
			}
		}
		@media screen and (max-width: 768px) {
			padding: 5px 5px 0;
			& > div {
				// padding: 1rem;
				border-radius: 5px 5px 0 0;
				font-size: 14px !important;
				font-weight: 700;
			}
		}
	}
}
.replaceDialogCon {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	background-color: #ffffff !important;
	padding: 10px;

	.artContent {
		overflow: hidden auto;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-row-gap: 10px;
		grid-column-gap: 10px;
		align-content: flex-start;
		padding: 10px 10px 0;
		background-color: #ffffff;
		height: 600px;

		.upload-box {
			overflow: hidden;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background: #ffffff;
			border: 1px dashed #cccccc;
			aspect-ratio: 1;

			&:hover {
				border-color: #2a96fa;
			}

			input {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				opacity: 0;
				cursor: pointer;
			}

			.plus {
				font-size: 38px;
			}
		}

		.art-item {
			position: relative;
			min-width: 0;
			aspect-ratio: 1;
			cursor: pointer;

			.imgWrap {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 100%;
				padding: 10px;
			}

			&:hover {
				.list-item-btn {
					opacity: 1;
				}
			}
		}
	}
	.myArtContent2 {
		height: 600px;
	}

	@media screen and (max-width: 487px) {
		border-radius: 5px;
		.artContent {
			grid-template-columns: repeat(3, 1fr);
			grid-row-gap: 5px;
			grid-column-gap: 5px;
			align-content: flex-start;
			padding: 5px 5px 0;
			height: 500px;

			.upload-box {
				.plus {
					font-size: 19px;
				}
			}
		}
		.myArtContent2 {
			height: 500px;
		}
	}
}
.drawerWrap {
	.diaLogHead {
		cursor: pointer;
		position: relative;
		height: 56px;
		background: #f4f4f4;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		// gap: 10px;
		.allCliparts,
		.myClipart {
			position: relative;
			width: 40%;
			height: 46px;
			background: transparent;
			border-radius: 10px 10px 0px 0px;
			text-align: center;
			line-height: 46px;
			font-size: 18px;
			color: #333333;

			&:hover {
				background-color: #fff;
			}
			&::after {
				display: none;
				content: "";
				position: absolute;
				left: 50%;
				bottom: 5px;
				width: 62px;
				height: 2px;
				background-color: #2a96fa;
				transform: translateX(-50%);
			}
			&.active {
				font-weight: 700;
				background: #ffffff !important;
				&::after {
					display: block;
				}
			}
		}
		.el-icon-close {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 24px;
			font-weight: 700;
			position: absolute;
			top: 12px;
			right: 8px;
			width: 24px;
			height: 24px;
			font-size: 22px;
			cursor: pointer;
			// background: #ffffff;
			// box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
			border-radius: 50%;
			z-index: 10;
		}
		@media screen and (max-width: 768px) {
			padding: 5px 5px 0;
			& > div {
				// padding: 1rem;
				border-radius: 5px 5px 0 0;
				font-size: 14px !important;
				font-weight: 700;
			}
		}
	}
}
</style>
